// actions/cartActions.js
import { callApi } from '../utils/Api';
import { ADD_TO_CART, REMOVE_FROM_CART,FETCH_CART_REQUEST,FETCH_CART_SUCCESS,FETCH_CART_FAILURE } from './actionType';

export const addtoCart = (product) => ({
  type: ADD_TO_CART,
  payload: product,
});

export const removeFromCart = (productId) => ({
  type: REMOVE_FROM_CART,
  payload: productId,
});
export const fetchCartRequest=()=>{
  return{
    type:FETCH_CART_REQUEST
  }
}
export const fetchCartSuccess=(carts)=>{
  return{
    type:FETCH_CART_SUCCESS,
    payload:carts
  }
}
export const fetchCartFailur=(error)=>{
  return{
    type:FETCH_CART_FAILURE,
    payload:error
  }
}


//Fetching Cart Data of users
export const fetchUserCart=(userId)=>{
  return (dispatch)=>{
    dispatch(fetchCartRequest)
    callApi("post", "cart/cartcrud/getCartData", {
      user: userId,
    }).then(res=>{
      const cartData=res.data;
      dispatch(fetchCartSuccess(cartData));
    }).catch(error=>{
      const errors=error;
      dispatch(fetchCartFailur(errors));
    })
  }
}
