import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
export default function AddressRequire({addressRequireModal,toggleAddressAvailable,closeAddressAvailable,args}) {
    return (
        <>
          <Modal
            isOpen={addressRequireModal}
            toggle={toggleAddressAvailable}
            {...args}
          >
            <ModalHeader
              toggle={toggleAddressAvailable}
              close={closeAddressAvailable}
            >
              Address Required
            </ModalHeader>
            <ModalBody>
              <div class="shadow-sm p-2 bg-white rounded">
                <div class="card-body mt-3 ml-3 mb-2">
                  <ul>
                    <li>
                      <p>
                      We regret to inform you that the selected item is not available for delivery to your unspecified location.
                      </p>
                    </li>
                    <li>
                      <p>
                        Please add an Address,  ensure it is set as the default address.
                      </p>
                    </li>
                  </ul>
                  {/* <p>Have a question?</p>
                    <p>For further information</p> */}
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      );

}
