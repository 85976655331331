import { callApi } from "../utils/Api";
import { ADD_TO_USER_CANCLE_ORDER,REMOVE_FROM_USER_CANCLE_ORDER,FETCH_USER_CANCLE_ORDER_REQUEST,FETCH_USER_CANCLE_ORDER_SUCCESS,FETCH_USER_CANCLE_ORDER_FAILURE } from "./actionType";

export const addToUserCancleOrder = (data)=>({
    type: ADD_TO_USER_CANCLE_ORDER,
    payload: data,
});
export const reomveFromUserCancleOrder = (returnId)=>({
    type: REMOVE_FROM_USER_CANCLE_ORDER,
    payload: returnId
});
export const fetchUserCancleOrderRequest = ()=>{
    return{
        type: FETCH_USER_CANCLE_ORDER_REQUEST
    }
};
export const fetchUserCancleOrderSuccess=(data)=>{
    return{
        type: FETCH_USER_CANCLE_ORDER_SUCCESS,
        payload: data,
    }
}
export const fetchUserCancleOrderFailur=(error)=>{
    return{
        type: FETCH_USER_CANCLE_ORDER_FAILURE,
        payload: error
    }
}


//Fetch User Visited Data
export const fetchUserCancleOrders =(userId,returnOrderId)=>{
    return (dispatch)=>{
        dispatch(fetchUserCancleOrderRequest)
        callApi('post','returnOrder/returnCurd/cancleOrderById',{userId:userId,returnOrderId:returnOrderId})
        .then(res=>{
            dispatch(fetchUserCancleOrderSuccess(res.data))
        }).catch(error=>{
            dispatch(fetchUserCancleOrderFailur(error))
        })
    }
}