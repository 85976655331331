/* eslint-disable */
import React, { useState } from "react";
import PageTitle from "../components/PageTitle";
import Footer from "../layouts/Footer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SimilarProductListing from "../components/SimilarProductListing";
import dateFormat from "dateformat";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { callApi } from "../utils/Api";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import axios from "axios";
import Payment from "./Payment";
import { paramCase } from "change-case";
import PriceDetails from "./CartComponet/PriceDetails";
import ConvenienceFeeModal from "./CartComponet/ConvenienceFeeModal";
import ItemUnavailableForYourPinCode from "./CartComponet/ItemUnavailableForYourPinCode";
import AddressRequire from "./CartComponet/AddressRequire";
import { fetchUserCart } from "../actions/cartActions";
import { useDispatch,useSelector } from "react-redux";
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css';
import LoadingOverlay from "react-loading-overlay";
var ObjectId = mongoose.Types.ObjectId;

function Cart(args) {
  const [loader,setLoader]=useState(false)
  const userReducCart=useSelector(state=>state.cart)
  const addingCarts=userReducCart?.cart||[];
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const toggle = () => setModal(!modal);
  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);
  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);
  const [modal4, setModal4] = useState(false);
  const toggle4 = () => setModal4(!modal4);
  const [modal5, setModal5] = useState(false);
  const toggle5 = () => setModal5(!modal5);
  const toggle1 = () => setModal1(!modal1);
  const [productCategory, setProcductCategory] = useState("");
  const [convenieceFeeModal, setConvenieceFeeModal] = useState(false);
  const toggleConvenieceFeeModal = () =>
    setConvenieceFeeModal(!convenieceFeeModal);
  const [isPlaceOrderButtonDisabled,setIsPlaceOrderButtonDisabled]=useState(false)
  // all state variables
  const dispatch=useDispatch();
  //bankoffer state variable
  const [bankOffer, setBankOffer] = useState([]);
  const [bankOfferCheck, setBankOfferCheck] = useState(false);
  const [BankOfferData, setBankOfferData] = useState([]);

  function calculateAdjustedDate(dateString) {
    if (!dateString) return '';
  
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '';
  
    date.setDate(date.getDate() + 5);
    return date.toISOString().split('T')[0]; 
  }
  
  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '';
  
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  }

  //address state variable
  const [name, setName] = useState("");
  const { id } = useParams();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [PinCode, setPinCode] = useState("");
  const [address, setAddress] = useState("");
  const [locality, setLocality] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [addressType, setAddressType] = useState("");
  const [defalutAddress, setDefalutAddress] = useState(false);
  const [cartProduct, setCartProduct] = useState(addingCarts);
  const [allAddress, setAllAddress] = useState([]);
  const [status, setStatus] = useState(1);
  const [productWeight, setProductWeight] = useState(0);
  const [productBreadth, setProductBreadth] = useState(0);
  const [productHeight, setProductHeight] = useState(0);
  const [productLength, setProductLength] = useState(0);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [availableForPinModal, setAvailableForPinModal] = useState(false);
  const [isUpdatingPrice, setIsUpdatingPrice] = useState(false); 

  //product state variable
  const [productList, setProductList] = useState([]);
  const [product, setProduct] = useState({});
  // console.log("Product********", product)

  //price calculation state variable
  const [priceCalculation, setPriceCalculation] = useState({});

  //default address state variable
  const [defaultAddress, setDefaultAddress] = useState({
    Name: "",
    phoneNumber: "",
    PinCode: "",
    Address: "",
    Locality: "",
    City: "",
    Country: "",
    State: "",
    AddressType: "",
    isActive: false,
    isDefault: false,
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 768 && window.innerWidth <= 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsMediumScreen(window.innerWidth > 768 && window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const buttonStyle = {
    border: "1px solid #ff0000",
    backgroundColor: "transparent",
    color: "#ff0000",
    fontSize: isMobile ? "8px" : "12px",
    textDecoration: "none",
    marginRight: isMobile ? "-15px" : "-55px",
    padding: isMobile ? "10px 15px" : "14px 24px",
    marginTop: isMobile ? "-5px" : "-15px",
  };

  //get specific product state variable
  const [productUpdateId, setProductUpdateId] = useState(null);
  const [specificProduct, setSpecificProduct] = useState([]);
  const [productQuantity, setProductQuantity] = useState(null);
  const [productSize, setProductSize] = useState("");
  const [productColor, setProductColor] = useState("");
  const [selectedColorImages, setSelectedColorImages] = useState([]);
  const [productId, setProductId] = useState(null);
  const [addressId, setAddressId] = useState("");
  const [paymentData, setPaymentData] = useState(false);
  const [courierPrice, setCourierPrice] = useState();
  //update address state variable
  const [uAddress, setUAddress] = useState({
    Name: "",
    phoneNumber: "",
    PinCode: "",
    Address: "",
    Locality: "",
    City: "",
    State: "",
    Country: "",
    AddressType: "",
    isDefault: false,
  });
  //coupon code state variable
  const [searchCouponCode, setSearchCouponCode] = useState("");
  const [checkCoupon, setCheckCoupon] = useState('');
  const [checkCouponCodeDel, setCheckCouponCodeDel] = useState("");
  const [couponDiscount, setCouponDiscount] = useState("");
  const [couponCodeMsg, setCouponCodeMsg] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [pincodeDetails, setPincodeDetails] = useState(null);
 

  //couppon code state variable
  const [couponCode, setCouponCode] = useState("");
  const [check, setCheck] = useState("");
  const [checkData, setCheckData] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState("");
  const [selectedCourierPrice, setSelectedCourierPrice] = useState("");
  //get service charge state variable
  const [serviceCharge, setServiceCharge] = useState([]);
  const [availableForPin,setAvailableForPin]=useState(true)
  const [itemUnableToDelivar,setItemUnableToDelivar]=useState([]);
  const [item, setItem] = useState(null); 
  const [deliveryAndRefundDetails, setDeliveryAndRefundDetails] = useState([]);
  const [addressRequireModal,setAddressRequireModel]=useState(false)
  const [hasDefaultAddress,setHasDefaulltAddress]=useState(false)
  const [hasCartData,setHasCartData]=useState(false)
  const [delivery_pincode,setDelivery_pincode]=useState("")
  const toggleAddressAvailable=()=>
  setAddressRequireModel(!addressRequireModal)
  const toggleAvailableForPin = () =>
    setAvailableForPinModal(!availableForPinModal);
  
  // console.log("Unavalable Delivery",itemUnableToDelivar);
  // api call for bank offer data
  useEffect(() => {
    callApi("post", "set/settings/getAllBankOffer")
      .then((res) => {
        let arr = [];
        res.data.forEach((item) => {
          arr.push(item.bankOfferDetails);
        });        
        setBankOfferData(arr);
        setBankOffer(arr.slice(0, 2));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //show more and show less button for bank offer
  const showMoreBankOffer = (e) => {
    e.preventDefault();
    setBankOfferCheck(true);
    setBankOffer(BankOfferData);
  };
  const showLessBankOffer = (e) => {
    e.preventDefault();
    setBankOfferCheck(false);
    setBankOffer(BankOfferData.slice(0, 2));
  };
  useEffect(() => {
    setBankOffer(BankOfferData.slice(0, 2));
  }, []);

  // navigation to listing page
  let navigate = useNavigate();
  const moveToListingAll = () => {
    navigate("/listing");
  };
  if(!localStorage.getItem('userid')){
    navigate("/login")
  }
  // const payment = () => {
  //   placeOrder()
  //   // navigate("/payment");
  // };

  //api call for add address data
  const addAddresshandel = (e) => {
    e.preventDefault();

    // Detailed validation function
  const validateForm = () => {
    let isValid = true;
    let errorMessage = "";

    // Check each field and set appropriate error messages
    if (!name) {
      errorMessage += "Name Field is required. ";
      isValid = false;
    }
    if (!phoneNumber) {
      errorMessage += "Phone Number Field is required. ";
      isValid = false;
    } else if (phoneNumber.length !== 10) {
      errorMessage += "Phone Number must be 10 digits. ";
      isValid = false;
    }
    if (!PinCode) {
      errorMessage += "Pincode Field is required. ";
      isValid = false;
    }
    if (!address) {
      errorMessage += "Address Field is required. ";
      isValid = false;
    }
    if (!locality) {
      errorMessage += "Locality Field is required. ";
      isValid = false;
    }
    if (!city) {
      errorMessage += "City Field is required. ";
      isValid = false;
    }
    if (!state) {
      errorMessage += "State Field is required. ";
      isValid = false;
    }
    if(!country){
      errorMessage += "Country Field is required. ";
      isValid = false;
    }
    if (!addressType) {
      errorMessage += "Address Type is required. ";
      isValid = false;
    }

    if (!isValid) {
      Store.addNotification({
        title: errorMessage.trim(),
        type: "warning",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 4000,
          onScreen: true,
        },
      });
    }

    return isValid;
  };

  if (!validateForm()) return;

    const data = {
      Name: name,
      phoneNumber: phoneNumber,
      PinCode: PinCode,
      Address: address,
      Locality: locality,
      City: city,
      State: state,
      Country: country,
      AddressType: addressType,
      isActive: false,
      isDefault: defalutAddress,
      user: localStorage.getItem("userid"),
    };
    callApi("post", "cart/cartcrud/addAddress", data)
      .then((res) => {
        // console.log(res);
        setName("");
        setPhoneNumber("");
        setPinCode("");
        setAddress("");
        setLocality("");
        setCity("");
        setState("");
        setCountry("");
        setAddressType("");
        setDefalutAddress(false);
        getAllAddress();
        toggle2()
        if(res.code===200){
          Store.addNotification({
            title: "Address Add Successfully!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }else{
          Store.addNotification({
            title: "Something is Wrong!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }
      })
      .catch((err) => {
        // console.log(err);
        Store.addNotification({
          title: "Something is Wrong!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
              duration: 5000,
              onScreen: true
          }
      });
      });
  };

  //api call for get all cart product
  const getCartProduct = async () => {
    try {
        const userId = localStorage.getItem("userid");
        const response = await callApi("post", "cart/cartcrud/getCartData", { user: userId });

        const res = response.data;

        // Initialize arrays
        const arr = [];
        const categoriesList = [];
        const returnDetailsList = [];

        
        res.forEach((item) => {
            arr.push(item?.product?._id);
            categoriesList.push(item?.product?.productCategory[0].value);

           
            if (item?.deliveryAndRefundDetails) {
                const returnDetail = item.deliveryAndRefundDetails.find(detail => detail.IsReturned);
                if (returnDetail) {
                    returnDetailsList.push({
                        productId: item?.product?._id,
                        returnWithIn: returnDetail.ReturnWithIn
                    });
                }
            }
        });

        // Set state and local storage
        setProductList(arr);
        setCartProduct(res);
        // console.log(res)
        setProcductCategory(categoriesList.slice(-1)[0]);
        localStorage.setItem("DataProductListNumber", JSON.stringify(arr));
        localStorage.setItem("DataProductListNum", JSON.stringify(arr.length));
        localStorage.setItem("cartProductt", JSON.stringify(res));
        localStorage.setItem("returnDetails", JSON.stringify(deliveryAndRefundDetails));

        // Update state for delivery and refund details
        setDeliveryAndRefundDetails(deliveryAndRefundDetails);

        // Check for items that are unavailable for delivery
        setItemUnableToDelivar(res.filter(item => !("estimatedDeliveryDate" in item)));

        // Update cart data availability state
        setHasCartData(res.length > 0);
    } catch (err) {
        console.log(err);
    }
};

  // console.log("Data For Product List", productList)
  //api call for get all Order Of User
  // const getMyOrder = () => {
  //     callApi('post', 'cart/cartcrud/getUserWiseOrder', { user: localStorage.getItem('userid') })
  //     .then((res) => {
  //         let arr = [];
  //         res.data.map((item) => {
  //             console.log("item",item)
  //             arr.push(item.product._id);
  //         })
  //         console.log("arr",arr)
  //         setProductList(arr);
  //         setCartProduct(res.data);
  //     }).catch((err) => {
  //         console.log(err);
  //     })
  // }
  // useEffect(() => {
  //     getMyOrder();
  // }, [])

  // api call for Product Data

  const productData = () => {
    let productData = {};
    if (ObjectId.isValid(productId) && productId !== "") {
      axios
        .post(`${apiUrl}/product/getProduct/getProductByid`, {
          productId: productId,
        })
        .then((response) => {
          console.log(response);
          productData = response.data.data.productData;
          setProduct(productData);
          // setProcductCategory(productData.productCategory[0].value)
          setSpecificationLess(productData.productFeatures.slice(0, 10));
          setSpecificationMore(productData.productFeatures);
          let img = [];
          productData.productImages?.forEach((item, index) => {
            img.push({ src: item.imgurl, width: 4, height: 3 });
          });          
          let size = [];
          let color = [];
          productData.productVarient?.filter((item) => {
            if (item.key == "Size") {
              size.push(item.value);
            } else {
              color.push(item.value);
            }
          });
          setSize(size);
          setColor(color);
          setImg(img);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    // productData();
  }, [productId]);
  useEffect(() => {
    setProductId(id);
  }, [id]);

  // console.log("Product", product)

  //api call for remove cart product
  const removeCartProduct = (id) => {
    let userId=localStorage.getItem("userid")
    callApi("post", "cart/cartcrud/removefromcart", {
      userId: userId,
      cartId: id,
    })
      .then((res) => {
        // console.log(res.data);
        getCartProduct();
        dispatch(fetchUserCart(userId));
        getServiceCharge();
        priceCal();
        if(res.code===200){
          Store.addNotification({
            title: "Item Removed Successfully!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }else{
          Store.addNotification({
            title: "Something is Wrong!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }
      })
      .catch((err) => {
        // console.log(err);
        Store.addNotification({
          title: "Something is Wrong!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
              duration: 5000,
              onScreen: true
          }
      })
      });
  };

  //api call for remove address
  const removeAddress = (id) => {
    callApi("post", "cart/cartcrud/removeAddress", { addressId: id })
      .then((res) => {
        // console.log(res.data);
        setAllAddress(prevAddresses => prevAddresses.filter(address => address._id !== id));
        getAllAddress();
        if(res.code===200){
          Store.addNotification({
            title: "Address Removed Successfully!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }else{
          Store.addNotification({
            title: "Something is Wrong!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }
      })
      .catch((err) => {
        // console.log(err);
        Store.addNotification({
          title: "Something is Wrong!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
              duration: 5000,
              onScreen: true
          }
      });
      });
  };

  //api call for get all address
  const getAllAddress = () => {
    callApi("post", "cart/cartcrud/getAddress", {
      userId: localStorage.getItem("userid"),
    })
      .then((res) => {
        setAllAddress(res.data);
        res.data.forEach((item) => {
          if (item.isDefault) {
            setDelivery_pincode(item.PinCode)
            // localStorage.setItem("delivery_pincode", item.PinCode);
            setDefaultAddress(item);
            setHasDefaulltAddress(true);
            setAddressRequireModel(false);
          }
        });        
        if(res.data.length===0){
          setHasDefaulltAddress(false)
          setAddressRequireModel(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCartProduct();
    getAllAddress();
  }, [courierPrice,addingCarts]);

  //step progress bar
  const onClickNext = () => {
    // console.log(status, "status next");
    if (status >= 1 && status <= 2) {
      setStatus(status + 1);
    }
  };
  const onClickBack = () => {
    // console.log(status, "status back");
    if (status >= 2 && status <= 4) {
      setStatus(status - 1);
    }
  };

  useEffect(() => {
    const backButton = document.getElementById("back");
    const nextButton = document.getElementById("next");
    if (status === 1) {
      if (backButton) {
        backButton.disabled = true;
        backButton.style.color = "gray";
        backButton.style.pointerEvents = "none";
      }
    } else {
      if (backButton) {
        backButton.disabled = false;
        backButton.style.color = "#ff0000";
        backButton.style.pointerEvents = "auto";
      }
    }
    if (status === 4) {
      if (nextButton) {
        nextButton.disabled = true;
        nextButton.style.color = "gray";
        nextButton.style.pointerEvents = "none";
      }
    } else {
      if (nextButton) {
        nextButton.disabled = false;
        nextButton.style.color = "#ff0000";
        nextButton.style.pointerEvents = "auto";
      }
    }
  }, [status]);

  //api call for price calculation
  const priceCal = () => {
    callApi("post", "cart/cartcrud/cartPriceCalculation", {
      user: localStorage.getItem("userid"),
      couponDiscount: couponDiscount,
      serviceCharge: courierPrice ,
    })
      .then((res) => {
        setPriceCalculation(res.data);
        // console.log("Price calculation From Cart===>>>",res.data)
        localStorage.setItem("CartPrice", JSON.stringify(res.data));
        // getCartProduct();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    priceCal();
  }, [courierPrice,productList]);

  //api call for get specific product
  const getSpecificProduct = (id) => {
    callApi("post", "cart/cartcrud/getCart", { id: id })
      .then((res) => {
        setSpecificProduct(res.data);
        setProductQuantity(res.data[0].quantity);
        setProductSize(res.data[0].size);
        setProductColor(res.data[0].colour);
        setSelectedColorImages(res.data[0].product?.productImages?.[res.data[0].colour] || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    setProductColor(color);
    const colorImages = specificProduct[0]?.product?.productImages?.[color] || [];
    setSelectedColorImages(colorImages);
  };
  // useEffect(() => {
  //     getSpecificProduct(productUpdateId);
  // }, [productUpdateId])

  //api call for update cart product
  const updateCartProduct = (id) => {
    const data = {
      id: id,
      quantity: productQuantity,
      size: productSize,
      colour: productColor,
    };
    callApi("post", "cart/cartcrud/updateCart", data)
      .then((res) => {
        // console.log(res);
        getCartProduct();
        getServiceCharge()
        priceCal();
        toggle5(); 
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //custom close button for modal
  const closeBtn = (
    <button
      className="close"
      style={{ fontSize: "25px" }}
      onClick={toggle5}
      type="button"
    >
      &times;
    </button>
  );
  const closeBtn1 = (
    <button
      className="close"
      style={{ fontSize: "25px" }}
      onClick={toggle}
      type="button"
    >
      &times;
    </button>
  );
  const closeBtn2 = (
    <button
      className="close"
      style={{ fontSize: "25px" }}
      onClick={toggle2}
      type="button"
    >
      &times;
    </button>
  );
  const closeBtn3 = (
    <button
      className="close"
      style={{ fontSize: "25px" }}
      onClick={toggle3}
      type="button"
    >
      &times;
    </button>
  );
  const closeBtn4 = (
    <button
      className="close"
      style={{ fontSize: "25px" }}
      onClick={toggle1}
      type="button"
    >
      &times;
    </button>
  );
  const closeAvailableForPin = (
    <button
      className="close"
      style={{ fontSize: "25px" }}
      onClick={toggleAvailableForPin}
      type="button"
    >
      &times;
    </button>
  );
  const closeConvineanceModal = (
    <button
      className="close"
      style={{ fontSize: "25px" }}
      onClick={toggleConvenieceFeeModal}
      type="button"
    >
      &times;
    </button>
  );
  const closeAddressAvailable=(
    <button
      className="close"
      style={{ fontSize: "25px" }}
      onClick={toggleAddressAvailable}
      type="button"
    >
      &times;
    </button>
  );

  //handel change for input field address update form
  const handelAdddressChange = (e) => {
    const { name, value } = e.target;
    setUAddress({
      ...uAddress,
      [name]: name === "isDefault" && value === "on" ? e.target.checked : value,
    });
  };
  useEffect(() => {}, [uAddress]);

  //api call for update address
  const updateAddress = () => {
    callApi("post", "cart/cartcrud/getAddressByID", { id: addressId })
      .then((res) => {
        // console.log(res);
        setUAddress(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getUpdateId = (id) => {
    toggle3();
    setAddressId(id);
  };
  useEffect(() => {
    updateAddress();
  }, [addressId]);

  //api call for update address data
  const updateAddressData = (e) => {
    e.preventDefault();
    const data = {
      id: addressId.toString(),
      Name: uAddress.Name,
      phoneNumber: uAddress.phoneNumber,
      PinCode: uAddress.PinCode,
      Address: uAddress.Address,
      Locality: uAddress.Locality,
      City: uAddress.City,
      State: uAddress.State,
      Country: uAddress.Country,
      AddressType: uAddress.AddressType,
      isDefault: uAddress.isDefault,
    };
    callApi("post", "cart/cartcrud/editAddress", data)
      .then((res) => {
        // console.log(res);
        getAllAddress();
        toggle3()
        if(res.code===200){
          Store.addNotification({
            title: "Address Updated Successfully!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }else{
          Store.addNotification({
            title: "Something is Wrong!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }
      })
      .catch((err) => {
        // console.log(err);
        Store.addNotification({
          title: "Something is Wrong!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
              duration: 5000,
              onScreen: true
          }
      });
      });
  };
  // Refresh Page
  function refreshPage() {
    window.location.reload(false);
  }

  //   Delete Coupon

  const deleteCoupon = (id) => {
    callApi("post", "set/settings/deleteACupon", { id: id })
      .then((response) => {
        console.log("deleteCoupon", response.data);
        // props.fetchData();
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //api call for coupon code validation
  const applyCouponCode = (e) => {
    e.preventDefault();
    const userId=localStorage.getItem("userid");
    const data = {
      couponCode: checkCoupon,
      totalAmount: priceCalculation.total,
      products: productList,
      userId: userId
    };
    callApi("post", "cart/cartcrud/applayCouponCode", data)
      .then((res) => {
        // console.log("res",res)
        if(res.code===200){
          setCouponDiscount(Math.floor(res.discount))
          // localStorage.setItem('coupon_d',JSON.stringify(Math.floor(res.discount)))
          Store.addNotification({
            title: "Coupon added Successfully!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }
        else {
          setCouponCodeMsg(res.message);
          setCouponDiscount('');
          Store.addNotification({
            title: res.message,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }
      })
      .catch((err) => {
        // console.log(err) 
        Store.addNotification({
          title: "Something is Wrong!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
              duration: 5000,
              onScreen: true
          }
      });

      });
  };

  const handlePincodeChange = async (e) => {
    const pincode = e.target.value;
    setPinCode(pincode);
  
    if (pincode.length === 6) { 
      try {
        const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`);
        const data = response.data[0];
  
        if (data.Status === "Success") {
          const { PostOffice } = data;
          if (PostOffice && PostOffice.length > 0) {
            const { State, District, Country } = PostOffice[0];
            setState(State);
            setCity(District);
            setCountry(Country);
          }
        } else {
          console.error("Invalid Pincode");
        }
      } catch (error) {
        console.error("Error fetching pincode details:", error);
      }
    }
  };
  //apply coupon code button
  // const applyCouponCode = (e) => {
  //   e.preventDefault();
  //   checkCouponCodeValidator(e);
  //   // deleteCoupon();
  //   // console.log("Applied coupon code:", couponCode[0]._id);
  //   setCheckCouponCodeDel(couponCode);
  // };
  const checkCouponCodeValidator=(e)=>{
    e.preventDefault();
    if(searchCouponCode && productList){
      const data={
        products:productList,
        couponCode:searchCouponCode,
        totalAmount:priceCalculation.total
      }
      callApi("post","cart/cartcrud/getCouponByCouponCode",data)
      .then((res)=>{
        setCouponCode(res.data);
      }).catch((error)=>{
        Store.addNotification({
          title: "Something is Wrong!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
              duration: 5000,
              onScreen: true
          }
      });
      })
    }
  }
  useEffect(() => {
    priceCal();
  }, [couponDiscount]);

  //api call for get all coupon code
  const getAllCouponCode = () => {
    callApi("post", "cart/cartcrud/getFillterCoupon", { products: productList })
      .then((res) => {
        setCouponCode(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllCouponCode();
  }, [productList]);
  // console.log("CupDatta for Checking", couponCode)

  //coupon code check button
  const couponcodeCheck = (couponData) => {
    // console.log("couponData",couponData)
    setCheckCoupon(couponData._id);
  };

  //defalut address set button
  const defaultAddressCheck = (e) => {
    e.preventDefault();
    updateDefaultAddress(e.target.value);
  };

  //api call for update default address
  const updateDefaultAddress = (id) => {
    callApi("post", "cart/cartcrud/updateAllAddress", {
      user: localStorage.getItem("userid"),
      id: id,
    })
      .then((res) => {
        getAllAddress();
        getServiceCharge()
        setSelectedCourier("")
        if(res.code===200){
          Store.addNotification({
            title: "Address Updated Successfully!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }else{
          Store.addNotification({
            title: "Something is Wrong!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }
      })
      .catch((err) => {
        // console.log(err);
        Store.addNotification({
          title: "Something is Wrong!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
              duration: 5000,
              onScreen: true
          }
      });
      });
  };
 

  //api call for get service charge
  const getServiceCharge = () => {
    if(delivery_pincode){
    callApi("post", "cart/cartcrud/getDeliveryCharges", {
      user: localStorage.getItem("userid"),
      delivery_postcode: delivery_pincode,
      cod: 0,
      courier_name:selectedCourier,
    })
      .then((res) => {
        // console.log("ServiceCharge=====>", res.data);
        setServiceCharge(res.data);
        localStorage.setItem('serv_char',JSON.stringify(res.data))
        setCourierPrice(res.data.cost) 
        // console.log("res.data.data.some(obj => Object.keys(obj).length === 0)",res.data.data.some(obj => Object.keys(obj).length === 0))
        if (Array.isArray(res.data.data) && res.data.data.some(obj => Object.keys(obj).length === 0)){
          setAvailableForPin(false);
          setAvailableForPinModl(true)
        } 
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };
  useEffect(() => {
      checkHandel()
      getServiceCharge();
  }, [defaultAddress,selectedCourier]);
  const getCourierByPin=()=>{
    if(check.length===6){
      checkHandel()
    }
  }
  const checkHandel = () => {
    const data = {
      user: localStorage.getItem("userid"),
      delivery_postcode: check||delivery_pincode,
      cod: 0,
    };
    if(check||delivery_pincode){
    callApi("post", "cart/cartcrud/getAvailableCourierService", data)
      .then((response) => {
        if(response.data[0]!==null){
          setCheckData([].concat(...response.data));
        }

      })
      .catch((error) => {
        console.log(error);
      });
    }
  };

const [selectedItems, setSelectedItems] = useState(new Set());

  const toggleSelectAll = () => {
    if (selectedItems.size === cartProduct.length) {
      setSelectedItems(new Set()); 
    } else {
      setSelectedItems(new Set(cartProduct.map(item => item._id))); 
    }
  };

  const toggleSelectItem = (id) => {
    const newSelectedItems = new Set(selectedItems);
    if (newSelectedItems.has(id)) {
      newSelectedItems.delete(id);
    } else {
      newSelectedItems.add(id);
    }
    setSelectedItems(newSelectedItems);
  };
  // const getServiceCharge = () => {
  //     const userId = localStorage.getItem('userid');
  //     const pincode = localStorage.getItem('Pincode');

  //     if (userId && pincode) {
  //       callApi('post', 'cart/cartcrud/getDeliveryCharges', {
  //         user: userId,
  //         delivery_postcode: pincode,
  //         cod: 0
  //       })
  //         .then((res) => {
  //           if (res.status === 'success' && res.data && res.data.pincode) {
  //             console.log("ServiceCharge=====>", res);
  //             setServiceCharge(res.data);
  //           } else {
  //             console.log("Invalid response data");
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     } else {
  //       console.log("User ID or Pincode is undefined.");
  //     }
  //   };

  //   useEffect(() => {
  //     if (pincode && defaultAddress) {
  //       getServiceCharge();
  //     }
  //   }, [defaultAddress]);

  // console.log("GetServiceCharge=====>", getServiceCharge);

  useEffect(() => {
    priceCal();
    // const selectedCourierPrice = localStorage.getItem("selectedCourierPrice");
    // setCourierPrice(selectedCourierPrice);
  }, [serviceCharge]);

  let [arrOfId, setArrOfId] = useState([]);

  const setArraofId = () => {
    let arrId = [];
    for (let index = 0; index < cartProduct.length; index++) {
      const element = cartProduct[index];

      arrId.push(element?.product?._id);
    }
    // console.log("test cart => ", arrId)
    setArrOfId(arrId);
  };
  useEffect(() => {
    setArraofId();
  }, [cartProduct]);
  // console.log(priceCalculation);
  const cartProductData = localStorage.getItem("cartProductt");
  const parsedData = JSON.parse(cartProductData);
  // console.log("Cart Product Data with Json",parsedData?.product?.merchant?.merchantId)
  const perProductweightWithPackage =
    parsedData?.product?.perProductweightWithPackage;
  // console.log("Check My Product Weight=====>>>>>", perProductweightWithPackage)
  const [orderData, setOrderData] = useState({});
  const placeOrder = () => {
    setLoader(!loader)
    const userDetails = JSON.parse(localStorage.getItem('UserData'));
    const Mid = parsedData?.product?.merchant?.merchantId
    const userid=localStorage.getItem("userid")
    // console.log("Mid",Mid)
    // console.log("parsedData",parsedData)
    const data = {
      userId:userid ,
      merchantId: Mid,
      cart: cartProductData,
      shipAddress: defaultAddress._id,
      totalMrp: priceCalculation.totalMRP,
      totalAmount: priceCalculation.total,
      totalDiscount: priceCalculation.totalDiscountAmount,
      couponDiscount: priceCalculation?.couponDiscount,
      deliveryCharge: priceCalculation.serviceCharge,
      paymentMethod: "ONLINE",
      productName: parsedData?.product?.productName,
      product: JSON.stringify(parsedData?.product),
      productImage: parsedData?.product?.productImages[0],
      couponUsed: checkCoupon ? true : false,
      couponCode: checkCoupon ||null,
      userId: userid,
      userEmail:userDetails.email,
      userPhoneNumber:userDetails.phoneNumber,
      paymentStatus: "PAYMENT_PROCESSING",
    };
    // console.log("Data",data)
    callApi('post', 'pay/payu/placeOrder', { data })
      .then((res) => {
        // console.log(res.code);
        setOrderData(res);
        setPaymentDetails(res.data);
        if (res.status === "SUCCESS") {
          // const orderId = res.orderId;
          // localStorage.setItem("orderId", orderId);
          // localStorage.setItem("orderData", JSON.stringify(res));
          // console.log("Response Chek", JSON.stringify(res.status))
          // console.log("Response Chek", res.data);
          localStorage.setItem("paymentDetails",JSON.stringify(res.data));
          localStorage.setItem('coupon_d',JSON.stringify(couponDiscount))
          // setStatus(5);
          // history.push('/refund');
           navigate("/payment");
           setLoader(false)
           Store.addNotification({
            title: res.data.message,
            type: "success",
            insert: "bottom",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }
      else if(res.code!==200){
        Store.addNotification({
          title: "Something is wrong ",
          type: "danger",
          insert: "bottom",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
              duration: 5000,
              onScreen: true
          }
      });
      setLoader(false)
      }
      })
      .catch((err) => {
        console.log(err);
        Store.addNotification({
          title: err.error,
          type: "danger",
          insert: "bottom",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
              duration: 5000,
              onScreen: true
          }
      });
      setLoader(false)
      });
      setIsPlaceOrderButtonDisabled(!isPlaceOrderButtonDisabled)
      
  };
  // const placeOrder = () => {
  //     const data = {
  //       userId: localStorage.getItem('userid'),
  //       cart: arrOfId,
  //       shipAddress: defaultAddress._id,
  //       totalMrp: priceCalculation.totalMRP,
  //       totalAmount: priceCalculation.total,
  //       totalDiscount: priceCalculation.totalDiscountAmount,
  //       couponDiscount: priceCalculation?.couponDiscount,
  //       deliveryCharge: priceCalculation.serviceCharge,
  //       paymentMethod: "ONLINE",
  //       couponUsed: checkCoupon ? true : false,
  //       couponCode: checkCoupon,
  //       paymentStatus: "PENDING",
  //     };
  //     axios
  //       .post('url', data)
  //       .then((response) => {
  //         // Redirect to the CCAvenue payment page
  //         window.location.href = response.data.redirect_url;
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   };
  
  const createOrder = () => {
    placeOrder();
  };
  // console.log(orderData);
  const storedAddress = localStorage.getItem("CourierAdd");
  // console.log("Courier Add Check",storedAddress)
  const location = JSON.parse(storedAddress);
  // const pincode = location?.pincode;
  const pincode = localStorage.getItem("Pincode");
  localStorage.setItem("Pincode", location?.pincode);
  // console.log("Add Checking ====>>>>", storedAddress)
  // console.log("Pincode  Checking for Courier ====>>>>", localStorage.getItem('Pincode'))
  // console.log("Pincode value:",location?.pinCode);
  // console.log("Default address:", defaultAddress);
  const disanleNextButtton= !(hasDefaultAddress && hasCartData)
  // console.log("first",cartProduct)

  return (
    <>
      <ReactNotifications />
      <PageTitle />
      <section className="content">
        {cartProduct.length !==0?(        <section className="block">
          <div className="container">
            {/* <div className="section-title clearfix">
                     <div className="float-xl-left float-md-left float-sm-none">
                            <h2 class="text-muted mb-0">CART</h2>
                        </div>
                    </div> */}
            <div className="mt-4"></div>
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <ProgressBar
                  percent={status * 34 - 34}
                  filledBackground="#ff0000"
                  width="100%"
                >
                  <Step transition="scale">
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        {index + 1}
                      </div>
                    )}
                  </Step>
                  <Step transition="scale">
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        {index + 1}
                      </div>
                    )}
                  </Step>
                  <Step transition="scale">
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        {index + 1}
                      </div>
                    )}
                  </Step>
                  <Step transition="scale">
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        {index + 1}
                      </div>
                    )}
                  </Step>
                </ProgressBar>
              </div>
              <div className="col-md-2"></div>
            </div>

            <div className="row">
              <div className="col-md-6 col-6">
                <Button
                  type="submit"
                  color="link"
                  className="float-right"
                  id="back"
                  style={{ color: "#ff0000" }}
                  onClick={onClickBack}
                >
                  Prev
                </Button>
              </div>
              <div className="col-md-6 col-6">
                <Button 
                  disabled={disanleNextButtton}
                  type="submit"
                  color="link"
                  id="next"
                  style={{ color: "#ff0000" }}
                  onClick={onClickNext}
                >
                  Next
                </Button>
              </div>
            </div>
            

            {(() => {
              switch (status) {
                case 1:
                  return (
                    <div className="row">
                      <div className="col-md-8 col-12">
                        <div className="card shadow-sm p-2 rounded" style={{ padding: "1rem", backgroundColor:  "#fdecea", borderRadius: "8px" }} >
                        <div className="card-body mt-3 ml-3" >
                            <div className="row">
                              <div className="col-md-10 col-10">
                                
                                {defaultAddress.isDefault === true ? (
                                  <>
                                    <h4 className="head-p">
                                      <span style={{ fontWeight: "300" }}>
                                        Deliver to:
                                      </span>{" "}
                                      <span style={{fontWeight: "600"}}>{defaultAddress.Name}
                                      (
                                        {defaultAddress.AddressType})
                                      </span>
                                    </h4>
                                    <h4 className="head-main">
                                      {defaultAddress.Locality},{" "}
                                      {defaultAddress.Address},
                                      {defaultAddress.City} ,
                                      {defaultAddress.Country} ,
                                      {defaultAddress.State} -
                                      {defaultAddress.PinCode}
                                    </h4>
                                  </>
                                ) : (
                                  <h4 className="add-address-warning">
                                    Enter your address and Check availability at your location
                                  </h4>
                                )}
                              </div>
                              <div className="col-md-1 col-2">
                                {" "}
                                <Button
                                  className="float-right"
                                  outline
                                  style={buttonStyle}
                                  onClick={toggle}
                                >
                                  {defaultAddress.isDefault === true ? (
                                    <>Change Address</>  
                                  ) : (
                                    <>Check</>
                                  )}
                                </Button>
                              </div>
                            </div>
                            <Modal isOpen={modal} toggle={toggle} {...args}>
                              <ModalHeader toggle={toggle} close={closeBtn1}>
                                <h4 className="payment-header-price">
                                  Change Delivery Address
                                </h4>
                              </ModalHeader>
                              <ModalBody>
                                <div className="card shadow-sm p-2 bg-white rounded">
                                  <div className="card-body mt-3 ml-3 mb-2">
                                    <form className="form clearfix">
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          value={check}
                                          onChange={(e) =>
                                            setCheck(e.target.value)
                                          }
                                          className="form-control"
                                          placeholder="Enter Pincode"
                                          aria-label="Recipient's username"
                                          aria-describedby="basic-addon2"
                                        />
                                        <div className="input-group-append">
                                          <button
                                            className="btn btn-outline-secondary"
                                            onClick={getCourierByPin}
                                            style={{
                                              color: "red",
                                              backgroundColor: "transparent",
                                            }}
                                            type="button"
                                          >
                                            Check
                                          </button>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-4 col-12"></div>
                                      </div>
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        Please enter PIN code to check delivery
                                        time & Pay on Delivery Availability
                                      </span>
                                      {check ? (
                                        <>
                                          {checkData ? (
                                            <>
                                              {checkData.map((x,index) => {
                                                return (
                                                  <>
                                                    {/* <h4 className='mt-4' style={{ fontSize: "14px", fontWeight: "500" }}><input type="radio" className='mr-3' id="html" name="fav_language" value="HTML" />Estimate Delivery Time: <span className='text-success'>{x?.etd}</span> </h4> */}
                                                    <h4
                                                      className="mt-4"
                                                      style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                      }}
                                                      key={index}
                                                    >
                                                      <input
                                                        type="radio"
                                                        className="mr-3"
                                                        id="html"
                                                        name="fav_language"
                                                        value={x.courier_name}
                                                        checked={
                                                          selectedCourier ===
                                                          x.courier_name
                                                        }
                                                        onChange={() => {
                                                          setSelectedCourier(
                                                            x.courier_name
                                                          );
                                                          setSelectedCourierPrice(
                                                            x.rate
                                                          );
                                                          // localStorage.setItem(
                                                          //   "selectedCourier",
                                                          //   x.courier_name
                                                          // );
                                                          // localStorage.setItem(
                                                          //   "selectedCourierPrice",
                                                          //   x?.rate
                                                          // );
                                                        }}
                                                      />
                                                      {/* {console.log(
                                                        "Selected Courier Check",
                                                        { selectedCourier }
                                                      )} */}
                                                      Estimate Delivery Time:{" "}
                                                      <span className="text-success">
                                                        {x?.etd}
                                                      </span>
                                                    </h4>
                                                    <h4
                                                      className=" mt-1 ml-4 "
                                                      style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                      }}
                                                    >
                                                      {" "}
                                                      &nbsp; Courier Name:
                                                      <span className="text-success">
                                                        {x?.courier_name}
                                                      </span>{" "}
                                                    </h4>
                                                    <h4
                                                      className=" mt-1 ml-4 "
                                                      style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                      }}
                                                    >
                                                      {" "}
                                                      &nbsp; Courier Charges:
                                                      <i
                                                        className="fa fa-inr"
                                                        aria-hidden="true"
                                                      ></i>
                                                      <span className="text-success">
                                                        {" "}
                                                        {x?.rate}
                                                      </span>
                                                    </h4>
                                                  </>
                                                );
                                              })}
                                            </>
                                          ) : (
                                            <h4
                                              className=" mt-1 text-danger"
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {" "}
                                              Delivery Unavailable Choose a
                                              Different Pincode
                                            </h4>
                                          )}
                                        </>
                                      ) : null}
                                    </form>
                                    <h2 className="middleline">
                                      <span className="midline-span">OR</span>
                                    </h2>
                                    <div
                                      className="scroll-coupon"
                                      onChange={(e) => {
                                        defaultAddressCheck(e);
                                      }}
                                    >
                                      {allAddress.map((item, index) => {
                                        return (
                                          <div key={index} className="card mt-4 shadow-sm p-2 bg-white rounded">
                                            <div className="card-body mt-3 ml-3 mb-2">
                                              <div className="row">
                                                <div className="col-md-1 col-1">
                                                  <input
                                                    type="radio"
                                                    id="dewey"
                                                    name="check"
                                                    value={item._id}
                                                    checked={
                                                      item.isDefault === true
                                                    }
                                                  />
                                                </div>
                                                <div className="col-md-11 col-11">
                                                  <div className="address-item">
                                                    <h4 className="head-p">
                                                      {item.Name}{" "}
                                                      <span className="home-btn-address ml-3">
                                                        {item.AddressType}
                                                      </span>
                                                    </h4>
                                                  </div>
                                                  <h4 className="head-p">
                                                    {item.Locality},{" "}
                                                    {item.Address}, {item.City},{" "}
                                                    {item.State},{" "}
                                                    {item.Country} - {""}
                                                    {item.PinCode}
                                                  </h4>
                                                  <h4 className="head-p">
                                                    Mobile:{" "}
                                                    <span
                                                      style={{
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      {item.phoneNumber}
                                                    </span>{" "}
                                                  </h4>
                                                  <h4 className="head-p">
                                                    {/* <i
                                                      className="fa fa-check mr-3"
                                                      style={{
                                                        color: "#ff0000",
                                                      }}
                                                      aria-hidden="true"
                                                    ></i>
                                                    Pay on Delivery available */}
                                                  </h4>
                                                </div>
                                                <div className="col-md-3 col-3 d-flex justify-content-end"
                                                style={{marginLeft:"290px"}}
                                                >
                                               {/* <Button
                                        outline
                                        className="mr-4"
                                        onClick={() => removeAddress(item._id)}
                                        style={{
                                          color: "#ff0000",
                                          fontSize: "12px",
                                          backgroundColor: "transparent",
                                          border: "1px solid #ff0000",
                                          textDecoration: "none",
                                        }}
                                      >
                                        Remove
                                      </Button> */}
                                              </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  outline
                                  className="float-right width-100"
                                  style={{
                                    color: "#ff0000",
                                    fontSize: "12px",
                                    backgroundColor: "transparent",
                                    border: "1px solid #ff0000",
                                    textDecoration: "none",
                                  }}
                                  onClick={toggle2}
                                >
                                  Add New Address
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </div>
                        </div>
                        <br />
                        {/* <div className="card shadow-sm p-2 bg-white rounded">
                                                    <div className="card-body mt-3 ml-3">

                                                        <h4 className="bank-offer-header">
                                                            <span className="fa-stack fa-lg mr-2" style={{ fontSize: "10px" }} >
                                                                <i className="fa fa-certificate fa-stack-2x" style={{ color: "#ff0000" }}></i>
                                                                <i className="fa fa-tag fa-stack-1x fa-inverse"></i>
                                                            </span>Bank Offer</h4>
                                                        <ul className='bank-offer' >
                                                            {bankOffer.map((item, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        {item}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                        {!bankOfferCheck ? <button type="button" class="btn btn-link see-more-bankoffer ml-3" style={{ color: "#ff0000", fontSize: "12px", marginTop: "-20px", textDecoration: "none" }} onClick={(e) => { showMoreBankOffer(e) }}>See More <i class="fa fa-angle-down"></i> </button>
                                                            : <button type="button" class="btn btn-link see-more-bankoffer ml-3" style={{ color: "#ff0000", fontSize: "12px", marginTop: "-20px", textDecoration: "none" }} onClick={(e) => { showLessBankOffer(e) }}>See Less <i class="fa fa-angle-up"></i></button>}


                                                    </div>
                                                </div> */}
                        {/* <br /> */}
                        {/* <div class="card shadow-sm p-2 bg-white rounded">
                                                    <div class="card-body mt-3 ml-3 mb-2">
                                                        <h4 className="bank-offer-header"><i class='fa fa-truck mr-3' style={{ color: "#ff0000" }}></i>Yay! <span style={{ fontWeight: "600" }}>No Convenience fee</span> on this order.</h4>
                                                    </div>
                                                </div> */}
                        {/* <br /> */}
                        <div className="card shadow-sm p-2 bg-white rounded">
                          <div className="card-body mt-3 ml-3 mb-2">
                            <div className="row">
                              <div className="col-md-6 col-6">
                                <h4 className="bank-offer-header">
                                  <span style={{ fontWeight: "600" }}>
                                    <input
                                      type="checkbox"
                                      className="mr-2"
                                      name="remember"
                                      value="1"
                                    />
                                    1/1 ITEMS SELECTED
                                  </span>
                                </h4>
                              </div>

                              <div className="col-md-6 col-6">
                                <Button
                                  className="float-right"
                                  outline
                                  onClick={moveToListingAll}
                                  style={{
                                    border: "1px solid #ff0000",
                                    backgroundColor: "transparent",
                                    color: "#ff0000",
                                    fontSize: "12px",
                                    marginTop: "-15px",
                                    textDecoration: "none",
                                  }}
                                >
                                  Add More Items
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        {cartProduct && cartProduct.length > 0 && cartProduct.map((item, index) => {
                          // console.log("Item Frontend Id Check",item?._id)
                          const productName = item?.product?.productName || '';
                          const productId = item?.product?._id || '';
                          const selectedColor = item?.colour; 
                          const productThumbnailImages = item.product?.productThumbnailImages; 
                          const productThumbnail = Array.isArray(productThumbnailImages) ? 
                                productThumbnailImages.find(image => 
                                image.varient && image.varient.length > 0 && image.varient[0].label === selectedColor
                                )?.imgurl : null;

                          const fallbackThumbnail = item?.product?.productThumbnailImages?.[0]?.imgurl || ''; 
                          const finalThumbnail = productThumbnail || fallbackThumbnail;
                          // const merchantCompanyName = item?.product?.merchant?.merchantCompanyName || '';

                          return (
                            <div key={index} className="card shadow-sm mb-3 p-1 bg-white rounded">
                            <div className="card-body mt-4 ml-2 mb-1">
                              <div className="row mt-2 mb-2">
                                <div className="col-lg-3 col-9 text-center">
                                <Link to={`/productdetails/${paramCase(productName)}/${productId}`}>
                                  <img
                                    src={finalThumbnail}
                                    className="image-product-main with-shadow"
                                    style={{
                                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                      backgroundColor: 'black',
                                      transition: '0.3s ease-in-out',
                                      borderRadius: '0px',
                                      marginDown: '15px',
                                      objectFit: 'cover',
                                      width: '150px',  
                                      height: '150px',
                                      display: 'block',
                                      margin: '5px auto', 
                                    }}
                                    alt={productName}
                                  />
                                </Link>
                              </div>
                              <div className="col-lg-9 col-12">
                                <div className="ml-3">
                                  <div className="row">
                                    <h4
                                      className="cart-item-title"
                                      style={{
                                        fontSize: '16px',  
                                        fontWeight: '900',  
                                        marginTop: '-2px',  
                                        color: '#333',
                                        textAlign: 'left', 
                                        paddingLeft: '8px',  
                                      }}
                                    >
                                      {productName}
                                    </h4>
                                  </div>
                                  {/* <div className="row">
                                    <p
                                      style={{
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        color: '#666',
                                        textAlign: 'left',
                                        paddingLeft: '18px',
                                        marginTop: '0px',
                                      }}
                                    >
                                      Sold by: {merchantCompanyName}
                                    </p>
                                  </div> */}
                                <div className="row align-items-center" style={{ marginTop: '0' }}>
                                {item?.size ? (
                                  <>
                                    <div className="col-md-4 col-4">
                                      <h4
                                        className="cart-item-details"
                                        style={{
                                          fontWeight: '600',
                                          fontSize: '14px',
                                          marginLeft: '6px',
                                          color: '#333',
                                        }}
                                      >
                                        Size: {item?.size}
                                      </h4>
                                    </div>
                                    <div className="col-md-4 col-4">
                                      <h4
                                        className="cart-item-details"
                                        style={{
                                          fontWeight: '600',
                                          fontSize: '14px',
                                          color: '#333',
                                          marginBottom: '10px', 
                                        }}
                                      >
                                        Quantity: <span style={{fontWeight:"600"}}>{item?.quantity}</span>
                                      </h4>
                                    </div>
                                    <div className="col-md-4 col-4">
                                      {item?.colour && (
                                        <h4
                                          className="cart-item-details"
                                          style={{
                                            fontWeight: '600',
                                            fontSize: '14px',
                                            color: '#333',
                                          }}
                                        >
                                         Color: <span style={{ fontWeight: '600' }}>{item?.colour}</span> 
                                          {/* <Button
                                            onClick={() => {}}
                                            className="colour-button ml-1"
                                            style={{
                                              borderRadius: '50%',
                                              width: '24px',
                                              height: '24px',
                                              fontWeight: '600',
                                              padding: '0',
                                              backgroundColor: item?.colour,
                                              border: '2px solid #ddd',
                                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                              cursor: 'pointer',
                                              transition: 'transform 0.2s, box-shadow 0.2s',
                                            }}
                                            onMouseOver={(e) => {
                                              e.currentTarget.style.transform = 'scale(1.1)';
                                              e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.3)';
                                            }}
                                            onMouseOut={(e) => {
                                              e.currentTarget.style.transform = 'scale(1)';
                                              e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)';
                                            }}
                                            title={`${item?.colour}`}
                                          >
                                            {' '}
                                          </Button> */}
                                        </h4>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="col-md-6 col-6">
                                      <h4
                                        className="cart-item-details"
                                        style={{
                                          fontWeight: '600',
                                          fontSize: '14px',
                                          marginLeft: '6px',
                                          color: '#333',
                                          marginBottom: '10px', 
                                        }}
                                      >
                                        Quantity: <span>{item?.quantity}</span>
                                      </h4>
                                    </div>
                                    <div className="col-md-6 col-6">
                                      {item?.colour && (
                                        <h4
                                          className="cart-item-details"
                                          style={{
                                            fontWeight: '600',
                                            fontSize: '14px',
                                            color: '#333',
                                          }}
                                        >
                                          Color: <span style={{ fontWeight: '600' }}>{item?.colour}</span> 
                                          {/* <Button
                                            onClick={() => {}}
                                            className="colour-button ml-1"
                                            style={{
                                              borderRadius: '50%',
                                              width: '24px',
                                              height: '24px',
                                              padding: '0',
                                              backgroundColor: item?.colour,
                                              border: '2px solid #ddd',
                                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                              cursor: 'pointer',
                                              transition: 'transform 0.2s, box-shadow 0.2s',
                                            }}
                                            onMouseOver={(e) => {
                                              e.currentTarget.style.transform = 'scale(1.1)';
                                              e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.3)';
                                            }}
                                            onMouseOut={(e) => {
                                              e.currentTarget.style.transform = 'scale(1)';
                                              e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)';
                                            }}
                                            title={`${item?.colour}`}
                                          >
                                            {' '}
                                          </Button> */}
                                        </h4>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="row">
                                    <div className="col-md-12 col-12">
                                      <h4 className="cart-item-title" style={{ margin: '0', fontSize: '16px', color: '#333' }}>
                                        <i className="ml-2 fa fa-inr mr-2" style={{ fontSize: '14px' }}></i>
                                        {item?.product?.grossPrice}
                                        <span className="product-cross" style={{ marginLeft: '10px', textDecoration: 'line-through', color: '#888' }}>
                                          <i className="fa fa-inr mr-2" style={{ fontSize: '14px' }}></i>
                                          <span className="cart-item-cross-p">
                                            {item.product?.offerSummary}
                                          </span>
                                        </span>
                                        <span className="offersummary-cart" style={{ marginLeft: '10px', color: '#d9534f', fontWeight: 'bold' }}>
                                          ({Math.round(((item?.product?.offerSummary - item?.product?.grossPrice) / item?.product?.offerSummary) * 100)}% OFF)
                                        </span>
                                        {/* {item?.product?.availability < 5 && (
                                          <span className="availability-info" style={{ marginLeft: '20px', fontSize: '14px', color: '#888', alignSelf: 'center' }}>
                                            {item?.product?.availability} left
                                          </span>
                                        )} */}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="row">
                                <div className="col-12 ml-3">
                                  {item?.product?.deliveryandRefundDetails?.map((detail, index) => (
                                    <div key={index} style={{ marginBottom: '5px' }}>
                                      {detail.IsReturned !== undefined && (
                                        <h4
                                          className="mt-2"
                                          style={{
                                            fontWeight: '600',
                                            fontSize: '12px',
                                            color: '#333',
                                            display: 'flex',
                                            alignItems: 'center',
                                            margin: '0',
                                          }}
                                        >
                                          <i
                                            className={`fa ${detail.IsReturned ? 'fa-refresh' : 'fa-ban'} mr-3`}
                                            aria-hidden="true"
                                            style={{
                                              fontSize: '14px',
                                              color: detail.IsReturned ? '#00c851' : '#ff4444',
                                            }}
                                          ></i>
                                          {detail.IsReturned
                                            ? `Return Available within ${detail.ReturnWithIn} days`
                                            : 'No Return Available'}
                                        </h4>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div> 
                              <div className="row">
                                      <div className="col-12 ml-3">
                                        {((item?.product?.deliveryandRefundDetails?.some(detail => detail.label === 'Pickup Required')) && (item?.estimatedDeliveryDate)) ? (
                                          <h4
                                            className="mt-2"
                                            style={{
                                              fontWeight: '600',
                                              fontSize: '12px',
                                              color: '#333',
                                              display: 'flex',
                                              alignItems: 'center',
                                              margin: '0',
                                            }}
                                          >
                                            <i
                                              className="fa fa-check mr-3"
                                              aria-hidden="true"
                                              style={{ color: '#ff0000', fontSize: '16px' }}
                                            ></i>
                                            Delivery by {formatDate(calculateAdjustedDate(item?.estimatedDeliveryDate))}
                                          </h4>
                                        ) : (item?.estimatedDeliveryDate) ? (
                                          <h4
                                            className="mt-2"
                                            style={{
                                              fontWeight: '600',
                                              fontSize: '12px',
                                              color: '#333',
                                              display: 'flex',
                                              alignItems: 'center',
                                              margin: '0',
                                            }}
                                          >
                                            <i
                                              className="fa fa-check mr-3"
                                              aria-hidden="true"
                                              style={{ color: '#ff0000', fontSize: '14px' }}
                                            ></i>
                                            Delivery by {formatDate(item?.estimatedDeliveryDate)}
                                          </h4>
                                        ) : (
                                          <h4
                                            className="mt-2"
                                            style={{
                                              fontWeight: '600',
                                              fontSize: '12px',
                                              color: '#ff0000',
                                              display: 'flex',
                                              alignItems: 'center',
                                              margin: '0',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => setAvailableForPinModal(!availableForPinModal)}
                                          >
                                            <i
                                              className="fa fa-close mr-3"
                                              aria-hidden="true"
                                              style={{ color: '#ff0000', fontSize: '16px' }}
                                            ></i>
                                            Item Unavailable in Your Area
                                          </h4>
                                        )}
                                      </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '20px', marginTop: '-15px' }}>
                                        <Button
                                          color="link"
                                          onClick={(e) => {
                                            toggle5();
                                            getSpecificProduct(item?._id);
                                          }}
                                          style={{
                                            backgroundColor: 'transparent',
                                            color: '#000',
                                            fontSize: '20px',
                                            padding: '5px',
                                            border: 'none',
                                            borderRadius: '4px',
                                            textDecoration: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            transition: 'background-color 0.3s, color 0.3s',
                                          }}
                                          onMouseOver={(e) => {
                                            e.currentTarget.style.backgroundColor = '#f0f0f0';
                                          }}
                                          onMouseOut={(e) => {
                                            e.currentTarget.style.backgroundColor = 'transparent';
                                          }}
                                        >
                                          <i className="fas fa-edit"></i>
                                        </Button>
                                        <Button
                                          color="link"
                                          onClick={() => removeCartProduct(item?._id)}
                                          style={{
                                            backgroundColor: 'transparent',
                                            color: '#ff0000',
                                            fontSize: '20px',
                                            padding: '5px',
                                            border: 'none',
                                            borderRadius: '4px',
                                            textDecoration: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            transition: 'background-color 0.3s, color 0.3s',
                                          }}
                                          onMouseOver={(e) => {
                                            e.currentTarget.style.backgroundColor = '#ffe6e6';
                                          }}
                                          onMouseOut={(e) => {
                                            e.currentTarget.style.backgroundColor = 'transparent';
                                          }}
                                        >
                                          <i className="fa-solid fa-xmark"></i>
                                        </Button>
                                      </div>

                                    {/* <hr></hr> */}
                                    {/* <div className="row">
                                        <div className="align-sub-total">
                                            <div className="float-right align-item-right">
                                              Subtotal {"("}{item.quantity} item{")"}: {item?.product?.grossPrice * item.quantity}
                                            </div>
                                          </div>
                                      </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          );
                        })}
                        <Modal isOpen={modal5} toggle={toggle5} {...args}>
                          <ModalHeader toggle={toggle5} close={closeBtn}>
                            Update Product Description
                        </ModalHeader>
                        <ModalBody>
                            <div className="card shadow-sm p-2 bg-white rounded">
                                <div className="card-body  ml-2 mb-4">
                                {specificProduct[0]?.product?.productVarient?.map((it, indexs) => (
                                        <React.Fragment key={indexs}>
                                            {it?.value?.length > 0 && (
                                                <>
                                                    <h1 className='mb-4 mt-4 ml-2' style={{ fontSize: "20px", fontWeight: "400" }}>
                                                        SELECT {it.key.toUpperCase()}
                                                        <i className="fa fa-angle-right" style={{ paddingLeft: "10px" }} aria-hidden="true"></i>
                                                    </h1>
                                                    {it?.key?.toUpperCase() === "SIZE" &&
                                                        it?.value?.map((size, index) => (
                                                            <Button
                                                                key={index}
                                                                onClick={() => {
                                                                    setProductSize(size);
                                                                }}
                                                                className="size-button ml-2"
                                                                style={{
                                                                    border: "1px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    padding: "10px 15px",
                                                                    backgroundColor: productSize === size ? "#ff0000" : "#fff",
                                                                    color: productSize === size ? "#fff" : "#000",
                                                                    transition: "background-color 0.3s, color 0.3s",
                                                                }}
                                                            >
                                                                {size}
                                                            </Button>
                                                        ))
                                                    }
                                                    {(it?.key?.toUpperCase() === "COLOR" || it?.key?.toUpperCase() === "COLOUR") && (
                                        <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                                            {it?.value?.map((color, index) => {
                                                const colorImage = specificProduct[0]?.product?.productImages?.find(image =>
                                                    image.varient.some(variant => variant.label === color)
                                                );

                                                return (
                                                    <React.Fragment key={index}>
                                                        <Button
                                                            onClick={() => {
                                                                setProductColor(color);
                                                            }}
                                                            className="colour-button ml-3"
                                                            style={{
                                                                border: productColor === color ? "2px solid #ff0000" : "2px solid #ccc",
                                                                borderRadius: "4px", 
                                                                padding: "0",
                                                                margin: "0 5px",
                                                                transition: "border-color 0.3s",
                                                                width: "60px", 
                                                                height: "60px", 
                                                                display: "flex", 
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            {colorImage && (
                                                                <img 
                                                                    src={colorImage.imgurl} 
                                                                    alt={color} 
                                                                    style={{ 
                                                                        width: "100%", 
                                                                        height: "100%", 
                                                                        objectFit: "cover",
                                                                        borderRadius: "4px" 
                                                                    }} 
                                                                />
                                                            )}
                                                        </Button>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                    )}
                                                </>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    <h1 className="mt-4 mb-4 ml-2" style={{ fontSize: "20px", fontWeight: "200" }}>
                                        SELECT QUANTITY
                                        <i className="fa fa-angle-right" style={{ paddingLeft: "10px" }} aria-hidden="true"></i>
                                    </h1>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <Button
                                                onClick={(e) => {
                                                    setProductQuantity(productQuantity > 1 ? productQuantity - 1 : productQuantity);
                                                }}
                                                className="size-button ml-2"
                                            >
                                                <span style={{ color: "#000", borderRadius: "50%" }}>-</span>
                                            </Button>
                                            <Button className="size-button ml-2">
                                                <span style={{ color: "#000", borderRadius: "50%" }}>{productQuantity}</span>
                                            </Button>
                                            <Button
                                                onClick={(e) => {
                                                    setProductQuantity(productQuantity + 1);
                                                }}
                                                className="size-button ml-2"
                                            >
                                                <span style={{ color: "#000", borderRadius: "50%" }}>+</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                onClick={() => {
                                    updateCartProduct(specificProduct[0]?._id);
                                    toggle5();
                                }}
                                outline
                                className="float-right width-100"
                                style={{
                                    color: "#ff0000",
                                    fontSize: "12px",
                                    backgroundColor: "transparent",
                                    border: "1px solid #ff0000",
                                    textDecoration: "none",
                                }}
                            >
                                Update
                            </Button>
                        </ModalFooter>
                    </Modal>
                        <br />
                        <Link to="/wishlist">
                          <div className="card shadow-sm p-2 bg-white rounded">
                            <div className="card-body mt-3 ml-3 mb-2">
                              <h4 className="bank-offer-header">
                                <i
                                  className="fa fa-bookmark mr-3"
                                  style={{ color: "#ff0000" }}
                                  aria-hidden="true"
                                ></i>
                                Add more from WishList
                                <i
                                  className="fa fa-angle-right float-right"
                                  aria-hidden="true"
                                ></i>
                              </h4>
                            </div>
                          </div>
                        </Link>

                        <br />
                        <br />
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="vline">
                          {/* <br /> */}
                          {bankOffer.length? <div className="card shadow-sm p-2 bg-white rounded">
                            <div className="card-body mt-3 ">
                              <h4 className="bank-offer-header">
                                <span
                                  className="fa-stack fa-lg mr-2"
                                  style={{ fontSize: "10px" }}
                                >
                                  <i
                                    className="fa fa-certificate fa-stack-2x"
                                    style={{ color: "#ff0000" }}
                                  ></i>
                                  <i className="fa fa-tag fa-stack-1x fa-inverse"></i>
                                </span>
                                Bank Offer
                              </h4>
                              <ul className="bank-offer">
                                {bankOffer.map((item, index) => {
                                  return <li key={index}>{item}</li>;
                                })}
                              </ul>
                              {!bankOfferCheck ? (
                                <button
                                  type="button"
                                  className="btn btn-link see-more-bankoffer ml-3"
                                  style={{
                                    color: "#ff0000",
                                    fontSize: "12px",
                                    marginTop: "-20px",
                                    textDecoration: "none",
                                  }}
                                  onClick={(e) => {
                                    showMoreBankOffer(e);
                                  }}
                                >
                                  See More <i className="fa fa-angle-down"></i>{" "}
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-link see-more-bankoffer ml-3"
                                  style={{
                                    color: "#ff0000",
                                    fontSize: "12px",
                                    marginTop: "-20px",
                                    textDecoration: "none",
                                  }}
                                  onClick={(e) => {
                                    showLessBankOffer(e);
                                  }}
                                >
                                  See Less <i className="fa fa-angle-up"></i>
                                </button>
                              )}
                            </div>
                          </div>:null}
                          {/* <br /> */}
                          <div className="card shadow-sm p-2 bg-light rounded" style={{ border: "none", display: 'flex', alignItems: 'center' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <i
                              className="fa fa-truck"
                              style={{
                                color: "#ff0000",
                                fontSize: "20px",
                                marginRight: "10px",
                              }}
                            ></i>
                            <h4
                              className="bank-offer-header"
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#333",
                                margin: "0",
                                lineHeight: "1.2",
                              }}
                            >
                              Yay!{" "}
                              <span style={{ color: "#ff0000" }}>
                                No Convenience Fee
                              </span>{" "}
                              on this order.
                            </h4>
                          </div>
                        </div>
                        <hr />
                          <br />
                          <div className="row mt-4 align-items-center">
                            <div className="col-md-8 col-8">
                              <h4
                                className="cou-head"
                                style={{
                                  fontSize: "16px", 
                                  color: "#333",
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "0", 
                                  fontWeight: "400",
                                }}
                              >
                                <i
                                  className="fa fa-tag fa-lg mr-2"
                                  style={{ color: "#ff0000" }}
                                ></i>
                                Apply Coupons
                              </h4>
                            </div>
                            <div className="col-md-4 col-4 text-right">
                              <Button
                                outline
                                style={{
                                  border: "1px solid #ff0000",
                                  backgroundColor: "transparent",
                                  color: "#ff0000",
                                  fontSize: "14px",
                                  padding: "5px", 
                                  textDecoration: "none",
                                  transition: "background-color 0.3s ease, color 0.3s ease",
                                  borderRadius: "5px", 
                                  marginTop: "0", 
                                }}
                                onClick={toggle1}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.backgroundColor = '#ff0000';
                                  e.currentTarget.style.color = '#ffffff';
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.backgroundColor = 'transparent';
                                  e.currentTarget.style.color = '#ff0000';
                                }}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>                  
                          <hr />

                          <br />
                          <PriceDetails
                            priceCalculation={priceCalculation}
                            serviceCharge={serviceCharge}
                            couponDiscount={couponDiscount}
                            toggleConvenieceFeeModal={toggleConvenieceFeeModal}
                          />
                          <ConvenienceFeeModal
                            convenieceFeeModal={convenieceFeeModal}
                            toggleConvenieceFeeModal={toggleConvenieceFeeModal}
                            closeConvineanceModal={closeConvineanceModal}
                          />
                          <ItemUnavailableForYourPinCode 
                          availableForPinModal={availableForPinModal}
                          toggleAvailableForPin={toggleAvailableForPin}
                          closeAvailableForPin={closeAvailableForPin}
                          />
                          <AddressRequire 
                          addressRequireModal={addressRequireModal}
                          toggleAddressAvailable={toggleAddressAvailable}
                          closeAddressAvailable={closeAddressAvailable}/>
                          {/* <div className="row">
                            <div className="col-md-6 col-6"><Button type="submit" color='link' className='float-right' id='back' style={{ border: "1px solid #ff0000", backgroundColor: "transparent",marginTop: "110px", color: "#ff0000", fontSize: "12px", textDecoration: "none" }} onClick={onClickBack} >Prev</Button></div>
                            <div className="col-md-6 col-6"><Button type="submit" color='link' id='next' style={{ border: "1px solid #ff0000", backgroundColor: "transparent",marginTop: "110px", color: "#ff0000", fontSize: "12px", textDecoration: "none" }} onClick={onClickNext} >Next</Button></div>
                        </div> */}
                          {productList.length !== 0 && availableForPin && hasDefaultAddress && (
                            <div className="row">
                              {/* <div className="col-md-6 col-6">
                                <div className="my-3">
                                  <Button
                                    type="submit"
                                    color="link"
                                    className="float-right"
                                    id="back"
                                    style={{
                                      border: "1px solid #ff0000",
                                      backgroundColor: "transparent",
                                      color: "#ff0000",
                                      fontSize: "12px",
                                      marginTop: "30px",
                                      textDecoration: "none",
                                    }}
                                    onClick={onClickBack}
                                  >
                                    Prev
                                  </Button>
                                </div>
                              </div> */}
                               <div className="col-md-12 col-12">
                                <div className="my-3">
                                  <Button
                                    type="submit"
                                    color="link"
                                    id="next"
                                    className="btn btn-primary mt-4 width-100"
                                    style={{
                                      border: "none",
                                      backgroundColor: "#ff0000",
                                      color: "#fff",
                                      fontSize: "16px",
                                      padding: "12px 20px",
                                      textAlign: "center",
                                      borderRadius: "4px",
                                      width: "100%",
                                      textDecoration: "none",
                                    }}
                                    onClick={onClickNext}
                                  >
                                    Checkout
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* <button type="submit" onClick={() => { createOrder(), payment()}} className="btn btn-primary mt-4 width-100" >Place Order</button> */}
                        </div>
                        <Modal isOpen={modal1} toggle={toggle1} {...args}>
                          <ModalHeader toggle={toggle1} close={closeBtn4}>
                            COUPON CODE
                          </ModalHeader>
                          <ModalBody>
                            <div className="card shadow-sm p-2 bg-white rounded">
                              <div className="card-body mt-3 ml-3 mb-2">
                                <form className="form clearfix">
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      value={searchCouponCode}
                                      onChange={(e) =>
                                        setSearchCouponCode(e.target.value)
                                      }
                                      className="form-control"
                                      placeholder="Enter Coupon Code*"
                                      aria-label="Recipient's username"
                                      aria-describedby="basic-addon2"
                                    />
                                    <div className="input-group-append">
                                      <button
                                        onClick={(e) =>
                                          checkCouponCodeValidator(e)
                                        }
                                        className="btn  btn-primary width-100"
                                        type="button"
                                      >
                                        Check
                                      </button>
                                    </div>
                                  </div>
                                  {couponCodeMsg ? (
                                    <h4
                                      className="head-p"
                                      style={{ color: "#ff0000" }}
                                    >
                                      {couponCodeMsg}
                                    </h4>
                                  ) : null}
                                </form>
                                <h2 className="middleline">
                                  <span className="midline-span">OR</span>
                                </h2>
                                <div
                                  className="scroll-coupon"
                                  // onChange={(e) => {
                                  //   couponcodeCheck(e);
                                  // }}
                                >
                                  {couponCode && couponCode.map((item, index) => {
    return (
        <div key={index} className="card mt-4 shadow-sm p-3 bg-white rounded" style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", transition: "transform 0.3s", cursor: "pointer" }}>
            <div className="card-body" style={{ padding: "1rem" }}>
                <div className="row align-items-center">
                    <div className="col-md-1 col-1">
                        <input
                            className="form-check-input"
                            name="coupon"
                            type="radio"
                            value={item.couponCode}
                            id={`flexCheckDefault-${index}`} // Unique ID for each radio button
                            style={{
                                accentColor: checkCoupon === item._id ? "#ff4d4d" : "" // For modern browsers
                            }}
                            onClick={() => couponcodeCheck(item)}
                        />
                    </div>
                    <div className="col-md-11 col-11">
                        <div className="address-item">
                            <h4 className="font-weight-bold" style={{ marginBottom: "0.5rem", color: "#333" }}>
                                {item.couponName}
                                {/* <span className="ml-3 mr-3">
                                    <img
                                        width={50}
                                        height={50}
                                        src={item.img}
                                        alt={item.couponName}
                                    />
                                </span> */}
                            </h4>
                            <h5 className="text-muted" style={{ marginBottom: "0.5rem" }}>
                                Use Code: 
                                <span className="font-weight-bold" style={{ color: "#007bff" }}> {item.couponCode}</span>
                            </h5>
                        </div>
                        <h5 style={{ marginBottom: "0.5rem", color: "#333" }}>
                            Save:{" "}
                            {item.couponVarient === "percentage" ? (
                                <span className="font-weight-bold" style={{ color: "#28a745" }}>
                                    {item.percentage}% OFF up to{" "}
                                    <i className="ml-2 fa fa-inr mr-2"></i>
                                    {item.maxCouponAmount}
                                </span>
                            ) : (
                                <span className="font-weight-bold" style={{ color: "#28a745" }}>
                                    <i className="ml-2 fa fa-inr mr-2"></i>
                                    {item.flatAmount}
                                </span>
                            )}
                        </h5>
                        <h5 style={{ marginBottom: "0.5rem", color: "#333" }}>
                            Expires:{" "}
                            <span>
                                {dateFormat(item.end, "dddd, mmmm dS, yyyy, h:MM:ss TT")}
                            </span>
                        </h5>
                        {item.couponType === "bxgy" && (
                            <Link to={`/offerProducts/${item._id}`}>
                                <h5 className="text-primary" style={{ cursor: "pointer" }}>Redeem now</h5>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
})}
                                </div>
                              </div>
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <button
                              type="submit"
                              className="btn btn-primary  width-100"
                              onClick={(e) => {
                                applyCouponCode(e);
                                // deleteCoupon(couponCode[0]._id);
                              }}
                            >
                              APPLY
                            </button>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </div>
                  );
                  break;
                // case 2:
                //     return (
                //         <div className="row">
                //             <div className="col-md-8 col-12">
                //                 <div className="container">
                //                     <div className="row justify-content-center">
                //                         <div className="col-md-2"></div>
                //                         <Modal isOpen={modal2} toggle={toggle2} {...args}>
                //                             <ModalHeader toggle={toggle2} close={closeBtn2}>ADD NEW ADDRESS</ModalHeader>
                //                             <ModalBody>
                //                                 <div className="card shadow-sm p-2 bg-white rounded">
                //                                     <div className="card-body mt-3 ml-3 mb-2">
                //                                         <form className="form clearfix">
                //                                             <h4 className='payment-header-price'>CONTACT DETAILS</h4>
                //                                             <div className="form-group">
                //                                                 <input name="name" value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" placeholder="Name*" required />
                //                                             </div>
                //                                             <div className="form-group">
                //                                                 <input name="phone" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} type="tel" className="form-control" placeholder="Phone Number*" required />
                //                                             </div>
                //                                             <h4 className='payment-header-price'>ADDRESS</h4>
                //                                             <div className="form-group">
                //                                                 <input name="pincode" value={PinCode} onChange={(e) => setPinCode(e.target.value)} type="text" className="form-control" placeholder="Your Pincode*" required />
                //                                             </div>
                //                                             <div className="form-group">
                //                                                 <input name="address" value={address} type="text" onChange={(e) => setAddress(e.target.value)} className="form-control" placeholder="Your Address*" required />
                //                                             </div>
                //                                             <div className="form-group">
                //                                                 <input name="locality" value={locality} onChange={(e) => setLocality(e.target.value)} type="text" className="form-control" placeholder="Your Locality/Town*" required />
                //                                             </div>
                //                                             <div className="row">
                //                                                 <div className="col-md-6 col-6">
                //                                                     <div className="form-group">
                //                                                         <input name="city" type="text" value={city} onChange={(e) => setCity(e.target.value)} className="form-control" placeholder="Your City*" required />
                //                                                     </div>
                //                                                 </div>
                //                                                 <div className="col-md-6 col-6">
                //                                                     <div className="form-group">
                //                                                         <input name="state" type="text" value={state} onChange={(e) => setState(e.target.value)} className="form-control" placeholder="Your State*" required />
                //                                                     </div>
                //                                                 </div>
                //                                             </div>
                //                                             <h4 className='payment-header-price'>SAVE ADDRESS AS</h4>
                //                                             <div className="row">
                //                                                 <div className="col-md-3 col-6"><div className="form-check">
                //                                                     <input className="form-check-input mr-3" onChange={(e) => setAddressType(e.target.value)} checked={addressType === 'Home'} value="Home" type="radio" name="home" />
                //                                                     <label className="form-check-label" for="flexRadioDefault1">
                //                                                         Home
                //                                                     </label>
                //                                                 </div></div>
                //                                                 <div className="col-md-3 col-6"> <div className="form-check">
                //                                                     <input className="form-check-input mr-3" value="Work" onChange={(e) => setAddressType(e.target.value)} checked={addressType === 'Work'} type="radio" name="work" />
                //                                                     <label className="form-check-label" for="flexRadioDefault2">
                //                                                         Work
                //                                                     </label>
                //                                                 </div></div>
                //                                                 <div className="col-md-6"></div>
                //                                             </div>

                //                                             <br />
                //                                             <label>
                //                                                 <input type="checkbox" onChange={(e) => setDefalutAddress(!defalutAddress)} className='mr-3' name="remember" value="1" />
                //                                                 Make This my Default Address
                //                                             </label>
                //                                             <button type="submit" className="btn mt-4 btn-primary width-100" onClick={(e) => addAddresshandel(e)} >Add address</button>
                //                                         </form>
                //                                     </div>
                //                                 </div>
                //                             </ModalBody>

                //                         </Modal>
                //                         <div className="col-md-2"></div>
                //                     </div>

                //                 </div>
                //             </div>
                //             <div className="col-md-4 col-12">
                //                 <div className="vline">
                //                     <br />
                //                     <PriceDetails priceCalculation={priceCalculation} serviceCharge={serviceCharge} couponDiscount={couponDiscount} toggleConvenieceFeeModal={toggleConvenieceFeeModal} />
                //                     <ConvenienceFeeModal convenieceFeeModal={convenieceFeeModal} toggleConvenieceFeeModal={toggleConvenieceFeeModal} closeConvineanceModal={closeConvineanceModal} />
                //                     <div className="row">
                //                         <div className="col-md-6 col-6">
                //                             <div className="my-3">
                //                                 <Button
                //                                     type="submit"
                //                                     color='link'
                //                                     className='float-right'
                //                                     id='back'
                //                                     style={{
                //                                         border: "1px solid #ff0000",
                //                                         backgroundColor: "transparent",
                //                                         color: "#ff0000",
                //                                         fontSize: "12px",
                //                                         marginTop: "110px",
                //                                         textDecoration: "none"
                //                                     }}
                //                                     onClick={onClickBack}
                //                                 >
                //                                     Prev
                //                                 </Button>
                //                             </div>
                //                         </div>
                //                         <div className="col-md-6 col-6">
                //                             <div className="my-3">
                //                                 <Button
                //                                     type="submit"
                //                                     color='link'
                //                                     id='next'
                //                                     style={{
                //                                         border: "1px solid #ff0000",
                //                                         backgroundColor: "transparent",
                //                                         color: "#ff0000",
                //                                         fontSize: "12px",
                //                                         marginTop: "110px",
                //                                         textDecoration: "none"
                //                                     }}
                //                                     onClick={onClickNext}
                //                                 >
                //                                     Next
                //                                 </Button>
                //                             </div>
                //                         </div>
                //                     </div>

                //                 </div>

                //             </div>
                //         </div>
                //     )
                //     break;
                case 2:
                  return (
                    <div className="row">
                      <div className="col-md-7 col-12">
                        <div className="row">
                          <div className="col-md-6 col-6">
                            <h4 className="payment-address">
                              Select Delivery Address
                            </h4>
                            {/* <h4
                              className="payment-address mt-3"
                              style={{ fontWeight: "200" }}
                            >
                              Default Address
                            </h4> */}
                          </div>
                          {/* <div className="col-md-6 col-6"><Button outline className='float-right' style={{ color: "#ff0000", fontSize: "12px", backgroundColor: "transparent", border: "1px solid #ff0000", textDecoration: "none" }} onClick={toggle2}>
                                                        Add New Address12
                                                    </Button></div> */}
                          {/* <ModalFooter>
                                                                <Button outline className='float-right width-100' style={{ color: "#ff0000", fontSize: "12px", backgroundColor: "transparent", border: "1px solid #ff0000", textDecoration: "none" }} onClick={toggle2}>
                                                                    Add Your Address Here
                                                                </Button>
                                                            </ModalFooter> */}
                        </div>

                        <div
                          onChange={(e) => {
                            defaultAddressCheck(e);
                          }}
                         >
                          {allAddress.map((item, index) => {
                            return (
                              <div key={index} className="card mt-4 shadow-sm p-2 bg-white rounded">
                                <div className="card-body mt-3 ml-3 mb-2">
                                  <div className="row">
                                    <div className="col-md-1 col-1">
                                      <input
                                        type="radio"
                                        id="dewey"
                                        value={item._id}
                                        name="drone"
                                        checked={item.isDefault === true}
                                      />
                                    </div>
                                    <div className="col-md-11 col-11">
                                      <div className="address-item">
                                        <h4 className="head-p">
                                          {item.Name}
                                          <span className="home-btn-address ml-3">
                                            {item.AddressType}
                                          </span>
                                        </h4>
                                      </div>
                                      <h4 className="head-p">
                                        {item.Address},{item.Locality},
                                        {item.City},{item.State},{item.Country}-{item.PinCode}
                                      </h4>
                                      <h4 className="head-p">
                                        Mobile:{" "}
                                        <span style={{ fontWeight: "600" }}>
                                          {item.phoneNumber}
                                        </span>{" "}
                                      </h4>
                                      <h4 className="head-p">
                                        {/* <i
                                          className="fa fa-check mr-3"
                                          style={{ color: "#ff0000" }}
                                          aria-hidden="true"
                                        ></i>
                                        Pay on Delivery available */}
                                      </h4>
                                      <Button
                                        outline
                                        className="mr-4"
                                        onClick={() => removeAddress(item._id)}
                                        style={{
                                          color: "#ff0000",
                                          fontSize: "12px",
                                          backgroundColor: "transparent",
                                          border: "1px solid #ff0000",
                                          textDecoration: "none",
                                        }}
                                      >
                                        Remove
                                      </Button>

                                      <Button
                                        onClick={() => getUpdateId(item._id)}
                                        outline
                                        style={{
                                          color: "#ff0000",
                                          fontSize: "12px",
                                          backgroundColor: "transparent",
                                          border: "1px solid #ff0000",
                                          textDecoration: "none",
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        <Modal isOpen={modal3} toggle={toggle3} {...args}>
                          <ModalHeader toggle={toggle3} close={closeBtn3}>
                            EDIT ADDRESS
                          </ModalHeader>
                          <ModalBody>
                            <div className="card shadow-sm p-2 bg-white rounded">
                              <div className="card-body mt-3 ml-3 mb-2">
                                <form className="form clearfix">
                                  <h4 className="payment-header-price">
                                    CONTACT DETAILS
                                  </h4>
                                  <div className="form-group">
                                    <input
                                      name="Name"
                                      value={uAddress.Name}
                                      onChange={(e) => handelAdddressChange(e)}
                                      type="text"
                                      className="form-control"
                                      placeholder="Name*"
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      name="phoneNumber"
                                      value={uAddress.phoneNumber}
                                      onChange={(e) => handelAdddressChange(e)}
                                      type="tel"
                                      className="form-control"
                                      placeholder="Phone Number*"
                                      required
                                    />
                                  </div>
                                  <h4 className="payment-header-price">
                                    ADDRESS
                                  </h4>
                                  <div className="form-group">
                                    <input
                                      name="PinCode"
                                      value={uAddress.PinCode}
                                      onChange={(e) => handelAdddressChange(e)}
                                      type="text"
                                      className="form-control"
                                      placeholder="Your Pincode*"
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      name="Address"
                                      value={uAddress.Address}
                                      type="text"
                                      onChange={(e) => handelAdddressChange(e)}
                                      className="form-control"
                                      placeholder="Your Address*"
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      name="Locality"
                                      value={uAddress.Locality}
                                      onChange={(e) => handelAdddressChange(e)}
                                      type="text"
                                      className="form-control"
                                      placeholder="Your Locality/Town*"
                                      required
                                    />
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 col-6">
                                      <div className="form-group">
                                        <input
                                          name="City"
                                          type="text"
                                          value={uAddress.City}
                                          onChange={(e) =>
                                            handelAdddressChange(e)
                                          }
                                          className="form-control"
                                          placeholder="Your City*"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-6">
                                      <div className="form-group">
                                        <input
                                          name="State"
                                          type="text"
                                          value={uAddress.State}
                                          onChange={(e) =>
                                            handelAdddressChange(e)
                                          }
                                          className="form-control"
                                          placeholder="Your State*"
                                          required
                                        />
                                      </div> 
                                      
                                    </div>
                                    <div className="col-md-12 col-6">
                                    <div className="form-group">
                                      <input
                                        name="Country"
                                        type="text"
                                        value={uAddress.Country}
                                        onChange={(e) => handelAdddressChange(e)}
                                        className="form-control"
                                        placeholder="Your Country*"
                                        required
                                      />
                                    </div>
                                    </div>        
                                  </div>
                                  <h4 className="payment-header-price">
                                    SAVE ADDRESS AS
                                  </h4>
                                  <div className="row">
                                  <div className="col-md-6 col-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input mr-3"
                                        onChange={(e) => handelAdddressChange(e)}
                                        checked={uAddress.AddressType === "Home"}
                                        value="Home"
                                        type="radio"
                                        id="flexRadioHome"
                                        name="AddressType"
                                        style={{ display: 'none' }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexRadioHome"
                                        style={{
                                          borderColor: uAddress.AddressType === "Home" ? "red" : "grey",
                                          color: uAddress.AddressType === "Home" ? "red" : "grey",
                                          backgroundColor: "white",
                                          padding: "10px 20px",
                                          borderRadius: "10px",
                                          borderWidth: "2px",
                                          borderStyle: "solid",
                                          display: "inline-block",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Home
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input mr-3"
                                        value="Work"
                                        onChange={(e) => handelAdddressChange(e)}
                                        checked={uAddress.AddressType === "Work"}
                                        type="radio"
                                        id="flexRadioWork"
                                        name="AddressType"
                                        style={{ display: 'none' }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexRadioWork"
                                        style={{
                                          borderColor: uAddress.AddressType === "Work" ? "red" : "grey",
                                          color: uAddress.AddressType === "Work" ? "red" : "grey",
                                          backgroundColor: "white",
                                          padding: "10px 20px",
                                          borderRadius: "10px",
                                          borderWidth: "2px",
                                          borderStyle: "solid",
                                          display: "inline-block",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Work
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                  <br />
                                  <label>
                                    <input
                                      type="checkbox"
                                      onChange={(e) => handelAdddressChange(e)}
                                      className="mr-3"
                                      name="isDefault"
                                      checked={uAddress.isDefault}
                                    />
                                    Make This my Default Address
                                  </label>
                                  <button
                                    type="submit"
                                    className="btn mt-4 btn-primary width-100"
                                    onClick={(e) => updateAddressData(e)}
                                  >
                                    Update address
                                  </button>
                                </form>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                        <br />
                        <div className="card shadow-sm p-2 bg-white rounded">
                          <div className="card-body mt-3 ml-3 mb-2">
                            {/* <Button color='link' style={{ color: "#ff0000", fontSize: "12px", backgroundColor: "transparent", textDecoration: "none" }} onClick={toggle2}>
                                                            <i class="fa fa-plus" aria-hidden="true"></i> Add New Address
                                                        </Button> */}
                            <ModalFooter>
                              <Button
                                outline
                                className="float-right width-100"
                                style={{
                                  color: "#ff0000",
                                  fontSize: "12px",
                                  backgroundColor: "transparent",
                                  border: "1px solid #ff0000",
                                  textDecoration: "none",
                                }}
                                onClick={toggle2}
                              >
                                Add Your Address Here
                              </Button>
                            </ModalFooter>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 col-12">
                        <div className="vline">
                        {/* {cartProduct.length === 1 && (
                        <>
                          <h4>Choose Delivery Vendor</h4>
                          <div className="scroll-courier">    
                            <LoadingOverlay
                              active={isUpdatingPrice}
                              spinner
                              text='Updating price...'
                            >
                              {checkData.length !== 0 ? (
                                <>
                                  {checkData.map((x, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <h6 className='mt-4' style={{ fontSize: "14px", fontWeight: "500" }}>
                                          <input
                                            type="radio"
                                            className='mr-2'
                                            id={`courier-${index}`}
                                            name="courier"
                                            value={x.courier_name}
                                            checked={selectedCourier === x.courier_name}
                                            onChange={() => {
                                              setIsUpdatingPrice(true);
                                              setSelectedCourier(x.courier_name);
                                              setTimeout(() => {
                                                setIsUpdatingPrice(false);
                                              }, 3800); 
                                            }}
                                          />
                                          Courier Name: <span className='text-success'>{x?.courier_name}</span>
                                        </h6>
                                        <h6 className='mt-1 ml-4' style={{ fontSize: "14px", fontWeight: "500" }}>
                                          Estimate Delivery Time: <span className='text-success'>{x?.etd}</span>
                                        </h6>
                                        <h6 className='mt-1 ml-4' style={{ fontSize: "14px", fontWeight: "500" }}>
                                          Courier Charges: <i className="fa fa-inr" aria-hidden="true"></i>
                                          <span className='text-success'> {x?.rate}</span>
                                        </h6>
                                      </React.Fragment>
                                    )
                                  })}
                                </>
                              ) : null}
                            </LoadingOverlay>
                          </div>
                        </>
                      )} */}
                        {cartProduct.length === 1 && (
                        <>
                         <h4>Choose Delivery Vendor</h4>
                              <div className="scroll-courier">    
                                {checkData.length !== 0 && (
                                  <>
                                    {checkData.map((x, index) => (
                                      <React.Fragment key={index}>
                                        <h6 className='mt-4' style={{ fontSize: "14px", fontWeight: "500" }}>
                                          <input
                                            type="radio"
                                            className='mr-2'
                                            id={`courier-${index}`}
                                            name="courier"
                                            value={x.courier_name}
                                            checked={selectedCourier === x.courier_name}
                                            onChange={() => {
                                              setSelectedCourier(x.courier_name);
                                            }}
                                          />
                                          Courier Name: <span className='text-success'>{x?.courier_name}</span>
                                        </h6>
                                        <h6 className='mt-1 ml-4' style={{ fontSize: "14px", fontWeight: "500" }}>
                                          Estimate Delivery Time: <span className='text-success'>{x?.etd}</span>
                                        </h6>
                                        <h6 className='mt-1 ml-4' style={{ fontSize: "14px", fontWeight: "500" }}>
                                          Courier Charges: <i className="fa fa-inr" aria-hidden="true"></i>
                                          <span className='text-success'> {x?.rate}</span>
                                        </h6>
                                      </React.Fragment>
                                    ))}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                          
                          <br />
                          <PriceDetails
                            priceCalculation={priceCalculation}
                            serviceCharge={serviceCharge}
                            couponDiscount={couponDiscount}
                            toggleConvenieceFeeModal={toggleConvenieceFeeModal}
                          />
                          {productList.length !== 0 && availableForPin && hasDefaultAddress && (
                            <div className="row">
                              <div className="col-md-6 col-6">
                                <Button
                                  type="submit"
                                  color="link"
                                  className="float-right"
                                  id="back"
                                  style={{
                                    border: "1px solid #ff0000",
                                    backgroundColor: "transparent",
                                    color: "#ff0000",
                                    fontSize: "12px",
                                    marginTop: "20px",
                                    textDecoration: "none",
                                  }}
                                  onClick={onClickBack}
                                >
                                  Prev
                                </Button>
                              </div>
                              <div className="col-md-6 col-6">
                                <Button
                                  type="submit"
                                  color="link"
                                  id="next"
                                  style={{
                                    border: "1px solid #ff0000",
                                    backgroundColor: "transparent",
                                    color: "#ff0000",
                                    fontSize: "12px",
                                    marginTop: "20px",
                                    textDecoration: "none",
                                  }}
                                  onClick={onClickNext}
                                >
                                  Next
                                </Button>
                              </div>
                            </div>
                          )}
                          <ConvenienceFeeModal
                            convenieceFeeModal={convenieceFeeModal}
                            toggleConvenieceFeeModal={toggleConvenieceFeeModal}
                            closeConvineanceModal={closeConvineanceModal}
                          />
                          <AddressRequire 
                          addressRequireModal={addressRequireModal}
                          toggleAddressAvailable={toggleAddressAvailable}
                          closeAddressAvailable={closeAddressAvailable}/>
                          {/* <button type="submit" className="btn btn-primary mt-4 width-100" >Continue</button> */}
                        </div>
                      </div>
                    </div>
                  );
                  break;
                case 3:
                  return (
                    <div className="row">
                       <div className="col-md-8 col-12">
                       {/* <div class="card shadow-sm p-2 bg-white rounded">
                          <div class="card-body mt-3 ml-3">
                            <h4 className="bank-offer-header">
                              <span
                                className="fa-stack fa-lg mr-2"
                                style={{ fontSize: "10px" }}
                              >
                                <i
                                  className="fa fa-certificate fa-stack-2x"
                                  style={{ color: "#ff0000" }}
                                ></i>
                                <i className="fa fa-tag fa-stack-1x fa-inverse"></i>
                              </span>
                              Bank Offer
                            </h4>
                            <ul className="bank-offer">
                              {bankOffer.map((item, index) => {
                                return <li key={index}>{item}</li>;
                              })}
                            </ul>
                            {!bankOfferCheck ? (
                              <button
                                type="button"
                                class="btn btn-link see-more-bankoffer ml-3"
                                style={{
                                  color: "#ff0000",
                                  fontSize: "12px",
                                  marginTop: "-20px",
                                  textDecoration: "none",
                                }}
                                onClick={(e) => {
                                  showMoreBankOffer(e);
                                }}
                              >
                                See More <i class="fa fa-angle-down"></i>{" "}
                              </button>
                            ) : (
                              <button
                                type="button"
                                class="btn btn-link see-more-bankoffer ml-3"
                                style={{
                                  color: "#ff0000",
                                  fontSize: "12px",
                                  marginTop: "-20px",
                                  textDecoration: "none",
                                }}
                                onClick={(e) => {
                                  showLessBankOffer(e);
                                }}
                              >
                                See Less <i class="fa fa-angle-up"></i>
                              </button>
                            )}
                          </div>
                        </div> */}
                        {/* <h4 className="payment-header">Choose Payment Mode</h4>
                        <div class="card shadow-sm p-2 bg-white rounded">
                          <div class="card-body mt-3 ml-3">
                            <label>
                              <h4 className="bank-offer-header">
                                <input
                                  type="checkbox"
                                  className="mr-3"
                                  name="remember"
                                  value="1"
                                />
                                My cash
                              </h4>
                              <div className="offer-subHeader ml-4">
                                Total cash available:
                                <i class=" ml-2 fa fa-inr mr-2"></i>1000
                              </div>
                            </label>
                          </div>
                        </div>
                        <div class="card mt-4 shadow-sm p-2 bg-white rounded">
                          <div class="card-body mt-3 ml-3">
                            <div className="row">
                              <div className="col-md-9 col-6">
                                <h4 className="bank-offer-header">
                                  <i
                                    class="fa fa-gift fa-lg mr-3"
                                    style={{ color: "#ff0000" }}
                                    aria-hidden="true"
                                  ></i>
                                  Have a Gift Card ?
                                </h4>
                              </div>
                              <div className="col-md-3 col-6">
                                <Button
                                  color="link"
                                  style={{
                                    color: "#ff0000",
                                    fontSize: "12px",
                                    marginTop: "-10px",
                                    textDecoration: "none",
                                  }}
                                  onClick={toggle4}
                                >
                                  APPLY GIFT CARD
                                </Button>
                              </div>
                            </div>

                            <Modal isOpen={modal4} toggle={toggle4} {...args}>
                              <ModalHeader toggle={toggle4}>
                                Modal title
                              </ModalHeader>
                              <ModalBody></ModalBody>
                              <ModalFooter>
                                <Button color="primary" onClick={toggle4}>
                                  Do Something
                                </Button>{" "}
                                <Button color="secondary" onClick={toggle4}>
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </div>
                        </div> */}
                        <div className="card mt-4 shadow-sm p-2 bg-white rounded">
                        <LoadingOverlay
                                active={loader}
                                spinner
                                text='Please wait for a moment...'
                                >
                          <div className="card-body mt-3 mb-3 ml-3">
                            <div className="row">
                              {/* <div className="col-md-6 col-12"></div> */}
                              <div className="col-md-12 col-12">
                                {/* <button type="submit" className="btn btn-primary width-100" >Place Order</button> */}
                                {/* <button type="submit" onClick={() => { createOrder(), payment()}} className="btn btn-primary mt-4 width-100" >Place Order</button> */}
                                <button
                                  type="submit"
                                  disabled={isPlaceOrderButtonDisabled}
                                  style={{
                                    cursor: isPlaceOrderButtonDisabled ? 'not-allowed' : 'pointer',
                                  }}
                                  onClick={() => {
                                    placeOrder()
                                    // payment();
                                  }}
                                  className="btn btn-primary mt-4 width-100"
                                >
                                  Place Order
                                </button>
                              </div>
                            </div>
                          </div>
                          </LoadingOverlay>
                        </div>
                        {/* <div>
                          {searchCouponCode
                            ? `Coupon code: ${searchCouponCode}`
                            : ""}
                        </div> */}
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="vline">
                          <br />
                          <PriceDetails
                            priceCalculation={priceCalculation}
                            serviceCharge={serviceCharge}
                            couponDiscount={couponDiscount}
                            toggleConvenieceFeeModal={toggleConvenieceFeeModal}
                          />
                          <ConvenienceFeeModal
                            convenieceFeeModal={convenieceFeeModal}
                            toggleConvenieceFeeModal={toggleConvenieceFeeModal}
                            closeConvineanceModal={closeConvineanceModal}
                          />
                          <AddressRequire 
                          addressRequireModal={addressRequireModal}
                          toggleAddressAvailable={toggleAddressAvailable}
                          closeAddressAvailable={closeAddressAvailable}/>
                          {/* <div className="row">
                                                        <div className="col-md-8 col-8">
                                                            <h4 className='head-p '>Total MRP</h4>
                                                            <h4 className='head-p '>Discount on MRP</h4>
                                                            {serviceCharge.cost ? <h4 className='head-p '>Delivery Charges</h4> : null}
                                                            {couponDiscount > 0 && priceCalculation.couponDiscount ? <h4 className='head-p '>Coupon Discount</h4> : null}
                                                            <h4 className='head-p '>Convenience Fee Know More</h4>


                                                        </div>
                                                        <div className="col-md-4 col-4">
                                                            <h4 className='amount-p float-right'> <i class=" ml-2 fa fa-inr mr-2"></i>{priceCalculation.totalMRP}</h4>
                                                            <h4 className='amount-p float-right text-success'>-<i class=" ml-2 fa fa-inr mr-2 "></i>&nbsp;{priceCalculation.totalDiscountAmount}</h4>
                                                            {serviceCharge.cost ? <h4 className='amount-p float-right'> <i class=" ml-2 fa fa-inr mr-2"></i>{serviceCharge.cost.toFixed(2)}</h4> : null}
                                                            {couponDiscount > 0 && priceCalculation.couponDiscount ? <h4 className='amount-p float-right text-success' style={{ fontSize: "12px" }}>-<i class=" ml-2 fa fa-inr mr-2 "></i>&nbsp; {priceCalculation.couponDiscount}</h4> : null}
                                                            <h4 className='amount-p float-right '> <i class=" ml-2 fa fa-inr mr-2"></i> <span className="cross-p">99</span> <span className='text-success'>FREE</span> </h4>
                                                        </div>
                                                    </div>

                                                    <hr />
                                                    <div className='total-amount'>
                                                        <h4 className='head'>Total Amount</h4> <h4 className='amount'><i class=" ml-2 fa fa-inr mr-2"></i>{priceCalculation.total}</h4>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  );
                  break;
                case 4:
                  return (
                    <>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-3"></div>
                          <div className="col-md-6 col-12">
                            <h4 className="head mt-4">
                              Your order id is {orderData?.orderId}
                            </h4>
                            <h4 className="head-p ">
                              Total MRP
                              <h4 className="amount-p float-right">
                                {" "}
                                <i className=" ml-2 fa fa-inr mr-2"></i>
                                {orderData?.data.totalMrp}
                              </h4>
                            </h4>
                            <h4 className="head-p ">
                              Discount on MRP
                              <h4 className="amount-p float-right text-success">
                                -<i className=" ml-2 fa fa-inr mr-2 "></i>&nbsp;
                                {orderData?.data.totalDiscount}
                              </h4>
                            </h4>
                            {serviceCharge.cost ? (
                              <h4 className="head-p ">
                                Delivery Charges
                                {serviceCharge.cost ? (
                                  <h4 className="amount-p float-right">
                                    {" "}
                                    <i className=" ml-2 fa fa-inr mr-2"></i>
                                    {orderData?.data.deliveryCharge}
                                  </h4>
                                ) : null}
                              </h4>
                            ) : null}
                            {couponDiscount > 0 &&
                            priceCalculation.couponDiscount ? (
                              <h4 className="head-p ">
                                Coupon Discount
                                {couponDiscount > 0 &&
                                priceCalculation.couponDiscount ? (
                                  <h4
                                    className="amount-p float-right text-success"
                                    style={{ fontSize: "12px" }}
                                  >
                                    -<i className=" ml-2 fa fa-inr mr-2 "></i>&nbsp;{" "}
                                    {orderData?.data.couponDiscount}
                                  </h4>
                                ) : null}
                              </h4>
                            ) : null}
                            <h4 className="head-p ">
                              Convenience Fee Know More
                              <h4 className="amount-p float-right ">
                                {" "}
                                <i className=" ml-2 fa fa-inr mr-2"></i>{" "}
                                <span className="cross-p">99</span>{" "}
                                <span className="text-success">FREE</span>{" "}
                              </h4>
                            </h4>
                            <div className="total-amount mt-4 mb-3">
                              <h4 className="head">Total Amount</h4>{" "}
                              <h4 className="amount">
                                <i className=" ml-2 fa fa-inr mr-2"></i>
                                {Number(orderData.totalAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </h4>
                            </div>
                          </div>
                          <ConvenienceFeeModal
                            convenieceFeeModal={convenieceFeeModal}
                            toggleConvenieceFeeModal={toggleConvenieceFeeModal}
                            closeConvineanceModal={closeConvineanceModal}
                          />
                          <div className="col-md-3"></div>
                        </div>
                        <div className="row">
                          <div className="col-md-3"></div>
                          <div className="col-md-3 col-6">
                            <button
                              type="submit"
                              className="btn btn-success mt-4 width-100"
                            >
                              Success
                            </button>
                          </div>
                          <div className="col-md-3 col-6">
                            <button
                              type="submit"
                              className="btn btn-primary mt-4 width-100"
                            >
                              Failure
                            </button>
                          </div>
                          <div className="col-md-3"></div>
                        </div>
                      </div>
                    </>
                  );
                  break;
                default:
                  break;
              }
            })()}
            <Modal isOpen={modal2} toggle={toggle2} {...args}>
              <ModalHeader toggle={toggle2} close={closeBtn2}>
                ADD NEW ADDRESS
              </ModalHeader>
              <ModalBody>
                <div className="card shadow-sm p-2 bg-white rounded">
                  <div className="card-body mt-3 ml-3 mb-2">
                    <form className="form clearfix">
                      <h4 className="payment-header-price">CONTACT DETAILS</h4>
                      <div className="form-group">
                        <input
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Name*"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="phone"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          type="tel"
                          className="form-control"
                          placeholder="Phone Number*"
                          required
                        />
                      </div>
                      <h4 className="payment-header-price">ADDRESS</h4>
                      <div className="form-group">
                        <input
                          name="pincode"
                          value={PinCode}
                          onChange={handlePincodeChange}
                          type="text"
                          className="form-control"
                          placeholder="Your Pincode*"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="address"
                          value={address}
                          type="text"
                          onChange={(e) => setAddress(e.target.value)}
                          className="form-control"
                          placeholder="Your Address*"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="locality"
                          value={locality}
                          onChange={(e) => setLocality(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Your Locality/Town*"
                          required
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <div className="form-group">
                            <input
                              name="city"
                              type="text"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              className="form-control"
                              placeholder="Your City*"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="form-group">
                            <input
                              name="state"
                              type="text"
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                              className="form-control"
                              placeholder="Your State*"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                          <input
                            name="country"
                            type="text"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            className="form-control"
                            placeholder="Your Country*" 
                            required
                          />
                        </div>
                      <h4 className="payment-header-price">SAVE ADDRESS AS</h4>
                      <div className="row">
                        <div className="col-md-3 col-6">
                          <div className="form-check">
                            <input
                              className="form-check-input mr-3"
                              onChange={(e) => setAddressType(e.target.value)}
                              checked={addressType === "Home"}
                              value="Home"
                              type="radio"
                              name="addressType" 
                              id="flexRadioDefault1"
                              style={{ display: 'none' }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                              style={{
                                borderColor: addressType === "Home" ? "red" : "grey",
                                color: addressType === "Home" ? "red" : "grey",
                                backgroundColor: "white",
                                padding: "10px 20px",
                                borderRadius: "10px",
                                borderWidth: "2px",
                                borderStyle: "solid",
                                display: "inline-block",
                                cursor: "pointer",
                              }}
                              onClick={() => setAddressType("Home")}
                            >
                              Home
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          {" "}
                          <div className="form-check">
                            <input
                              className="form-check-input mr-3"
                              value="Work"
                              onChange={(e) => setAddressType(e.target.value)}
                              checked={addressType === "Work"}
                              type="radio"
                              name="addressType" 
                              id="flexRadioDefault2"
                              style={{ display: 'none' }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault2"
                              style={{
                              borderColor: addressType === "Work" ? "red" : "grey",
                              color: addressType === "Work" ? "red" : "grey",
                              backgroundColor: "white",
                              padding: "10px 20px",
                              borderRadius: "10px",
                              borderWidth: "2px",
                              borderStyle: "solid",
                              display: "inline-block",
                              cursor: "pointer",
                            }}
                            onClick={() => setAddressType("Work")}
                            >
                              Work
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6"></div>
                      </div>

                      <br />
                      <label>
                        <input
                          type="checkbox"
                          onChange={(e) => setDefalutAddress(!defalutAddress)}
                          className="mr-3"
                          name="remember"
                          value="1"
                        />
                        Make This my Default Address
                      </label>
                      <button
                        type="submit"
                        className="btn mt-4 btn-primary width-100"
                        onClick={(e) => addAddresshandel(e)}
                      >
                        Add address
                      </button>
                    </form>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <br />
            <br />
            {productCategory && (
              <SimilarProductListing productCategory={productCategory} />
            )}
          </div>
          
        </section>):(
                   <section style={{ textAlign: 'center', marginTop: '30px', padding: '30px',  }}>
                   <img 
                     src="/assets/images/3020773.png" 
                     alt="Empty Cart" 
                     style={{ width: '220px', marginTop: '-45px', display: 'block', margin: '0 auto', height: "220px" }}
                   />
                   <h2 style={{ fontSize: '20px', color: '#333', fontWeight: '600', marginBottom: '10px' }}>Your Cart is Empty!</h2>
                   <p style={{ margin: '10px 0', fontSize: '16px', color: '#666', lineHeight: '1.5' }}>
                    Start exploring our collection and add your favorites to enjoy a seamless shopping experience!
                   </p>
                   <br />
                   <Button
                     tag={Link}
                     to="/wishlist"
                     color="primary"
                     style={{ padding: '10px 20px', fontSize: '14px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.2)', textTransform: 'uppercase' }}
                   >
                     ADD PRODUCTS FROM WISHLIST
                   </Button>
                 </section>                                
        )}

      </section>
      {/* {paymentData?<Payment
            paymentData={paymentData}
            />:""} */}

      <Footer />
    </>
  );
}

export default Cart;