import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
export default function CarouselGallery(props) {
  
  return (
    <div>
      <Carousel autoPlay={true} interval={2000} infiniteLoop={true}>
        {props.img.map((item, index) => {
          
          return (
            
            <div key={index} className="imgwrap">
              <img src={item.imgurl} className="imgwrap-img" alt={item} />
              <p>{item.varient.key}:{item.varient.value}</p>
            
            </div>
            
           
            
          )
        })}
        
      </Carousel>

    </div>
  )
}
