import React from 'react';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';  // Import compose
import {thunk} from 'redux-thunk';  // Correct import statement for thunk
import cartReducer from '../reducers/cartReducer';
import wishlistReducer from '../reducers/wishlistReducer';
import productReducer from '../reducers/productReducer';
import userVisitedReducer from '../reducers/userVisitedProductReducer';
import userOrderReducer from '../reducers/userOrderReducer';
import userReturnOrderReducer from '../reducers/userReturnOrderReducer';
import userCancleOrderReducer from '../reducers/userCancleOrderReducer';
import offerModalReducer from '../reducers/offerModalReducer';

const rootReducer = combineReducers({
  cart: cartReducer,
  wishlist: wishlistReducer,
  products: productReducer,
  userVisited: userVisitedReducer,
  userOrder:userOrderReducer,
  userReturnOrder:userReturnOrderReducer,
  userCancleOrder:userCancleOrderReducer,
  offerModal:offerModalReducer
});

// Use compose to combine the enhancers
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Apply middleware and DevTools extension together using composeEnhancers
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
