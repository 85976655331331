import React,{useState,useEffect} from 'react';
import PageTitle from '../../components/PageTitle';
import Footer from '../../layouts/Footer';
import { Link,useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { callApi } from '../../utils/Api';
import EditAddress from './EditAddress';
import AddNewAddress from './AddNewAddress';
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

const AddressPage=()=> {
    const navigate=useNavigate()
    const userId=localStorage.getItem('userid')
    if(!userId){
        navigate("/login")
    }
    const [allAddress,setAllAddress]=useState([]);
    const [addressId, setAddressId] = useState("");
    const [userName,setUserName]=useState("")
    const [userEmail,setUserEmail]=useState("")
    const [userPhone,setUserPhone]=useState()
    const [birthday,setBirthday]=useState("")
    const [gender,setGender]=useState('Other')
    const [editAddModal, setEditAddModal] = useState(false);
    const [addAddressModal,setAddAddressModal]=useState(false)
    const toggleAddAddressModal=()=>setAddAddressModal(!addAddressModal)
    const toggleEditAddModal = () => setEditAddModal(!editAddModal);
    const [uAddress, setUAddress] = useState({
        Name: "",
        phoneNumber: "",
        PinCode: "",
        Address: "",
        Locality: "",
        City: "",
        State: "",
        AddressType: "",
        isDefault: false,
      });
    const userData = ()=>{
        callApi('post', 'merchant/getMerchant/getUserDetailsByUserId', { userId: userId }).then(res => {
            // console.log(res.data);
            if (res.data) {
                setUserName(res.data.displayName||"");
                setUserPhone(res.data.phoneNumber);
                setUserEmail(res.data.email);
                setBirthday(res.data.dateOfBirth||"");
                // setBirthday(new Date(res.data.dateOfBirth).toLocaleString('en-US', {day: '2-digit',month: '2-digit',year: 'numeric'}));
                setGender(res.data.gender);
                localStorage.setItem('UserData', JSON.stringify(res.data));

            }
        }).catch(err => { console.log(err); })
    }
    const getAllAddress = () => {
        callApi("post", "cart/cartcrud/getAddress", {
          userId: userId,
        })
          .then((res) => {
            setAllAddress(res.data);
          
          })
          .catch((err) => {
            console.log(err);
          });
      };
    useEffect(() => {
        userData();
        getAllAddress();
      }, []);
    const defaultAddressCheck = (e) => {
        e.preventDefault();
        updateDefaultAddress(e.target.value);
      };
    const updateDefaultAddress = (id) => {
        callApi("post", "cart/cartcrud/updateAllAddress", {
          user: userId,
          id: id,
        })
          .then((res) => {
            getAllAddress();
          })
          .catch((err) => {
            console.log(err);
          });
      };
    const removeAddress = (id) => {
        callApi("post", "cart/cartcrud/removeAddress", { addressId: id })
          .then((res) => {
            // console.log(res.data);
            getAllAddress();
            if(res.code===200){
              Store.addNotification({
                title: "Address Removed Successfully!",
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            }else{
              Store.addNotification({
                title: "Something is Wrong!",
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            }
          })
          .catch((err) => {
            console.log(err);
            Store.addNotification({
              title: "Something is Wrong!",
              type: "danger",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                  duration: 5000,
                  onScreen: true
              }
          });
          });
      };
    const handelAdddressChange = (e) => {
        const { name, value } = e.target;
        setUAddress({
          ...uAddress,
          [name]: name === "isDefault" && value === "on" ? e.target.checked : value,
        });
      };
    const getUpdateId = (id) => {
        toggleEditAddModal();
        setAddressId(id);
        updateAddress(id)
      };
  const updateAddress = (id) => {
        callApi("post", "cart/cartcrud/getAddressByID", { id: id })
          .then((res) => {
            setUAddress(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      };
    const changePhoneNo=()=>{

    }
    const closeAddressUpdteBtn = (
      <button
        className="close"
        style={{ fontSize: "25px" }}
        onClick={toggleEditAddModal}
        type="button"
      >
        &times;
      </button>
    );
    const closeAddBtn = (
      <button
        className="close"
        style={{ fontSize: "25px" }}
        onClick={toggleAddAddressModal}
        type="button"
      >
        &times;
      </button>
    );
    const updateAddressData = (e) => {
      e.preventDefault();
      const data = {
        id: addressId.toString(),
        Name: uAddress.Name,
        phoneNumber: uAddress.phoneNumber,
        PinCode: uAddress.PinCode,
        Address: uAddress.Address,
        Locality: uAddress.Locality,
        City: uAddress.City,
        State: uAddress.State,
        AddressType: uAddress.AddressType,
        isDefault: uAddress.isDefault,
      };
      callApi("post", "cart/cartcrud/editAddress", data)
        .then((res) => {
          // console.log(res);
          getAllAddress();
          toggleEditAddModal()
          if(res.code===200){
            Store.addNotification({
              title: "Address Updated Successfully!",
              type: "success",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                  duration: 3000,
                  onScreen: true
              }
          });
          }else{
            Store.addNotification({
              title: "Something is Wrong!",
              type: "danger",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                  duration: 5000,
                  onScreen: true
              }
          });
          }   
        })
        .catch((err) => {
          Store.addNotification({
            title: "Something is Wrong!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        });
    };
    const saveChanges=(e)=>{
      e.preventDefault();
      if(userId){
        const updateData={
          // phoneNumber: userPhone,
          displayName:userName,
          email : userEmail,
          gender: gender,
          dateOfBirth: birthday
        }
      // console.log("first",updateData)  
      callApi("put",`users/updateUserById/${userId}`,updateData)
      .then((res)=>{
        // console.log(res.data,res.code,res.message)
        if(res.code===202){
          Store.addNotification({
            title: res.message,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }
        else{
          Store.addNotification({
            title: res.message,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        }
        userData()
      })
      .catch((error)=>{
      // console.log(error)
      Store.addNotification({
        title: "Something is Wrong!",
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
  })}
  }
      
    // console.log("dateofBirth",birthday)
  return (<>
    <ReactNotifications />
    <section className="h-100 gradient-custom">
    <PageTitle />
    <div className="container h-100">
    <hr></hr>
        <div className="row">
            <div className="col-md-8 col-12">
                <h4 className='edit-details'>Address</h4>
                    <div
                          onChange={(e) => {
                            defaultAddressCheck(e);
                          }}
                         >
                          {allAddress.map((item, index) => {
                            return (
                              <div className="card mt-4 shadow-sm p-2 bg-white rounded" key={index}>
                                <div className="card-body mt-3 mb-2">
                                  <div className="row">
                                    <div className="col-md-1 col-1">
                                      <input
                                        type="radio"
                                        id="dewey"
                                        value={item._id}
                                        name="drone"
                                        checked={item.isDefault === true}
                                        onChange={(e) => {
                                          defaultAddressCheck(e);
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-10 col-10">
                                      <div className="address-item">
                                        <h4 className="head-p">
                                          {item.Name}
                                          <span className="home-btn-address ml-3">
                                            {item.AddressType}
                                          </span>
                                        </h4>
                                      </div>
                                      <h4 className="head-p">
                                        {item.Address},{item.Locality},
                                        {item.City},{item.State}-{item.PinCode}
                                      </h4>
                                      <h4 className="head-p">
                                        Mobile:{" "}
                                        <span style={{ fontWeight: "600" }}>
                                          {item.phoneNumber}
                                        </span>{" "}
                                      </h4>
                                      <Button
                                        outline
                                        className="mr-4"
                                        onClick={() => removeAddress(item._id)}
                                        style={{
                                          color: "#ff0000",
                                          fontSize: "12px",
                                          backgroundColor: "transparent",
                                          border: "1px solid #ff0000",
                                          textDecoration: "none",
                                        }}
                                      >
                                        Remove
                                      </Button>

                                      <Button
                                        onClick={() => getUpdateId(item._id)}
                                        outline
                                        style={{
                                          color: "#ff0000",
                                          fontSize: "12px",
                                          backgroundColor: "transparent",
                                          border: "1px solid #ff0000",
                                          textDecoration: "none",
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <br />
                        <div className="col-12">
                                <button
                                  type="submit"
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={toggleAddAddressModal}
                                  className="btn btn-primary mt-2 width-100"
                                >
                                Add New Address
                                </button>
                              </div>
                </div>
             <div className="col-md-4 col-12">
                    <div className='vline'>
                    <div className='mt-2'>
                    <h2>{userName||userEmail}</h2>
                    <h4 className="edit-details">Edit Details</h4>
                <hr></hr>
                <div className="card mt-4 shadow-sm p-2 bg-white rounded">
                          <div className="card-body mt-3 mb-3 ml-3">
                            <div className="row">
                              <div className="col-md-6 col-12">
                                <div>
                                    <p>Mobile Number</p>
                                </div>
                                 <div className='mt-2 mb-2'>
                                    {userPhone}
                                 </div>
                                </div>
                              <div className="col-md-6 col-12">
                                <button
                                  type="submit"
                                  style={{
                                    cursor: 'not-allowed', //'pointer',
                                  }}
                                  onClick={() => {
                                    changePhoneNo();
                                  }}
                                  className="btn btn-primary mt-2 width-100"
                                >
                                Change
                                </button>
                              </div>
                            </div>
                    </div>
                  </div>
                  <br></br>
                  <div className=" shadow-sm p-2 bg-white rounded">
                        <div className="row">
                                <label>
                                   Full Name
                                </label>
                                <input
                                    type="text"
                                    className="mr-2"
                                    name="remember"
                                    value={userName}
                                    onChange={(e)=>setUserName(e.target.value)}
                                    /> 
                        </div>
                  </div>
                  <div className="shadow-sm p-2 bg-white rounded">
                        <div className="row">
                                <label>
                                   Email
                                </label>
                                <input
                                    type="text"
                                    className="mr-2"
                                    name="remember"
                                    value={userEmail}
                                    onChange={(e)=>setUserEmail(e.target.value)}
                                    /> 
                        </div>
                  </div>
                  <br></br>
                  <div className=" shadow-sm p-2 bg-white rounded">
                        <div className="row">
                                  <div className="col-6">
                                  <button
                                  type="submit"
                                  style={{
                                    cursor:  'pointer',
                                  }}
                                  onClick={() =>setGender("Male") }
                                  className={`btn mt-2 width-100 ${gender==="Male"?"btn-primary":"btn-light"}`}
                                >
                                Male
                                </button>
                                  </div>
                                  <div className="col-6">
                                  <button
                                  type="submit"
                                  style={{
                                    cursor:  'pointer',
                                  }}
                                  onClick={() =>setGender("Female")}
                                  className={`btn mt-2 width-100 ${gender==="Female"?"btn-primary":"btn-light"}`}
                                >
                                Female
                                </button>
                                  </div>
                                
                        </div>
                  </div>
                  <br />
                  <div className="shadow-sm p-2 bg-white rounded">
                        <div className="row">
                                <input
                                    type="text"
                                    className="mr-2"
                                    name="remember"
                                    placeholder='Birthday (dd/mm/yyyy)'
                                    value={birthday}
                                    onChange={(e)=>setBirthday(e.target.value)}
                                    /> 
                        </div>
                  </div>
                  <br />
                  <button
                                  type="submit"
                                  style={{
                                    cursor:  'pointer',
                                  }}
                                  onClick={(e) => {
                                    saveChanges(e);
                                  }}
                                  className="btn btn-primary mt-1 width-100"
                                >
                                Save Change
                                </button>
            </div>
                <div>
                    <h4 className='edit-details'>Orders </h4>
                    <ul className='edit-order-list'>
                        <li>
                            <Link to='/Myorder'><p>Orders</p></Link>
                        </li>
                        <li>
                            <Link to='/wishlist'><p>Wishlist</p></Link>
                        </li>
                        <li>
                            <Link to='/cart'><p>Cart</p></Link>
                        </li>
                    </ul>
                </div>
                    
                <EditAddress 
                editAddModal={editAddModal} 
                toggleEditAddModal={toggleEditAddModal} 
                closeAddBtn={closeAddressUpdteBtn} 
                handelAdddressChange={handelAdddressChange} 
                uAddress={uAddress} 
                updateAddressData={updateAddressData} />
                <AddNewAddress 
                addAddressModal={addAddressModal} 
                toggleAddAddressModal={toggleAddAddressModal} 
                closeAddBtn={closeAddBtn} />
               </div>
            </div>
        </div>
    </div>
    </section>
<Footer/>
    </>
  )
}
export default React.memo(AddressPage);