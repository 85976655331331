import React from 'react'
import { Button, Row, Col, Card, CardBody, CardTitle, CardText,  ModalHeader, ModalBody  } from 'reactstrap';
import dateFormat from 'dateformat';
import {
    Table, Progress
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from "react-router-dom";
import ListingListSideBox from '../ListingListSideBox'
import CarouselGallery from './CarouselGallery'
import InstaPostCard from './InstaPostCard'
import ReviewForm from './ReviewForm'
import ContentLoader from 'react-content-loader'
import { useParams } from 'react-router-dom'
import Description from './Description'
import { useEffect, useState, useCallback } from 'react'
import axios from "axios";
import Gallery from "react-photo-gallery";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { apiUrl } from '../../constants/defaultValue'
import mongoose from 'mongoose'
import ModalComp from './ModalComp';
import Carousel, { ModalGateway, Modal } from "react-images";
import SimilarProductListing from '../SimilarProductListing';
import { callApi } from '../../utils/Api';
import WishListButton from './WishListButton';
import { ReactNotifications, Store, addNotification } from 'react-notifications-component';
import ImageZoom from "react-image-zooom";
import  ReactImageMagnify  from 'react-image-magnify';
import {paramCase} from "change-case";
import { addtoCart } from '../../actions/cartActions';
import { useDispatch,useSelector } from 'react-redux';
import Masonry,{ ResponsiveMasonry } from 'react-responsive-masonry';
import LoadingOverlay from 'react-loading-overlay';


var ObjectId = mongoose.Types.ObjectId;
export default function ProductPage(args) {
    const { name, id } = useParams();
    const userReducProduct=useSelector(state=>state.products)
    // console.log("userReducProduct.products.length",userReducProduct.products.length)
    const [productId, setProductId] = useState('');
    const [product, setProduct] = useState(userReducProduct.products.find(product => product._id === id)||{});
    const [productMRP,setProductMRP]=useState(product?.offerSummary||0);
    // const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [img, setImg] = useState(product.length?[...product?.productImages?.map(item => ({ src: item.imgurl, width: 4, height: 3 ,varient:item.varient}))]:[]);
    const [currentImage, setCurrentImage] = useState(img.length > 0 ? img[0].src : '');
    const [imgModal, setImgModal] = useState(false);
    const [displayreview, setDisplayreview] = useState(true);
    const [displaySpec, setDisplaySpec] = useState(true);
    const [imgModal1, setImgModal1] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [reviewLess, setReviewLess] = useState([]);
    const [adData, setAdData] = useState(null);
    const [reviewImg, setReviewImg] = useState([]);
    const [specLess, setSpecificationLess] = useState([]);
    const [specMore, setSpecificationMore] = useState([]);
    const [offerModal, setOfferModal] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [userIp, setUserIp] = useState(null);
    const [showSpecMore, setShowSpecMore] = useState([]);
    const [showSpecLess, setShowSpecLess] = useState([]);
    const [productWeight,setProductWeight]=useState();
    const [productLength,setProductLength]=useState();
    const [productBreadth,setProductBreagth]=useState();
    const [productHeight,setProductHeight]=useState()
    const [showMore,setShowMore]=useState(false);
    const [currentReviewIndexImages,setCurrentReviewIndexImages]=useState([]);
    const [deliveryAndRefundDetails, setDeliveryAndRefundDetails] = useState([]);
    const [displayDetails, setDisplayDetails] = useState([]); 
    const [details, setDetails] = useState([]);
    const [colorData, setColorData] = useState("");
    
    // const [selectedCourier, setSelectedCourier] = useState("");
    // const [selectedCourierPrice, setSelectedCourierPrice] = useState(0);
    // console.log("img",img)
    const toggle = () => {
        setImgModal(!imgModal);
        setCurrentImage(0);
    };
    
    
    const toggle1 = () => {
        setImgModal1(!imgModal1);
        setCurrentImage(0);
        setCurrentReviewIndexImages([])
    };
    const toggle2 = () => setModal1(!modal1);
    const [modal1, setModal1] = useState(false);
    const [productCategory,setProcductCategory]=useState("")
    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);
    const [modal, setModal] = useState(false);
    const [addModal1, setAddModal1] = useState(false);
    const togglemodel = () => setModal(!modal);
    const [modal2, setModal2] = useState(false);
    const togglemodel2 = () => setModal2(!modal2);
    const [modal3, setModal3] = useState(false);
    const toggle3 = () => setModal3(!modal3);
    const [modal4, setModal4] = useState(false);
    const toggle4 = () => setModal4(!modal4);
    const [modal5, setModal5] = useState(false);
    const toggle5 = () => setModal5(!modal5);
    const togglemodel1 = () => setModal1(!modal1);
    const toggleOfferModal = () => setOfferModal(!offerModal);
    const dispatch=useDispatch()
    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    const [size, setSize] = useState([]);
    const [color, setColor] = useState([]);
    const closeBtn4 = (<button className="close" style={{ fontSize: "25px" }} onClick={toggle1} type="button">
        &times;
    </button>);
    
    const handleOfferClick = (offer) => {
        setSelectedOffer(offer);
        toggleOfferModal();
    };
   const productData = async () => {
    let productData = {};
    if (ObjectId.isValid(productId) && productId !== "") {
        await axios.post(`${apiUrl}/product/getProduct/getProductByid`, { productId: productId }).then((response) => {
            // console.log("*******", response);
            let arr = [];
            productData = response.data.data.productData;
            setProduct(productData);
            setProcductCategory(productData.productCategory[0].value);
            setSpecificationLess(productData.productFeatures.slice(0, 10));
            setSpecificationMore(productData.productFeatures);
            setProductMRP(Math.round(Number(productData.grossPrice) / (1 - (Number(productData.offerSummary) / 100))));

            // console.log("Product Data For Courier",productData)
            // Set delivery and refund details
            const deliveryAndRefundDetails = productData.deliveryandRefundDetails || [];
            setDeliveryAndRefundDetails(deliveryAndRefundDetails);

            const merchantAddress = productData.merchant.merchantShippingAddress[0];
            localStorage.setItem('CourierAdd', JSON.stringify(merchantAddress));
            
            let img = [];
            productData.productImages?.forEach((item) => {
                img.push({ src: item?.imgurl, width: 4, height: 3, varient: item.varient });
            });
            // console.log("test pcitures => ", img[0].src)
            let size = [];
            let color = [];
            let colour = [];
            productData.productVarient?.forEach((item) => {
                if (item.key === 'Size') {
                  size.push(item.value);
                } else if (item.key === 'Color') {
                  color.push(item.value);
                } else if (item.key === 'Colour') {
                    colour.push(item.value);
                }
            });

            
            const combinedColors = [...new Set([...color, ...colour])];

            setSize(size);
            setColor(combinedColors);
            setImg([...img]);
            setProductLength(parseInt(productData?.perProductDimensionWithPackage?.productLengthValue || 1));
            setProductBreagth(parseInt(productData?.perProductDimensionWithPackage?.productWidthValue || 1));
            setProductHeight(parseInt(productData?.perProductDimensionWithPackage?.productHeightValue || 1));
            if (productData.perProductweightWithPackage?.key === "kg") {
                setProductWeight(productData.perProductweightWithPackage?.value);
            }
            if (productData.perProductweightWithPackage?.key === "gm") {
                setProductWeight(productData.perProductweightWithPackage?.value / 1000);
            }
            if (productData.perProductweightWithPackage?.key === "mg") {
                setProductWeight(productData.perProductweightWithPackage?.value / 1000000);
            }
        }).catch((error) => {
            console.log(error);
        });
    }
};

    useEffect(() => {
        async function getData() {
            await productData();
        }
        getData();
    }, [productId]);
    
   

    const handleShowMore = () => {
        setShowMore(true);
        setSpecificationLess(specMore);
    };

    const handleShowLess = () => {
        setShowMore(false);
        setSpecificationLess(specMore.slice(0, 10));
    };
    useEffect(() => {
        // console.log("ets => ", id)
        setProductId(id);
    }, [id]);

    // useEffect(()=>{
    //     if(!localStorage.getItem('token')){
    //         navigate('/login')
    //     }
    // },[])

    useEffect(() => {
        // Fetch user's public IP address
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                setUserIp(data.ip);
            })
            .catch(error => {
                console.error('Error fetching user IP:', error);
            });
    }, []);

    const LoginPass = () => {
        if (!localStorage.getItem('token')) {
            sessionStorage.setItem('previousPage', `/productdetails/${paramCase(product.productName)}/${product._id}`);
            navigate('/login')
        }
        else{
            sessionStorage.removeItem('previousPage');
        }
    }

    function refreshPage() {
        window.location.reload();
    }

    // function storeCartData() {
    //     localStorage.setItem('cartProductt', JSON.stringify(res.data));
    // }

    // Retrieve the stored cart data after the page reloads
    const storedCartData = localStorage.getItem('cartProductt');
    const cartData = storedCartData ? JSON.parse(storedCartData) : [];


    function refreshPage() {
        window.location.reload();
    }

    // function storeCartData(product) {
    //     // Retrieve the stored cart data
    //     console.log("SSSSSSSSSSSSSSSSSSSSSSSSSSSS",product)
    //     const storedCartData = localStorage.getItem('cartProductt');
    //     let cartData = storedCartData ? JSON.parse(storedCartData) : [];

    //     // Add the product to the cart data
    //     cartData.push(product);

    //     // Store the updated cart data
    //     // localStorage.setItem('cartData', JSON.stringify(cartData));
    // }





    // console.log("Product", product)
    const getReviews = () => {



        axios.post(`${apiUrl}/review/reviewCrud/getReviews`, { productId: product.productId })
            .then((response) => {

                setReviewLess(response.data.data.reviewsData.slice(0, 2));
                // console.log("first",response.data.data.reviewsData);
                setReviews(response.data.data.reviewsData);
                const reviewData = response.data.data.reviewsData || [];
                const img = reviewData.flatMap((item) =>
                  (item.image || []).map((imageItem) => ({ src: imageItem.imgurl }))
                );
                setReviewImg(img);
                
            }).catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getReviews();

    }, [product]);

    const setReviewMore = () => {
        setReviewLess(reviews);
        setDisplayreview(false);
    }
    const setReviewLesss = () => {
        setReviewLess(reviews.slice(0, 2));
        setDisplayreview(true);
    }
    const setSpecMore = () => {
        setDisplaySpec(false);
        setSpecificationLess(specMore);
    }
    const setSpecLess = () => {
        setDisplaySpec(true);
        setSpecificationLess(specMore.slice(0, 10));
    }
    const [options, setOptions] = useState([])
    // const updateCartData = async () => {
    //     const cartProductString = localStorage.getItem('DataProductListNumber');
    //     const cartProduct = JSON.parse(cartProductString);
    //     // const cartProduct = cartProductString ? JSON.parse(cartProductString) : [];
    //     const numItemsInCart = cartProduct ? cartProduct.length : 1;
    //     // const newItem = "newItemId";
    //     // cartProduct.push(newItem);
    //     // const updatedCartProductString = JSON.stringify(cartProduct);
    //     console.log("CartProduct=====>>>", numItemsInCart)
    //     // await localStorage.setItem('DataProductListNum', JSON.stringify(numItemsInCart));

    //     // Update the numItemsInCart variable or use it as needed in your code
    //     // ...

    //     // Update any UI elements that display the number of items in the cart
    //     // ...
    // };
    // console.log("merchentIdssssssssssssssssssssssssssssssssssss",product?.merchant?._id)
    
    const addToCart = (id, type) => {
        let userId = localStorage.getItem('userid');
        if (!userId) {
            LoginPass();
            return;
        }
        if (color.length > 0 && !colorData) {
            Store.addNotification({
                title: "Please select a color",
                message: "Color selection is required for this product",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            return;
        }
        if (size.length > 0 && !sizeData) {
            Store.addNotification({
                title: "Please select a size",
                message: "Size selection is required for this product",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            return;
        }
    
        let data = {
            product: id,
            user: userId,
            quantity: 1,
            size: sizeData || "",
            colour: colorData || "",
            merchentId: product?.merchant?._id
        }
    
        callApi('post', 'cart/cartcrud/addtoCart', data).then((response) => {
            checkCart();
            if (type === "Buy Now") {
                dispatch(addtoCart(response.data))
                goToCart();
            } else if (type === "Add to Cart") {
                dispatch(addtoCart(response.data))
                Store.addNotification({
                    title: "Added to Cart",
                    message: "The product has been added to your cart",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
            }
        }).catch((error) => {
            console.log(error);
            Store.addNotification({
                title: "Error",
                message: "There was an error adding the product to your cart",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        });
    }

    

    const [productList, setProductList] = useState([]);
    const [couponCode, setCouponCode] = useState([]);
    const [ad, setAd] = useState('');
    const [checkCoupon, setCheckCoupon] = useState('');
    const [checkCouponCode, setCheckCouponCode] = useState('');
    const [couponDiscount, setCouponDiscount] = useState('');
    const [priceCalculation, setPriceCalculation] = useState({});
    const [couponCodeMsg, setCouponCodeMsg] = useState('');
    const [serviceCharge, setServiceCharge] = useState([]);

    //api call for price calculation
    const priceCal = () => {
        callApi('post', 'cart/cartcrud/cartPriceCalculation', { user: localStorage.getItem('userid'), couponDiscount: couponDiscount, serviceCharge: serviceCharge.cost })
            .then((res) => {
                setPriceCalculation(res.data);
            }).catch((err) => {
                console.log(err);
            }
            )
    }
    useEffect(() => {
        priceCal();
    }, [])
    //api call for coupon code validation
    const checkCouponCodeValidator = (e) => {
        e.preventDefault();
        const data = {
            couponCode: checkCouponCode || checkCoupon,
            total: priceCalculation.total
        }
        callApi('post', 'cart/cartcrud/checkCouponCode', data)
            .then((res) => {

                setCouponDiscount(res.data.totalDiscount);
                if (res.data === false) {

                    setCouponCodeMsg('Invalid Coupon Code');
                } else {

                    setCouponCodeMsg('Valid Coupon Code and your total discount Amount is ' + res.data.totalDiscount);
                }

            }).catch((err) => {
                console.log(err);
            }
            )
    }
    //apply coupon code button
    const applyCouponCode = (e) => {
        e.preventDefault();
        checkCouponCodeValidator(e)
        // console.log("Coupon Code Apply", e)
    }
    useEffect(() => {
        priceCal();
    }, [couponDiscount, checkCouponCode])

    // console.log("Product Id .......>>>>>", { productId })

    //   setProductList(productId)
    //api call for get all coupon code
    // const getAllCouponCode = () => {
    //     callApi('post', 'cart/cartcrud/getFillterCoupon', { products: productList })
    //         .then((res) => {
    //             setCouponCode(res.data);
    //         }).catch((err) => {
    //             console.log(err);
    //         }
    //         )
    // }


    // AD SPACE GET ALL DATA

    // const getAllAd = (productId) => {
    //     callApi('post', 'ad/ads/getAllAd', { products: [productId] })
    //         .then((res) => {
    //             setAd(res.data);
    //         }).catch((err) => {
    //             console.log(err);
    //         }
    //         )
    // }
    const getAllAd = (productId) => {
        callApi('post', 'ad/ads/getAdByProduct', { products: [{ id: productId }] })
            .then((res) => {
                setAd(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }


    const getAdById = async (id) => {
        callApi('post', 'ad/ads/getAdById', { productId })
            .then((res) => {
                setAdData(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    // const getAllAd = (productId) => {
    //     callApi('post', 'ad/ads/getAdById', { products: [productId] })
    //       .then((res) => {
    //         console.log('API response:', res.data); // Debugging statement
    //         if(res.data.status === "success" && res.data.data){
    //           setAd(res.data);
    //         } else {
    //           setAd({});
    //         }
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }

    // console.log('Initial ad value:', ad);

    useEffect(() => {
        getAdById(productId)
        getAllAd(productId);
    }, [productId]);
    // console.log("Get All Ad ====>>>>", ad)

    //   Get All Coupon

    const getAllCouponCode = (productId) => {
        callApi('post', 'cart/cartcrud/getFillterCoupon', { products: [productId] })
            .then((res) => {
                setCouponCode(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getAllCouponCode(productId);
        getCartProduct();
    }, [productId]);
    // useEffect(() => {
    //     getCartProduct();
    // }, [productList])
    // console.log("Final CUP Data", couponCode);

    // console.log("Data For Product List", productList)
    // localStorage.setItem('DataProductListNumber', JSON.stringify(productList));



    const getCartProduct = () => {
        callApi('post', 'cart/cartcrud/getCartData', { user: localStorage.getItem('userid') })
            .then((res) => {
                let arr = [];
                res.data?.forEach((item) => {
                  // console.log("item for ProdctData Cart", item)
                  arr.push(item.product?._id);
                });
                // console.log("arr", arr)
                setProductList(arr);                
                // localStorage.setItem('DataProductListNumber', JSON.stringify(arr));
                // setCartProduct(res.data);
                // console.log("Set cart Product", res.data)
            }).catch((err) => {
                console.log(err);
            })
    }



    //coupon code check button
    const couponcodeCheck = (e) => {
        e.preventDefault();
        // console.log("Check Button", e);
        setCheckCoupon(e.target.value);
    }

    // const getAllAd = (productId) => {
    //     callApi('post', 'ad/ads/getAllAd', { products: productId })
    //       .then((res) => {
    //         setAd(res.data.filter((ad) => ad.product === productId));
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   };
    // console.log("getAd FrontEnd=====>>>>>", ad)

    const colorImages = product.productImages?.reduce((acc, img) => {
        const color = img.varient[0]?.value;
        if (color && !acc[color]) {
          acc[color] = img.imgurl;
        }
        return acc;
      }, {}) || {};

    const [check, setCheck] = useState("");
    const [checkData, setCheckData] = useState([]);
    const handleSizeClick = (selectedSize) => {
        setSizeData((prevSize) => (prevSize === selectedSize ? null : selectedSize));
    };

    const checkHandel = async (e) => {
        e.preventDefault();  

        // Debugging: Check the values of pincode and check
        // console.log("Pincode:", pincode);
        // console.log("Check:", check);
    
        
        let companyCode = pincode;
        if (product?.deliveryandRefundDetails?.some(detail => detail.label === 'Pickup Required')) {
            companyCode = '751012';
        }else{
            companyCode= pincode;
        }
    
        // Create the data object for the API request
        const data = {
            "pickup_postcode": companyCode,
            "delivery_postcode": check,
            "weight":+productWeight,
            "length":productLength||1,
            "breadth":productBreadth||1,
            "height":productHeight||1,
            "cod": 0,
        };

        // Debugging: Log the data object to verify its contents
        // console.log("Data sent to API:", data);

        try {
            const response = await callApi('post', 'cart/cartcrud/getShiprocketServiceability', data);

            // Debugging: Log the API response
            // console.log("Serviceability Response:", response.data);

            setCheckData(response.data?.data?.available_courier_companies);
        } catch (error) {
            console.log("Error during API call:", error);
        }
    };


    const [sizeData, setSizeData] = useState('');

    const [checkCartData, setCheckCartData] = useState(null);

    const checkCart = () => {
        callApi('post', `cart/cartcrud/checkCartProduct`, { user: localStorage.getItem('userid'), product: product._id }).then((response) => {
            // console.log("ResponseCart", response);
            setCheckCartData(response.data);
            // console.log("Check Cart Data",checkCartData)
        }
        ).catch((error) => {
            console.log(error);
        }
        );

    }
    useEffect(() => {
        checkCart();
    }, [product]);

    // const handleColorClick = (color) => {

    //     setColorData(color); 
    // };
    const handleColorClick = (color) => {
        setColorData(color);
        const reorderedImages = [
            ...img.filter(image => image.varient.some(variant => variant.value === color)),
            ...img.filter(image => !image.varient.some(variant => variant.value === color))
            
        ];
        setImg(reorderedImages);
    };

    let navigate = useNavigate();
    const goToCart = () => {
        setTimeout(() => {
            // refreshPage();
            navigate("/cart");
        }, 1000); // 1000 milliseconds = 1 seconds
    };



      const filteredImages = img.filter(image => image.varient[0]?.value === colorData);

    // Set initial image display
    useEffect(() => {
        if (filteredImages.length > 0) {
            setCurrentImage(0);
        }
    }, [colorData, filteredImages, setCurrentImage]);
    // const imageZoomComponent = (size, data) => {

    //     return (
    //         <>
    //             {
    //                 Array.isArray(data) && data.map((x, i) => {
    //                     // console.log("Test ImgZoom++>", x)
    //                     return (
    //                         <ImageZoom
    //                             key={i}
    //                             className="gallery-scroll"
    //                             src={x.src}
    //                             alt="A image to apply the ImageZoom plugin"
    //                         // onClick={openLightbox}
    //                         />
    //                     )
    //                 })
    //             }
    //         </>
    //     )
    // }

    // Retrieve the stored address from local storage
    const storedAddress = localStorage.getItem('CourierAdd');

    // Convert the string back to an object
    const address = JSON.parse(storedAddress);
    //   console.log("Stored Add",storedAddress)

    // Access individual properties of the address object
    const storeRefName = address?.storeRefName;
    const addressLine = address?.addressLine;
    const locality = address?.locality;
    const city = address?.city;
    const country = address?.country;
    const pincode = address?.pincode;

    const [name1, setName1] = useState('');
    // const { id } = useParams();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [PinCode, setPinCode] = useState('');
    const [address1, setAddress1] = useState('');
    const [locality1, setLocality1] = useState('');
    const [city1, setCity1] = useState('');
    const [state, setState] = useState('');
    const [addressType, setAddressType] = useState('');
    const [defalutAddress, setDefalutAddress] = useState(false);
    const [allAddress, setAllAddress] = useState([]);
    const [defaultAddress, setDefaultAddress] = useState({
        Name: '',
        phoneNumber: '',
        PinCode: '',
        Address: '',
        Locality: '',
        City: '',
        State: '',
        AddressType: '',
        isActive: false,
        isDefault: false,
    });

    // useEffect(() => {
    //     if (img.length > 0) {
    //         setCurrentImage(img[0].src);
    //     }
    // }, [img]);
    const [uAddress, setUAddress] = useState({
        "Name": "",
        "phoneNumber": "",
        "PinCode": "",
        "Address": "",
        "Locality": "",
        "City": "",
        "State": "",
        "AddressType": "",
        "isDefault": false
    });

    //api call for add address data
    const addAddresshandel = (e) => {
        e.preventDefault();
        const data = {
            Name: name1,
            phoneNumber: phoneNumber,
            PinCode: PinCode,
            Address: address1,
            Locality: locality1,
            City: city1,
            State: state,
            AddressType: addressType,
            isActive: false,
            isDefault: defalutAddress,
            user: localStorage.getItem('userid')
        }
        callApi('post', 'cart/cartcrud/addAddress', data)
            .then((res) => {
                console.log(res);
                setName1('');
                setPhoneNumber('');
                setPinCode('');
                setAddress1('');
                setLocality1('');
                setCity1('');
                setState('');
                setAddressType('');
                setDefalutAddress(false);
                getAllAddress();
            }).catch((err) => {
                console.log(err);
            })
    }

    //api call for get all address
    const getAllAddress = () => {
        callApi('post', 'cart/cartcrud/getAddress', { userId: localStorage.getItem('userid') })
            .then((res) => {
                setAllAddress(res.data);
                res.data.forEach((item) => {
                    if (item.isDefault) {
                      setDefaultAddress(item);
                    }
                  });                  
            }).catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getAllAddress();
    }, [])

    useEffect(() => {
        if (img.length > 0) {
            setCurrentImage(img[0].src); 
        }
    }, [img]);
    //custom close button for modal
    const closeBtn = (<button className="close" style={{ fontSize: "25px" }} onClick={toggle5} type="button">
        &times;
    </button>);
    const closeBtn1 = (<button className="close" style={{ fontSize: "25px" }} onClick={toggle} type="button">
        &times;
    </button>);
    const closeBtn2 = (<button className="close" style={{ fontSize: "25px" }} onClick={toggle2} type="button">
        &times;
    </button>);
    const closeBtn3 = (<button className="close" style={{ fontSize: "25px" }} onClick={toggle3} type="button">
        &times;
    </button>);

//Format Date
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2); // Get 2-digit day
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    return `${day} ${monthNames[monthIndex]} ${year}`;
  };

    return (
        <>
        <ReactNotifications />
            <Helmet>
                <title>The Zero Commission Online Shopping Site In India</title>
                <meta
                    name="description"
                    content="Online shopping for moblies, laptops, cameras, clothes, fashion, health, lifestyle, industrial goods, agricultural goods, machines and plants"
                />
            </Helmet>
            {product ?
                <div>
                    <section className="content" >
                        <section className="block">
                            <div className="container-fluid" >
                                <div className="row">
                                    <div className="col-md-7 col-12 ">
                                        { img.length !==0 &&
                                        <section className="img-gallery mb-5">
                                            <ResponsiveMasonry
              columnsCountBreakPoints={{ 250: 1, 350: 1, 750: 1, 900: 2 }}
              gutter={0}
            ><Masonry>
                                            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                            {
                                            Array.isArray(img) && img.map((x, i) => {
                                             // console.log("Test ImgZoom++>", x)
                                             return (
                                                <div  
                                                key={i}
                                                className="gallery-scroll mt-2"
                                                // photos={img}
                                                onClick={()=>{ setCurrentImage(i);
                                                    setViewerIsOpen(true);}} 
                                                       
                                                >
                                                    
                                                    <ReactImageMagnify {...{
                                                    smallImage: {
                                                        alt: 'Product Image',
                                                        isFluidWidth: true, 
                                                        src: x?.src,
                                                        width:550,
                                                        height: 660
                                                    },
                                                    largeImage: {
                                                        src: x?.src,
                                                        width: 1200,
                                                        height: 1800 
                                                    },
                                                    imageStyle: {
                                                        border: colorData && x.varient[0]?.value === colorData ? '2px solid #000' : 'none',
                                                        borderRadius: '0px',
                                                        filter: colorData && x.varient[0]?.value === colorData ? 'brightness(105%)' : 'none',
                                                        transition: 'transform 0.3s ease, border 0.3s ease',
                                                        transform: colorData && x.varient[0]?.value === colorData ? 'scale(1)' : 'scale(1)',
                                                    },
                                                    // enlargedImageContainerStyle: {
                                                    //     zIndex: 10,
                                                    //     position: 'absolute',
                                                    //     left: '100%',
                                                    //     top: '0',
                                                    //     marginLeft: '10px',
                                                    //     overflow: 'hidden',
                                                    //     backgroundColor: '#fff',
                                                    //     borderRadius: '10px',
                                                    //     boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                                                    //     width: 'calc(100% - 10px)', 
                                                    //     height: 'calc(100% - 10px)', 
                                                    // },
                                                    // enlargedImagePosition: 'beside'          
                                                        
                                                    }} />
                                                    </div> )
                                                 })
                                                 }
                                            {/* <Gallery className="gallery-scroll" photos={img} onClick={openLightbox}/> */}
                                            {/* <h2>Click the images given below to Zoom</h2> */}
                                            {/* {
                                                imageZoomComponent(img?.length, img)
                                            } */}
                                            <div className="row  ">



                                                {/* <div className="col-md-6 col-12 instacard"  >
                                                    <InstaPostCard />
                                                </div>
                                                <div className="col-md-6 col-12 instacard" >
                                                    <InstaPostCard />
                                                </div>
                                                <div className="col-md-6 col-12 instacard" >
                                                    <InstaPostCard />
                                                </div>
                                                <div className="col-md-6 col-12 instacard" >
                                                    <InstaPostCard />
                                                </div> */}
                                            </div>
                                            </Masonry>
                                            </ResponsiveMasonry>
                                        </section>
                                            }
                                        {/* <ModalGateway>
                                            {viewerIsOpen ? (
                                                <Modal onClose={closeLightbox}>
                                                    <Carousel
                                                        currentIndex={currentImage}
                                                        views={img.map(x => ({
                                                            ...x,
                                                            srcset: x.srcSet,
                                                            caption: x.title
                                                        }))}
                                                    />
                                                </Modal>
                                            ) : null}
                                        </ModalGateway>
                                        <ReviewForm productId={product.productId} getReviews={getReviews} />
                                        // <SimilarProductListing /> */}

                                        {/* <SimilarProductListing /> */}


                                    </div>
                                    <div className="col-md-5 col-12">

                                        {/* <h1 style={{ fontSize: "25px", fontWeight: "400" }}>{product.merchant?.merchantCompanyName}</h1> */}

                                        <div className="row">
                                            <div className="col-md-10">
                                            <h4 className="cart-item-title">{product.productName}</h4>
                                                </div>
                                            <div className="col-md-2">
                                                <WishListButton item={product._id} user={localStorage.getItem('userid')} getProducts={productData}></WishListButton>
                                                </div>
                                        </div>
                                        {/* <a href="/">
                                            <div className='rating-section col-md-4' >
                                                &nbsp; {product.reviewsSummery?.rating}  <i className='fa fa-star clr-star'></i>&nbsp;|
                                                &nbsp;{product.reviewsSummery?.totalReviews} Ratings </div>
                                        </a> */}

                                        <hr />
                                        {productMRP?(
                                        <div >

                                            <span className='rupees ml-1' style={{ fontSize: "20px" }}><i className='fa fa-inr'></i>{product.grossPrice}</span>
                                            <span className='cross-mark' style={{ fontSize: "18px", paddingLeft: "10px" }}>
                                                <i className='fa fa-inr'></i> {product.offerSummary} </span>
                                            <span className='offersummary' style={{ fontSize: "18px", paddingLeft: "10px" }}>({Math.round(((product.offerSummary-product.grossPrice)/product.offerSummary)*100)}% OFF)</span><br />
                                            <span className='tax-text'>inclusive of all taxes</span>
                                        </div>
                                        ):null}
                                        <br />

                                        {size.length > 0 && (
                                        <div className='mt-3'>
                                            <h4 className='cou-head'>
                                            <span style={{ fontSize: 20, marginRight: '15px' }} className='ml-1'>
                                                SIZE:
                                            </span>
                                            <span style={{ fontSize: 20, fontWeight: 'bold', color: '#333' }}>
                                                {sizeData || "NONE"}
                                            </span>
                                            </h4>

                                            <div className='d-flex mt-2'>
                                            {size[0].map((x) => (
                                                <Button
                                                key={x}
                                                onClick={() => handleSizeClick(x)}
                                                className='size-button'
                                                style={{
                                                    backgroundColor: sizeData === x ? '#ff0000' : '#f0f0f0',
                                                    color: sizeData === x ? '#fff' : '#000',
                                                    borderRadius: '25px',
                                                    width: '50px',
                                                    height: '50px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                    border: '1px solid #ddd',
                                                    fontSize: '16px',
                                                    marginRight: '10px',
                                                    transition: 'background-color 0.3s ease',
                                                }}
                                                >
                                                {x}
                                                </Button>
                                            ))}
                                            </div>
                                            {sizeData === "" && (
                                            <div className='mt-2' style={{ color: 'red', fontSize: '16px' }}>
                                                Please select a size
                                            </div>
                                            )}
                                        </div>
                                        )}
                                        <br />




                                       


                                        {/* <div>
                                            {size.length > 0 ? (
                                                <>
                                                    <div className='mt-3'>
                                                        <h1 style={{ fontSize: "20px", fontWeight: "400" }}>
                                                            SELECT SIZE
                                                            <Button style={{ color: "#ff0000" }} className="sizechart m-0" color='link'>
                                                                SIZE CHART<i className="fa fa-angle-right" style={{ paddingLeft: "10px" }} aria-hidden="true"></i>
                                                            </Button>
                                                        </h1>
                                                        <div className="row mt-3 mb-5">
                                                            {size[0].map((x, index) => (
                                                                <div className="col-md-1 col-2 mr-4" key={index}>
                                                                    <Button
                                                                        onClick={() => handleSizeClick(x)}
                                                                        className="size-button"
                                                                        style={{
                                                                            backgroundColor: sizeData === x ? '#ff0000' : 'transparent',
                                                                            color: sizeData === x ? '#fff' : '#000',
                                                                            borderRadius: '50%',
                                                                            padding: '8px',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        {x}
                                                                    </Button>
                                                                </div>
                                                            ))}
                                                            <div className="col-md-8"></div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
{/*                                         
    {size.length > 0 ? (
        <div className='mt-3'>
            <h1 style={{ fontSize: "20px", fontWeight: "400" }}>
                SELECT SIZE
                <Button style={{ color: "#ff0000" }} className="sizechart m-0" color='link'>
                    SIZE CHART<i className="fa fa-angle-right" style={{ paddingLeft: "10px" }} aria-hidden="true"></i>
                </Button>
            </h1>
            <div className="row mt-3 mb-5">
                {size[0].map((sizeOption, index) => (
                    <div className="col-md-1 col-2 mr-4" key={index}>
                        <Button
                            onClick={() => handleSizeClick(sizeOption)}
                            className="size-button"
                            style={{
                                backgroundColor: sizeData === sizeOption ? '#ff0000' : 'transparent',
                                color: sizeData === sizeOption ? '#fff' : '#000',
                                borderRadius: '50%',
                                padding: '8px',
                                cursor: 'pointer',
                            }}
                        >
                            {sizeOption}
                        </Button>
                    </div>
                ))}
                <div className="col-md-8"></div>
            </div>
        </div>
    ) : null} */}
                    {color?.length > 0 && (
  <div className='mt-3'>
    <h4 className='cou-head'>
      <span style={{ fontSize: 20, marginRight: '15px' }} className='ml-1'>
        COLOR:
      </span>
      <span style={{ fontSize: 20, fontWeight: 'bold', color: '#333' }}>
        {colorData || "NONE"}
      </span>
    </h4>

    <div className='d-flex mt-2 flex-wrap' style={{ gap: '10px' }}>
      {Object.entries(colorImages).map(([color, imgUrl]) => (
        <div
          key={color}
          onClick={() => handleColorClick(color)}
          style={{
            cursor: 'pointer',
            position: 'relative',
            width: '60px',
            height: '80px',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              border: colorData === color ? '2px solid #000' : '1px solid #ddd',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
          >
            <img
              src={imgUrl}
              alt={color}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div>
          {colorData === color && (
            <div
              style={{
                position: 'absolute',
                bottom: '-5px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '20px',
                height: '20px',
                backgroundColor: '#000',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <i className="fa fa-check" style={{ color: '#fff', fontSize: '12px' }}></i>
            </div>
          )}
        </div>
      ))}
    </div>
    {!colorData && (
      <div className='mt-2' style={{ color: 'red', fontSize: '16px' }}>
        Please select a color
      </div>
    )}
  </div>
)}








                                            <br />
                                        {/* <div>
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    {checkCartData ? <Button size="lg" className=' btn-productpage ' block onClick={() => { addToCart(product._id); LoginPass() }}>
                                                        <i class="fa fa-shopping-bag fa-bag" aria-hidden="true"></i>
                                                        Buy More
                                                    </Button> : <Button size="lg" className=' btn-productpage ' block onClick={() => { addToCart(product._id); LoginPass() }}>
                                                        <i class="fa fa-shopping-bag fa-bag" aria-hidden="true"></i>
                                                        Add To Cart
                                                    </Button>}
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Button size="lg" className=' btn-productpage-buy' onClick={() => { goToCart(); LoginPass(); addToCart(product._id); refreshPage(); storeCartData() }} block> <i className='fa fa-cart-plus fa-bag' style={{ color: "#fff" }}></i>Buy Now</Button>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div>
                                        <LoadingOverlay
                                            active={img.length===0}
                                            spinner
                                            text='Loading your content...'
                                             >
    <div className="row">
        {checkCartData && product.listingType==='service'? (
            <div className="col-md-12">
                <Button
                    size="lg"
                    className='btn-productpage'
                    block
                    onClick={() => { 
                        // addToCart(product._id); 
                        LoginPass();
                        window.location.href = '/requirement'; // Navigate to "/requirement" route
                    }}
                >
                    <i className="fa fa-shopping-bag fa-bag" aria-hidden="true"></i>
                    Book Your Requirement
                </Button>
            </div>
        ) : (
            <>
                <div className="col-md-6 col-12">
                {checkCartData ? <Button size="lg" className=' btn-productpage ' block onClick={() => { addToCart(product._id,"Buy More"); LoginPass() }}>
                                                        <i className="fa fa-shopping-bag fa-bag" aria-hidden="true"></i>
                                                        Buy More
                                                    </Button> : <Button size="lg" className=' btn-productpage ' block onClick={() => { addToCart(product._id,"Add to Cart"); LoginPass() }}>
                                                        <i className="fa fa-shopping-bag fa-bag" aria-hidden="true"></i>
                                                        Add To Cart
                                                    </Button>}
                </div>
                <div className="col-md-6 col-12">
                    <Button
                        size="lg"
                        className='btn-productpage-buy'
                        onClick={() => { 
                            LoginPass(); 
                            addToCart(product._id,"Buy Now"); 
                            // storeCartData(product);  
                            // goToCart();
                            // refreshPage(); 
                            
                        }}
                        block
                    >
                        <i className='fa fa-cart-plus fa-bag' style={{ color: "#fff" }}></i>
                        Buy Now
                    </Button>
                </div>
            </>
        )}
    </div>
    </LoadingOverlay>
</div>

                                        <hr />
{(Array.isArray(couponCode) && couponCode.length>0)&&(<div onChange={e => { couponcodeCheck(e) }}>
    <div className="">
        <h4 className='cou-head'>
            <i className="fa fa-tag fa-lg mr-1" style={{ color: "#ff0000" }}></i>
            <span style={{ fontSize: 20 }} className='ml-1'>OFFERS</span>
        </h4>
    </div>
       {couponCode.map((item, index) => {
            // console.log("Coupon show Data", couponCode)
            return (
                <div key={index} className="card mt-4 shadow-sm p-3 bg-white rounded">
                    <div className="card-body">
                        <div className="row">
                            {/* <div className="col-md-1 col-1" >
                                <input className="form-check-input" name='nmae' type="radio" value={item.couponCode} id="flexCheckDefault" />
                            </div> */}
                            <div className="col-md-12">
                                <div className="address-item mb-2">
                                    {/* <h4 className='head-p'>{item.couponName} <span className='ml-3 mr-3'><img width={50} height={50} src={item.img} /></span> </h4> */}
                                    <span style={{ fontSize: 15, color: 'red' }}>Use Code: <strong>{item.couponCode}</strong></span>
                                </div>
                                <h4 className='head-p' style={{ color: 'green', fontSize: 18 }}>
                                    Save: {item.couponVarient === "percentage" ? 
                                        <span style={{ fontWeight: "600" }}>{item.percentage}% OFF up to <i className="ml-2 fa fa-inr mr-2"></i>{item.maxCouponAmount}</span> : 
                                        <span style={{ fontWeight: "600" }}><i className="ml-2 fa fa-inr mr-2"></i>{item.flatAmount}</span>}
                                </h4>
                                <h4 className='head-p' style={{ color: 'black', fontSize: 16 }}>{item.description}</h4>
                                <h4 className='head-p' style={{ fontWeight: 'bold', fontSize: 15 }}>Expires on: {dateFormat(item.end, "dddd, mmmm dS, yyyy, h:MM:ss TT")}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        }
</div>
                                        )}
                                        





                                        <div>
                                            <h1 className='mb-3' style={{ fontSize: "19px", fontWeight: "300" }}>Check Your Delivery Location
                                                <i className="fa fa-truck" style={{ paddingLeft: "10px", color: "#ff0000" }} aria-hidden="true"></i>
                                            </h1>
                                            <div className="row">

                                                <div className="col-md-8 col-12">
                                                    <div className="form-group ">
                                                        <div className="input-group mb-3">
                                                            <input type="text" value={check} onChange={(e) => setCheck(e.target.value)} className="form-control" placeholder="Enter Pincode" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                            <div className="">
                                                                <button className="btn btn-outline-secondary" onClick={e => checkHandel(e)} style={{ color: "#000", backgroundColor: "transparent" }} type="button">Check</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                </div>
                                            </div>
                                            <span style={{ fontSize: "12px", fontWeight: "400" }}>Please enter PIN code to check delivery time & Pay on Delivery Availability</span>
                                            {check ? <>{checkData ? <>
                                                {checkData.map((x,index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            {/* <h4 className='mt-4' style={{ fontSize: "14px", fontWeight: "500" }}><input type="radio" className='mr-3' id="html" name="fav_language" value="HTML" />Estimate Delivery Time: <span className='text-success'>{x?.etd}</span> </h4> */}
                                                            <h4 className=' mt-4 ml-4 ' style={{ fontSize: "14px", fontWeight: "500", }}>Courier Name:<span className='text-success'>{x?.courier_name}</span> </h4>
                                                            <h4 className='mt-1 ml-4' style={{ fontSize: "14px", fontWeight: "500" }}>
                                                                {/* <input
                                                                    type="radio"
                                                                    className='mr-3'
                                                                    id="html"
                                                                    name="fav_language"
                                                                    value={x.courier_name}
                                                                    checked={selectedCourier === x.courier_name}
                                                                    onChange={() => {
                                                                        // setSelectedCourier(x.courier_name);
                                                                        // setSelectedCourierPrice(x.rate);
                                                                        // localStorage.setItem('selectedCourier', x.courier_name);
                                                                        // localStorage.setItem('selectedCourierPrice', x?.rate);
                                                                    }}

                                                                /> */}
                                                                {/* {console.log("Selected Courier Check", { selectedCourier })} */}
                                                                Estimate Delivery Time: <span className='text-success'>{x?.etd}</span>
                                                            </h4>
                                                            <h4 className=' mt-1 ml-4 ' style={{ fontSize: "14px", fontWeight: "500", }}>Courier Charges:<i className="fa fa-inr" aria-hidden="true"></i><span className='text-success'> {x?.rate}</span></h4>
                                                        </React.Fragment>

                                                    )
                                                })}


                                            </> : <h4 className=' mt-1 text-danger' style={{ fontSize: "14px", fontWeight: "500", }}> Delivery Unavailable Choose a Different Pincode</h4>}</> : null}


                                            <div className="mt-4 mb-4">
                                                {product.deliveryandRefundDetails?.map((item, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <span style={{ fontSize: "14px", fontWeight: "200" }}>{item.value}</span><br />
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        </div>

                                        {/* <div>
                                            <h1 className='mt-4 mb-4 ' style={{ fontSize: "20px", fontWeight: "400" }}> BEST OFFERS  <i className='fa fa-tag' style={{ color: "#ff0000" }}></i></h1>
                                            <h1 style={{ fontSize: "18px", fontWeight: "400" }}>Claim Offer: <span style={{ color: "#ff0000" }}></span>  </h1>
                                            <ul>
                                                <li className='list-prdouct'>Coupon Discount: Rs. 76 off (check cart for final savings)</li>
                                                <li className='list-prdouct'>Applicable on: Orders above Rs. 599 (only on first purchase)</li>
                                                <li className='list-prdouct'>Coupon code: MYNTRA200</li>
                                            </ul>
                                            <a href="" style={{ color: "#ff0000" }}>View Eligible Products</a>
                                        </div> */}

                                        {/* <div>
                                            <h1 className='mt-4 mb-3' style={{ fontSize: "16px", fontWeight: "500" }}>EMI option available</h1>
                                            <ul>
                                                <li className='list-prdouct'>EMI starting from Rs.11/month</li>

                                            </ul>
                                            <a href="" style={{ color: "#ff0000" }}>View Plan</a>

                                        </div> */}
                                        <hr />

                                        {/* <ModalFooter>
                                            <Button outline className='float-right width-100' style={{ color: "#ff0000", fontSize: "12px", backgroundColor: "transparent", border: "1px solid #ff0000", textDecoration: "none" }} onClick={toggle2}>
                                                Add New Address
                                            </Button>
                                        </ModalFooter> */}

                                        {/* <Modal isOpen={modal2} toggle={toggle2} {...args}> */}
                                        {/* <ModalHeader toggle={toggle2} close={closeBtn2}>ADD ADDRESS TO SHIP BY SELLER</ModalHeader>
                                        <ModalBody>
                                            <div class="card shadow-sm p-2 bg-white rounded">
                                                <div class="card-body mt-3 ml-3 mb-2">
                                                    <form className="form clearfix">
                                                        <h4 className='payment-header-price'>CONTACT DETAILS</h4>
                                                        <div className="form-group">
                                                            <input name="name" value={name1} onChange={(e) => setName1(e.target.value)} type="text" className="form-control" placeholder="Name*" required />
                                                        </div>
                                                        <div className="form-group">
                                                            <input name="phone" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} type="tel" className="form-control" placeholder="Phone Number*" required />
                                                        </div>
                                                        <h4 className='payment-header-price'>ADDRESS</h4>
                                                        <div className="form-group">
                                                            <input name="pincode" value={PinCode} onChange={(e) => setPinCode(e.target.value)} type="text" className="form-control" placeholder="Your Pincode*" required />
                                                        </div>
                                                        <div className="form-group">
                                                            <input name="address" value={address1} type="text" onChange={(e) => setAddress1(e.target.value)} className="form-control" placeholder="Your Address*" required />
                                                        </div>
                                                        <div className="form-group">
                                                            <input name="locality" value={locality1} onChange={(e) => setLocality1(e.target.value)} type="text" className="form-control" placeholder="Your Locality/Town*" required />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 col-6">
                                                                <div className="form-group">
                                                                    <input name="city" type="text" value={city1} onChange={(e) => setCity1(e.target.value)} className="form-control" placeholder="Your City*" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-6">
                                                                <div className="form-group">
                                                                    <input name="state" type="text" value={state} onChange={(e) => setState(e.target.value)} className="form-control" placeholder="Your State*" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h4 className='payment-header-price'>SAVE ADDRESS AS</h4>
                                                        <div className="row">
                                                            <div className="col-md-3 col-6"><div className="form-check">
                                                                <input className="form-check-input mr-3" onChange={(e) => setAddressType(e.target.value)} checked={addressType === 'Home'} value="Home" type="radio" name="home" />
                                                                <label className="form-check-label" for="flexRadioDefault1">
                                                                    Home
                                                                </label>
                                                            </div></div>
                                                            <div className="col-md-3 col-6"> <div className="form-check">
                                                                <input className="form-check-input mr-3" value="Work" onChange={(e) => setAddressType(e.target.value)} checked={addressType === 'Work'} type="radio" name="work" />
                                                                <label className="form-check-label" for="flexRadioDefault2">
                                                                    Work
                                                                </label>
                                                            </div></div>
                                                            <div className="col-md-6"></div>
                                                        </div>

                                                        <br />
                                                        <label>
                                                            <input type="checkbox" onChange={(e) => setDefalutAddress(!defalutAddress)} className='mr-3' name="remember" value="1" />
                                                            Make This my Default Address
                                                        </label>
                                                        <button type="submit" className="btn mt-4 btn-primary width-100" onClick={(e) => addAddresshandel(e)} >Add address</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </ModalBody> */}

                                        {/* </Modal> */}

                                        <div>
                                            <h1 style={{ fontSize: "16px", fontWeight: "600",color:"black",display:'inline' }}>PRODUCT DETAILS<i style={{ paddingLeft: "10px", color: "#ff0000" }} className='fa fa-list'></i> </h1>
                                            <div className={showMore?"show-all-description":'product-html-description'}>
                                            <ReactQuill theme={"bubble"} value={product.productHtmlDescription} readOnly={true} />
                                            </div>
                                           {!showMore?<span onClick={(e)=>setShowMore(!showMore)} className="conveniece-fee-know-more">Show More </span>:<span onClick={(e)=>setShowMore(!showMore)} className="conveniece-fee-know-more">Show Less </span>} 
                                        </div>

                                        {/* Specifications */}
                                        {/* <div style={{ padding: '20px' }}>
  {(specLess.length > 0 || specMore.length > 0) && (
    <Card style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '2px', marginLeft: "-20px" }}>
      <h1 className='mb-3' style={{ fontSize: "20px", fontWeight: "900", marginBottom: '25px', marginTop: "10px" }}>
        Specifications
        <i style={{ paddingLeft: "10px", color: "#ff0000" }} className='fa fa-list'></i>
      </h1>
      <hr />
      <CardBody style={{ paddingTop: '10px' }}>
        <div className={displaySpec ? "specifications" : "show-all-specifications"}>
          {specLess
            .filter(feature => feature.variants?.some(variant => variant.key && variant.value))
            .slice(0, showMore ? specLess.length : 2)
            .map((feature, index) => (
              <div key={index} style={{ marginBottom: '20px' }}>
                <CardTitle tag="h5" style={{ marginBottom: '10px', fontWeight: 'bold' }}>{feature.features}</CardTitle>
                {feature.variants.map((variant, idx) => (
                  variant.key && variant.value && (
                    <Row key={idx} style={{ marginBottom: '5px' }}>
                      <Col xs="6">
                        <CardText style={{ fontWeight: 'bold', color: '#888' }}>{variant.key}</CardText>
                      </Col>
                      <Col xs="6">
                        <CardText style={{ fontWeight: 'bold' }}>{variant.value}</CardText>
                      </Col>
                    </Row>
                  )
                ))}
                {index < specLess.length - 1 && <hr />}
              </div>
            ))
          }
        </div>
        {specLess.length > 2 && (
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            {!showMore ? (
              <span onClick={handleShowMore} className="conveniece-fee-know-more">View All</span>
            ) : (
              <span onClick={handleShowLess} className="conveniece-fee-know-more">View Less</span>
            )}
          </div>
        )}
      </CardBody>
    </Card>
  )}
  <hr />
</div> */}
<div style={{ padding: '20px', marginLeft: "-20px" }}>
  {(specLess.some(feature => feature.variants?.some(variant => variant.key && variant.value)) ||
    specMore.some(feature => feature.variants?.some(variant => variant.key && variant.value))) && (
    <Card style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '2px' }}>
      <h1 className='mb-3' style={{ fontSize: "20px", fontWeight: "900", marginBottom: '15px' }}>
        Specifications
        <i style={{ paddingLeft: "10px", color: "#ff0000" }} className='fa fa-list'></i>
      </h1>
      <div style={{ borderTop: '1px solid #ddd', marginBottom: '15px' }}></div> {/* Replaces <hr> */}
      <CardBody style={{ paddingTop: '10px' }}>
        <div className={displaySpec ? "specifications" : "show-all-specifications"}>
          {specLess
            .filter(feature => feature.variants?.some(variant => variant.key && variant.value))
            .slice(0, showMore ? specLess.length : 2)
            .map((feature, index) => (
              <div key={index} style={{ marginBottom: '20px' }}>
                <CardTitle tag="h5" style={{ marginBottom: '20px', fontWeight: 'bold' }}>{feature.features}</CardTitle>
                {feature.variants?.map((variant, idx) => (
                  variant.key && variant.value && (
                    <Row key={idx} style={{ marginBottom: '10px' }}>
                      <Col xs="6" style={{ paddingRight: '10px' }}>
                        <CardText style={{ 
                            fontWeight: 'bold', 
                            fontSize: '15px',
                            color: '#081f37', 
                            marginBottom: '20px' 
                        }}>
                            {variant.key}
                        </CardText>
                      </Col>
                      <Col xs="6" style={{ paddingLeft: '10px' }}>
                        <CardText style={{ fontWeight: 'bold', color: "#081c15", fontSize: '14px' }}>{variant.value}</CardText>
                      </Col>
                    </Row>
                  )
                ))}
                {index < specLess.length - 1 && (
                  <Col xs="12">
                    <div style={{ borderTop: '1px solid #ddd', margin: '10px 0 0 0' }}></div> 
                  </Col>
                )}
    </div>
            ))}
        </div>
        {specLess.length > 2 && (
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            {!showMore ? (
              <span onClick={handleShowMore} className="conveniece-fee-know-more">View All</span>
            ) : (
              <span onClick={handleShowLess} className="conveniece-fee-know-more">View Less</span>
            )}
          </div>
        )}
      </CardBody>
    </Card>
  )}
  <br />
  <div style={{ borderTop: '1px solid #ddd', width: '100%', margin: '0' }}></div> 
</div>



        {/* <div className="row" >
            {specLess.map((item, index) => (
                item.value && (
                    <div key={index} className="col-md-6 col-6">
                        <h1 style={{ fontSize: "11px", fontWeight: "200" }}>{item.key}</h1>
                        <h1 style={{ fontSize: "15px", fontWeight: "400" }}>{item.value}</h1>
                        <hr />
                    </div>
                )
            ))}
        </div>
        {specLess.length > 10 && (
            <>
                {displaySpec ? (
                    <Button onClick={setSpecMore} color='link' style={{ color: "#ff0000" }}>View all</Button>
                ) : (
                    <Button onClick={setSpecLess} color='link' style={{ color: "#ff0000" }}>View Less</Button>
                )}
            </>
        )} */}

                                        
                                        <div className="row"><h4 className='cou-head'><i className="fa fa-tag fa-lg mb-3" style={{ color: "#ff0000" }}></i>Advertise Your Business Here</h4></div>
                                        {/* <p>User's IP address: {userIp}</p> */}
                                        {Array.isArray(ad) && ad.map((item, index) => {
                                            return (
                                                <div key={index} className="card mt-4 shadow-sm p-2 bg-white rounded ">
                                                    <div className="card-body mt-3 ml-3 mb-2">
                                                        <div className="row mt-4">
                                                            {/* <div className="col-md-1 col-1" >
                            <input className="form-check-input" name='nmae' type="radio" value={item.couponCode} id="flexCheckDefault" />
                        </div> */}
                                                            <div className="col-md-12 col-sm-12">
                                                                <div className="col-md-6 col-sm-12 text-center">
                                                                    {/* <h4 className='head-p'> <span className='ml-3 mr-3'><img width={"auto"} height='auto' src={item.img} /></span> </h4> */}
                                                                    {/* <h4 className='head-p'>{item.couponName} <span className='ml-3 mr-3'><img width={50} height={50} src={item.img} /></span> </h4> */}
                                                                    {/* <span style={{ fontSize: 15, color: 'red' }}>{item.adName}</span> */}
                                                                </div>
                                                                {/* <h4 className='head-p' style={{ color: 'green', fontSize: 16 }}>{item.link} </h4> */}
                                                                {/* <a href={item.link}>
                                                                    <h4 className='head-p' style={{ color: 'green', fontSize: 16 }}>{item.link}</h4>
                                                                </a> */}
                                                                   <a href={item.link} target="_blank" rel="noopener noreferrer">
                                        <img width={"auto"} height='auto' src={item.img} alt={item.description} />
                                    </a>
                                                                {/* <Link>{item.link}</Link> */}
                                                                <h4 className='head-p' style={{ fontColor: 'black', fontSize: 15 }}>{item.description}</h4>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {/* <hr />
                                        <div>
                                            <h1 style={{ fontSize: "20px", fontWeight: "500" }}>Complete The Look
                                                <i class="fa fa-search" style={{ paddingLeft: "10px", color: "#ff0000" }} aria-hidden="true"></i></h1>
                                            <p className='mb-4 mt-4'>
                                                {
                                                    product.productTags?.map((tag, index) => {
                                                        return <span className='rating-section-tag ml-2' key={index}><i class="fa fa-hashtag"></i>{tag}</span>
                                                    })
                                                }
                                            </p>
                                        </div>
                                        <hr /> */}

                                        <div className='mt-3'>
                                            <h1 className='cou-head' style={{ fontSize: "16px", fontWeight: "600" }}>RATINGS<i style={{ paddingLeft: "10px", color: "#ff0000" }} className='fa fa-star clr-star'></i></h1>
                                            <div className="row">
                                                <div className="col-md-3 col-5">
                                                    <div className='row star-rating-main ml-1' >
                                                        {product.reviewsSummery?.rating} 
                                                        {" "}<i className='fa fa-star star-rating-main-icon'></i>
                                                    </div>

                                                    {/* <div style={{ fontSize: "12px", fontWeight: "400" }}>
                                                        {product.reviewsSummery?.totalReviews} Verified Buyers </div> */}
                                                </div>
                                                <div className="col-md-1 col-1">
                                                    <div className="vl"></div>
                                                </div>
                                                <div className="col-md-8 col-6 mt-4">
                                                    <div className="row">
                                                        <div className="col-md-3 col-4">
                                                            <span className='star-m' >5
                                                                <i className='fa fa-star ml-1'></i>
                                                            </span>
                                                        </div>
                                                        <div className="col-md-5 col-4" id='cb1'>
                                                            <Progress className='progress-m ' value={(product.reviewsSummery?.fiveStar / product.reviewsSummery?.totalReviews) * 100} />
                                                        </div>
                                                        {/* <div className="col-md-2 col-2">
                                                            <span >{product.reviewsSummery?.fiveStar}</span>
                                                        </div> */}
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-3 col-4">
                                                            <span className='star-m' >4
                                                                <i className='fa fa-star ml-1'></i>
                                                            </span>
                                                        </div>
                                                        <div className="col-md-5 col-4" id='cb1'>
                                                            <Progress className='progress-m ' value={(product.reviewsSummery?.fourStar / product.reviewsSummery?.totalReviews) * 100} />
                                                        </div>
                                                        {/* <div className="col-md-2 col-2">
                                                            <span >{product.reviewsSummery?.fourStar}</span>
                                                        </div> */}
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-3 col-4">
                                                            <span className='star-m' >3
                                                                <i className='fa fa-star ml-1'></i>
                                                            </span>
                                                        </div>
                                                        <div className="col-md-5 col-4" id='cb1'>
                                                            <Progress className='progress-m ' value={(product.reviewsSummery?.threeStar / product.reviewsSummery?.totalReviews) * 100} />
                                                        </div>
                                                        {/* <div className="col-md-2 col-2">
                                                            <span >{product.reviewsSummery?.threeStar}</span>
                                                        </div> */}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3 col-4">
                                                            <span className='star-m' >2
                                                                <i className='fa fa-star ml-1'></i>
                                                            </span>
                                                        </div>
                                                        <div className="col-md-5 col-4" id='cb1'>
                                                            <Progress className='progress-m ' value={(product.reviewsSummery?.twoStar / product.reviewsSummery?.totalReviews) * 100} />
                                                        </div>
                                                        {/* <div className="col-md-2 col-2">
                                                            <span >{product.reviewsSummery?.twoStar}</span>
                                                        </div> */}
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-3 col-4">
                                                            <span className='star-m' >1
                                                                <i className='fa fa-star ml-2'></i>
                                                            </span>
                                                        </div>
                                                        <div className="col-md-5 col-4" id='cb1'>
                                                            <Progress className='progress-m ' value={(product.reviewsSummery?.oneStar / product.reviewsSummery?.totalReviews) * 100} />
                                                        </div>
                                                        {/* <div className="col-md-2 col-4">
                                                            <span >{product.reviewsSummery?.oneStar}</span>
                                                        </div> */}
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        {reviewImg.length?<div>
                                            <h4 className='cou-head'
                                                style={{ fontSize: "16px", fontWeight: "600" }}>
                                                Customer Photos 
                                                {/* ({reviewImg.length}) */}
                                            </h4>
                                            <div className="row mt-4 mb-4 custom-row">
                                            {reviewImg.slice(0, 2).map((img, index) => {
                                                    return (
                                                        <div key={index} className="col-md-3 col-4 custom-column" onClick={()=>{
                                                           setCurrentImage(index)
                                                            setImgModal(true)
                                                            // console.log("Image clicked:", index);
                                                        }}>
                                                            <img width={100} height={100} src={img.src} alt="" />
                                                        </div>
                                                    )
                                                })}
                                                {reviewImg.length > 2 ? <>
                                                    <div className="col-md-3 col-4 custom-column">
                                                        <div className="image-more" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(${reviewImg[2].src})`,width:"100px",height:"100px", backgroundSize: 'cover',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                                                            <div className="text-center">
                                                                <Button className='btn-img-more'
                                                                    onClick={toggle}><i className="fa fa-plus image-more-icon" aria-hidden="true"><span>{reviewImg.length - 2}</span></i>
                                                                </Button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : null}


                                            </div>
                                        </div>:null}

                                        <Modal isOpen={offerModal} toggle={toggleOfferModal}>
                <ModalHeader toggle={toggleOfferModal}>Offer Details</ModalHeader>
                <ModalBody>
                    {selectedOffer ? (
                        <>
                            <h4 style={{ fontSize: 18, color: 'red' }}>Coupon Code: {selectedOffer.couponCode}</h4>
                            <h5 style={{ color: 'green', fontSize: 16 }}>
                                Save: {selectedOffer.couponVarient === "percentage" ? 
                                    <span>{selectedOffer.percentage}% OFF up to <i className="fa fa-inr mr-1"></i>{selectedOffer.maxCouponAmount}</span> : 
                                    <span><i className="fa fa-inr mr-1"></i>{selectedOffer.flatAmount}</span>
                                }
                            </h5>
                            <p style={{ fontSize: 15 }}>{selectedOffer.description}</p>
                            <p style={{ fontWeight: 'bold', fontSize: 14 }}>
                                Expires on: {dateFormat(selectedOffer.end, "dddd, mmmm dS, yyyy, h:MM:ss TT")}
                            </p>
                            <button 
                                className="btn btn-primary mt-3" 
                                onClick={() => {
                                    setCheckCoupon(selectedOffer.couponCode);
                                    toggleOfferModal();
                                }}
                            >
                                Apply Coupon
                            </button>
                        </>
                    ) : (
                        <p>No offer details available.</p>
                    )}
                </ModalBody>
            </Modal>

                                        <ModalComp toggle={toggle} imgModal={imgModal} images={reviewImg} currentImage={currentImage} />
                                        {reviews.length?
                                        <div>
                                            <h4 className='cou-head'
                                                style={{ fontSize: "16px", fontWeight: "600" }}>
                                                Customer Reviews 
                                                {/* ({reviews.length}) */}
                                            </h4>
                                            {reviewLess.map((review, index) => {

                                                return (
                                                    <div key={index} className="mt-4 ml-4 ">

                                                        <div className="row">
                                                                {review.rating} 
                                                                <span className='ml-1'>
                                                                <i className='fa fa-star clr-star'></i>
                                                                </span>
                                                                <span className='ml-2' style={{ fontSize: "14px", fontWeight: "500" }}> {review.review}
                                                                </span> {/* <div className="col-md-4 col-3"></div> */}

                                                        </div>
                                                        <div className="row">
                                                            {/* <div className="col-md-2 col-3">
                                                            </div> */}
                                                            <div className="col-md-12 col-11">
                                                                <div className="row ml-3 mt-2 custom-row">
                                                                    {review.image?.slice(0, 3).map((img, ind) => {

                                                                        return (
                                                                            <div key={ind} className="col-md-3 col-3 custom-column"
                                                                                onClick={()=>{
                                                                                setCurrentReviewIndexImages(review.image)
                                                                                setCurrentImage(ind)
                                                                                setImgModal1(true)}}>
                                                                                <img width={70} height={70} src={img.imgurl} alt="" />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                    <div className="col-md-3 col-3 custom-column">
                                                                        {review.image.length > 3 ? <>
                                                                            <div className="">
                                                                                <div className="image-more-review" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(${review.image[3].imgurl})`,width:"70px",height:"70px", backgroundSize: 'cover',backgroundPosition: 'center',backgroundRepeat: 'no-repeat', }}>  
                                                                                    <div className="text-center">
                                                                                        <Button className='btn-img-more-review'
                                                                                            onClick={()=>{
                                                                                                setCurrentReviewIndexImages(review.image)
                                                                                                setImgModal1(true)}}><i className="fa fa-plus image-more-icon-review" aria-hidden="true"><span>{review.image.length - 2}</span></i>
                                                                                        </Button>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </> : null}
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="row mt-3 ml-2">
                                                            <div className="col-md-12 col-12 ">
                                                            <span className='product-review-user'>{review?.user?.displayName?review?.user?.displayName:review.user?.firstName? review.user?.firstName:"Mayifind Customer"} | <span>{formatDate(review.createdAt)}</span></span>
                                                            </div>
                                                            {/* <div className="col-md-3 col-6 text-left">
                                                                <p style={{ fontSize: "12px", fontWeight: "400"}}>{new Date(review.createdAt).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: 'numeric' })}</p>
                                                            </div> */}
                                                        </div>
                                                        <hr />


                                                       

                                                    </div>

                                                )
                                            })}
                                            {imgModal1&&currentReviewIndexImages.length!==0?<ModalComp toggle={toggle1} imgModal={imgModal1} images={currentReviewIndexImages} currentImage={currentImage} />:null}
                                            {reviews.length > 2 ?
                                                <>
                                                    {displayreview ? <Button onClick={setReviewMore} color='link' style={{ color: "#ff0000" }}>View all {reviews.length} reviews</Button> : <Button onClick={setReviewLesss} color='link' style={{ color: "#ff0000" }}>View Less</Button>}


                                                </>

                                                : null}

                                            <ModalGateway>
                                                {viewerIsOpen ? (
                                                    <Modal onClose={closeLightbox}>
                                                        <Carousel
                                                            currentIndex={currentImage}
                                                            views={img.map(x => ({
                                                                ...x,
                                                                srcset: x.srcSet,
                                                                caption: x.title
                                                            }))}
                                                        />
                                                    </Modal>
                                                ) : null}
                                            </ModalGateway>
                                        </div>:null}
                                        <ReviewForm productId={product.productId} getReviews={getReviews} />



                                    </div>

                                </div>

                            </div>

                        </section>
                    </section>
                    <SimilarProductListing productCategory={productCategory} />

{/* {console.log("Check product Category:",product)} */}
                </div>

                : <div><ContentLoader
                    speed={2}
                    width={476}
                    height={124}
                    viewBox="0 0 476 124"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"

                >
                    <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                    <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
                    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                    <circle cx="20" cy="20" r="20" />
                </ContentLoader></div>

            }
        </>
    )
}
