import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { apiUrl } from '../constants/defaultValue';
import { addProducts } from '../actions/productActions';
import WishListButton from './ProductDetails/WishListButton';
import AddToCartButton from './ProductDetails/AddToCartButton';
import { paramCase } from "change-case";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

export default function Listing() {
  const [categoriesWithProducts, setCategoriesWithProducts] = useState([]);
  const [product, setProduct] = useState([]);
  const [pageSize, setPageSize] = useState(1);
  const [loading, setLoading] = useState(true); 
  const [productsToShow, setProductsToShow] = useState(5);
  const [productCheck, setProductCheck] = useState(true);
  const [Limit, setLimit] = useState(4);
  const [totalCategory, setTotalCategory] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const usedispatch = useDispatch();

  const getProducts = () => {
    setLoading(true);
    axios.post(`${apiUrl}/product/getProduct/getProductsByCategory`, { limit: Limit, page: pageSize })
      .then((response) => {
        setTotalCategory(response.data.totalPage);
        const categories = response.data.data; 
        setProduct([...product, ...categories]);
        usedispatch(addProducts(categories));
        if (product.length === 0) {
          setCategoriesWithProducts(categories);
        }
        // setLoadingMore(false)
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      })
      .finally(() => {
        setLoading(false); 
      });
  };

  useEffect(() => {
    getProducts();
  }, [pageSize]);

  useEffect(() => {
    const handleResize = () => {
      setProductsToShow(window.innerWidth < 750 ? 2 : 5); 
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (product.length > 0) {
      setCategoriesWithProducts([...product]);
      // setCategoriesWithProducts(product.slice(0, Limit * pageSize));
    }
  }, [product]);

  const showMoreCategories = () => {
    setLoadingMore(true); 
    setPageSize(prev => prev + 1); 
    // setProductCheck(!productCheck);
    // console.log(totalCategory, product.length);
    // if (totalCategory <= (product.length + Limit)) {
    //   setProductCheck(!productCheck);
    // }
    
    // setTimeout(() => {
    //   setLoadingMore(false);
    // }, 1000); 
  };
  useEffect(()=>{
    if (totalCategory <= (product.length)) {
      setProductCheck(!productCheck);
    }
  },[product])
  const showLessCategories = () => {
    setLoadingMore(false);
    setCategoriesWithProducts(product.slice(0, Limit));
    setProduct([]); 
    setPageSize(1); 
    setProductCheck(!productCheck);
  };

  const addUserVisited = (productId) => {
    // console.log(`User visited product with ID: ${productId}`);
  };

  const renderSkeletonCard = () => (
    <div className="card masonry-card h-100">
      <Skeleton height={200} />
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col-6">
            <Skeleton width={60} />
          </div>
          <div className="col-3">
            <Skeleton circle={true} height={30} width={30} />
          </div>
          <div className="col-3">
            <Skeleton circle={true} height={30} width={30} />
          </div>
        </div>
        <div className="mt-2">
          <Skeleton count={2} />
          <Skeleton width="70%" />
        </div>
      </div>
    </div>
  );

  const getRandomInterval = () => {
    return Math.floor(Math.random() * (5000 - 3000 + 1)) + 3000;
  };
  if (categoriesWithProducts.length === 0) {
    return (
      <div>
        <section className="content">
          <section className="block">
            <div className="container">
              <div className="section-title clearfix">
                <div className="float-xl-left float-md-left float-sm-none">
                  <h2 className="text-muted mb-0">Recent Listings</h2>
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <AliceCarousel
                  items={Array.from({ length: productsToShow }).map((_, index) => (
                    <div key={index} className="col-auto" style={{ minWidth: '200px', maxWidth: '250px' }}>
                      {renderSkeletonCard()}
                    </div>
                  ))}
                  responsive={{ 0: { items: 2 }, 1024: { items: 5 } }} 
                  controlsStrategy="alternate"
                  autoPlay
                  autoPlayInterval={3000}
                  infinite
                  disableDotsControls 
                />
              </div>
            </div>
          </section>
        </section>
      </div>
    );
  }

  return (
    <div>
      <section className="content">
        <section className="block">
          <div className="container">
            <div className="section-title clearfix">
              <div className="float-xl-left float-md-left float-sm-none">
                 {/* <ButtonDropdown isOpen={open} className='sorting-option' toggle={toggle1}>
                  <DropdownToggle style={{ backgroundColor: "rgba(256, 256, 256, 1)", padding: '8px' }} className="small text-dark sorting-option width-200px" caret size="lg">
                    Choose Category
                  </DropdownToggle>
                  <DropdownMenu className='width-200px '>
                    {categories.map((category) => {
                      return (
                        <>
                          <DropdownItem className='width-200px ' onClick={e => routeChange(category)}>{category}</DropdownItem>
                        </>

                      )
                    })}
                  </DropdownMenu>
                </ButtonDropdown> */}
                {/* <ButtonDropdown isOpen={isopen} className='sorting-option' toggle={toggle}>
                  <DropdownToggle style={{ backgroundColor: "rgba(256, 256, 256, 1)", padding: '8px' }} className="small text-dark sorting-option width-200px" caret size="lg">
                    Default Sorting
                  </DropdownToggle>
                  <DropdownMenu className='width-200px '>
                    <DropdownItem className='width-200px ' onClick={allProduct}>All</DropdownItem>
                    <DropdownItem className='width-200px ' onClick={isFeaturedFilter}>Recommended</DropdownItem>
                    <DropdownItem className='width-200px ' onClick={newestFilter}>What's New</DropdownItem>
                    <DropdownItem className='width-200px ' onClick={maxFilter}>Sort by Max Price</DropdownItem>
                    <DropdownItem className='width-200px ' onClick={minFilter}>Sort Min Price</DropdownItem>
                    <DropdownItem className='width-200px ' onClick={maxDiscountFilter}>Sort by Discount</DropdownItem>
                    <DropdownItem className='width-200px ' onClick={highratingFilter}>Sort by Ratings</DropdownItem>
                    <DropdownItem className='width-200px ' onClick={oldestFilter}>Sort by oldest</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown> */}
                <h2 className="text-muted mb-0">Recent Listings</h2>
              </div>
            </div>

            {categoriesWithProducts.map((category,index )=> (
              category.products.length > 0 && (
                <div key={category.categoryId} style={{ position: 'relative', marginRight: "10px"}}>
                  <AliceCarousel  
                    items={category.products.map((item, key) => (
                      <Link key={key} to={`/productdetails/${paramCase(item.productName)}/${item._id}`}>
                        <div
                          onClick={() => { addUserVisited(item._id) }}
                          className="card masonry-card"
                          style={{marginTop:"20px"}}
                        >
                          {item.isFeatured === 'yes' && (
                            <div className="ribbon-featured"> 
                              <div className='ribbon-start'></div>
                              <div className='ribbon-content'>Featured</div>
                              <div className='ribbon-end'>
                                <figure className='ribbon-shadow'></figure>
                              </div>
                            </div>
                          )}
                          <div className="wrapper-img">
                            <div className="img">
                              <div className="slide-container">
                                <img style={{ width: "100%", height: "200px" }} src={item.productThumbnailImages[0]?.imgurl} alt={item.productName} />
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-6 col-6 mt-4">
                                <div className="m-price">{item.reviewsSummery.rating}&nbsp;<i className='fa fa-star clr-star'></i>
                                </div>
                              </div>
                              <div className="col-md-3 col-3 ">
                                <WishListButton item={item._id} user={localStorage.getItem('userid')} getProducts={getProducts}></WishListButton>
                              </div>
                              <div className="col-md-3 col-3 ">
                                <AddToCartButton pid={item._id} />
                              </div>
                            </div>
                            <div className="m-meta">
                              <a className="title m-indtitle text-capitalize">
                                {item.productName}
                              </a>
                              <a href="/" className="m-category macategory">
                                {item.productCategory[item.productCategory.length - 1]?.value}
                              </a>
                              <div>
                                <div className="row" style={{ whiteSpace: "nowrap" }}>
                                  <div className="col-md-6 col-6">
                                    <span className='rupees'>Rs.{item.grossPrice}&nbsp;</span>
                                    <span className='cross-mark'>
                                      Rs. {item.offerSummary} </span>
                                    <span className='offersummary'>({Math.round(((item.offerSummary - item.grossPrice) / item.offerSummary) * 100)}% OFF)</span>
                                  </div>
                                  <div className="col-md-6 col-6 ">
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </Link>
                    ))}
                    responsive={{ 250: { items: 2 }, 750: { items: 2 }, 900: { items: 3 }, 1000: { items: 4 }, 1200: { items: 5 } }} 
                    controlsStrategy="alternate"
                    autoPlay
                    // autoPlayInterval={index===0 ? 3000 : index/2===0 ? 3000 : 5000}
                    autoPlayInterval={getRandomInterval()}
                    infinite
                    disableDotsControls
                    renderNextButton={() => null}
                  />
                   
                  
                  {/* <div className="" style={{ position:'absolute', top: '50%', zIndex:3, alignItems:"end", right:"-30px" }}> */}
                  <Link to={`/category/${category.categoryName}`} className="btn" style={{ position:'absolute', top: '30%', zIndex:3, alignItems:"end", right:"-28px", outline:'none',boxShadow:'none' }}>
                    <svg viewBox="0 0 16 16" style={{ width: '30px', height: '100px', color: 'rgba(0, 0, 0, 0.3)', marginBottom:"10px" }}> 
                      <path fill="currentColor" d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                    </svg>
                  </Link>
                  {/* </div> */}
                </div>
              )
            ))}

            {/* {pageSize < categoriesWithProducts.length ? ( 
              <div className="center">
                <button className="btn btn-primary btn-framed btn-rounded" onClick={showMoreCategories}>
                  Show More
                </button>
              </div>
            ) : (
              pageSize > Limit && (
                <div className="center">
                  <button className="btn btn-secondary" onClick={showLessCategories}>Show Less</button>
                </div>
              )
            )} */}
            
            <div className="center">
              {!productCheck ? <button className="btn btn-primary btn-framed btn-rounded" onClick={(e) => showLessCategories(e)}>
                Show Less
              </button> : (
                <>
                {(loading&&loadingMore) && (
              <div style={{ position: 'relative' }}>
                <AliceCarousel
                  items={Array.from({ length: productsToShow }).map((_, index) => (
                    <div key={index} className="col-auto" style={{ minWidth: '200px', maxWidth: '250px' }}>
                      {renderSkeletonCard()}
                    </div>
                  ))}
                  responsive={{ 0: { items: 2 }, 1024: { items: 5 } }} 
                  controlsStrategy="alternate"
                  autoPlay
                  autoPlayInterval={3000}
                  infinite
                  disableDotsControls 
                />
              </div>
                    
                  )}

                  <button className="btn btn-primary btn-framed btn-rounded" onClick={(e) => showMoreCategories(e)}>
                    Show More
                  </button>
                  
                </>
              )}
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}