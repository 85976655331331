//Cart Action
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const FETCH_CART_REQUEST = 'FETCH_CART_REQUEST';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CART_FAILURE = 'FETCH_CART_FAILURE';
//Wish-list Action
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';
export const FETCH_WISHLIST_REQUEST = 'FETCH_WISHLIST_REQUEST';
export const FETCH_WISHLIST_SUCCESS = 'FETCH_WISHLIST_SUCCESS';
export const FETCH_WISHLIST_FAILURE = 'FETCH_WISHLIST_FAILURE';
//Product Action
export const FETCH_PRODUCTS_REQUEST='FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';
export const ADD_PRODUCTS='ADD_PRODUCTS';
export const REMOVE_PRODUCTS='REMOVE_PRODUCTS';
export const REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS';
export const REMOVE_PRODUCT_FAILURE = 'REMOVE_PRODUCT_FAILURE';
//User Visited Action
export const ADD_TO_USER_VISITED = 'ADD_TO_USER_VISITED';
export const REMOVE_FROM_USER_VISITED = 'REMOVE_FROM_USER_VISITED';
export const FETCH_USER_VISITED_REQUEST = 'FETCH_USER_VISITED_REQUEST';
export const FETCH_USER_VISITED_SUCCESS = 'FETCH_USER_VISITED_SUCCESS';
export const FETCH_USER_VISITED_FAILURE = 'FETCH_USER_VISITED_FAILURE';
//User Order
export const ADD_TO_USER_ORDER = 'ADD_TO_USER_ORDER';
export const REMOVE_FROM_USER_ORDER = 'REMOVE_FROM_USER_ORDER';
export const FETCH_USER_ORDER_REQUEST = 'FETCH_USER_ORDER_REQUEST';
export const FETCH_USER_ORDER_SUCCESS = 'FETCH_USER_ORDER_SUCCESS';
export const FETCH_USER_ORDER_FAILURE = 'FETCH_USER_ORDER_FAILURE';
//User Return Order
export const ADD_TO_USER_RETURN_ORDER = 'ADD_TO_USER_RETURN_ORDER';
export const REMOVE_FROM_USER_RETURN_ORDER = 'REMOVE_FROM_USER_RETURN_ORDER';
export const FETCH_USER_RETURN_ORDER_REQUEST = 'FETCH_USER_RETURN_ORDER_REQUEST';
export const FETCH_USER_RETURN_ORDER_SUCCESS = 'FETCH_USER_RETURN_ORDER_SUCCESS';
export const FETCH_USER_RETURN_ORDER_FAILURE = 'FETCH_USER_RETURN_ORDER_FAILURE';
//User Cnacle Order
export const ADD_TO_USER_CANCLE_ORDER = 'ADD_TO_USER_CANCLE_ORDER';
export const REMOVE_FROM_USER_CANCLE_ORDER = 'REMOVE_FROM_USER_CANCLE_ORDER';
export const FETCH_USER_CANCLE_ORDER_REQUEST = 'FETCH_USER_CANCLE_ORDER_REQUEST';
export const FETCH_USER_CANCLE_ORDER_SUCCESS = 'FETCH_USER_CANCLE_ORDER_SUCCESS';
export const FETCH_USER_CANCLE_ORDER_FAILURE = 'FETCH_USER_CANCLE_ORDER_FAILURE';

// offer modal
export const Offer_Modal_Controller = 'Offer_Modal_Controller';