import React from "react";

export default function Step(props) {
    return (
        <div key={props.index} className={"stepBlock" + (props.selected ? " selected" : "")}>
            <div className="circleWrapper" onClick={() => props.updateStep(props.index + 1)}>
                <div className="row">
                <div className="circle"></div><span className="ml-2 mb-3">
                    <p>{props.label['sr-status-label']}</p>
                    {props.selected&&<>
                    <p className="text-capitalize">{props.label.location.toLowerCase()
  .replace(/\b\w/g, (match) => match.toUpperCase())}</p>
                    <p>{new Date(props.label.date).toLocaleString('en-US', {year: 'numeric',month: 'long',day: 'numeric',hour: 'numeric',minute: 'numeric',second: 'numeric'})}</p>
                    </>}
                </span>
                </div>
            </div>
            
        </div>
    )
}