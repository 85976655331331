import React, { useEffect, useState } from 'react'
import { callApi } from '../utils/Api'
import Footer from '../layouts/Footer'
import { Button } from 'reactstrap'
import PageTitle from '../components/PageTitle'
import { Link,useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from "react-redux";
import { fetchUserOrders,fetchUserOrderSuccess,fetchUserOrderFailur } from '../actions/userOrderAction';

export default function MyOrder() {
  const dispatch=useDispatch()
  const userOrder=useSelector(state=>state.userOrder)
  const [myOrder, setMyOrder] = useState(userOrder?.userOrder||[])
  const navigate=useNavigate()
  const userId=localStorage.getItem('userid')
  if(!userId){
      navigate("/login")
  }
  useEffect(() => {
    if(userId){
    callApi('post', 'cart/cartcrud/getUserWiseOrder', { userId:userId , limit: 100, page: 1 }) // "64c35a8e4f8d444b3d0ee15e"
      .then((res) => {
        if(res.code===200){
          dispatch(fetchUserOrderSuccess(res.data))
        }
      }).catch((err) => {
        dispatch(fetchUserOrderFailur(err))
      })}
  }, []);
  useEffect(()=>{
    setMyOrder(userOrder?.userOrder||[])
  },[userOrder?.userOrder])
  return (
    <section className="h-100 gradient-custom">
      <PageTitle />
      <div className="container py-5 h-100">
      {/* <div className="section-title clearfix">
                     <div className="float-xl-left float-md-left float-sm-none">
                            <h2 class="text-muted mb-0">MY ORDER</h2>
                        </div>
        </div> */}
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-lg-10 col-xl-8">
            {myOrder.length!==0?
              <div className="card" style={{ borderRadius: 10 }}>
                <div className="card-header px-4 py-5">
                  <h5 className="text-muted mb-0">Thanks for your Order !</h5>
                </div>
                <div className="card-body p-4">
                {myOrder?.map((orders, orderIndex) => (<React.Fragment key={orderIndex}>
                <div className="row">
                  <div className="col-md-6 col-6">
                  <p >Order Date: <span className='order-page-order-date'>{new Date(orders.createdAt).toLocaleString('en-US', {year: 'numeric',month: 'long',day: 'numeric',})}</span> 
                  </p>
                  </div>
                  <div className="col-md-6 col-6 text-right">
                  <p>Order Id:<span className='order-page-order-date'>{orders?.orderId}</span></p>
                  </div>
                </div>
                  { orders.cart.map((car,indx)=><>
                  <div key={indx} className="card shadow-0 border mb-4">
                    <div className="card-body">
                      <Link to={`/productdetails/rr/${car?.product?._id}`}>
                        <div className="row">
                          <div className="col-md-3">
                            {car.productThumbnailImages?.[0].length!==0 ? (
                              <img
                                src={car?.product?.productThumbnailImages?.[0]?.imgurl}
                                className="img-fluid"
                                alt="Phone"
                              />
                            ) : (
                              <img
                                src="https://baconmockup.com/250/250/"  // Replace this with the actual URL of your default avatar image
                                className="img-fluid"
                                alt="Default Avatar"
                              />
                            )}
                          </div>


                          <div className="col-md-3 col-12 text-center d-flex justify-content-center align-items-center">
                            <p className="text-muted mb-0"><span className='order-page-order-date'>{car?.product?.productName}</span></p>
                          </div>
                          <div className="col-md-3 col-12 text-center d-flex justify-content-center align-items-center">
                            <p className="text-muted mb-0 small">Delivar by <br></br><span className='order-page-order-date'>{car?.estimatedDeliveryDate}</span></p>
                          </div>
                          <div className="col-md-3 col-12 text-center d-flex justify-content-center align-items-center">
                            <p className="text-muted mb-0 small">Price <br></br><span className='order-page-order-date'> Rs.{car?.product?.grossPrice}</span></p>
                          </div>
                        </div>
                      </Link>
                      {/* ... Other card content */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-6">
                       <p className='order-page-order-date'>Shipping charge</p>
                    </div>
                    <div className="col-md-6 col-6 text-right">
                       <span className='tax-text'><i className='fa fa-inr'></i>{Math.round(car?.deliveryCharges)}</span>   
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-6">
                         <p className='order-page-order-date'>Total</p> 
                    </div>
                    <div className="col-md-6 col-6 text-right">
                    <span className='rupees' style={{ fontSize: "14px" }}><i className='fa fa-inr'></i>{Math.round(car?.quantity*(car?.deliveryCharges+car?.product?.grossPrice))}</span>    
                    </div>
                  </div>
                  <div className="text-center">
                <Link to={`/trackOrder/${car?._id}`} className="btn btn-primary btn-framed btn-rounded width-8 mb-4">Track Order <span><i className='fa fa-shopping-basket'></i></span></Link>
              </div>
              </>)}
                  <div className="row">
                    <div className="col-md-6 col-6">
                       <p className='order-page-order-date'>Total Shipping charge</p>
                    </div>
                    <div className="col-md-6 col-6 text-right">
                       <span className='tax-text'><i className='fa fa-inr'></i>{Math.round(orders?.deliveryCharge)}</span>   
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-6">
                         <p className='order-page-order-date'>Total amount paid</p> 
                    </div>
                    <div className="col-md-6 col-6 text-right">
                    <span className='rupees' style={{ fontSize: "14px" }}><i className='fa fa-inr'></i>{orders?.totalAmount}</span>    
                    </div>
                  </div>
                  <hr></hr>
                </React.Fragment>))}



                {/* <div class="d-flex justify-content-between pt-2">
                  <p class="fw-bold mb-0">Order Details</p>
                  <p class="text-muted mb-0"><span class="fw-bold me-4">Total</span> Rs.*****</p>
                </div>

                <div class="d-flex justify-content-between pt-2">
                  <p class="text-muted mb-0">Invoice Number : ******</p>
                  <p class="text-muted mb-0"><span class="fw-bold me-4">Discount</span> Rs.****</p>
                </div>

                <div class="d-flex justify-content-between">
                  <p class="text-muted mb-0">Invoice Date : DD/MM/YYYY</p>
                  <p class="text-muted mb-0"><span class="fw-bold me-4">GST **</span>****</p>
                </div>

                <div class="d-flex justify-content-between mb-5">
                  <p class="text-muted mb-0">Recepits Voucher : ---------</p>
                  <p class="text-muted mb-0"><span class="fw-bold me-4">Delivery Charges</span> Free</p>
                </div> */}
              </div>
              {/* <div class="card-footer border-0 px-4 py-5"
                style={{backgroundcolor: "#a8729a", borderbottomleftradius: 10, borderbottomrightradius: 10}}>
                <h5 class="d-flex align-items-center justify-content-end text-red text-uppercase mb-0">Total
                  paid: <span class="h2 mb-0 ms-2">Rs.1040</span></h5>
              </div> */}
              
              <br />
             </div>:    
            <div className="card-header px-4 py-5">
              <div className="text-center">
               <img src="/assets/images/delivery-boy.png" alt="No Orders" className="img-fluid mb-4" style={{ maxWidth: '200px' }} />
               <h5 className="text-muted mb-0">No Orders Yet</h5>
               <p>You haven't placed any orders yet. Once you do, you can track them here! Start shopping now!</p>
               <div style={{ marginTop: "40px" }}>
                    <Button
                      tag={Link}
                      to="/shop"
                      color="primary"
                      style={{ padding: "8px" }}
                    >
                      Back to Shopping
                    </Button>
                  </div>
             </div>
             </div>    
            }

          </div>
        </div>
      </div>

      <Footer />
    </section>

  )
}
