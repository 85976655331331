// actions/productActions.js
import axios from 'axios';
import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  REMOVE_PRODUCT_SUCCESS,
  REMOVE_PRODUCT_FAILURE,
  ADD_PRODUCTS,
} from './actionType';

export const addProducts = (products) => ({
  type: ADD_PRODUCTS,
  payload: products,
});

export const removeProducts = (productId) => ({
  type: REMOVE_PRODUCT_SUCCESS,
  payload: productId,
});
export const fetchProductRequest=()=>{
  return{
    type:FETCH_PRODUCTS_REQUEST
  }
}
export const fetchProductSuccess=(products)=>{
  return{
    type:FETCH_PRODUCTS_SUCCESS,
    payload:products
  }
}
export const fetchProductFailur=(error)=>{
  return{
    type:FETCH_PRODUCTS_FAILURE,
    payload:error
  }
}

// export const fetchProducts = () => {
//   return (dispatch) => {
//     axios.get('your-api-endpoint/products')
//       .then(response => dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: response.data }))
//       .catch(error => dispatch({ type: FETCH_PRODUCTS_FAILURE, payload: error.message }));
//   };
// };

// export const addProduct = (newProduct) => {
//   return (dispatch) => {
//     axios.post('your-api-endpoint/products', newProduct)
//       .then(response => dispatch({ type: ADD_PRODUCT_SUCCESS, payload: response.data }))
//       .catch(error => dispatch({ type: ADD_PRODUCT_FAILURE, payload: error.message }));
//   };
// };

// export const removeProduct = (productId) => {
//   return (dispatch) => {
//     axios.delete(`your-api-endpoint/products/${productId}`)
//       .then(response => dispatch({ type: REMOVE_PRODUCT_SUCCESS, payload: productId }))
//       .catch(error => dispatch({ type: REMOVE_PRODUCT_FAILURE, payload: error.message }));
//   };
// };
