import React, { useEffect, useState } from 'react'
import ListingListSideBox from './ListingListSideBox'

import jp from 'jsonpath';
import { Button } from 'reactstrap';
import ContentLoader from 'react-content-loader'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { callApi } from '../utils/Api'
import SimilarProductListing from './SimilarProductListing'
import InstaPostCard from './ProductDetails/InstaPostCard'
import { Link, useParams } from 'react-router-dom'
import CategoriesCard from './Cards/CategoryCard'
import axios from 'axios'
import SellerProductListing from './SellerProductListing'
import ReviewForm from './Form/ReviewForm';
import { apiUrl } from '../constants/defaultValue';
export default function SellerDetailsComp() {
    const { name, id } = useParams();
    console.log(name, id);
    const [vendorId, setVendorId] = useState(id);
    const [sellerDetails, setSellerDetails] = useState([])
    const [userDetails, setUserDetails] = useState([])
    const [category, setCategory] = useState([]);
    const [categoryLess, setCategoryLess] = useState([]);
    const [categoryCheck, setCategoryCheck] = useState(false);
    const [sellerProduct, setSellerProduct] = useState([]);
    const [filterVendorData, setFilterVendorData] = useState([]);
    const [filterPriceData, setFilterPriceData] = useState([]);
    const [filterOption, setFilterOption] = useState([]);
    const [displayreview, setDisplayreview] = useState(true);
    const [sellerProductDetails, setSellerProductDetails] = useState([]);
    const userId = localStorage.getItem('userid')
    console.log("userIdTest", vendorId)
    useEffect(() => {
        callApi('post', 'merchant/getMerchant/getUserDetailsById', { userId: vendorId }).then(res => {
            // console.log(res.data);
            if (res.data) {
                setSellerDetails(res.data)
                setVendorId(userId)
            }
        }).catch(err => { console.log(err); })
        callApi('post', 'merchant/getMerchant/getUserDetailsByUserId', { userId: vendorId }).then(res => {
            // console.log(res.data);
            if (res.data) {
                setUserDetails(res.data)
                setVendorId(userId)
            }
        }).catch(err => { console.log(err); })
        callApi('post', 'filter/filterCrud/getFilterSection').then(response => {
            if (response.data) {
                let vendorData = response.data.filter((item) => item.filterType === 'Vendor');
                let priceData = response.data.filter((item) => item.filterType === 'Price');
                setFilterVendorData(vendorData);
                setFilterPriceData(priceData);
            }
        }
        ).catch(err =>
            console.log(err)
        );
    }, [])
    console.log(sellerDetails);
    console.log("User Details by Id",userDetails)

    useEffect(() => {
        callApi('post', `product/getProduct/getProductByUserId`, { "user": vendorId }).then(res => {
            console.log(res.data);
            // setSellerProduct(res.data.data)
            setSellerProductDetails(res.data)
        }).catch(err => {
            console.log(err);
        })



    }, [])
    useEffect(() => {
        callApi('post', `category/curd/getAllCategory`).then(res => {
            let keys = Object.keys(res.data[0]?.category);
            // console.log(res);
            let selectedKeys = [];
            sellerProduct.map((item) => {
                for (let index = 0; index < keys.length; index++) {
                    if (item.productCategory[0]?.value === keys[index]) {
                        selectedKeys.push(keys[index])
                    }
                }
            })
            // console.log(selectedKeys);

            let obj = []
            for (let index = 0; index < keys.length; index++) {
                for (let i = 0; i < selectedKeys.length; i++) {
                    if (selectedKeys[i] === keys[index]) {
                        obj.push({
                            categoryListing: [Object.keys(res.data[0].category[keys[index]]).slice(1, res.data[0].category[keys[index]].length)],
                            title: keys[index], count: Object.keys(res.data[0].category[keys[index]]).length - 1, subTitle: [Object.keys(res.data[0].category[keys[index]]).slice(1, 3)]
                        });
                    }
                }

            }

            setCategory(obj);
            setCategoryLess(obj.slice(0, 3));
        }).catch(err => {
            console.log(err);
        })

    }, [sellerProductDetails])

    const showHideSection = (e) => {
        // console.log('showHideSection', e);
        setCategoryCheck(!categoryCheck);
    }

    useEffect(() => {
        if (filterOption.length > 0) {
            const checkFilter = (item) => {


                let check = false;
                for (let index = 0; index < filterOption.length; index++) {
                    console.log(filterOption[index].filterType);
                    switch (filterOption[index].filterType) {
                        case 'Vendor':
                            var flo = filterOption[index];

                            var filedAccessor = flo.filterField;

                            var filterValue = flo.filterValue;

                            var itemValue = jp.query(item, `$..${filedAccessor}`);
                            console.log(itemValue);
                            console.log(item);
                            console.log(itemValue[0], filterValue);
                            if (itemValue[0] === filterValue) {
                                check = true;
                            }

                            break;
                        case 'Price':
                            var flo = filterOption[index];
                            var filedAccessor = flo.filterField;
                            var filterValue = flo.filterValue;
                            console.log(filterValue[0], filterValue[1]);
                            var itemValue = jp.query(item, `$..${filedAccessor}`);
                            if (Number(itemValue[0]) >= Number(filterValue[0]) && Number(itemValue[0]) <= Number(filterValue[1])) {
                                check = true;
                            }
                            console.log(itemValue, 'itemValue');
                            break;
                        default:
                            break;
                    }
                }
                return check;
            }
            let data = sellerProductDetails.filter((item) => checkFilter(item));
            console.log(data);
            setSellerProduct((e) => [...data]);
        } else {
            setSellerProduct((e) => ([...sellerProductDetails]));
        }
    }, [filterOption, sellerProductDetails]);
    // console.log(sellerDetails);
    const handelFilter = (filterOptions, e) => {
        if (e) {
            setFilterOption((e) => ([...e, filterOptions]));
        } else {
            setFilterOption((e) => e.filter((item) => item.filterValue !== filterOptions.filterValue));
        }
    }
    const [review, setReview] = useState([]);
    const [reviewLess, setReviewLess] = useState([]);
    const getReviews = () => {
        callApi('post', `review/reviewCrud/getSellerReviews`, { "seller": sellerDetails.merchant?._id }).then(res => {
            console.log(res.data);
            setReview(res.data)
            setReviewLess(res.data.slice(0, 2));
        }).catch(err => {
            console.log(err);
        })
    }
    useEffect(() => {
        getReviews();
    }, [sellerDetails])
    const setReviewMore = () => {
        setReviewLess(review);
        setDisplayreview(false);
    }
    const setReviewLesss = () => {
        setReviewLess(review.slice(0, 2));
        setDisplayreview(true);
    }

    localStorage.setItem('merchantIId', JSON.stringify(sellerDetails?.merchantId));
    // console.log("Set Seller Product For Merchant Data Website",sellerProduct)
    function formatPhoneNumber(phoneNumber) {
        if (typeof phoneNumber !== 'number') return '';
      
        // Convert the phone number to a string and remove any non-digit characters
        const digitsOnly = phoneNumber.toString().replace(/\D/g, '');
      
        if (digitsOnly.length < 5) return digitsOnly; // Return as it is if the length is less than 5
      
        // Get the first 3 characters of the phone number
        const firstThreeChars = digitsOnly.slice(0, 3);
      
        // Get the last 2 characters of the phone number
        const lastTwoChars = digitsOnly.slice(-2);
      
        // Create the masked phone number with asterisks
        const maskedPhoneNumber = `${firstThreeChars}******${lastTwoChars}`;
      
        return maskedPhoneNumber;
      }
      
      
      

    function formatEmail(email) {
        if (!email) return '';

        // Split the email address into local part and domain
        const [localPart, domain] = email.split('@');

        // Get the first character of the local part
        const firstChar = localPart.slice(0, 1);

        // Get the last character of the local part
        const lastChar = localPart.slice(-1);

        // Create the masked email with asterisks
        const maskedEmail = `${firstChar}******${lastChar}@${domain}`;

        return maskedEmail;
    }




    return (
        <div>
            <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: -1 }}>
                <img src="/assets/images/backg.jpeg" alt="" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
            </div>
            <br />
            {sellerDetails ? <section className="content">
                <section className="block">
                    <div className="container">
                        <div className="row flex-column-reverse flex-md-row">
                            <div className="col-md-12">

                                <div className="row">
                                    <div className="col-md-4 col-12">
                                        <div className="author big">
                                            <div className="author-image">
                                                <div>
                                                    <img src={sellerDetails?.avatar} className="vendor-logo" style={{ width: '100%' }} alt="" />
                                                </div>
                                            </div>

                                            <div className="section-title1">
                                                <h2 align='center' style={{ marginBottom: '4px' }}>{sellerDetails?.merchant?.merchantCompanyName}</h2>
                                                <h4 align='center' className="location" >
                                                    <a href="/">{sellerDetails.merchant?.merchantCompanyCity},{sellerDetails.merchant?.merchantCompanyState},{sellerDetails.merchant?.merchantCompanyCountry}</a>
                                                </h4>


                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-md-8 col-12">
                                        <div className="author-description">
                                            <div className="row ">
                                                <div className="col-md-4 col-4">
                                                    <aside >{sellerProduct.length}</aside>
                                                    <figure >Products</figure>
                                                </div>
                                                {/* <div className="col-md-3 col-4">
                                                    <aside >23</aside>
                                                    <figure >Sells</figure>
                                                </div> */}
                                                <div className="col-md-4 col-4">
                                                    <aside >{sellerDetails.merchant?.reviewSummery?.rating} <i className='fa fa-star clr-star'></i></aside>
                                                    <figure >Rating</figure>
                                                </div>
                                                {/* <div className="col-md-3"></div> */}
                                            </div>
                                            <h2 style={{ fontSize: "18px", marginBottom: '10px', fontWeight: '400' }}>About Seller</h2>
                                            <ReactQuill theme={"bubble"} value={sellerDetails.merchant?.description} readOnly={true} />
                                            <br />
                                            <div className="row mt-2">
                                                {/* <div className="col-md-4 col-6">
                                                    <figure>Phone</figure>
                                                    <aside>{sellerDetails?.phoneNumber}</aside>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <figure>Email</figure>
                                                    <aside><a href="/">{sellerDetails?.email}</a></aside>
                                                </div> */}
                                                <div className="col-md-4 col-6">
                                                    <figure>Phone</figure>
                                                    <aside>{formatPhoneNumber(sellerDetails?.phoneNumber)}</aside>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <figure>Email</figure>
                                                    <aside><a href="/">{formatEmail(sellerDetails?.email)}</a></aside>
                                                </div>
                                                <div className="col-md-4"></div>

                                                <div className="col-md-4">

                                                </div>
                                            </div>
                                            {/* <div className='mt-5' style={{ cursor: 'pointer' }} >
                                                <Link to="/website">Vendors webSite</Link>
                                            </div> */}




                                        </div>
                                    </div>
                                </div>



                                <hr />
                                {/* <section>
                                    {category.length > 0 ? <section className="category-section1  sec-pad">
                                        <div className="container"><h2>Categories</h2></div>
                                        <div className="auto-container centred">
                                            
                                            <div className="inner-content clearfix">
                                                {categoryCheck ? <CategoriesCard data={category} /> : <CategoriesCard data={categoryLess} />}
                                            </div>
                                            {console.log("Product Seller Category",{category})}
                                            {category.length > 3 ? <div className="more-btn">
                                                <button className="theme-btn-one btn" onClick={(e) => showHideSection(e)}>
                                                    {categoryCheck ? 'Show Less' : 'Show More'}
                                                </button>
                                            </div> : null}

                                        </div>
                                    </section> : 'Welcome To MayIFind'}

                                </section> */}

                                <section>
                                    <SellerProductListing productData={sellerProduct} />


                                </section>
                                <section>
                                    <h2>Add a Review</h2>
                                    <ReviewForm seller={sellerDetails.merchant?._id} getReviews={getReviews} />
                                </section>

                            </div>

                            {/* <div className="col-md-4"> */}
                                {/* <section className=" seller-insta-scroll">
                                    <div className="container">
                                        <div className="row">
                                            
                                            <div className="col-md-12" style={{ marginLeft: '-20px' }}>
                                                <InstaPostCard />
                                                <InstaPostCard />
                                                <InstaPostCard />
                                                <InstaPostCard />
                                                <InstaPostCard />
                                            </div>

                                        </div>



                                    </div>


                                </section> */}
                                {/* <div className="section-title clearfix">
                                    <div className="float-xl-left float-md-left float-sm-none">
                                        <h2 style={{ marginBottom: '-10px' }}>FILTERS</h2>
                                    </div>
                                </div>
                                <hr />
                                <section className='filter-box' >
                                    <h2 style={{ fontSize: '15px', marginBottom: '8px' }}>VENDORS</h2>
                                    {filterVendorData.map((item, index) => {
                                        return (
                                            <div className="form-check" key={index}>
                                                <input className="form-check-input" onChange={(e) => handelFilter(item, e.target.checked)} type="checkbox" value="true" id="cb1" />
                                                <label className="form-check-label ml-2 filter-text" htmlFor="flexCheckDefault" >
                                                    {item.label}
                                                </label>
                                            </div>
                                        )
                                    })}


                                </section>
                                <hr />
                                <section className='filter-box' >

                                    <h2 style={{ fontSize: '15px', marginBottom: '8px' }}>PRICE</h2>
                                    {filterPriceData.map((item, index) => {
                                        return (
                                            <div className="form-check" key={index}>
                                                <input className="form-check-input" onChange={(e) => handelFilter(item, e.target.checked)} type="checkbox" value="true" id="cb1" />
                                                <label className="form-check-label ml-2 filter-text" htmlFor="flexCheckDefault" >
                                                    {item.label}
                                                </label>
                                            </div>
                                        )
                                    })}


                                </section> */}
                                {/* <section>
                                    {/* <h2>Reviews</h2> */}
                                    {/* <div className="comments">
                                        {reviewLess.length > 0 ? reviewLess.map((item, index) => {
                                            // console.log(r);
                                            return (
                                                <>

                                                    <div className="row mb-4">
                                                        <div className="col-md-2 col-2">
                                                            <img src={item.user.avatar} style={{ borderRadius: '50%' }} width={50} height={50} alt="" srcset="" />
                                                        </div>
                                                        <div className="col-md-10 col-10">
                                                            <p>
                                                                {item.review}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-5">
                                                        <div className="col-md-2 col-2">{item.rating}<i className='fa fa-star clr-star'></i></div>
                                                        <div className="col-md-6 col-6"><a >{item.user?.firstName} {item.user?.lastName}</a></div>
                                                        <div className="col-md-4 col-4"><span>{item.createdAt.slice(0, 10)}</span></div>

                                                    </div>


                                                </>

                                            )
                                        }) : ''}
                                    </div> */}

                                    {/* {review.length > 2 ?
                                        <>
                                            {displayreview ? <Button onClick={setReviewMore} color='link' style={{ color: "#ff0000" }}>View all {review.length} reviews</Button> : <Button onClick={setReviewLesss} color='link' style={{ color: "#ff0000" }}>View Less</Button>}


                                        </>

                                        : null} */}
                                {/* </section> */}
                            {/* </div> */}

                        </div>
                    </div>

                </section>

            </section> : <div><ContentLoader
                speed={2}
                width={476}
                height={124}
                viewBox="0 0 476 124"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"

            >
                <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
                <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                <circle cx="20" cy="20" r="20" />
            </ContentLoader></div>}
            {/* <div className="background" style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: -1 }}>
                <div>
                    <img src="/assets/images/backg.jpeg" alt="" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                </div>
            </div> */}

            <div className="text-center">
                <Link to="/" className="btn btn-primary btn-framed btn-rounded width-8">Home <span><i className='fa fa-home'></i></span></Link>
            </div>
            <br />
        </div>
    )
}
