import React from 'react'
import {Link} from 'react-router-dom'
import { useState, useEffect } from 'react';
import ReactTypingEffect from 'react-typing-effect'
import { FaFacebookSquare,FaInstagramSquare,FaYoutubeSquare,FaLinkedin } from "react-icons/fa";
import { callApi } from '../utils/Api';

export default function Footer() {
    const [newsData, setNewsData] = useState([]);
    const [headlines, setHeadlines] = useState([]);
    const [apiCallSuccess, setApiCallSuccess] = useState(false);

    useEffect(() => {
        callApi('post', 'ad/ads/getRecentNews', { limit: 100, page: 1 })
          .then((res) => {
            // console.log("Full API Response:", res);
            // console.log("API Response data:", res.data);
            if (res.status === 'success' && Array.isArray(res.data)) {
              const extractedHeadlines = res.data.slice(0, 5).map(item => item.Headline);
            //   console.log("Extracted headlines:", extractedHeadlines);
              setHeadlines(extractedHeadlines);
              setApiCallSuccess(true);
            } else {
              console.error("Unexpected API response structure:", res);
              setApiCallSuccess(false);
            }
          })
          .catch((err) => {
            console.error("API call error:", err);
            setApiCallSuccess(false);
          });
      }, []);
    const randomDataTexts1 = [
        "Summer Deals",
        "Flash Sale",
        "Exclusive Offers",
        "Clearance Event",
        "Limited Time Offer",
        "Weekend Discounts",
        "Hot Picks",
        "New Arrivals",
        "Last Chance Sale",
        "Customer Favorites",
        "Best Sellers",
        "Trending Now",
        "Bargain Bonanza",
        "Must-Have Items",
        "Mega Savings",
        "Special Collection",
        "Popular Picks",
        "Daily Markdowns",
        "Featured Products",
        "Shop the Look",
        "Seasonal Specials",
        "Luxury Selection",
        "Holiday Bonanza",
        "Hidden Gems",
        "Party Essentials",
        "Unique Finds",
        "Fashion Forward",
        "Timeless Classics",
        "Gift Ideas",
        "Statement Pieces",
        "On-Trend Styles",
        "Wardrobe Staples",
        "Ultimate Deals",
        "Designers' Picks",
        "Clearance Sale",
        "Glamour and Glitz",
        "Under $20",
        "Lifestyle Essentials",
        "Exclusive Discounts",
        "Daily Deals",
        "Savings Extravaganza",
        "Boutique Favorites",
        "Chic & Stylish",
        "Cozy Winter Warmers",
        "Spring Fever",
        "Sneak Peek",
        "The Perfect Fit",
        "Unbeatable Prices",
        "Instant Classics",
        "Vintage Vibes"
    ];
    
    const randomDataTexts2 = [
        "Fashion Finds",
        "Electronics Galore",
        "Kitchen Essentials",
        "Home Decor Ideas",
        "Outdoor Adventures",
        "Gaming Goodies",
        "Tech Gadgets",
        "Bookworm Heaven",
        "Fitness Gear",
        "Beauty Essentials",
        "DIY Supplies",
        "Health & Wellness",
        "Travel Must-Haves",
        "Pet Lovers' Paradise",
        "Artistic Creations",
        "Cozy Comforts",
        "Work From Home",
        "Culinary Delights",
        "Kids' Corner",
        "Eco-Friendly Choices",
        "Smart Living",
        "Mindful Living",
        "Outdoor Essentials",
        "Home Office Upgrades",
        "Fashion Forward",
        "Entertainment Hub",
        "Gourmet Delights",
        "Inspiring Reads",
        "Sustainable Picks",
        "Vintage Treasures",
        "Game On",
        "Fitness Focus",
        "Pamper Yourself",
        "Pet Pampering",
        "Nature Exploration",
        "Creative Corner",
        "Digital Delights",
        "Wellness Retreat",
        "Budget Buys",
        "Collectibles",
        "Artisanal Crafts",
        "Home Spa Day",
        "Indulgence Zone",
        "Discover New Worlds",
        "Create & Craft",
        "Pet Playtime",
        "Eco-Friendly Finds",
        "Outdoor Escapes",
        "Home Sanctuary"
    ];

    // const recentHeadlines = newsData.map((item) => item.Headline);
    
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

  return (
    <div>
        <footer className="footer">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <Link to="/" >
                                <img src="/assets/img/logo.png" className='logo' alt=""/>
                            </Link>
                            <p>
                            M A Y <span style={{color:'#ff0000',fontWeight:'500',fontSize:'16px'}}> I</span> F I N D is a ' 0% Commission ' based e-commerce platform. Apart from the regular ones, you will 'find' uncommon and unusual items on the platform, at 'unusual' prices.
                            {/* M A Y <span style={{color:'#ff0000',fontWeight:'500',fontSize:'16px'}}> I</span> F I N D is a ' 0% Commission ' based new age method of doing business. Alternately called as "Yellow Commerce", it promotes the businesses as well as the business owners, so that buyers can ultimately 'find' them . Very clearly sellers don't have to pay commission on the transactions or business generated using MayIFind's platform. */}
                            </p>
                            <p>M A Y <span style={{color:'#ff0000',fontWeight:'500',fontSize:'16px'}}> I</span> F I N D is an entity of Convolutions Infrasys Pvt. Ltd.</p>
                        </div>
                       
                        <div className="col-md-3">
                            <h2>About Us</h2>
                            <div className="row">
                                <div className="col-md-6 col-sm-6" style={{paddingRight:"10px"}}>
                                    <nav>
                                        <ul className="list-unstyled">
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li>
                                                <Link to='/listing'>Recent Listing</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/sellerDetails">Seller Details</Link>
                                            </li> */}
                                            {/* <li>
                                                <Link to="/vendorListing">Vendors Listed</Link>
                                            </li> */}
                                            {/* <li>
                                                <Link to="/website">Vendors Site</Link>
                                            </li> */}
                                            <li>
                                                <Link to='/myOrder'>My Orders</Link>
                                            </li>
                                            <li>
                                                <Link to='/branding'>Digital Branding</Link>
                                            </li>
                                            <li>
                                            <span>
                                                <span className='m-2' onClick={() => openInNewTab('https://www.facebook.com/mayifind')} style={{ cursor: 'pointer' }}>
                                                    <FaFacebookSquare size={17} color='#3b5998' />
                                                </span>
                                                <span className='m-2' onClick={() => openInNewTab('https://www.instagram.com/mayifind/')} style={{ cursor: 'pointer' }}>
                                                    <FaInstagramSquare size={17} color='#E4405F' />
                                                </span>
                                                <span className='m-2' onClick={() => openInNewTab('https://www.youtube.com/channel/UCJgZpmcD2VQjKKLbU7zzkOg')} style={{ cursor: 'pointer' }}>
                                                    <FaYoutubeSquare size={17} color='#FF0000' />
                                                </span>
                                                <span className='m-2' onClick={() => openInNewTab('https://www.linkedin.com/company/mayifind-com/posts/?feedView=all')} style={{ cursor: 'pointer' }}>
                                                    <FaLinkedin size={17} color='#0e76a8' />
                                                </span>
                                            </span>
                                            </li>   
                                        </ul>
                                    </nav>
                                </div>
                                <div className="col-md-6 col-sm-6" style={{paddingLeft:"10px"}}>
                                    <nav>
                                        <ul className="list-unstyled" >
                                            {/* <li>
                                                <Link to='/wishlist'>Wishlist</Link>
                                            </li>
                                            <li>
                                                <Link to='/login'>Sign In</Link>
                                            </li>
                                            <Link to='/register'>Register</Link>
                                            <li>
                                            </li>
                                            <li>
                                            <a href='https://merchant.mayifind.com'>Seller Login</a>
                                            </li> */}
                                            <li>
                                                <Link to="/privacy">Privacy Policy</Link>
                                            </li>
                                            <li>
                                                <Link to='/terms'>Terms of Use</Link>
                                            </li>
                                             <li>
                                                <Link to='/refund'>Return, Refund & Cancellation</Link>
                                            </li>
                                             <li>
                                                <Link to='/shippingPolicy'>Shipping Policy</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-4">
                            <h2>Contact</h2>
                            <address>
                                <figure>
                                426/2660, 4th Floor, NB Tower,<br/> Nayapalli, NH-16, Bhubaneswar,<br/> Odisha,751012, INDIA <br/><strong>Email:</strong> <a href="https://mail.google.com/mail/u/0/#inbox?compose=new">care@mayifind.com</a>
                                </figure>
                                <span></span>
                                {/* <strong>Email:</strong> <a href="https://mail.google.com/mail/u/0/#inbox?compose=new">hello@mayifind.com</a> */}
                                
                                {/* <strong>Skype: </strong> Craigs */}
                                
                                {/* <a href="contact.html" className="btn btn-primary text-caps btn-framed">Contact Us</a> */}
                            </address>
                            <>
                            <Link to='/requirement' style={{ color: 'red', fontWeight: 'bold' }}>Submit Your Requirement</Link>
                            </>   
                            <div className="container">
                    <div className="row pt-3">
                        <div className="col-md-4 col-4"><a href='https://www.ccavenue.com/'><img src="/assets/images/ccavenue.png" className='logo-ccavenue' alt=""/></a></div>
                        <div className="col-md-4 col-4"><a href='https://www.phonepe.com/'><img src="/assets/images/Phonepe.png" className='logo-ccavenue' alt=""/></a></div>
                        <div className="col-md-4 col-4"><a href='https://www.shiprocket.in/'><img src="/assets/images/Shiprocket.jpg" className='logo-shiprocket' alt=""/></a></div>
                    </div>
                </div>  
                        </div>
                        
                    </div>
                 
                </div>
                <div className="page-title">
                <div className="container">
                    <h1 className=" center">
                        <a style={{ color: "#ff0000",fontSize:"16px" }} href="/">
                        <ReactTypingEffect
                        // text={["Todays Sale", "Recent Sale", "Purchaged From","Live Sale"]}
                        // text={randomDataTexts1}
                        speed={100} eraseSpeed={50} eraseDelay={3500} typingDelay={1000}
                        />
                        </a>
                     
                         <a style={{ color: "#ff0000",fontSize:"16px" }} href="/">
                            {apiCallSuccess ? (
                                headlines.length > 0 ? (
                                    <ReactTypingEffect
                                        text={headlines}
                                        speed={100}
                                        eraseSpeed={50}
                                        eraseDelay={3500}
                                        typingDelay={1000}
                                    />
                                ) : (
                                    "No headlines available"
                                )
                            ) : (
                                "Loading headlines..."
                            )}
                        </a>
                    </h1>
                    {/* {console.log("Most Recent HEadline",mostRecentHeadline)} */}
                </div>

            </div>
                <div className="background">
                    <div className="background-image original-size">
                        <img src="/assets/img/footer-background-icons.jpg" alt=""/>
                    </div>
                  
                </div>
               
            </div>
        </footer>
    </div>
  )
}