import React from 'react'
// import {
//   Button, Modal, ModalFooter,
//   ModalHeader, ModalBody
// } from "reactstrap"
import Carousel, { ModalGateway, Modal } from "react-images";
import { useEffect, useState, useCallback } from 'react'

import Gallery from "react-photo-gallery";
export default function ModalComp({toggle,imgModal,images,currentImage}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(currentImage);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

      
    images = images.map((image) => {
      // console.log("iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii",image)
        return {
          src: image.imgurl?image.imgurl:image.src? image.src:"",
        }
      })
    // console.log("imahhhhhhh",images)
  return (
    <ModalGateway>
      {imgModal?<Modal size="lg" onClose={toggle}>
        <Carousel
          currentIndex={currentImage}
          views={images.map(x => ({
                              ...x,
                              srcset: x.srcSet,
                              caption: x.title
                              }))}
          />
      
      
      
    </Modal>:null}
    </ModalGateway>
  )
}
