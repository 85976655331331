import React, { useEffect, useState } from 'react'
import {
    Row,  Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
// import CustomSelectInput from 'components/common/CustomSelectInput';
import { Colxx } from './CustomBootstrap.js';

import ImageCropper from './ImageCropper';
import Upload from './upload';


function CropImageModal({ modalActive, selectedAspectRatio, imageUrl, getModalData, purpose, imageUrlBanner, imageUrlCard, varient }) {
    const [showModal, setShowMOdal] = useState(modalActive);
    let modalPurpose = purpose
    // console.log(selectedAspectRatio, 'selectedAspectRatio');
    const [imageToCrop, setImageToCrop] = useState(undefined);
    const aspectRatio = selectedAspectRatio;
    const [croppedImage, setCroppedImage] = useState(undefined);

    const onUploadFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                const image = reader.result;
                setImageToCrop(image);
            });
            reader.readAsDataURL(event.target.files[0]);
        }
    };
    const handleClick = event => {
        // event.preventDefault();
        // let file = fileInput.current.files[0];
        // let newFileName = fileInput.current.files[0].name;
        // const config = { bucketName: 'etaiiler-image-upload', region: 'ap-south-1', accessKeyId: 'AKIATPAILJSQ3KSDD3P4', secretAccessKey: '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' };
        // const ReactS3Client = new S3(config);
        // ReactS3Client.uploadFile(file, newFileName).then(data => {
        //     console.log(data);
        //     if (data.status === 204) {
        //         console.log('success')
        //     } else {
        //         console.log('Fail')
        //     }
        // });
    };
    useEffect(() => {
        setShowMOdal(modalActive)
        // console.log(modalActive)
    }, [modalActive])
    // console.log(modalPurpose)
    // console.log(varientType.value);
    const uploadToS3Function = async () => {
        setShowMOdal(!showModal)
        let url = await Upload(croppedImage)
        getModalData(!showModal)
        imageUrl(url, modalPurpose);
    }
    // console.log(varient);
    // const [varientList, setVarientList] = useState([]);
    // useEffect(() => {
    //     varient.forEach(function(val, key){
    //         console.log(val);
    //         setVarientList(prevState => [...prevState, {label: val.value, value: val.value, key: key}])  
    //       })

    // }, [varient])
    // console.log(varientList);
    return (
        <Modal
            isOpen={showModal}
            size="md"
            toggle={() => setShowMOdal(!modalActive)}
        >
            <ModalHeader>
                {/* <IntlMessages id="Set Image" /> */}
                set image
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Colxx xxs='12'>
                        <div className="app">
                            <form onSubmit={handleClick}>
                                {/* <p>Selected aspectRatio is {aspectRatio}</p> */}
                                <div className="text-center">
                                <label  className="custom-file-upload">
                                    <i className="fa fa-cloud-upload"></i> Add an Image
                                    <input id="file-upload" accept="image/*" type="file" onChange={onUploadFile} />
                                </label>
                               
                               
                                </div>
                                
                                    
                               

                                {/* <input type="file" accept="image/*" onChange={onUploadFile} class="form-control" /> */}
                                <div>
                                    <ImageCropper
                                        imageToCrop={imageToCrop}
                                        selectedaspectRatio={aspectRatio}
                                        onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
                                    />
                                </div>
                                {croppedImage && (
                                    <div>
                                        <h2>Cropped Image</h2>
                                        <img alt="Cropped Img" src={croppedImage} />
                                    </div>
                                )}
                            </form>
                        </div>
                    </Colxx>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button

                    style={{ height: '30px', pointerEvents: 'all', backgroundColor: 'transparent', border: '1px solid #ff0000', color: '#ff0000' }}
                    className="ml-2 btn-review"
                    onClick={() => uploadToS3Function()}
                >
                    {/* <IntlMessages id="Upload" /> */} upload
                </button>
                <button
                    outline
                    color="secondary"
                    style={{ pointerEvents: 'all', backgroundColor: "#ff0000", height: '30px' }}
                    className="ml-2 btn-review"
                    onClick={() => (setShowMOdal(!showModal), getModalData(!showModal))}
                >
                    {/* <IntlMessages id="Cancel" /> */} cancel
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default CropImageModal