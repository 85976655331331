import React, { useEffect, useState } from 'react'
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import axios from "axios";
import NewAdsCard from './Cards/NewAdsCard';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { apiUrl } from '../constants/defaultValue';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { callApi } from '../utils/Api';
import AddToCartButton from './ProductDetails/AddToCartButton';
import WishListButton from './ProductDetails/WishListButton';
import {
  camelCase,
  capitalCase,
  constantCase,
  dotCase,
  headerCase,
  noCase,
  paramCase,
  pascalCase,
  pathCase,
  sentenceCase,
  snakeCase,
} from "change-case";

export default function SimilarProductListing({productCategory}) {
  // console.log("----------------------------",productCategory)
  const [product, setProduct] = useState([]);
  const [show, setShow] = useState(null);
  const [categories, setCategories] = useState([]);
  let [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let navigate = useNavigate();
  // const getProducts = () => {
  //   axios.post(`${apiUrl}/product/getProduct/getSimilarProducts`, { limit: 10, page: 1 }).then((response) => {
  //     // console.log(response.data);
  //     setProduct(response.data.data);
  //     if (screenWidth < 768) {
  //       setFilteredProduct(response.data.data.slice(0, 6));
  //     } else {
  //       setFilteredProduct(response.data.data.slice(0, 15));
  //     }
  //   });
  // }
  // useEffect(() => {
  //   getProducts();
  // }, []);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [isopen, setIsopen] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentPage,setCurrentPage]=useState(1)
  const [productCheck, setProductCheck] = useState(true);
  const [pageLimit,setPagelimit]=useState(25)
  const [totalProduct,setTotalProducts]=useState(1)

  const getProductData = () => {
    if(totalProduct!==product?.length){
    axios.post(`${apiUrl}/product/getProduct/getSimilarProducts`, { limit: pageLimit, page: currentPage, productCategory: productCategory }).then((response) => {
      // console.log(response.data);
      setProduct(privState=>[...privState,...response?.data?.data]);
      if (screenWidth < 768 && product.length===0) {
        setFilteredProduct(response?.data?.data?.slice(0, 6));
      } else if(product.length===0) {
        setFilteredProduct(response?.data?.data?.slice(0, 10));
      }
      setTotalProducts(response?.data?.total||1)
    });
  }
  }
  useEffect(() => {
    getProductData();
  }, [productCategory,currentPage]);
  const allProduct = () => {
    setFilteredProduct(product);
  }
  const maxFilter = () => {
    let data = product.sort((a, b) => b.grossPrice - a.grossPrice);
    console.log(data);
    setFilteredProduct((e) => [...data]);
  }
  const minFilter = () => {
    let data = product.sort((a, b) => a.grossPrice - b.grossPrice);
    console.log(data);
    setFilteredProduct((e) => [...data]);
  }
  const oldestFilter = () => {
    let data = product.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
    console.log(data);
    setFilteredProduct((e) => [...data]);
  }
  const newestFilter = () => {
    let data = product.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
    console.log(data);
    setFilteredProduct((e) => [...data]);
  }
  const isFeaturedFilter = () => {
    let data = product.filter((item) => item.isFeatured === 'yes');
    console.log(data);
    setFilteredProduct((e) => [...data]);
  }
  const highratingFilter = () => {
    let data = product.sort((a, b) => b.reviewsSummery.rating - a.reviewsSummery.rating);
    console.log(data);
    setFilteredProduct((e) => [...data]);
  }
  const maxDiscountFilter = () => {
    let data = product.sort((a, b) => b.offerSummary - a.offerSummary);
    console.log(data);
    setFilteredProduct((e) => [...data]);
  }
  const toggle = () => {
    setIsopen(!isopen);
  }
  const toggle1 = () => {
    setOpen(!open);
  }
  useEffect(() => {
    callApi('post', 'category/curd/getAllKeys').then(response => {
      // console.log(response.data);
      let Category = Object.keys(response.data);

      setCategories(Category);
    });
  }, []);
  const routeChange = (item) => {
    let path = `/category/${item}`;
    navigate(path);
  }
  const zoomOutProperties = {
    duration: 2000,
    transitionDuration: 600,
    infinite: true,
    indicators: false,
    arrows: false,
    autoplay: true,
  };
  const showDetails = (e) => {

    setShow(e);
  }
  const removeDetails = (e) => {
    setShow(null);
  }
  const addUserVisited=(p_Id)=>{
    axios.post(`${apiUrl}/product/getProduct/userVisited`, { userId: localStorage.getItem('userid')|| null, productId: p_Id })
    .then((response) => {
      // console.log(response.data)
  }).catch((error)=>{
    console.error(error)
  })
}

const showHideSection = (e) => {
  if (screenWidth < 768) {
    setFilteredProduct(product.slice(0, 6));
  } else {
    setFilteredProduct(product.slice(0, 10));
  }
  // setFilteredProduct(product.slice(0, 10));
  setProduct([])
  setCurrentPage(1)
  setProductCheck(!productCheck);
}
const showMoreSection = (e) => {
  const itemsToAdd = screenWidth < 768 ? 6 : 10;
  const newItems = product.slice(filteredProduct.length, filteredProduct.length + itemsToAdd);
  setFilteredProduct(prevState => [...prevState, ...newItems]);
  setCurrentPage(currentPage+1)
  // console.log(newItems.length)
  if(newItems.length===0){
    setCurrentPage(1)
  }
  // if (product.length <= (filteredProduct.length+newItems.length)) {
    if (totalProduct <= (filteredProduct.length+newItems.length)) {
    setProductCheck(!productCheck);
  }
}

  return (
    <div>
      <section className="content" >
        <section className="block" >
          <div className="container">

            <div className="section-title clearfix">
              <div className="float-xl-left float-md-left float-sm-none">
                <h2>Similar Products</h2>
              </div>
              {/* <div className="float-xl-right float-md-right float-sm-none">
                <select
                  name="categories"
                  id="categories"
                  className="small width-200px"
                  data-placeholder="Category"
                >
                  <option value="">Category</option>
                  <option value="1">Computers</option>
                  <option value="2">Real Estate</option>
                  <option value="3">Cars & Motorcycles</option>
                  <option value="4">Furniture</option>
                  <option value="5">Pets & Animals</option>
                </select>
                <select
                  name="sorting"
                  id="sorting"
                  className="small width-200px"
                  data-placeholder="Default Sorting"
                >
                  <option value="">Default Sorting</option>
                  <option value="1">Newest First</option>
                  <option value="2">Oldest First</option>
                  <option value="3">Lowest Price First</option>
                  <option value="4">Highest Price First</option>
                </select>
              </div> */}
              <div className="float-xl-right float-md-right float-sm-none">

                {/* <ButtonDropdown isOpen={open} className='sorting-option' toggle={toggle1}>
                  <DropdownToggle style={{ backgroundColor: "rgba(256, 256, 256, 1)", padding: '8px' }} className="small text-dark sorting-option width-200px" caret size="lg">
                    Choose Category
                  </DropdownToggle>
                  <DropdownMenu className='width-200px '>
                    {categories.map((category) => {
                      return (
                        <>
                          <DropdownItem className='width-200px ' onClick={e => routeChange(category)}>{category}</DropdownItem>
                        </>

                      )
                    })}
                  </DropdownMenu>
                </ButtonDropdown>
                <ButtonDropdown isOpen={isopen} className='sorting-option' toggle={toggle}>
                  <DropdownToggle style={{ backgroundColor: "rgba(256, 256, 256, 1)", padding: '8px' }} className="small text-dark sorting-option width-200px" caret size="lg">
                    Default Sorting
                  </DropdownToggle>
                  <DropdownMenu className='width-200px '>
                    <DropdownItem className='width-200px ' onClick={allProduct}>All</DropdownItem>
                    <DropdownItem className='width-200px ' onClick={isFeaturedFilter}>Recommended</DropdownItem>
                    <DropdownItem className='width-200px ' onClick={newestFilter}>What's New</DropdownItem>
                    <DropdownItem className='width-200px ' onClick={maxFilter}>Sort by Max Price</DropdownItem>
                    <DropdownItem className='width-200px ' onClick={minFilter}>Sort Min Price</DropdownItem>
                    <DropdownItem className='width-200px ' onClick={maxDiscountFilter}>Sort by Discount</DropdownItem>
                    <DropdownItem className='width-200px ' onClick={highratingFilter}>Sort by Ratings</DropdownItem>
                    <DropdownItem className='width-200px ' onClick={oldestFilter}>Sort by oldest</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown> */}
              </div>

            </div>

            <ResponsiveMasonry
              columnsCountBreakPoints={{ 250: 1, 350: 2, 750: 3, 900: 3,1000:4,1200:5 }}
              gutter={0}
            >
              <Masonry>
                {filteredProduct?.map((item, key) => {
                                  if (!item.merchant?.active) {
                                    // Skip inactive merchantss
                                    return null;
                                  }
                                  let a = item.merchant
                                  // console.log("Filter Product as category", a);
                  switch (item.listingType) {
                    case "product":
                      return (
                        <Link key={key} to={`/productdetails/${paramCase(item.productName)}/${item._id}`}>
                          <div 
                          // onMouseEnter={() => showDetails(item._id)}
                          // onMouseLeave={() => removeDetails()}
                          onClick={()=>addUserVisited(item._id)} className="card masonry-card" >

                            {item.isFeatured === 'yes' ?
                              <div className="ribbon-featured">
                                <div className='ribbon-start'></div>
                                <div className='ribbon-content'>Featured</div>
                                <div className='ribbon-end'>
                                  <figure className='ribbon-shadow'></figure>
                                </div>
                              </div>
                              : null}
                            <div className="wrapper-img">
                              <div className="img">

                                <div className="slide-container">
                                  {show === item._id ? <>
                                    <Fade  {...zoomOutProperties}>
                                      {
                                        item.productThumbnailImages.map((each, index) => {
                                          return (
                                            <div className="each-fade" key={index}>
                                              <img style={{ width: "100%",height:"200px" }} src={each.imgurl} />
                                            </div>
                                          )
                                        })
                                      }
                                    </Fade>
                                  </>

                                    : <img style={{ width: "100%", height:"200px" }} src={item.productThumbnailImages[0]?.imgurl} />
                                  }
                                </div>
                              </div>

                            </div>
                            <div className="container">
                              <div className="row">
                                <div className="col-md-6 col-6 mt-4">
                                  <div className="m-price">{item.reviewsSummery.rating}&nbsp;<i className='fa fa-star clr-star'></i>
                                    {/* &nbsp;{item.reviewsSummery.totalReviews}  */}
                                    </div>
                                </div>
                                <div className="col-md-3 col-3 ">
                                  <WishListButton item={item._id} user={localStorage.getItem('userid')} getProducts={getProductData}></WishListButton>

                                </div>
                                <div className="col-md-3 col-3 ">
                                  {/* <Button className="float-right n-price" color='link' >
                                    <i className='fa fa-cart-plus'></i>

                                  </Button> */}
                                  <AddToCartButton pid={item._id} />
                                </div>

                              </div>



                              <div className="m-meta">
                                <a className="title m-indtitle text-capitalize">
                                  {item.productName}
                                </a>
                                <a href="/" className=" m-category macategory">
                                  {item.productCategory[0]?.value}

                                </a>
                                <div>

                                  <div className="row">
                                    <div className="col-md-12 col-12">
                                      <span className='rupees'>Rs.{item.grossPrice}</span>

                                      <span className='cross-mark'>
                                        Rs. {item.offerSummary}</span>
                                      <span className='offersummary'>({Math.round(((item.offerSummary-item.grossPrice)/item.offerSummary)*100)}% OFF)</span>
                                    </div>
                                    <div className="col-md-6 col-6 ">

                                      {/* {show != item._id ?
                                  <div><Button className='float-right n-price' style={{top:0}} color='link' onClick={e => showDetails(item._id)} >
                                    <i className='fa fa-angle-down '></i>  </Button></div>
                                  :
                                  <div>
                                    <Button className='float-right n-price' color='link' onClick={e => removeDetails()} >
                                      <i className='fa fa-angle-up'></i> </Button></div>} */}


                                    </div>
                                  </div>



                                </div>
                              </div>


                              {/* {show === item._id ?
                                <div className='show-details'>
                                  <div className="description">
                                    {item.productDescription}
                                  </div>
                                  <div className="m-additional-info">

                                    <ul>
                                      {item.productFeatures.map((i,index) => {

                                        return <li key={index}>
                                          <figure style={{ fontSize: "1.1rem", fontWeight: '600', color: '#000' }} >{i.key}</figure>
                                          <aside style={{ fontSize: "1.1rem", fontWeight: '100' }} >{i.value}</aside>
                                        </li>
                                      })}


                                    </ul>

                                  </div>

                                </div>
                                : null} */}

                            </div>

                          </div>
                        </Link>
                      )
                    case "ads":
                      return <NewAdsCard />
                  }


                })}
              </Masonry>
            </ResponsiveMasonry>


            {filteredProduct?.length >= (screenWidth<768?6:10) ?
              <div className="center">
                {!productCheck ? <button className="btn btn-primary btn-framed btn-rounded" onClick={(e) => showHideSection(e)}>
                  Show Less
                </button> : <button className="btn btn-primary btn-framed btn-rounded" onClick={(e) => showMoreSection(e)}>
                  Show More
                </button>}
              </div>
              : ''}
          </div>
        </section>
      </section>













    </div>
  )
}
