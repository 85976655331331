import React from 'react';
import { Link } from 'react-router-dom';

export default function Privacy() {
  return (
    <div style={{ marginLeft: "40px", marginRight: "40px", marginTop: "20px", marginBottom: "35px", textAlign: "justify", textJustify: "inter-word" }}>
      <h1 className="text-center">Privacy Policy</h1>

      This privacy policy ("policy") will help you understand how Convolutions Infrasys Pvt Ltd ("us", "we", "our") uses and protects the data you provide to us when you visit and use www.mayifind.com ("website", "service") or our mobile application ("app").

      <br />We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.<br />
      <br /><h4>What User Data We Collect</h4>
      When you visit the website or use the app, we may collect the following data:
      <ul>
        <li>Your IP address.</li>
        <li>Your contact information and email address.</li>
        <li>Other information such as interests and preferences.</li>
        <li>Data profile regarding your online behaviour on our website and app.</li>
      </ul>
      <h4>Why We Collect Your Data</h4>
      We are collecting your data for several reasons:
      <ul>
        <li>To better understand your needs.</li>
        <li>To improve our services and products.</li>
        <li>To send you promotional emails containing the information we think you will find interesting.</li>
        <li>To contact you to fill out surveys and participate in other types of market research.</li>
        <li>To customize our website and app according to your online behaviour and personal preferences.</li>
      </ul>
      <h4>Safeguarding and Securing the Data</h4>
      Convolutions Infrasys Pvt Ltd is committed to securing your data and keeping it confidential. We have taken all reasonable measures to prevent data theft, unauthorized access, and disclosure by implementing the latest technologies and software to safeguard all the information we collect online.

      <h4>Our Cookie Policy</h4>
      Once you agree to allow our website to use cookies, you also agree to use the data it collects regarding your online behaviour (analyze web traffic, web pages you spend the most time on, and websites you visit).<br />
      <br />The data we collect by using cookies is used to customize our website and app to your needs. After we use the data for statistical analysis, the data is completely removed from our systems.<br />
      <br />Please note that cookies don't allow us to gain control of your computer or device in any way. They are strictly used to monitor which pages you find useful and which you do not so that we can provide a better experience for you.<br />
      <br />If you want to disable cookies, you can do it by accessing the settings of your internet browser or device. (Provide links for cookie settings for major internet browsers).

      <h4>Links to Other Websites</h4>
      Our website and app may contain links that lead to other websites. If you click on these links, Convolutions Infrasys Pvt Ltd is not held responsible for your data and privacy protection. Visiting those websites is not governed by this privacy policy agreement. Make sure to read the privacy policy documentation of the website you go to from our website or app.

      <h4>Payment Processors</h4>
      We use third-party payment processors, including PhonePe and CCAvenue, to handle payment transactions. These processors may collect personal information from you to facilitate the payment process. We encourage you to review their privacy policies to understand how they handle your personal information.

      <h4>Shipping Services</h4>
      For order fulfillment, we may use Shiprocket to manage shipping and logistics. Shiprocket may collect and process your data for order delivery purposes. Please refer to their privacy policy for more information on how they handle your personal data.

      <h4>Restricting the Collection of your Personal Data</h4>
      <ul>
        <li>At some point, you might wish to restrict the use and collection of your personal data. You can achieve this by doing the following:</li>
        <li>When you are filling out forms on the website or app, make sure to check if there is a box which you can leave unchecked, if you don't want to disclose your personal information.</li>
        <li>If you have already agreed to share your information with us, feel free to contact us via email and we will be more than happy to change this for you.</li>
        <li>Convolutions Infrasys Pvt Ltd will not lease, sell, or distribute your personal information to any third parties unless we have your permission. We might do so if the law requires us. Your personal information will be used when we need to send you promotional materials if you agree to this privacy policy.</li>
      </ul>

      <h4>Contact Information</h4>
      If you have any questions about this Privacy Policy or want to exercise your rights regarding your personal data, please contact us at:
      <br />
      <address>
        426/2660, 4th Floor, NB Tower,<br />
        Nayapalli, NH-16, Bhubaneswar,<br />
        Odisha, 751012, INDIA<br />
        Email: <a href="mailto:care@mayifind.com">care@mayifind.com</a>
      </address>

      <br />
      <div className="text-center">
        <Link to="/" className="btn btn-primary btn-framed btn-rounded width-8">Home <span><i className='fa fa-home'></i></span></Link>
      </div>
      <br />
    </div>
  );
}
