import React from 'react'

export default function Description(props) {
    console.log(props);
    return (
        <div>
            <section>
                <h2>Description</h2>
                <p>{props.desc}</p>
            </section>

            <section>
                <h2>Features</h2>
                <dl>
                        {props.feature.map(i => {
                          
                          return (
                            <>
                            <dt style={{fontSize:"1.1rem",fontWeight:'600',color:'#000'}} >{i.key}</dt>
                            <dd style={{fontSize:"1.1rem",fontWeight:'100'}} >{i.value}</dd>
                            </>
                            
                          )
                        })}
                        
                        
                      </dl>



                
                <h2>Product Description</h2>
                <div className="map height-300px" id="map-small"></div>

            </section>

            {/* <section>
                <h2>Features</h2>
                <ul className="features-checkboxes columns-3">
                    <li>Quality Wood</li>
                    <li>Brushed Alluminium Handles</li>
                    <li>Foam mattress</li>
                    <li>Detachable chaise section</li>
                    <li>3 fold pull out mechanism</li>
                </ul>
            </section> */}
        </div>
    )
}
