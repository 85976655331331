import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { callApi } from '../../utils/Api';
import PageTitle from '../PageTitle';
import Footer from '../../layouts/Footer';
import CategoriesCard from '../Cards/CategoryCard';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Fade } from 'reactstrap';
import WishListButton from '../ProductDetails/WishListButton';
import AddToCartButton from '../ProductDetails/AddToCartButton';
import NewAdsCard from '../Cards/NewAdsCard';
import { paramCase } from 'change-case';
import axios from 'axios';
import { apiUrl } from '../../constants/defaultValue';
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";

export default function OfferDetails() {
  const { bannerId } = useParams();  // Get bannerId from the URL parameters
  // console.log(bannerId)
  const [show, setShow] = useState(null);
  const [bannerDetails, setBannerDetails] = useState(null);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBannerDetails = async () => {
    try {
      setLoading(true);
      const response = await callApi('get', `ad/ads/getBannerByIdForUser/${bannerId}`);
      // console.log(response.data);
      setBannerDetails(response?.data);
      const extractedProducts = response?.data?.products//?.map(item => item._id);
      setProducts(extractedProducts);
      // const extractedCategories = response?.data?.categories//?.map(item => item._id);
      // setCategories(extractedCategories);

      let extractedCategories = []
      response?.data?.categories.forEach(element => {
        if (element.isFeaturedAtHomePage) {
          extractedCategories.push({ title: element.categoryName, subTitle: element.categoryName, img: element.img, categoryOrder: element.categoryOrder });
        }
      });
      setCategories(extractedCategories);

      // console.log("product", extractedProducts)
      // console.log("category", extractedCategories)
    } catch (error) {
      console.error('Error fetching banner details:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBannerDetails();
  }, [bannerId]);

  // if (!bannerDetails) {
  //   return <p>Loading...</p>;
  // }

  const removeDetails = (e) => {
    setShow(null);
  }

  const addUserVisited=(p_Id)=>{
    axios.post(`${apiUrl}/product/getProduct/userVisited`, { userId: localStorage.getItem('userid')|| null, productId: p_Id })
    .then((response) => {
      // console.log(response.data)
    }).catch((error)=>{
      console.error(error)
    })
  }

  const zoomOutProperties = {
    duration: 2000,
    transitionDuration: 600,
    infinite: true,
    indicators: false,
    arrows: false,
    autoplay: true,
  };

  const ProductSkeleton = () => (
    <div className="card masonry-card">
      <Skeleton height={200} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-6 mt-4">
            <Skeleton width={80} />
          </div>
          <div className="col-md-3 col-3">
            <Skeleton circle width={30} height={30} />
          </div>
          <div className="col-md-3 col-3">
            <Skeleton circle width={30} height={30} />
          </div>
        </div>
        <Skeleton count={2} />
        <Skeleton width={150} />
      </div>
    </div>
  );

  return (
    <>
      <PageTitle />
      <div className="container" >
        {/* <h2>{bannerDetails?.bannerName}</h2> */}
        {bannerDetails?.bannerType === 'product-wise' ? (
          <div>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 250: 1, 350: 2, 750: 2, 900: 3,1000:4,1200:5 }}
              gutter={0}
              className="mt-3"
            >
              <Masonry>
                {loading ? (
                  Array(5).fill().map((_, index) => (
                    <ProductSkeleton key={index} />
                  ))
                ) : (
                  products?.slice().map((item, key) => {
                    if (!item?.merchant?.active){
                      // Skip inactive merchants
                      return null;
                    }
                    let a = item?.merchant?.active
                    // console.log("Filter Product", !a);

                    switch (item.listingType) {
                      case "product":
                        return (
                          <Link key={key} to={`/productdetails/${paramCase(item.productName)}/${item._id}`}>
                            <div  
                            // onMouseEnter={() => showDetails(item._id)} 
                            onMouseLeave={() => removeDetails()} 
                            onClick={()=>{addUserVisited(item._id)}} 
                            className="card masonry-card" >

                              {item.isFeatured === 'yes' ?
                                <div className="ribbon-featured">
                                  <div className='ribbon-start'></div>
                                  <div className='ribbon-content'>Featured</div>
                                  <div className='ribbon-end'>
                                    <figure className='ribbon-shadow'></figure>
                                  </div>
                                </div>
                                : null}
                              <div className="wrapper-img">
                                <div className="img">

                                  <div className="slide-container">
                                    {show === item._id ? <>
                                      <Fade  {...zoomOutProperties}>
                                        {
                                          item.productThumbnailImages.map((each, index) => {
                                            return (
                                              <div className="each-fade">
                                                <img key={index} style={{ width: "100%",height:"200px" }} src={each.imgurl} />
                                              </div>
                                            )
                                          })
                                        }
                                      </Fade>
                                    </>

                                      : <img style={{ width: "100%",height:"200px" }} src={item.productThumbnailImages[0]?.imgurl} />
                                    }
                                  </div>
                                </div>

                              </div>
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-md-6 col-6 mt-4">
                                    <div className="m-price" >{item.reviewsSummery.rating}&nbsp;<i className='fa fa-star clr-star'></i>
                                      {/* &nbsp;{item.reviewsSummery.totalReviews}  */}
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-3 ">
                                    <WishListButton item={item._id} user={localStorage.getItem('userid')} getProducts={fetchBannerDetails}></WishListButton>

                                  </div>
                                  <div className="col-md-3 col-3 ">
                                    {/* <Button className="float-right n-price" color='link' >
                                      <i className='fa fa-cart-plus'></i>

                                    </Button> */}
                                    <AddToCartButton pid={item._id} />
                                  </div>
                                </div>
                                <div className="m-meta">
                                  <a className="title m-indtitle text-capitalize" >
                                    {item.productName}
                                  </a>
                                  <a href="/" className="m-category macategory"  >
                                    {item.productCategory[item.productCategory.length - 1]?.value}
                                  </a>
                                  <div>

                                  <div className="row" style={{ whiteSpace: "nowrap" }}>
                                      <div className="col-md-6 col-6">
                                        <span className='rupees'>Rs.{item.grossPrice}&nbsp;</span>
                                        <span className='cross-mark'>
                                          Rs. {item.offerSummary} </span>
                                        <span className='offersummary'>({Math.round(((item.offerSummary-item.grossPrice)/item.offerSummary)*100)}% OFF)</span>
                                      </div>
                                      <div className="col-md-6 col-6 ">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* {show === item._id ?
                                  <div className='show-details'>
                                    <div className="description">
                                      {item.productDescription}
                                    </div>
                                    <div className="m-additional-info">

                                      <ul>
                                        {item.productFeatures.map(i => {

                                          return <li>
                                            <figure style={{ fontSize: "1.1rem", fontWeight: '600', color: '#000' }} >{i.key}</figure>
                                            <aside style={{ fontSize: "1.1rem", fontWeight: '100' }} >{i.value}</aside>
                                          </li>
                                        })}


                                      </ul>

                                    </div>

                                  </div>
                                  : null} */}

                              </div>

                            </div>
                          </Link>
                        )
                      case "ads":
                        return <NewAdsCard />
                    }
                  })
                )}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        ) : bannerDetails?.bannerType === 'category-wise' ? (
          <div>
            <section className="category-section  sec-pad mt-3 mb-5">
              {/* <div className="container"><h2>Categories</h2></div> */}
              <div className="auto-container centred">
                <div className="inner-content clearfix">
                  <CategoriesCard data={categories} />
                </div>
              </div>
            </section>
          </div>
        ) : null}
      </div>
      <Footer />
    </>
  );
}