// actions/wish-listActions.js
import { callApi } from '../utils/Api';
import { ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST,FETCH_WISHLIST_REQUEST,FETCH_WISHLIST_SUCCESS,FETCH_WISHLIST_FAILURE } from './actionType';

export const addToWishlist = (product) => ({
  type: ADD_TO_WISHLIST,
  payload: product,
});

export const removeFromWishlist = (productId) => ({
  type: REMOVE_FROM_WISHLIST,
  payload: productId,
});
export const fetchWishlistRequest=()=>{
  return{
    type:FETCH_WISHLIST_REQUEST
  }
}
export const fetchWishlistSuccess=(wishlists)=>{
  return{
    type:FETCH_WISHLIST_SUCCESS,
    payload:wishlists
  }
}
export const fetchWishlistFailur=(error)=>{
  return{
    type:FETCH_WISHLIST_FAILURE,
    payload:error
  }
}


//Fetching Wishlist Data of users
export const fetchUserWishlist=(userId)=>{
  return (dispatch)=>{
    dispatch(fetchWishlistRequest)
    callApi('post',`product/getProduct/getWishlistById`, {
      user: userId,
    }).then(res=>{
      const cartData=res.data;
      dispatch(fetchWishlistSuccess(cartData));
    }).catch(error=>{
      const errors=error;
      dispatch(fetchWishlistFailur(errors));
    })
  }
}
