import React from 'react'
import { Link } from 'react-router-dom'
import { useState,useEffect } from 'react'
import axios from 'axios'
import { callApi } from '../utils/Api'
import ReactQuill from 'react-quill';
import { useParams } from 'react-router-dom'
import ContentLoader from 'react-content-loader'
import { apiUrl } from '../constants/defaultValue'

export default function Website() {
  const { name, id } = useParams();
  console.log(name, id);
  const [vendorId, setVendorId] = useState(id);
  const [sellerDetails, setSellerDetails] = useState([])
  const [category, setCategory] = useState([]);
  const [categoryLess, setCategoryLess] = useState([]);
  const [categoryCheck, setCategoryCheck] = useState(false);
  const [sellerProduct, setSellerProduct] = useState([]);
  const [filterVendorData, setFilterVendorData] = useState([]);
  const [filterPriceData, setFilterPriceData] = useState([]);
  const [filterOption, setFilterOption] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [users, setUsers] = useState([]);
  const [data, setData] = useState('');
  const [displayreview, setDisplayreview] = useState(true);
  const [sellerProductDetails, setSellerProductDetails] = useState([]);
  const userId=localStorage.getItem('userid')
  console.log("User Id",userId)
  console.log("userIdTest",vendorId)
  useEffect(() => {
    callApi('post', 'merchant/getMerchant/getUserDetailsById', { userId: userId}).then(res => {
        // console.log(res.data);
        if (res.data) {
            setSellerDetails(res.data)
            setVendorId(userId)
        }
    }).catch(err => { console.log(err); })
    callApi('post', 'filter/filterCrud/getFilterSection').then(response => {
        if (response.data) {
            let vendorData = response.data.filter((item) => item.filterType === 'Vendor');
            let priceData = response.data.filter((item) => item.filterType === 'Price');
            setFilterVendorData(vendorData);
            setFilterPriceData(priceData);
        }
    }
    ).catch(err =>
        console.log(err)
    );
}, [])
console.log(sellerDetails);
useEffect(() => {
    callApi('post',`product/getProduct/getProductByUserId`, { "user": vendorId }).then(res => {
        console.log(res.data);
        // setSellerProduct(res.data.data)
        setSellerProductDetails(res.data)
    }).catch(err => {
        console.log(err);
    })
}, [])

const merchantIId = JSON.parse(localStorage.getItem('merchantIId'));

const requestData = {
    merchantId: merchantIId,
    // status: status,
  };
useEffect(()=>{
  callApi('post',`/web/webs/getWebByMerchantId`,)
})
  async function fetchData() {
    axios
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        setItems(
          data.data
            .filter((x) => x.isActive) // Filter out inactive items
            .map((x) => {
              return { ...x, img: x.img.replace('img/', 'img/products/') };
            })
        );
        setSelectedItems([]);
        // setIsLoaded(true);
      });
    axios
      .post(`${apiUrl}/web/webs/getWebByMerchantId`, requestData)
      .then((response) => {
        //   console.log("Website By Mer Id For Mer",response.data.data[0].shopName);
        console.log("Website By Mer Id For Mer", response.data);
        setUsers(response.data);
        setData(response.data);
        localStorage.setItem('data', JSON.stringify(response.data));
        console.log("Set Data For Website", data)
      });
  }

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('data'));
    if (storedData) {
      setData(storedData);
    } else {
      fetchData();
    }
  }, []);
  return (
    <div style={{marginLeft:"40px",marginRight:"40px",marginTop:"20px",marginBottom:"35px",textAlign:"justify",textJustify:"inter-word"}}>
        <h1 className="text-center">Merchant Website</h1>

          <div>
            {sellerDetails ? <section className="content">
                <section className="block">
                    <div className="container">
                        <div className="row flex-column-reverse flex-md-row">
                            <div className="col-md-8">

                                <div className="row">
                                    <div className="col-md-4 col-12">
                                        <div className="author big">
                                            <div className="author-image">
                                                <div className="background-image">
                                                    <img src={sellerDetails?.avatar} style={{ width: '100%' }} alt="" />
                                                </div>
                                            </div>

                                            <div className="section-title1 text-center">
                                                <h2 style={{ marginBottom: '4px' }}>{sellerDetails?.merchant?.merchantCompanyName}</h2>
                                                <h4 className="location" >
                                                    <a href="/">{sellerDetails.merchant?.merchantCompanyCity},{sellerDetails.merchant?.merchantCompanyState},{sellerDetails.merchant?.merchantCompanyCountry}</a>
                                                </h4>


                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-md-8 col-12">
                                        <div className="author-description">

                                            <div className="row ">
                                                <div className="col-md-3 col-4">
                                                    <aside >{sellerProduct.length}</aside>
                                                    <figure >Products</figure>
                                                </div>
                                                <div className="col-md-3 col-4">
                                                    <aside >23</aside>
                                                    <figure >Sells</figure>
                                                </div>
                                                <div className="col-md-3 col-4">
                                                    <aside >{sellerDetails.merchant?.reviewSummery?.rating} <i className='fa fa-star clr-star'></i></aside>
                                                    <figure >Rating</figure>
                                                </div>
                                                <div className="col-md-3"></div>
                                            </div>
                                            <h2 style={{ fontSize: "18px", marginBottom: '10px', fontWeight: '400' }}>About Seller</h2>
                                            <ReactQuill theme={"bubble"} value={sellerDetails.merchant?.description} readOnly={true} />
                                            <br />
                                            <div className="row mt-2">
                                                <div className="col-md-4 col-6">
                                                    <figure>Phone</figure>
                                                    <aside>{sellerDetails?.phoneNumber}</aside>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <figure>Email</figure>
                                                    <aside><a href="/">{sellerDetails?.email}</a></aside>
                                                </div>
                                                <div className="col-md-4"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />                               

                            </div>

                            <div className="col-md-4">
                                <section className=" seller-insta-scroll">
                                    <div className="container">
                                        <div className="row">
                                        </div>
                                    </div>
                                </section>

                            </div>

                        </div>
                    </div>

                </section>

            </section> : <div><ContentLoader
                speed={2}
                width={476}
                height={124}
                viewBox="0 0 476 124"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"

            >
                <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
                <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                <circle cx="20" cy="20" r="20" />
            </ContentLoader></div>}

        </div>  
<div className="text-center">
    <Link to="/" className="btn btn-primary btn-framed btn-rounded width-8">Home <span><i className='fa fa-home'></i></span></Link>
    </div>
<br/>
</div>

  )
}
