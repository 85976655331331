import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function CategoriesCard(props) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    const styles = {
        categoryLink: {
            display: 'inline-block',
            marginRight: '20px',
            textDecoration: 'none',
            color: 'inherit',
        },
        categoryBlock: {
            width: '220px',
            height: '222px',
            padding: '5px',
            boxSizing: 'border-box',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            background: 'transparent',
        },
        innerBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        iconBox: {
            width: '100%',
            height: '150px',
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
        },
        textBox: {
            marginTop: '10px',
            textAlign: 'center',
            wordWrap: 'break-word',
            maxWidth: '100%',
        },
        navButton: {
            position: 'absolute',
            top: '40%',
            transform: 'translateY(-50%)',
            background: 'transparent',
            border: 'none',
            padding: 0,
            cursor: 'pointer',
            zIndex: 1,
            outline: 'none',
        },
        arrowIcon: {
            width: '60px',
            height: '60px',
            opacity: 0.3,
            transition: 'opacity 0.3s ease',
        },
        arrowSkeleton: {
            width: '24px',
            height: '24px',
            backgroundColor: '#e0e0e0',
            clipPath: 'polygon(100% 50%, 0 0, 0 100%)',
        },
    };

    const renderSkeletons = () => {
        return Array(5).fill().map((_, i) => (
            <div key={i} style={styles.categoryLink}>
                <Skeleton style={styles.categoryBlock} />
            </div>
        ));
    };

    const renderCategoryItems = () => {
        return props.data.map((index, i) => (
            <Link to={`/category/${index.title}`} key={i} style={styles.categoryLink}>
                <div className="category-block-one" style={styles.categoryBlock}>
                    <div className="inner-box" style={styles.innerBox}>
                        <div className="icon-box" style={styles.iconBox}>
                            {/* <i className='icon-6'></i> */}
                            {/* Uncomment the following code if you want to use specific images based on titles */}
                            {/* 
                            {
                                index.title === "Animals & Pet Supplies" && <img src="assets/images/panda.png" />
                            }{
                                index.title === "Cameras & Optics" && <img src="assets/images/camera.png" />
                            }{
                                index.title === "Electronics" && <img src="assets/images/user-interface.png" />
                            }{
                                index.title === "Furniture" && <img src="assets/images/workspace.png" />
                            }{
                                index.title === "Apparel & Accessories" && <img src="assets/images/clothes.png" />
                            }{
                                index.title === "Arts & Entertainment" && <img src="assets/images/concert.png" />
                            }{
                                index.title === "Baby & Toddler" && <img src="assets/images/baby-boy.png" />
                            }{
                                index.title === "Business & Industrials" && <img src="assets/images/industry.png" />
                            }{
                                index.title === "Bussiness" && <img src="assets/images/industry.png" />
                            }
                            {
                                index.title === "Food  and  Beverages" && <img src="assets/images/healthy-drinks.png" />
                            }{
                                index.title === "Business & Industrial" && <img src="assets/images/hook.png" />
                            }{
                                index.title === "Health & Beauty" && <img src="assets/images/cosmetics.png" />
                            }{
                                index.title === "Home Renovation" && <img src="assets/images/house.png" />
                            }{
                                index.title === "Luggage & Bags" && <img src="assets/images/suitcase.png" />
                            }{
                                index.title === "Mature" && <img src="assets/images/nuclear-bomb.png" />
                            }{
                                index.title === "Media" && <img src="assets/images/carpent.png" />
                            }{
                                index.title === "Office Supplies" && <img src="assets/images/binders.png" />
                            }{
                                index.title === "Religious & Ceremonial" && <img src="assets/images/praying.png" />
                            }{
                                index.title === "Software" && <img src="assets/images/software.png" />
                            }{
                                index.title === "Sporting Goods" && <img src="assets/images/trophy.png" />
                            }{
                                index.title === "Toys & Games" && <img src="assets/images/storage-box.png" />
                            }{
                                index.title === "Vehicles & Parts" && <img src="assets/images/car.png" />
                            }{
                                index.title === "Event" && <img src="assets/images/Events.png" />
                            }{
                                index.title === "Professional Services" && <img src="assets/images/professional-services.png" />
                            }{
                                index.title === "Home and Office Services" && <img src="assets/images/Home&Office.png" />
                            }{
                                index.title === "Home Renovations" && <img src="assets/images/Reinnovation.png" />
                            }{
                                index.title === "Health and Wellness" && <img src="assets/images/healthcare.png" />
                            }{
                                index.title === "Travel and Transport" && <img src="assets/images/Travel.png" />
                            }{
                                index.title === "Education and Career" && <img src="assets/images/Carrer Education.png" />
                            }{
                                index.title === "Real Estate" && <img src="assets/images/Real Estate.png" />
                            }{
                                index.title === "Pre Owned" && <img src="assets/images/second-hand.png" />
                            }{
                                index.title === "Food and Stay" && <img src="assets/images/Hotel & Stay.png" />
                            }{
                                index.title === "Home Decor" && <img src="assets/images/furniture.png" />
                            } */}
                        </div>
                        <div className="icon-box">
                            <img src={index.img} alt={index.title} style={styles.image} />
                        </div>
                        <p className="icon-box1 text-box-1" style={styles.textBox}>{index.title}</p>
                        {/* <p className='text-box'>{index.subTitle}</p> */}
                        <div className='text-des'>
                            {/* <ul>
                            {
                                index.categoryListing[0].slice(0, 6).map((item, index) => {
                                    console.log(item);
                                    return <>
                                     
                                     <li key={index}><Link to={`/category/${item}`}> <a  className='text-description'>{item}</a> </Link>  </li>
                                     
                                        
                                    </>
                                })
                            }
                        </ul>
                        {
                            index.categoryListing[0].length > 6 ? <a className='text-description-btn'>more details</a> : ''
                        } */}
                        </div>
                        {/* <span>{index.count}</span> */}
                    </div>
                </div>
            </Link>
        ));
    };

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
        1200: { items: 4 },
    };

    const renderNextButton = ({ isDisabled }) => (
        <button style={{ ...styles.navButton, right: "0px" }} disabled={isDisabled}>
            {loading ? (
                <div style={styles.arrowSkeleton}></div>
            ) : (
                <svg viewBox="0 0 24 24" style={styles.arrowIcon}>
                    <path fill="currentColor" d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                </svg>
            )}
        </button>
    );

    return (
        <div>
            <AliceCarousel
                mouseTracking
                items={loading ? renderSkeletons() : renderCategoryItems()}
                responsive={responsive}
                controlsStrategy="alternate"
                disableDotsControls
                renderNextButton={renderNextButton}
                autoPlay
                autoPlayInterval={3000}
                infinite
            />
            <style>
                {`
                .alice-carousel .alice-carousel__prev-btn {
                    display: none !important;
                }
                .alice-carousel__next-btn-item {
                    background: transparent !important;
                }
                .category-block-one {
                    background: transparent; 
                }
                .category-block-one:hover {
                    transform: translateY(-5px);
                    box-shadow: none !important;
                    background: transparent !important; 
                }
                .alice-carousel__next-btn-item:hover svg {
                    opacity: 0.6; 
                }
                `}
            </style>
        </div>
    )
}