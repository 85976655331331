// reducers/cartReducer.js
import { ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST,FETCH_WISHLIST_REQUEST,FETCH_WISHLIST_SUCCESS, FETCH_WISHLIST_FAILURE } from '../actions/actionType';

const initialState = {
  loading:true,
  wishlists: [],
  error:""
};

const wishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_WISHLIST:
      return {
        ...state,
        wishlists: [...state.wishlists, action.payload],
      };
    case FETCH_WISHLIST_REQUEST:
      return {
        ...state,
        loading:true
      }
    case FETCH_WISHLIST_SUCCESS:
      return {
        loading:false,
        wishlists:action.payload,
        error:""
      }
    case FETCH_WISHLIST_FAILURE:
      return {
        loading:false,
        wishlists:[],
        error:action.payload
      }
    case REMOVE_FROM_WISHLIST:
      return {
        ...state,
        wishlists: state.wishlists.filter((item) => item.id !== action.payload),
      };

    default:
      return state;
  }
};

export default wishlistReducer;
