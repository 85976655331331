import React from 'react';
import { Link } from 'react-router-dom';

export default function PhonepePaymentFailure() {
  localStorage.removeItem("paymentDetails");
  localStorage.removeItem("CartPrice");
  localStorage.removeItem('coupon_d');
  localStorage.removeItem('serv_char');
  return (
    <div className="payment-container">
      <div className="payment-content">
        <div className="icon-container failure-icon">
          <i className="fas fa-times-circle"></i>
        </div>
        <h4 className="heading">Oops, something went wrong!</h4>
        <p className="message">Your payment could not be processed. Please try again.</p>
        <Link to='/' className="button">
          Back to Home
        </Link>
      </div>
    </div>
  );
}