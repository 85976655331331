
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { callApi } from '../utils/Api.js';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default function Banner() {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await callApi('post', 'ad/ads/getAllActiveBanners');
        const activeBanners = response?.data.filter(banner => banner.isBannerActive); 
        if (activeBanners.length > 0) {
          setBanners(activeBanners);
        }
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();
  }, []);

  const getResponsivePadding = () => {
    const width = window.innerWidth;
    if (width >= 1200) {
      return '30%';
    } else if (width >= 768) {
      return '35%'; 
    } else {
      return '45%';
    }
  };
  const getResponsiveMargin = () => {
    const width = window.innerWidth;
    if (width >= 1200) {
      return '40px';
    } else if (width >= 768) {
      return '45px';
    } else {
      return '1px'; 
    }
  };

  const [responsivePadding, setResponsivePadding] = useState(getResponsivePadding());
  const [responsiveMargin, setResponsiveMargin] = useState(getResponsiveMargin());

  useEffect(() => {
    const handleResize = () => {
      setResponsivePadding(getResponsivePadding());
      setResponsiveMargin(getResponsiveMargin());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className="container" style={{marginTop:responsiveMargin}}>
        <div className="float-xl-left float-md-left float-sm-none my-3" >
          {/* <h2 className="text-muted mb-0">Banner</h2> */}
        </div>
        {banners.length > 0 && (
          <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay interval={2500} showStatus={false} showIndicators swipeable emulateTouch>
            {banners
              .sort((a, b) => a.bannerOrder - b.bannerOrder)
              .map(banner => (
                <Link to={`/offerdetails/${banner._id}`} key={banner._id}>
                  <div
                    style={{
                      position: 'relative',
                      // marginTop:responsiveMargin,
                      width: '100%',
                      paddingBottom: responsivePadding, 
                    }}
                  >
                    <img
                      src={banner?.img?.imgurl}
                      alt={banner.bannerName}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        // objectFit: 'cover',
                      }}
                    />
                  </div>
                </Link>
              ))}
          </Carousel>
        )}
      </div>
    </div>
  );
}
