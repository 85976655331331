import React,{useEffect,useState} from 'react'
import Footer from '../layouts/Footer'
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { Link,useParams,useNavigate } from 'react-router-dom';
import PageTitle from '../components/PageTitle'
import { callApi } from '../utils/Api';
import { setDataWithExpiration } from '../utils/SetDataWithExpiration';
import { useDispatch,useSelector } from "react-redux";
import { fetchUserReturnOrders } from '../actions/userReturnOrderAction';
import MyOrderTrackPage from './MyOrderTrackPage';

export default function TrackOrder() {
  const dispatch=useDispatch()
  const { cartId } = useParams();
  // console.log("Shipment Id",cartId)
  const [trackData,setTrackData]=useState({});
  const [trackDetails,setTrackDetails]=useState([]);
  const [shipmentStatus,setShipmentStatus]=useState('');
  const [validForReturn,setValidForReturn]=useState(false);
  const [validForCancle,setValidForCancle]=useState(false);
  const [returnProductDate,setReturnProductDate]=useState('')
  const [myOrderById, setMyOrderById] = useState({})
  const [cancleOrder,setCancleOrder]=useState([cartId])
  const [selectedOrderForCancle,setSelectedOrderForCancle]=useState([])
  // const [ordersForReturn,setOrdersForReturn]=useState([])
  const navigate=useNavigate()
  const userId=localStorage.getItem('userid')
  if(!userId){
    navigate("/login")
}
  useEffect(() => {
    if(cartId && userId){
      callApi('post', 'cart/cartcrud/getUseCartWiseTrack', { userId:userId ,cart:cartId })//"64c35a8e4f8d444b3d0ee15e"
      .then((res) => {
        let shipmentTrackActivities=res.data.track?.tracking_data?.shipment_track_activities;
        setTrackData(res.data)
        setTrackDetails(shipmentTrackActivities)
        setShipmentStatus(res.data.track?.tracking_data?.shipment_track[0]?.current_status)
        setValidForReturn(isValidForReturn(res.data.track?.tracking_data?.shipment_track[0]?.delivered_date,res.data.track?.tracking_data?.shipment_track[0]?.current_status))
        if(isValidForReturn(res.data.track?.tracking_data?.shipment_track[0]?.delivered_date,res.data.track?.tracking_data?.shipment_track[0]?.current_status)){
          addForReturnOrCancle(res.data)
        }else if(isValidForCancle(shipmentTrackActivities)){
          setValidForCancle(isValidForCancle(shipmentTrackActivities))
          addForReturnOrCancle(res.data)
        }
       
        
      }).catch((err) => {
        console.log(err)
      })
    } 
  }, [cartId])
  const isValidForReturn = (deliveryDate, shipmentStatus) => {
    const currentDate = new Date();
    const returnWithinDate = new Date(deliveryDate);
    returnWithinDate.setDate(returnWithinDate.getDate() + 7);
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = returnWithinDate.toLocaleDateString('en-US', options);
    setReturnProductDate(formattedDate)
    if (shipmentStatus === "Delivered" && currentDate < returnWithinDate) {
        return true
    } else {
        return false
    }
};
const isValidForCancle=(shipmentTrackActivities)=>{
  var cancleValid=false
  for(let track of shipmentTrackActivities){
    if(track["sr-status-label"]==="OUT FOR PICKUP" && track["sr-status"]===19){
      // console.log(track["sr-status-label"],"ddddddddddddd",track["sr-status"])
      cancleValid= false
      break
    }else{
      cancleValid=true
    }
  }
  return cancleValid
}
useEffect(() => {
  if(userId && cartId){
  callApi('post', 'cart/cartcrud/getOrderByOrderId',{userId:userId,cartId:cartId}) // "64c35a8e4f8d444b3d0ee15e"
    .then((res) => {
      if(res.code===200){
        setMyOrderById(res.data)
      }
    }).catch((err) => {
      console.log("error",err)
    })}
}, []);

  const checkOrder=(e)=>{
    let ids=e.target.value
    if(cancleOrder.includes(ids)){
      setCancleOrder(cancleOrder.filter(item=>item!==ids))
      removeFromReturnOrCancle(ids)
    }else{
      setCancleOrder([...cancleOrder,ids])
    }
    navigate(`/trackOrder/${ids}`)
  }

  const addForReturnOrCancle=(data)=>{
    setSelectedOrderForCancle([...selectedOrderForCancle,data])
  }

  const removeFromReturnOrCancle=(ids)=>{
    setSelectedOrderForCancle(selectedOrderForCancle.filter(item=>item?.cart?._id!==ids))
  }
  const handleReturnOrder=()=>{
    let returnData=[]
    let cartArray=[]
    selectedOrderForCancle.forEach((item)=>{
    returnData.push({
      cart:item.cart,
      track:item.track,
      isReturned:isValidForReturn(item.track?.tracking_data?.shipment_track[0]?.delivered_date,item.track?.tracking_data?.shipment_track[0]?.current_status)
    })})
    // setOrdersForReturn(returnData)
    for (const item of returnData) {
      if (item.isReturned === true) {
          setValidForReturn(true);
          cartArray.push(item.cart._id)
      } else {
          setValidForReturn(false);
          break;
      }
  }
  if(cartArray.length !==0 && userId){
    callApi('post', 'returnOrder/returnCurd/returnOrder', { userId:userId ,cartId:cartArray })
    .then((res) => {
      // console.log("first",res.data)
      if(res?.code===200){
        setDataWithExpiration("returnOrderId",res.data?.returnOrder?._id,5)
        dispatch(fetchUserReturnOrders(userId,res.data?.returnOrder?._id))
        Store.addNotification({
          title: "Return order created Successfully",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
              duration: 5000,
              onScreen: true
          }
      });
      navigate("/return")
      }
    }).catch((err) => {
      console.log(err)
    })
  } 
  }
  const handleCancleOrder=()=>{
    let cancleCartArray=[]
    selectedOrderForCancle.forEach((item)=>{
      if(isValidForCancle(item?.track?.tracking_data?.shipment_track_activities)){
        cancleCartArray.push(item?.cart?._id)
      }
    })
    if(userId && cancleCartArray.length!==0){
      callApi('post', 'returnOrder/returnCurd/cancleOrder', { userId:userId ,cartId:cancleCartArray })
    .then((res) => {
      // console.log("first",res.data)
      if(res?.code===200){
        setDataWithExpiration("cancleOrderId",res.data?.returnOrder?._id,5)
        dispatch(fetchUserReturnOrders(userId,res.data?.returnOrder?._id))
        Store.addNotification({
          title: "Return order created Successfully",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
              duration: 5000,
              onScreen: true
          }
      });
      navigate("/cancle")
      }
    }).catch((err) => {
      console.log(err)
    })
    }
  }
  return (
    <>
    <ReactNotifications />
    <section className="h-100 gradient-custom w-100">
      <PageTitle/>
    <div className="p-2 h-100 w-100">
      <div className="row d-flex">
      <div className="col-lg-6 col-xl-5">
      <div className="card border-top border-bottom border-3" style={{borderColor: "#ff0000"}}>
      <div className="card-body p-3">
      {/* <p className="lead fw-bold mb-3" style={{ color: "#5a3841"}}>All Orders by OrderId</p> */}
      {myOrderById && Object.keys(myOrderById).length !== 0?
        // <div className="card" style={{ borderRadius: 10 }}>
             <div className="card-body p-4">
                <div className="row">
                  <div className="col-md-6 col-6">
                  <p >Order Date: <span className='order-page-order-date'>{new Date(myOrderById?.createdAt).toLocaleString('en-US', {year: 'numeric',month: 'long',day: 'numeric',})}</span> 
                  </p>
                  </div>
                  <div className="col-md-6 col-6 text-right">
                  <p>Order Id:<span className='order-page-order-date'>{myOrderById?.orderId}</span></p>
                  </div>
                </div>
                  { myOrderById?.cart.map((car,indx)=><React.Fragment key={indx}>
                  <div className="card shadow-0 border mb-4">
                    <div className="card-body">
                      <input
                        type="checkbox"
                        id={car?._id}
                        value={car?._id}
                        name="drone"
                        checked={cancleOrder.includes(car?._id)}
                        onChange={(e)=>checkOrder(e)}
                        />
                      <Link to={`/productdetails/rr/${car?.product?._id}`}>
                        <div className="row">
                          <div className="col-md-3">
                            {car.productThumbnailImages?.[0].length!==0 ? (
                              <img
                                src={car?.product?.productThumbnailImages?.[0]?.imgurl}
                                className="img-fluid"
                                alt="Image"
                              />
                            ) : (
                              <img
                                src="https://baconmockup.com/250/250/"  // Replace this with the actual URL of your default avatar image
                                className="img-fluid"
                                alt="Default Avatar"
                              />
                            )}
                          </div>
                         <div className="col-md-8 col-12">
                            <div className='row'>
                            <p className="text-muted ml-3"><span className='cancle-order small'>{car?.product?.productName}</span></p>
                            </div>  
                            <div className='row'>
                            <p className="text-muted ml-3 small">Delivar by <span className='order-page-order-date'>{car?.estimatedDeliveryDate}</span></p>
                            </div>
                            <div className='row'>
                            <p className="text-muted ml-3 small">Price <span className='order-page-order-date'> Rs.{car?.product?.grossPrice}</span></p>
                            </div>
                          </div>
                          {/* <div className="col-md-3 col-12 text-center d-flex justify-content-center align-items-center">
                            <p className="text-muted mb-0 small">Delivar by <br></br><span className='order-page-order-date'>{car?.estimatedDeliveryDate}</span></p>
                          </div>
                          <div className="col-md-3 col-12 text-center d-flex justify-content-center align-items-center">
                            <p className="text-muted mb-0 small">Price <br></br><span className='order-page-order-date'> Rs.{car?.product?.grossPrice}</span></p>
                          </div> */}
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-6">
                       <p className='order-page-order-date' style={{ fontSize: "14px" }}>Shipping charge</p>
                    </div>
                    <div className="col-md-6 col-6 text-right">
                       <span className='tax-text'><i className='fa fa-inr'></i>{Math.round(car?.deliveryCharges)}</span>   
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-6">
                         <p className='order-page-order-date' style={{ fontSize: "14px" }}>Total</p> 
                    </div>
                    <div className="col-md-6 col-6 text-right">
                    <span className='rupees' style={{ fontSize: "14px" }}><i className='fa fa-inr'></i>{Math.round(car?.quantity*(car?.deliveryCharges+car?.product?.grossPrice))}</span>    
                    </div>
                  </div>
                  <div className="text-center">
                <Link to={`/trackOrder/${car?._id}`} className="btn btn-primary btn-framed btn-rounded width-8 mb-3">Track Order</Link>
              </div>
              </React.Fragment>)}
                  <div className="row">
                    <div className="col-md-6 col-6">
                       <p className='order-page-order-date'>Total Shipping charge</p>
                    </div>
                    <div className="col-md-6 col-6 text-right">
                       <span className='tax-text'><i className='fa fa-inr'></i>{Math.round(myOrderById?.deliveryCharge)}</span>   
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-6">
                         <p className='order-page-order-date'>Total amount paid</p> 
                    </div>
                    <div className="col-md-6 col-6 text-right">
                    <span className='rupees' style={{ fontSize: "14px" }}><i className='fa fa-inr'></i>{myOrderById?.totalAmount}</span>    
                    </div>
                  </div>
              {/* </div> */}
              <br />
            </div>
            :
            <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-header px-4 py-5">
              <h5 className="text-muted mb-0">No Orders, <span style={{ colour: "#a8729a" }}>Anna</span>!</h5>
            </div>
            </div>
            }
      </div>
      </div>
      </div>
        <div className="col-lg-6 col-xl-3">
          <div className="card border-top border-bottom border-3" style={{borderColor: "#ff0000"}}>
            <div className="card-body p-3">
  
              <p className="lead fw-bold mb-5" style={{ color: "#5a3841"}}>Track</p>
  
              <div className="row justify-content-center align-items-center mb-4">
                
                {trackData?.cart?.product?.productThumbnailImages?.[0].length!==0 ? (
                              <img
                                src={trackData?.cart?.product?.productThumbnailImages?.[0]?.imgurl}
                                className="img-fluid justify-content-center"
                                alt="Phone"
                                style={{ height: '200px' }} 
                              />
                            ) : (
                              <img
                                src="https://baconmockup.com/250/250/"  // Replace this with the actual URL of your default avatar image
                                className="img-fluid"
                                alt="Default Avatar"
                                style={{ height: '200px' }} 
                              />
                            )}
                  {/* <p class="small text-muted mb-1">Date</p>
                  <p>DD/MM/YYYY</p> */}
            
                {/* <div class="col-6 mb-3">
                  <p class="small text-muted mb-1">Order No.</p>
                  <p>012j1gvs356c</p>
                </div> */}
              </div>
  
              <div className="mx-n5 px-5 py-4" style={{backgroundColor: "#f2f2f2"}}>
                <div className="row">
                  <div className="col-md-8 col-9">
                    <p>{trackData?.cart?.product?.productName}</p>
                  </div>
                  <div className="col-md-4 col-3">
                    <p >Rs <span>{trackData?.cart?.product?.grossPrice}</span></p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 col-9">
                    <p className="mb-0">Shipping</p>
                  </div>
                  <div className="col-md-4 col-3">
                    <p className="mb-0">Rs <span>{Math.round(trackData?.cart?.deliveryCharges)}</span></p>
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                <div className="col-md-8 col-9">
                    <p className="mb-0">Total</p>
                  </div>
                <div className="col-md-4 col-3">
                  <p className="mb-0" style= {{color: "#72a89b"}}>Rs. <span>{Math.round(trackData?.cart?.deliveryCharges+trackData?.cart?.product?.grossPrice)}</span></p>
                </div>
              </div>
              </div> 
              {trackDetails && trackDetails.length!==0? <div className=' mt-4 scroll-track-order'>
              <MyOrderTrackPage orderData={trackDetails}/>
              </div>
             :null} 
              <p className="mt-4 pt-2 mb-0">Want any help? <Link to="#" style={{color: "#72a89b"}}>Please contact
                  us</Link></p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-4">
          <div className="card border-top border-bottom border-3" style={{borderColor: "#ff0000"}}>
            <div className="card-body p-3">
              <p className="lead fw-bold mb-3" style={{ color: "#5a3841"}}>{selectedOrderForCancle.length!==0?"Selected products for return/cancle":"No Item to be return/cancle"}</p>
            </div>
            {selectedOrderForCancle.length!==0&& selectedOrderForCancle.map((prod,index)=>
                  <div key={index} className="card shadow-0 border mb-4 m-3">
                    <div className="card-body">
                    <div className="row">
                          <div className="col-md-3">
                            {prod?.cart?.product?.productThumbnailImages?.[0].length!==0 ? (
                              <img
                                src={prod?.cart?.product?.productThumbnailImages?.[0]?.imgurl}
                                className="img-fluid"
                                alt="Image"
                              />
                            ) : (
                              <img
                                src="https://baconmockup.com/250/250/"  // Replace this with the actual URL of your default avatar image
                                className="img-fluid"
                                alt="Default Avatar"
                              />
                            )}
                          </div>
                          <div className='col-md-8'>
                            <div className='row'>
                            <p className="text-muted ml-3"><span className='cancle-order small'>{prod?.cart?.product?.productName}</span></p>
                            </div>
                            <div className='row'>
                            {prod?.track?.tracking_data?.shipment_track[0].current_status==="Delivered"?
                            <p className="text-muted ml-3 small">{prod?.track?.tracking_data?.shipment_track[0].current_status} : <span className='cancle-order'>{new Date(prod?.track?.tracking_data?.shipment_track[0].delivered_date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).replace(',', '')}</span>
                            </p>:<p className="text-muted ml-3 small">Delivar by : <span className='cancle-order'>{prod?.cart?.estimatedDeliveryDate}</span>
                            </p>}
                            </div>
                            <div className='row'>
                              
                            </div>
                          </div>
                          </div>
                    </div>
                  </div>
                )}
                {/* </div> */}
                <div>
              {validForReturn && returnProductDate!==""?<p className="m-3 pt-2 mb-0">Return by <span className='order-page-order-date'>{returnProductDate}</span></p>:null}
              <div className="text-center mt-2">
                {shipmentStatus !== "Delivered" && validForCancle ? 
                 <button
                 type="button"
                 style={{
                   cursor: 'pointer',
                 }}
                 onClick={() => {
                   console.log("first")
                   handleCancleOrder()
                 }}
                 className="btn btn-primary mt-4 mb-4 m-2 width-90"
               >
                 Cancle
               </button>
              :validForReturn? <button
              type="button"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                handleReturnOrder()
              }}
              className="btn btn-primary mt-4 mb-4 m-2 width-90"
            >
              Return
            </button>:null}
              </div>
              </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      <Footer/>
    </div>
    
  </section>
  </>
  )
}
