import React, { useEffect, useState } from 'react'
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import NewAdsCard from '../components/Cards/NewAdsCard.js';
import { Button,DropdownItem ,DropdownMenu,DropdownToggle,ButtonDropdown} from 'reactstrap';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Link,useParams } from 'react-router-dom';
import { paramCase } from "change-case";
import PageTitle from '../components/PageTitle.js';
import Footer from '../layouts/Footer.js';
import jp from 'jsonpath';
import { callApi } from '../utils/Api.js';
import WishListButton from '../components/ProductDetails/WishListButton.js';
import axios from 'axios';
import { apiUrl } from '../constants/defaultValue.js';
import AddToCartButton from '../components/ProductDetails/AddToCartButton.js';
export default function ProductSearch() {
  const { query } = useParams();
  const [searchword, setSearchword] = useState(query);
  const [product, setProduct] = useState([]);
  const [show, setShow] = useState(null);
  const [filterVendorData, setFilterVendorData] = useState([]);
  const [filterPriceData, setFilterPriceData] = useState([]);
  const [filterOption, setFilterOption] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isopen, setIsopen] = useState(false);
  const pull_data=(data)=>{
    setSearchword(data)
  }
//   console.log(searchword);
  useEffect(() => {
    getProducts();
  }, [searchword,query])
//   console.log("first",query||searchword)
  const getProducts=()=>{
      axios.post(`${apiUrl}/product/getProduct/productFind`, { productName:query||searchword,limit:20,page:1 }).then((res) => {
            // console.log(res.data)
            setProduct(res.data.data)
        }
        ).catch((err) => {
            console.log(err)
        }
        )
  }
  useEffect(() => {
      callApi('post', 'filter/filterCrud/getFilterSection').then(response => {
          if (response.data) {
              let vendorData = response.data.filter((item) => item.filterType === 'Vendor');
              let priceData = response.data.filter((item) => item.filterType === 'Price');
              setFilterVendorData(vendorData);
              setFilterPriceData(priceData);
          }
      }
      ).catch(err =>
          console.log(err)
      );
      
      callApi('post', 'category/curd/getAllKeys').then(response => {
        //   console.log(response.data);
          let Category = Object.keys(response.data);

          setCategories(Category);
      });
  }, []);
  useEffect(() => {
      if (selectedCategory) {
          let data = product.filter((item) => item.productCategory[0]?.value === selectedCategory);
          setFilteredProduct((e) => [...data]);
      }
  }, [selectedCategory]);
  const allProduct=()=>{
      setFilteredProduct(product);
  }
  const maxFilter = () => {
      let data = product.sort((a, b) => b.grossPrice - a.grossPrice);
      console.log(data);
      setFilteredProduct((e) => [...data]);
  }
  const minFilter = () => {
      let data = product.sort((a, b) => a.grossPrice - b.grossPrice);
      console.log(data);
      setFilteredProduct((e) => [...data]);
  }
  const oldestFilter = () => {
      let data = product.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
      console.log(data);
      setFilteredProduct((e) => [...data]);
  }
  const newestFilter = () => {
      let data = product.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
      console.log(data);
      setFilteredProduct((e) => [...data]);
  }
  const isFeaturedFilter = () => {
      let data = product.filter((item) => item.isFeatured === 'yes');
      console.log(data);
      setFilteredProduct((e) => [...data]);
  }
  const highratingFilter = () => {
      let data = product.sort((a, b) => b.reviewsSummery.rating - a.reviewsSummery.rating);
      console.log(data);
      setFilteredProduct((e) => [...data]);
  }
  const maxDiscountFilter = () => {
      let data = product.sort((a, b) => b.offerSummary - a.offerSummary);
      console.log(data);
      setFilteredProduct((e) => [...data]);
  }
  
  useEffect(() => {
      if (filterOption.length > 0) {
          const checkFilter = (item) => {
              
              let check = false;
              for (let index = 0; index < filterOption.length; index++) {
                  switch (filterOption[index].filterType) {
                      case 'Vendor':
                          var flo = filterOption[index];
                          var filedAccessor = flo.filterField;
                          var filterValue = flo.filterValue;
                          var itemValue = jp.query(item, `$..${filedAccessor}`);
                          if (itemValue[0] === filterValue) {
                              check = true;
                          }
                          break;
                      case 'Price':
                          var flo = filterOption[index];
                          var filedAccessor = flo.filterField;
                          var filterValue = flo.filterValue;
                          console.log(filterValue[0], filterValue[1]);
                          var itemValue = jp.query(item, `$..${filedAccessor}`);
                          if (Number(itemValue[0]) >= Number(filterValue[0]) && Number(itemValue[0]) <= Number(filterValue[1])) {
                              check = true;
                          }
                          console.log(itemValue, 'itemValue');
                          break;
                      default:
                          break;
                  }
              }
              return check;
          }
          let data = product.filter((item) => checkFilter(item));
          setFilteredProduct((e) => [...data]);
      } else {
          setFilteredProduct((e) => ([...product]));
      }
  }, [filterOption, product]);
  const zoomOutProperties = {
      duration: 2000,
      transitionDuration: 600,
      infinite: true,
      indicators: false,
      arrows: false,
      autoplay: true,
  };
  const showDetails = (e) => {
      setShow(e);
  }
  const removeDetails = (e) => {
      setShow(null);
  }
  const handelFilter = (filterOptions, e) => {
      if (e) {
          setFilterOption((e) => ([...e, filterOptions]));
      } else {
          setFilterOption((e) => e.filter((item) => item.filterValue !== filterOptions.filterValue));
      }
  }
  const toggle=()=> {
      setIsopen(!isopen);
  }
  return (
    <>
        <PageTitle word={searchword} pulldata={pull_data}/>
        <section className="content" >
                <section className="block" >
                    <div className="container-fluid">

                        <div className="row">
                            {/* <div className="col-md-2 col-12">
                                <div className="section-title clearfix">
                                    <div className="float-xl-left float-md-left float-sm-none">
                                        <h2 style={{ marginBottom: '-10px' }}>FILTERS</h2>
                                    </div>
                                </div>
                                <hr />
                                <section className='filter-box' >
                                    <h2 style={{ fontSize: '15px', marginBottom: '8px' }}>VENDORS</h2>
                                    {filterVendorData.map((item, index) => {
                                        return (
                                            <div className="form-check" key={index}>
                                                <input className="form-check-input" onChange={(e) => handelFilter(item, e.target.checked)} type="checkbox" value="true" id="cb1" />
                                                <label className="form-check-label ml-2 filter-text" htmlFor="flexCheckDefault" >
                                                    {item.label}
                                                </label>
                                            </div>
                                        )
                                    })}


                                </section>
                                <hr />
                                <section className='filter-box' >

                                    <h2 style={{ fontSize: '15px', marginBottom: '8px' }}>PRICE</h2>
                                    {filterPriceData.map((item, index) => {
                                        return (
                                            <div className="form-check" key={index}>
                                                <input className="form-check-input" onChange={(e) => handelFilter(item, e.target.checked)} type="checkbox" value="true" id="cb1" />
                                                <label className="form-check-label ml-2 filter-text" htmlFor="flexCheckDefault" >
                                                    {item.label}
                                                </label>
                                            </div>
                                        )
                                    })}


                                </section>
                            </div> */}
                            <div className="col-md-12 col-12">
                                <div className="section-title clearfix">
                                    {/* <div className="float-xl-left float-md-left float-sm-none">
                                        <h2>Products</h2>
                                    </div> */}
                                    <div className="float-xl-right float-md-right float-sm-none">
                                        {/* <select
                                            name="categories"
                                            id="categories"
                                            className="small width-200px mr-2"
                                            data-placeholder="Category"
                                            onChange={e => setSelectedCategory(e.target.value)}
                                            value={selectedCategory}
                                        >
                                            <option>Choose Category</option>
                                            {categories.map((category) => {
                                                return (
                                                    <option key={category.id} value={category}>{category}</option>
                                                )
                                            })}

                                        </select> */}

                                        {/* <ButtonDropdown  isOpen={isopen} className='sorting-option' toggle={toggle}>
                                            <DropdownToggle style={{backgroundColor: "rgba(256, 256, 256, 1)",padding:'8px'}} className="small text-dark sorting-option width-200px" caret size="lg">
                                                Default Sorting
                                            </DropdownToggle>
                                            <DropdownMenu className='width-200px '>
                                            <DropdownItem className='width-200px ' onClick={allProduct}>All</DropdownItem>
                                                <DropdownItem className='width-200px ' onClick={isFeaturedFilter}>Recommended</DropdownItem>
                                                <DropdownItem className='width-200px ' onClick={newestFilter}>What's New</DropdownItem>
                                                <DropdownItem className='width-200px ' onClick={maxFilter}>Sort by Max Price</DropdownItem>
                                                <DropdownItem className='width-200px ' onClick={minFilter}>Sort Min Price</DropdownItem>
                                                <DropdownItem className='width-200px ' onClick={maxDiscountFilter}>Sort by Discount</DropdownItem>
                                                <DropdownItem className='width-200px ' onClick={highratingFilter}>Sort by Ratings</DropdownItem>
                                                <DropdownItem className='width-200px ' onClick={oldestFilter}>Sort by oldest</DropdownItem>
                                            </DropdownMenu>
                                        </ButtonDropdown> */}

                                    </div>
                                </div>
                                <ResponsiveMasonry
                                    columnsCountBreakPoints={{ 250: 1, 350: 2, 750: 3, 900: 3,1000:4, 1200:5 }}
                                    gutter={0}
                                >
                                    <Masonry>
                                        {filteredProduct?.map((item, key) => {

                                            switch (item.listingType) {
                                                case "product":
                                                    return (
                                                        <Link to={`/productdetails/${paramCase(item.productName)}/${item._id}`} key={key}>
                                                            <div  
                                                            // onMouseEnter={() => showDetails(item._id)} 
                                                            // onMouseLeave={() => removeDetails()} 
                                                            className="card masonry-card" >

                                                                {item.isFeatured === 'yes' ?
                                                                    <div className="ribbon-featured">
                                                                        <div className='ribbon-start'></div>
                                                                        <div className='ribbon-content'>Featured</div>
                                                                        <div className='ribbon-end'>
                                                                            <figure className='ribbon-shadow'></figure>
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                                <div className="wrapper-img">
                                                                    <div className="img">

                                                                        <div className="slide-container">
                                                                            {show === item._id ? <>
                                                                                <Fade  {...zoomOutProperties}>
                                                                                    {
                                                                                        item.productThumbnailImages.map((each, index) => {
                                                                                            return (
                                                                                                <div key={index} className="each-fade">
                                                                                                    <img  style={{ width: "100%",height:'200px' }} src={each.imgurl} />
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Fade>
                                                                            </>

                                                                                : <img style={{ width: "100%",height:'200px' }} src={item.productThumbnailImages[0].imgurl} />
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="col-md-6 col-6 mt-4">
                                                                            <div className="m-price">{item.reviewsSummery.rating}&nbsp;<i className='fa fa-star clr-star'></i>
                                                                                {/* &nbsp;{item.reviewsSummery.totalReviews}  */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 col-3 ">
                                                                            <WishListButton item={item._id} user={localStorage.getItem('userid')} getProducts={getProducts}/>
                                                                            

                                                                        </div>
                                                                        <div className="col-md-3 col-3 ">
                                                                            {/* <Button className="float-right n-price" color='link' >
                                                                                <i className='fa fa-cart-plus'></i>

                                                                            </Button> */}
                                                                            <AddToCartButton pid={item._id} />
                                                                        </div>

                                                                    </div>



                                                                    <div className="m-meta">
                                                                        <a className="title m-indtitle text-capitalize">
                                                                            {item.productName}
                                                                        </a>
                                                                        <a href="/" className=" m-category macategory">
                                                                            {item.productCategory[0]?.value}

                                                                        </a>
                                                                        <div>

                                                                        <div className="row" style={{ whiteSpace: "nowrap" }}>
                                                                            <div className="col-md-6 col-6">
                                                                                <span className='rupees'>Rs.{item.grossPrice}&nbsp;</span>
                                                                                <span className='cross-mark'>Rs. {item.offerSummary} </span>
                                                                                <span className='offersummary'>({Math.round(((item.offerSummary-item.grossPrice)/item.offerSummary)*100)}% OFF)</span>
                                                                            </div>
                                                                            <div className="col-md-6 col-6 ">
                                                                             </div>
                                                                         </div>


                                                                        </div>
                                                                    </div>


                                                                    {show === item._id ?
                                                                        <div className='show-details'>
                                                                            <div className="description">
                                                                                {item.productDescription}
                                                                            </div>
                                                                            <div className="m-additional-info">

                                                                                <ul>
                                                                                    {item.productFeatures.map((i,index) => {

                                                                                        return <li key={index}>
                                                                                            <figure style={{ fontSize: "1.1rem", fontWeight: '600', color: '#000' }} >{i.key}</figure>
                                                                                            <aside style={{ fontSize: "1.1rem", fontWeight: '100' }} >{i.value}</aside>
                                                                                        </li>
                                                                                    })}


                                                                                </ul>

                                                                            </div>

                                                                        </div>
                                                                        : null}

                                                                </div>

                                                            </div>
                                                        </Link>
                                                    )
                                                case "ads":
                                                    return <NewAdsCard />
                                            }


                                        })}
                                    </Masonry>
                                </ResponsiveMasonry>

                                        
                                {/* <div className="center">
                                    <a href="/" className="btn btn-primary btn-framed btn-rounded">
                                        Load More
                                    </a>
                                </div> */}
                            </div>
                        </div>


                    </div>
                </section>
            </section>



        <Footer/>
    </>
  )
}
