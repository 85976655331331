import React, { useState } from 'react';
import PageTitle from '../components/PageTitle';
import Footer from '../layouts/Footer';
import axios from 'axios';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { apiUrl } from '../constants/defaultValue';
import { useNavigate } from 'react-router-dom';

export default function ForgotPassword() {
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [token, setToken] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [resendOtp, setResendOtp] = useState(true);
    const [activePhone,setActivePhone]=useState(true);
    const [activeEmail,setActiveEmail]=useState(false);

    const validatePhone = () => {
        const reg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (!phone || reg.test(phone) === false) {
            setError("Phone Field is Invalid");
            return false;
        }
        setError('');
        return true;
    }

    const validateEmail = () => {
        // Basic email validation
        const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || reg.test(email) === false) {
            setError("Email Field is Invalid");
            return false;
        }
        setError('');
        return true;
    }

    const handlePhoneChange = (e) => {
        e.preventDefault();
        setPhone(e.target.value);
    }

    const handleEmailChange = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
    }

    const handleOtpChange = (e) => {
        e.preventDefault();
        setOtp(e.target.value);
    }

    const handleSubmitPhone = (e) => {
        setResendOtp(true)
        e.preventDefault();
        // if (validatePhone()) {
            if (validatePhone() || validateEmail()) {
            axios.post(`${apiUrl}/users/generateOtpReset`, {
                phoneNumber: phone,
                // email: email, // Include email in the request
                api_key: "forgotPassword"
            }).then(function (response) {
                // console.log(response.data.message);
                // console.log(response.data);
                if (response.data.code === 200) {
                    setOtpSent(true);
                
                Store.addNotification({
                    title: "OTP Sent",
                    message: "A 6-digit OTP has been sent to your phone.",
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }else{
                Store.addNotification({
                    title: response.data.message,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
                setTimeout(() => setResendOtp(false), 3*60000); // Enable resend OTP after 1 minute
            }).catch(function (error) {
                Store.addNotification({
                    title: error.response.data.message,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                setError("Failed to send OTP. Please try again.");
            });
        }
    }

    const handleSubmitOtp = (e) => {
        e.preventDefault();
        axios.post(`${apiUrl}/users/validateOtp`, {
            phoneNumber: phone,
            otp: otp,
            api_key: "ForgotPassword"
        }).then(function (response) {
            // console.log(response);
            if (response.data.code === 200) {
                setToken(response?.data?.data?.token)
                setTimeout(() => {
                    navigate('/reset-password', { state: { token: response?.data?.data?.token } });
                }, 1000);
            
            Store.addNotification({
                title: response.data.message,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }else{
            Store.addNotification({
                title: response.data.message,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }
        }).catch(function (error) {
            // console.log(error);
            Store.addNotification({
                title: error.response.data.message,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            setError("Invalid OTP. Please try again.");
        });
    }

    let navigate = useNavigate();
    const togglePhone=()=>{
        setActivePhone(true)
        setActiveEmail(false)
        setEmail('')
    }
    const toggleEmail=()=>{
        setActivePhone(false)
        setActiveEmail(true)
        setPhone('')
    }
    return (
        <>
            <ReactNotifications />
            <PageTitle title="Forgot Password" />
            <section className="content">
                <section className="block">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <form className="form clearfix">
                                    <div className="form-group" onClick={togglePhone}>
                                        <label htmlFor="phone" className="col-form-label required">Phone Number</label>
                                        <input name="phone" maxLength={10} disabled={!activePhone} value={phone} onChange={handlePhoneChange} type="tel" className="form-control" placeholder="Your Phone Number" required />
                                        <span className="text-danger">{error}</span>
                                    </div>
                                    {/* <div className="logInFooter pt-4">
                                        <hr></hr>
                                     <p className="registerOr"><span>or</span></p>
                                    </div>                   
                                    <div className="form-group" onClick={toggleEmail}>
                                        <label htmlFor="email" className="col-form-label required">Email</label>
                                        <input name="email" value={email}  disabled={!activeEmail} onChange={handleEmailChange} type="email" className="form-control" placeholder="Your Email" required />
                                        <span className="text-danger">{error}</span>
                                    </div> */}  
                                    {otpSent && (
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="otp" className="col-form-label required">Enter OTP</label>
                                                <input name="otp" maxLength={6} value={otp} onChange={handleOtpChange} type="text" className="form-control" placeholder="6-digit OTP" required />
                                            </div>
                                            <div className="d-flex justify-content-between align-items-baseline">
                                                <button type="button" onClick={handleSubmitPhone} disabled={resendOtp} className="btn btn-secondary">Resend OTP</button>
                                                <button type="submit" onClick={handleSubmitOtp} className="btn btn-primary">Verify OTP</button>
                                            </div>
                                        </>
                                    )}
                                    {!otpSent && (
                                        <div className="d-flex justify-content-between align-items-baseline">
                                            <button type="submit" onClick={handleSubmitPhone} className="btn btn-primary">Send OTP</button>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </>
    );
}
