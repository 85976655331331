import React from 'react'
import { Link } from 'react-router-dom';
import { Card,CardBody,CardTitle,CardSubtitle, } from 'reactstrap';
import {
    camelCase,
    capitalCase,
    constantCase,
    dotCase,
    headerCase,
    noCase,
    paramCase,
    pascalCase,
    pathCase,
    sentenceCase,
    snakeCase,
  } from "change-case";
const CarosalCards=({item})=> {

  return (
    <Link to={`/productdetails/${paramCase(item.productName)}/${item._id}`}>
    <Card className='user-visited-product-card mt-1 mb-3'>
    
  <img
    alt="Sample"
    src={item.productThumbnailImages[0]?.imgurl}
    style={{
        height:"150px"
    }}
  />
  <CardBody className='user-visited-card-body'>
    <CardTitle tag="h4" className='m-1 text-center font-weight-bold'>
    <i className='fa fa-inr'></i>{" "} {item?.grossPrice}
    </CardTitle>
    <CardSubtitle tag="h5" className='m-1 text-center font-weight-bold'>
    {Math.round(((item.offerSummary-item.grossPrice)/item.offerSummary)*100)}% OFF
    </CardSubtitle>
    {/* <CardSubtitle
      className="mb-1 text-center"
      tag="h5"
    >
        Shop Now
    </CardSubtitle> */}
  </CardBody>
  
</Card>
</Link>
  )
};
export default CarosalCards;
