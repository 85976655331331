import { callApi } from "../utils/Api";
import { ADD_TO_USER_VISITED,REMOVE_FROM_USER_VISITED,FETCH_USER_VISITED_REQUEST,FETCH_USER_VISITED_SUCCESS,FETCH_USER_VISITED_FAILURE } from "./actionType";

export const addToUserVisited = (data)=>({
    type: ADD_TO_USER_VISITED,
    payload: data,
});
export const reomveFromUserVisited = (productId)=>({
    type: REMOVE_FROM_USER_VISITED,
    payload: productId
});
export const fetchUserVisitedRequest = ()=>{
    return{
        type: FETCH_USER_VISITED_REQUEST
    }
};
export const fetchUserVisitedSuccess=(data)=>{
    return{
        type: FETCH_USER_VISITED_SUCCESS,
        payload: data,
    }
}
export const fetchUserVisitedFailur=(error)=>{
    return{
        type: FETCH_USER_VISITED_FAILURE,
        payload: error
    }
}


//Fetch User Visited Data
export const fetchUserVisited =(userId)=>{
    return (dispatch)=>{
        dispatch(fetchUserVisitedRequest)
        callApi('post','product/getProduct/getUserVisitedProduct',{userId:userId})
        .then(res=>{
            dispatch(fetchUserVisitedSuccess(res.data))
        }).catch(error=>{
            dispatch(fetchUserVisitedFailur(error))
        })
    }
}