import React from 'react'
import ListingSideBar from '../components/ListingSideBar'
import PageTitle from '../components/PageTitle'
import PrimaryNav from '../components/NavBar/PrimaryNav'
import Footer from '../layouts/Footer'
import Nav from '../components/NavBar/Nav'

export default function ListingListWithSidebar() {
  return (
    <div>
      <header className="hero">
            <div className="hero-wrapper">
                <PrimaryNav/>
                {/* <PageTitle/> */}
                <Nav/>
            </div>
        </header>
        <ListingSideBar/>
        <Footer/>
    </div>
  )
}
