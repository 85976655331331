import React,{useState} from 'react'
import RefundModel from './RefundModel';

export default function RefundDetails({ returnOrderData }) {
    const [refundModal, setRefundModal] = useState(false);
    const toggleRefundModal = () =>setRefundModal(!refundModal);
    const closeRefundModal = (
        <button
          className="close"
          style={{ fontSize: "25px" }}
          onClick={toggleRefundModal}
          type="button"
        >
          &times;
        </button>
      );
    const handleRefund = () => {
        toggleRefundModal()
    }
    return (
        <>
            <RefundModel refundModal={refundModal} toggleRefundModal={toggleRefundModal} closeRefundModal={closeRefundModal} />
            <h4 className="edit-details">Refund Details</h4>
            <hr></hr>
            <div className="row ml-0">
                <div className='col-6'>
                    <h4 className="mt-0 float-left" style={{ fontSize: "13px", }} >
                        Product price
                    </h4>
                </div>
                <div className='col-6'>
                    <h4 className="float-right" style={{ fontWeight: "bold", fontSize: "13px", color: 'green' }} >
                        + <i className="fa fa-inr mr-2"></i> {""}{returnOrderData?.totalProductPrice}
                    </h4>
                </div>
            </div>
            <div className="row ml-0 mt-3">
                <div className='col-6'>
                    <h4 className="mt-0 float-left" style={{ fontSize: "13px" }} >
                        Shipment Price
                    </h4>
                </div>
                <div className='col-6'>
                    <h4 className="float-right" style={{ fontWeight: "bold", fontSize: "13px", color: 'green' }} >
                        + <i className="fa fa-inr mr-2"></i> {""}{returnOrderData?.shipmentCharge}
                    </h4>
                </div>
            </div>
            <div className="row ml-0 mt-3">
                <div className='col-6'>
                    <h4 className="mt-0 float-left" style={{ fontSize: "13px", }} >
                        Total Paid
                    </h4>
                </div>
                <div className='col-6'>
                    <h4 className="float-right" style={{ fontWeight: "bold", fontSize: "13px", }} >
                        <i className="fa fa-inr mr-2"></i> {""}{returnOrderData?.shipmentCharge + returnOrderData?.totalProductPrice}
                    </h4>
                </div>
            </div> 
            <hr></hr>
            <div className="row ml-0 mt-3">
                <div className='col-6'>
                    <h4 className="mt-0 float-left" style={{ fontSize: "13px", }} >
                        Refund Amount
                    </h4>
                </div>
                <div className='col-6'>
                    <h4 className="float-right" style={{ fontWeight: "bold", fontSize: "13px", }} >
                        <i className="fa fa-inr mr-2"></i> {""}{Math.round(returnOrderData?.totalRefundAmount)}
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <button className="btn btn-primary mt-4 width-100" type="submit"
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            handleRefund()
                        }}

                    >
                        Refund
                    </button>
                </div>
            </div>
        </>
    )
}
