import React from 'react'

import PageTitle from '../components/PageTitle'
import Footer from '../layouts/Footer'
import SellerDetailsComp from '../components/SellerDetailsComp'

export default function SellersDetails() {
  
  return (
    <div>
         {/* <PageTitle/> */}
        <SellerDetailsComp/>
        {/* <Footer/> */}
    </div>
  )
}
