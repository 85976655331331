import React, { useEffect, useState } from 'react'
import ReactTypingEffect from 'react-typing-effect';
import { useNavigate } from "react-router-dom";
import { callApi } from '../utils/Api';
import GoogleMapReact from 'google-map-react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { paramCase } from "change-case";
import { Link, useParams } from 'react-router-dom';
import { click } from '@testing-library/user-event/dist/click';
import { data } from 'jquery';
import { Helmet } from 'react-helmet';
import OfferModal from './NavBar/OfferModal';
import Marquee from "react-fast-marquee";
import { useDispatch,useSelector } from "react-redux";
import { fetchUserVisited } from '../actions/userVisitedAction';
import { fetchUserOrders } from '../actions/userOrderAction';
import CarosalCards from './Shared/Cards/CarosalCards';
import { offerModalController } from "../actions/offerModalAction";


export default function HeroComponent() {
    const [vendor, setVendor] = useState([])
    const [product, setProduct] = useState([])
    const [category, setCategory] = useState([])
    const [searchName, setSearchName] = useState('')
    let [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [userDetails, setUserDetails] = useState([])
    const [filteredProduct, setFilteredProduct] = useState([]);
    const userId = localStorage.getItem('userid')
    const offerModalOpen=useSelector(state=>state?.offerModal?.offerModalOpen)
    const isUserViewed=useSelector(state=>state?.offerModal?.isUserViewed)
    // console.log("redux offermodal check",offerModalOpen)
    const [offerModal,setOfferModal]=useState(offerModalOpen);
    const dispatch=useDispatch()
    const userVisitedProduct=useSelector(state=>state.userVisited)
    let allVisitedProduct=userVisitedProduct?.userVisited?.productIds||[]
    // console.log("userVisitedProduct",allVisitedProduct)

    const fetchBanners = async () => {
        try {
            const response = await callApi('post', 'ad/ads/getAllActiveBanners');
            const activeBanners = response?.data.filter(banner => banner.isBannerActive); // Filter all active banners
            if (activeBanners.length > 0) {
                dispatch(offerModalController(true))
            } else {
                dispatch(offerModalController(false))
            }
        } catch (error) {
            console.error('Error fetching banners:', error);
        }
    };
    useEffect(() => {
        if (!isUserViewed) {
            fetchBanners();
        }
    }, []);
    useEffect(() => {
        setOfferModal(offerModalOpen)
    }, [offerModalOpen]);

    const toggleOfferModal=()=>{
        setOfferModal(!offerModal)
        dispatch(offerModalController(false))
    }
    // const location = navigator.geolocation.watchPosition((position) => {
    //     console.log(position.coords.latitude, position.coords.longitude);
    //   });


    // const touch = () => {
    //     <Link to={`/productdetails/${paramCase(item.productName)}/${item._id}`}></Link>
    // }
    // useEffect(()=>{
    //     dispatch(fetchUserVisited(localStorage.getItem('userid')||null))
    // },[])
    const touch = (item) => {
        // console.log("item to be displyed => ", item)
        if (data) {
            navigate(`/productdetails/rr/${item._id}`)
        }
    }


    const searchVendor = (item) => {
        // console.log("vendor to be displyed => ", item)
        if (data) {
            navigate(`/sellerDetails/aj/${item.user._id}`)
        }
    }

    const findCategory = (item) => {
        // console.log("Category to be displyed => ", item)
        if (data) {
            navigate(`/sellerDetails/aj/${item.category}`)
        }
    }

    const userData = ()=>{
        callApi('post', 'merchant/getMerchant/getUserDetailsByUserId', { userId: userId }).then(res => {
            // console.log(res.data);
            if (res.data) {
                setUserDetails(res.data)
                localStorage.setItem('UserData', JSON.stringify(res.data));

            }
        }).catch(err => { console.log(err); })
    }

    const searchValidator = (e) => {
        let serchNamelength=searchName.length
        if (serchNamelength > 0){
            callApi('post', `/product/getProduct/productSearch`, { productName: searchName,limit:10,page:1 }).then((res) => {
                // console.log("***ProductSearch***", res.data)
                setProduct(res.data)
            }
            ).catch((err) => {
                console.log(err)
            }
            )
            callApi('post', `/merchant/getMerchant/merchantSearch`, { merchantCompanyName: searchName }).then((res) => {
                // console.log("********MerChant*********", res.data)
                setVendor(res.data)
            }
            ).catch((err) => {
                console.log(err)
            }
            )
            callApi('post', `/category/curd/searchCategory`, { key: searchName }).then((res) => {
                // console.log(res.data)
                setCategory(res.data)
            }
            ).catch((err) => {
                console.log(err)
            }
            )}
            if(serchNamelength===0) {
                setProduct([])
                setVendor([])
                setCategory([])
        }
    }

    useEffect(() => {
        // searchValidator();
        dispatch(fetchUserVisited(localStorage.getItem('userid')||null))
        dispatch(fetchUserOrders(localStorage.getItem('userid')))
        userData();
    }, [searchName])
    let navigate = useNavigate();
    const navigateHandel = () => {
        navigate(`/productSearch/${searchName}`)
    }

    useEffect(() => {
        if (selectedCategory) {
            let data = product.filter((item) => item.productCategory[0]?.value === selectedCategory);
            setFilteredProduct((e) => [...data]);
        }
    }, [selectedCategory]);
    const onClosedSearch=()=>{
        setProduct([]);
        setVendor([]);
        setCategory([]);
        setSearchName('');
    }

    return (
        <div>
                <Helmet>
        <title>The Zero Commission Online Shopping Site In India</title>
        <meta
          name="description"
          content="Online shopping for moblies, laptops, cameras, clothes, fashion, health, lifestyle, industrial goods, agricultural goods, machines and plants"
        />
      </Helmet>
            <div className="page-title">
                <div className="container">
                    <h1 className=" center">
                        Find  <a style={{ color: "#ff0000" }} href="/">
                            <ReactTypingEffect
                                // text={["Anything", "Anywhere", "Anytime","@Anyprice"]}
                                text={["The Uncommon", "The Unusual","@Unusual prices"]}
                                speed={100} eraseSpeed={50} eraseDelay={1000} typingDelay={1000}
                            />
                        </a>
                    </h1>
                </div>
            </div>

            <form className="hero-form form">
                <div className="container">
                    <div className="main-search-form">
                        <div className="form-row">
                            <div className="col-md-10 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="what" className="col-form-label">What Are You Looking For?</label>
                                    <input name="keyword" type="text" value={searchName} onChange={(e) => setSearchName(e.target.value)} onKeyUp={searchValidator} className="form-control" id="what" placeholder="Find Any Product, Item, Service, Facility…." />
                                </div>

                            </div>
                            <div className="col-md-2 col-sm-12">
                                <button type="submit" className="btn btn-primary width-100" onClick={navigateHandel}>Find</button>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-10 col-sm-12">
                                {(product.length > 0 || vendor.length > 0 || category.length > 0) && searchName.length>0 ? <div className="search-section">
                                    <ul className="justify-content-center  ">
                                    <button className="close"
                                        style={{ fontSize: "30px",textDecoration:"none" }}
                                        onClick={onClosedSearch}
                                         type="button"
                                     >
                                    <i className="fa fa-close fa-1x"></i>
                                    </button>
                                        <div className="row">
                                        <div className="col-md-12 col-sm-18" style={{ padding: '15px' }}>
                                        <li className="nav-item search-section-item">
                                            <a className="nav-link active" href="#" aria-current="page" style={{
                                                fontSize: '20px',
                                                fontWeight: 'normal', 
                                                color: '#666',        
                                                marginBottom: '15px',
                                                display: 'block',
                                            }}>
                                                Search Results
                                            </a>
                                        </li>
                                        {product.length > 0 ? (
                                            <>
                                              {product.map((item, index) => (
                                            <li key={index} className="nav-item search-section-item" onClick={() => touch(item)}>
                                                <div style={{ flex: '0 0 70px', marginRight: '15px' }}>
                                                    <img 
                                                        src={item.productThumbnailImages[0]?.imgurl} 
                                                        alt={item.productName} 
                                                    />
                                                </div>
                                                <div style={{ flex: '1' }}>
                                                    <a className="nav-link product-main-heading" href="">{item.productName}</a>
                                                    <a className="nav-link product-main-sub" href="#">{item.productCategory[0]?.value}</a>
                                                    <a className="nav-link product-main-price" href="#">
                                                        Rs. {item.grossPrice} 
                                                        <span className='cross-mark'>Rs. {item.offerSummary}</span>
                                                    </a>
                                                </div>
                                            </li>
                                        ))}
                                            </>
                                        ) : (
                                            <p style={{
                                                paddingLeft: "20px",
                                                paddingTop: "10px",
                                                fontSize: '16px',
                                                color: '#777'
                                            }}>No Products Found</p>
                                        )}
                                        {product.length >= 1 ? (
                                            <li className="nav-item search-section-item" style={{
                                                textAlign: 'left',
                                                marginTop: '20px',
                                                paddingLeft: '15px',
                                            }}>
                                                <a className="nav-link active" href="#" aria-current="page" style={{
                                                    fontSize: '18px',
                                                    fontWeight: 'bold',
                                                    textDecoration: 'none',
                                                }}>
                                                    See More
                                                </a>
                                            </li>
                                        ) : null}
                                    </div>
                                            {/* <div className="col-md-4 col-sm-4">
                                                <li className="nav-item search-section-item">
                                                    <a className="nav-link active" href="#" aria-current="page">VENDOR</a>
                                                </li>
                                                {vendor.length > 0 ? <> {vendor.map((item, index) => {

                                                    return (
                                                        <React.Fragment key={index}>
                                                            <li className="nav-item search-section-item">
                                                                <div className='row mt-2 ' onClick={() => { console.log("******Search Vendor******"); searchVendor(item) }}>
                                                                    <div className='col-md-2 col-2'>
                                                                        <img src={item.user?.avatar} className="image-vendor" alt="" />
                                                                    </div>
                                                                    <div className='col-md-10 col-10'>
                                                                        <a className="nav-link" href="#" aria-current="page" style={{ fontSize: "12px" }}>{item.user?.firstName} {item.user?.lastName}</a>
                                                                        <a className="nav-link" style={{ fontSize: "10px", marginTop: "-10px", marginBottom: "-3px" }} href="#" aria-current="page">{item.merchantCompanyName}</a>
                                                                        <p className='address-vendor'>{item.merchantCompanyCity},{item.merchantCompanyState},{item.merchantCompanyCountry}</p>
                                                                    </div>
                                                                </div>

                                                            </li>
                                                        </React.Fragment>

                                                    )
                                                })}</> : <p style={{ paddingLeft: "20px", paddingTop: "10px" }}>No vendor found</p>}
                                                {vendor.length >= 1 ? <li className="nav-item search-section-item">
                                                    <a className="nav-link active" href="#" aria-current="page">see more</a>
                                                </li> : null}

                                            </div> */}
                                            {/* <div className="col-md-4 col-sm-4">
                                                <li className="nav-item search-section-item">
                                                    <a className="nav-link active" href="#" aria-current="page">CATEGORY</a>
                                                </li>
                                                {category.length > 0 ? <>{category.slice(0, 10).map((item, index) => {

                                                    return (
                                                        <li key={index} className="nav-item search-section-item-category">

                                                            <p className='category-sec'> {item} </p>
                                                        </li>
                                                    )
                                                })}</> : <p style={{ paddingLeft: "20px", paddingTop: "10px" }}>No Category Found</p>}

                                                {category.length > 10 ? <li className="nav-item search-section-item ">
                                                    <a className="nav-link active" href="#" aria-current="page">see more({category.length - 10})</a>
                                                </li> : null}

                                            </div> */}
                                        </div>
                                    </ul>
                                </div> : null}
                            </div>
                            <div className="col-md-2 col-sm-12"></div>
                        </div>
                    </div>
                    <div className="alternative-search-form">
                        {/* <a href="#collapseAlternativeSearchForm" className="icon" data-toggle="collapse" aria-expanded="false" aria-controls="collapseAlternativeSearchForm"><i className="fa fa-plus"></i>More Options</a> */}
                        <div className="collapse" id="collapseAlternativeSearchForm">
                            <div className="wrapper">
                                <div className="form-row">
                                    <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 d-xs-grid d-flex align-items-center justify-content-between">
                                        <div className="form-row">
                                            <div className="col-md-4 col-sm-4">
                                                <div className="form-group">
                                                    <input
                                                        // defaultValue={this.state.location}
                                                        // onChange={this.handleChange}
                                                        id="pac-input"
                                                        name="min_price"
                                                        type="text"
                                                        className="form-control small"
                                                        placeholder="Location" />
                                                    {/* onClick={() => { location() }} */}
                                                    <span className="input-group-addon small"><i className='fa fa-map-marker-alt'></i></span>
                                                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3740.820266831282!2d85.805547413966!3d20.3490423863705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1908e025984c55%3A0xee1fcd1f11e55141!2sDLF%20CYBER%20CITY!5e0!3m2!1sen!2sin!4v1677834384406!5m2!1sen!2sin"
             width="10%" height="30" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>   */}

                                                </div>

                                            </div>


                                            <div className="col-md-4 col-sm-4">
                                                <div className="form-group">
                                                    <input name="max_price" type="text" className="form-control small" id="max-price3" placeholder=" Category" />
                                                    <span className="input-group-addon small"><i className='fa fa-shapes'></i></span>
                                                </div>

                                            </div>
                                            {/* <div className="col-md-4 col-sm-4">
                                                <div className="form-group">
                                                    <input name="max_price" type="text" className="form-control small" id="max-price" placeholder="Search By Category" />
                                                    <span className="input-group-addon small"><i className='fa fa-shapes'></i></span>
                                                </div>

                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12">
                                        <div className="form-row ">
                                            <div className="col-md-12 col-sm-12">
                                                <label htmlFor="pricing" className='check-prop float-left'>Distance</label>
                                                <span className='check-prop float-right'>23</span>
                                                <div className="form-group">
                                                    <input type="range" className=" form-control  slider small" id="customRange1" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 d-xs-grid d-flex align-items-center justify-content-between">
                                        <div className="form-row">
                                            <div className="col-md-4 col-sm-4">
                                                <div className="form-group">
                                                    <input name="min_price" type="text" className="form-control small" id="min-price" placeholder="Search By Min Price" />
                                                    <span className="input-group-addon small">$</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4">
                                                <div className="form-group">
                                                    <input name="max_price" type="text" className="form-control small" id="max-price1" placeholder="Search By Max Price" />
                                                    <span className="input-group-addon small">$</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4">
                                                <div className="form-group">
                                                    <input name="max_price" type="text" className="form-control small" id="max-price2" placeholder="Search By Max Price" />
                                                    <span className="input-group-addon small">$</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 d-xs-grid d-flex align-items-center  justify-content-between">
                                        <label>
                                            <input type="checkbox" name="new" />
                                            <span className='check-prop'>New</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" name="used" />
                                            <span className='check-prop'>Used</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" name="with_photo" />
                                            <span className='check-prop'>With Photo</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" name="featured" />
                                            <span className='check-prop'>Featured</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="background">
                <div className="background-image">
                    <img src="/assets/img/hero-background-image-02.jpg" alt="" />
                </div>
            </div>
            {allVisitedProduct.length>4&&
                        <div className="container z-3 mt-2 mb-2">
                            <Marquee
                            // autoFill={true}
                            play={true}
                            pauseOnHover={true}
                            pauseOnClick={true}
                            direction="left"
                            speed={50}
                            delay={0}
                            loop={0}
                            gradient={true}
                            gradientColor='white'
                            gradientWidth={100}
                            className='justfy-center'
                             >
                                {allVisitedProduct.map((item,index)=>(
                                <React.Fragment key={index}>
                                    <CarosalCards item={item} />
                                </React.Fragment>))}
                            </Marquee>
                        </div>
            }
            {screenWidth>870 && allVisitedProduct.length<=4&&
            <div className="row mt-2 mb-2 justify-content-center">
                  {allVisitedProduct.map((item,index)=>(
                                <div key={index}>
                                    <CarosalCards item={item} />
                                </div>))}
            </div>
            }
            {screenWidth<870 && allVisitedProduct.length<=4 &&allVisitedProduct.length!==1 &&
               <div className="container z-3 mt-2 mb-2">
               <Marquee
               // autoFill={true}
               play={true}
               pauseOnHover={true}
               pauseOnClick={true}
               direction="left"
               speed={50}
               delay={0}
               loop={0}
               gradient={true}
               gradientColor='white'
               gradientWidth={100}
               className='justfy-center'
                >
                   {allVisitedProduct.map((item,index)=>(
                   <React.Fragment key={index}>
                       <CarosalCards item={item} />
                   </React.Fragment>))}
               </Marquee>
           </div>
            }
              {screenWidth<870 && allVisitedProduct.length===1&&
            <div className="row mt-2 mb-2 justify-content-center">
                  {allVisitedProduct.map((item,index)=>(
                                <div key={index}>
                                    <CarosalCards item={item} />
                                </div>))}
            </div>
            }
            {/* <OfferModal offerModal={offerModal} toggleOfferModal={toggleOfferModal}/> */}
        </div>
    )
}

