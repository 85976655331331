import React from 'react'

export default function CancleOrderItems({ returnOrderData }) {
    const findReturnShipmentPrice = (courier, cart_id) => {
        var foundItem = courier.find(item => item.cartId === cart_id)
        if (foundItem) return foundItem.courier.rate
    }
    const refundAmount = (courier, cart_id, productPrice) => {
        let returnShipment = findReturnShipmentPrice(courier, cart_id)
        return productPrice - returnShipment
    }
    return (<>
        <h4 className="edit-details">Cancel Items</h4>
        <hr></hr>
        {returnOrderData?.cart?.map((item, index) => (
            <div key={index} className="card shadow-sm mb-4 p-2 bg-white rounded">
                <div className="card-body mt-3 ml-3 mb-2">
                    <div className="row mt-2 mb-2">
                        <div className="col-lg-3 col-12 text-center">
                            <img src={item?.product?.productThumbnailImages[0]?.imgurl} className="image-product-main" alt="" />
                        </div>
                        <div className="col-lg-9 col-12 ">
                            <div className="row ml-4">
                                <h4 className="return-item-title">{item?.product?.productName.split(" ").slice(0, 10).join(" ")}</h4>
                            </div>
                            <div className="row ml-4">
                                <h4 className=" mt-0" style={{ fontWeight: "bold", marginTop: "-10px", fontSize: "12px", }}>
                                    Delivered at{" "}
                                    {item?.estimatedDeliveryDate}
                                </h4>
                            </div>
                            <div className="row ml-0 mt-3">
                                <div className='col-6'>
                                    <h4 className="mt-0 float-left" style={{ fontSize: "12px", }} >
                                        Product price
                                    </h4>
                                </div>
                                <div className='col-6'>
                                    <h4 className="float-right" style={{ fontWeight: "bold", fontSize: "12px", color: 'green' }} >
                                        + <i className="fa fa-inr mr-2"></i> {""}{item?.product?.grossPrice*item?.quantity}
                                    </h4>
                                </div>
                            </div>
                            <div className="row ml-0 mt-3">
                                <div className='col-6'>
                                    <h4 className=" mt-0" style={{ fontSize: "12px", }}>
                                        Shipment charge
                                    </h4>
                                </div>
                                <div className='col-6'>
                                    <h4 className="float-right" style={{ fontWeight: "bold", fontSize: "12px", color: 'green' }}>
                                        + <i className="fa fa-inr mr-2"></i> {""} {Math.ceil(item?.deliveryCharges)}
                                    </h4>
                                </div>
                            </div>
                            <div className="row ml-0 mt-3">
                                <div className='col-6'>
                                    <h4 className=" mt-0" style={{ fontSize: "12px", }}>
                                        Total paid
                                    </h4>
                                </div>
                                <div className='col-6'>
                                    <h4 className="float-right" style={{ fontWeight: "bold", fontSize: "12px", }}>
                                        <i className="fa fa-inr mr-2"></i> {""}  {Math.ceil(item?.product?.grossPrice + item?.deliveryCharges)}
                                    </h4>
                                </div>
                            </div>
                           {returnOrderData?.orderReturnCourier.length!==0&&<div className="row ml-0">
                                <div className='col-6'>
                                    <h4 className="mt-0" style={{ fontSize: "12px", }}>
                                        Shipment charge
                                    </h4>
                                </div>
                                <div className='col-6'>
                                    <h4 className="float-right" style={{ fontWeight: "bold", fontSize: "12px", color: 'red' }}>
                                        -<i className="fa fa-inr mr-2"></i> {""}  {Math.ceil(item?.deliveryCharges)}
                                    </h4>
                                </div>
                            </div>}
                           {returnOrderData?.orderReturnCourier.length!==0&&<div className="row ml-0">
                                <div className='col-6'>
                                    <h4 className=" mt-0" style={{ fontSize: "12px", }}>
                                        Return shipment charge
                                    </h4>
                                </div>
                                <div className='col-6'>
                                    <h4 className="float-right" style={{ fontWeight: "bold", fontSize: "12px", color: 'red' }}>
                                        -<i className="fa fa-inr mr-2"></i> {""} {Math.ceil(findReturnShipmentPrice(returnOrderData?.orderReturnCourier, item._id))}
                                    </h4>
                                </div>
                            </div>} 
                            {returnOrderData?.orderReturnCourier.length!==0? <div className="row ml-0">
                                <div className='col-6'>
                                    <h4 className=" mt-0" style={{ fontSize: "12px", }}>
                                        Refund amount
                                    </h4>
                                </div>
                                <div className='col-6'>
                                    <h4 className="float-right" style={{ fontWeight: "bold", fontSize: "12px", }}>
                                        <i className="fa fa-inr mr-2"></i> {""} {Math.round(refundAmount(returnOrderData?.orderReturnCourier, item._id, item?.product?.grossPrice))}
                                    </h4>
                                </div>
                            </div>:
                            <div className="row ml-0 mt-3">
                            <div className='col-6'>
                                <h4 className=" mt-0" style={{ fontSize: "12px", }}>
                                    Refund amount
                                </h4>
                            </div>
                            <div className='col-6'>
                                <h4 className="float-right" style={{ fontWeight: "bold", fontSize: "12px", }}>
                                    <i className="fa fa-inr mr-2"></i> {""} {Math.round((item?.quantity*item?.product?.grossPrice)+Math.round(item.deliveryCharges))}
                                </h4>
                            </div>
                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </>
    )
}
