import React,{useState} from 'react';
import { Modal,ModalHeader,ModalBody } from 'reactstrap';
import { callApi } from '../../utils/Api';
import { Store } from 'react-notifications-component';
import axios from 'axios';

export default function AddNewAddress({addAddressModal, toggleAddAddressModal ,closeAddBtn}) {
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [PinCode, setPinCode] = useState("");
    const [address, setAddress] = useState("");
    const [locality, setLocality] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [addressType, setAddressType] = useState("");
    const [country, setCountry] = useState("");
    const [defalutAddress, setDefalutAddress] = useState(false);
    const addAddresshandel = (e) => {
        e.preventDefault();
        if (!name || !phoneNumber || !PinCode || !address || !locality || !city || !state || !country || !addressType) {
          Store.addNotification({
            title: "All fields are required!",
            message: "Please fill in all the fields.",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
          return;
        }
        const data = {
          Name: name,
          phoneNumber: phoneNumber,
          PinCode: PinCode,
          Address: address,
          Locality: locality,
          City: city,
          State: state,
          Country: country,
          AddressType: addressType,
          isActive: false,
          isDefault: defalutAddress,
          user: localStorage.getItem("userid"),
        };
        callApi("post", "cart/cartcrud/addAddress", data)
          .then((res) => {
            // console.log(res);
            setName("");
            setPhoneNumber("");
            setPinCode("");
            setAddress("");
            setLocality("");
            setCity("");
            setState("");
            setCountry("");
            setAddressType("");
            setDefalutAddress(false);
            toggleAddAddressModal();
            Store.addNotification({
              title: "Address Added Successfully!",
              message: "Your address has been added.",
              type: "success",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            });
          })
          .catch((err) => {
            console.log(err);
            Store.addNotification({
              title: "Something is Wrong!",
              message: "Unable to add address.",
              type: "danger",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            });
          });
      };
      const handlePincodeChange = async (e) => {
        const pincode = e.target.value;
        setPinCode(pincode);
      
        if (pincode.length === 6) { 
          try {
            const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`);
            const data = response.data[0];
      
            if (data.Status === "Success") {
              const { PostOffice } = data;
              if (PostOffice && PostOffice.length > 0) {
                const { State, District, Country } = PostOffice[0];
                setState(State);
                setCity(District);
                setCountry(Country);
              }
            } else {
              console.error("Invalid Pincode");
            }
          } catch (error) {
            console.error("Error fetching pincode details:", error);
          }
        }
      };
    // console.log("first,",addAddressModal, toggleAddAddressModal ,closeAddBtn)
  return (<Modal isOpen={addAddressModal} toggle={toggleAddAddressModal} >
    <ModalHeader toggle={toggleAddAddressModal} close={closeAddBtn}>
      ADD NEW ADDRESS
    </ModalHeader>
    <ModalBody>
      <div className="card shadow-sm p-2 bg-white rounded">
        <div className="card-body mt-3 ml-3 mb-2">
          <form className="form clearfix">
            <h4 className="payment-header-price">CONTACT DETAILS</h4>
            <div className="form-group">
              <input
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Name*"
                required
              />
            </div>
            <div className="form-group">
              <input
                name="phone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                type="tel"
                className="form-control"
                placeholder="Phone Number*"
                required
              />
            </div>
            <h4 className="payment-header-price">ADDRESS</h4>
            <div className="form-group">
              <input
                name="pincode"
                value={PinCode}
                onChange={(e) => {handlePincodeChange(e)}}
                type="text"
                className="form-control"
                placeholder="Your Pincode*"
                required
              />
            </div>
            <div className="form-group">
              <input
                name="address"
                value={address}
                type="text"
                onChange={(e) => setAddress(e.target.value)}
                className="form-control"
                placeholder="Your Address*"
                required
              />
            </div>
            <div className="form-group">
              <input
                name="locality"
                value={locality}
                onChange={(e) => setLocality(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Your Locality/Town*"
                required
              />
            </div>
            <div className="row">
              <div className="col-md-6 col-6">
                <div className="form-group">
                  <input
                    name="city"
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="form-control"
                    placeholder="Your City*"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 col-6">
                <div className="form-group">
                  <input
                    name="state"
                    type="text"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    className="form-control"
                    placeholder="Your State*"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
                          <input
                            name="country"
                            type="text"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            className="form-control"
                            placeholder="Your Country*" 
                            required
                          />
                        </div>
            <h4 className="payment-header-price">SAVE ADDRESS AS</h4>
            <div className="row">
              <div className="col-md-3 col-6">
                <div className="form-check">
                  <input
                    className="form-check-input mr-3"
                    onChange={(e) => setAddressType(e.target.value)}
                    checked={addressType === "Home"}
                    value="Home"
                    type="radio"
                    name="addressType" 
                    id="flexRadioDefault1"
                    style={{ display: 'none' }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                    style={{
                      borderColor: addressType === "Home" ? "red" : "grey",
                      color: addressType === "Home" ? "red" : "grey",
                      backgroundColor: "white",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      borderWidth: "2px",
                      borderStyle: "solid",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                    onClick={() => setAddressType("Home")}
                  >
                    Home
                  </label>
                </div>
              </div>
              <div className="col-md-3 col-6">
                {" "}
                <div className="form-check">
                  <input
                    className="form-check-input mr-3"
                    value="Work"
                    onChange={(e) => setAddressType(e.target.value)}
                    checked={addressType === "Work"}
                    type="radio"
                    name="addressType" 
                    id="flexRadioDefault2"
                    style={{ display: 'none' }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                    style={{
                      borderColor: addressType === "Work" ? "red" : "grey",
                      color: addressType === "Work" ? "red" : "grey",
                      backgroundColor: "white",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      borderWidth: "2px",
                      borderStyle: "solid",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                    onClick={() => setAddressType("Work")}
                  >
                    Work
                  </label>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>

            <br />
            <label>
              <input
                type="checkbox"
                onChange={(e) => setDefalutAddress(!defalutAddress)}
                className="mr-3"
                name="remember"
                value="1"
              />
              Make This my Default Address
            </label>
            <button
              type="submit"
              className="btn mt-4 btn-primary width-100"
              onClick={(e) => addAddresshandel(e)}
            >
              Add address
            </button>
          </form>
        </div>
      </div>
    </ModalBody>
  </Modal>
  )
}
