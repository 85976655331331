// import S3 from 'react-aws-s3';
import AWS from 'aws-sdk'



// async function Upload(filepath) {
//     let blob=await fetch(filepath).then(r=>r.blob()).catch(e=>console.log(e))
//     let file_name=Math.random().toString(36).substring(6)+"_uploads.png";
//     var file_object=new File([blob],file_name,{type:"application/png"})
//     const config = { bucketName: 'etaiiler-image-upload', region: 'ap-south-1', accessKeyId: 'AKIATPAILJSQ3KSDD3P4', secretAccessKey: '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' };
//     const ReactS3Client = new S3(config);
//     const a = await ReactS3Client.uploadFile(file_object)
//     return a.location
// }
async function Upload(filepath) {
    const blob = await fetch(filepath).then((r) => r.blob());
    const file_name = Math.random().toString(36).substring(6) + '_uploads.png';
    const file_object = new File([blob], file_name, { type: 'application/png' });
  
  const config = {
    endpoint: new AWS.Endpoint('blr1.digitaloceanspaces.com'), // e.g., 'nyc3.digitaloceanspaces.com'
    accessKeyId: 'DO002LEBDZP7CX647E47',
    secretAccessKey: '+7A2cDpxBhzPAo9OY+TCvLNLeVFzQInTY/dKZzBd+tc',
    signatureVersion: 'v4',
  };

  const s3 = new AWS.S3(config);

  try {
    const params = {
      Bucket: 'esevabemc',
      Key: file_name,
      Body: file_object,
      ContentType: 'application/png',
      ACL: 'public-read',
    };

    await s3.upload(params).promise();

    const fileUrl = `https://${config.endpoint.host}/${params.Bucket}/${params.Key}`;
    return fileUrl;
  } catch (error) {
    console.error('Error uploading the file:', error);
    return null;
  }
}

export default Upload