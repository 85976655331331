import { ADD_TO_USER_CANCLE_ORDER,REMOVE_FROM_USER_CANCLE_ORDER,FETCH_USER_CANCLE_ORDER_REQUEST,FETCH_USER_CANCLE_ORDER_SUCCESS,FETCH_USER_CANCLE_ORDER_FAILURE } from "../actions/actionType";
const initialState={
    loading:true,
    userCancleOrder:{},
    error:""
};
const userCancleOrderReducer=(state=initialState, action)=>{
    switch(action.type) {
        case ADD_TO_USER_CANCLE_ORDER:
            return{
                ...state,
                userCancleOrder: action.payload,
            };
        case FETCH_USER_CANCLE_ORDER_REQUEST:
            return {
                ...state,
                loading:true
            };
        case FETCH_USER_CANCLE_ORDER_SUCCESS:
            return{
                loading: false,
                userCancleOrder: action.payload,
                error:'',
            };
        case FETCH_USER_CANCLE_ORDER_FAILURE:
            return{
                loading:false,
                userCancleOrder:{},
                error: action.payload
            };
        case REMOVE_FROM_USER_CANCLE_ORDER:
            return {
                ...state,
                userCancleOrder: state.userOrder
            };
        default:
            return state;
}
};
export default userCancleOrderReducer;