export const styles = () => ({
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(255, 255, 255, 0.5)'
                    }),
                    spinner: (base) => ({
                        ...base,

                        '& svg circle': {
                            stroke: 'rgba(255, 0, 0)'
                        }
                    })
})
export const styles1 = () => ({
    overlay: base => ({
        ...base,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.5)',
        zIndex: 1000,
        marginLeft: '24%',
    }),
    spinner: base => ({
        ...base,
        width: '37px',
        height: '27px',
        marginTop: '10px',
        marginLeft: '2px',
        '@media (max-width: 1024px)': {
            marginTop: '30%',
            marginLeft: '2%', 
        },
        '@media (max-width: 768px)': {
            marginTop: '29%',
            marginLeft: '-2%', 
        },
        '@media (max-width: 400px)': {
            marginTop: '29%',
            marginLeft: '-10%', 
        },
        '& svg circle': {
            stroke: 'rgba(255, 0, 0)',
        },
    }),
});



export const styles2 = {
    overlay: {
      background: 'rgba(255, 255, 255, 0.5)'
    },
    spinner: {
      '& svg circle': {
        stroke: 'rgba(255, 0, 0)'
      }
    },
    select: {
      borderRadius: '8px',
      padding: '8px',
      appearance: 'none',
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      border: '1px solid #ccc',
      background: '#fff', 
      fontSize: '16px',
      height: 'auto', 
      marginTop: '10px',
      width: '100%', 
      boxShadow: '0 1px 2px rgba(0,0,0,0.1)' 
    }
  };