import React, { useEffect, useState } from 'react'
import PageTitle from '../components/PageTitle'
import Footer from '../layouts/Footer'
import { callApi } from '../utils/Api'
import { Link } from 'react-router-dom'
import ReactQuill from 'react-quill';
import { useNavigate } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import {
    paramCase,
} from "change-case";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { data } from 'jquery'
// export default function VendorListing() {
//     const [vendorList, setVendorList] = useState([])
//     const userId = localStorage.getItem('userid')
//     console.log("userIdTest", userId)
//     useEffect(() => {
//         callApi('post', 'merchant/getMerchant/getAllMerchant').then((res) => {
//             console.log("SetVendorListFor=====>>", res.data.users)
//             setVendorList(res?.data?.users)

//         }).catch((err) => {
//             console.log(err)
//         })
//     }, []);
//     let navigate = useNavigate();
//     const searchVendor = (item) => {
//         // console.log("vendor to be displyed => ", item.user._id)
//         navigate(`/sellerDetails/aj/${item.user._id}`)
//         if (data) {
//             navigate(`/sellerDetails/aj/${item.user._id}`)
//         }
//     }

//     return (
//         <>
//             <PageTitle />
//             <div className="container-fluid">
//                 <ResponsiveMasonry
//                     columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 5 }}
//                     gutter={0}
//                 >
//                     <Masonry>
//                         {vendorList?.map((item, key) => {
//                             if (!item.active) {
//                                 // Skip inactive merchantss
//                                 return null;
//                             }
//                             console.log("Vendor Iteam data", item);
//                             return (
//                                 // <Link key={key} to={`/sellerDetails/${item?.user?._id}`}>

//                                 <div className='card masonry-card'>

//                                     <div className="wrapper-img ">
//                                         <div className="img">
//                                             <h3 className='name'>
//                                                 <a href="seller-detail-1.html" className='title' >{item.user?.firstName} {item.user?.lastName}</a>
//                                             </h3>
//                                             <h4 className="location loc">
//                                                 <a href="#">{item.merchantCompanyCity},{item.merchantCompanyState},{item.merchantCompanyCountry}</a>
//                                             </h4>
//                                             <div className="slide-container">
//                                                 <div className="img-overl"></div>
//                                                 <img style={{ width: "100%" }} className="img-container" src={item.user?.avatar} alt="" />
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="container">
//                                         <div className='row'>
//                                             <div className="col-md-6 col-6 mt-4">
//                                                 <div className="m-price">{item.reviewSummery?.rating} <i className='fa fa-star clr-star'></i>&nbsp;{item.reviewSummery?.totalReviews} </div>
//                                             </div>
//                                             {/* <div className="col-md-6 col-6 ">
//                                               <p style={{fontSize:"10px",paddingTop:"1.8rem"}}>50 products</p>
//                                           </div> */}
//                                         </div>
//                                         <div className="description">
//                                             <ReactQuill theme={"bubble"} value={item?.description} style={{ height: "100px" }} readOnly={true} />
//                                         </div>
//                                         {/* <div className="mt-5">
//                                       <a href="/sellerDetails/aj/">Detail</a>
//                                       </div> */}
//                                         <div className='mt-5' style={{cursor: 'pointer'}} onClick={() => { console.log("******Search Vendor******"); searchVendor(item) }}>Details</div>
//                                     </div>
//                                 </div>
//                             )

//                         })}

//                     </Masonry>
//                 </ResponsiveMasonry>
//             </div>

//             <Footer />
//         </>
//     )
// }
export default function VendorListing() {
    const [vendorList, setVendorList] = useState([]);
    const userId = localStorage.getItem('userid');
    console.log("userIdTest", userId);

    useEffect(() => {
        callApi('post', 'merchant/getMerchant/getAllMerchant')
            .then((res) => {
                console.log("SetVendorListFor=====>>", res.data.users);
                setVendorList(res?.data?.users);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    let navigate = useNavigate();

    const searchVendor = (item) => {
        // console.log("Item For Search vendor List",{item})
        const userIdWeb = item.user._id;
        navigate(`/sellerDetails/aj/${item.user._id}`);
    };

    // Filter out inactive merchants
    const activeVendorList = vendorList.filter((item) => item.active);

    return (
        <>
            <PageTitle />
            <div className="container-fluid">
                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 250: 1, 350: 2, 750: 2, 900: 3,1000:4,1200:5 }}
                    gutter={0}
                >
                    <Masonry>
                        {activeVendorList.map((item, key) => {
                            console.log("Vendor Iteam data", item);
                            return (
                                <div className='card masonry-card' key={key}>
                                    <div className="wrapper-img ">
                                        <div className="img">
                                            <h3 className='name'>
                                                <a href="seller-detail-1.html" className='title' >{item.user?.firstName} {item.user?.lastName}</a>
                                            </h3>
                                            <h4 className="location loc">
                                                <a href="#">{item.merchantCompanyCity},{item.merchantCompanyState},{item.merchantCompanyCountry}</a>
                                            </h4>
                                            <div className="slide-container">
                                                <div className="img-overl"></div>
                                                <img style={{ width: "100%", height:"200px" }} className="img-container" src={item.user?.avatar} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className='row'>
                                            <div className="col-md-6 col-6 mt-4">
                                                <div className="m-price">{item.reviewSummery?.rating} &nbsp; <i className='fa fa-star clr-star'></i>
                                                {/* &nbsp;{item.reviewSummery?.totalReviews}  */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4 vendor-description">
                                            <ReactQuill theme={"bubble"} value={item?.description} style={{ height: "100px",overflowY: "hidden" }} readOnly={true} />
                                        </div>
                                        <div className='mt-5' style={{ cursor: 'pointer' }} onClick={() => { console.log("******Search Vendor******"); searchVendor(item) }}>Details</div>
                                      
                                    </div>
                                </div>
                            )
                        })}
                    </Masonry>
                </ResponsiveMasonry>
            </div>
            <Footer />
        </>
    );
}

