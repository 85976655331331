import React from 'react'

export default function NewAdsCard() {
  return (
    <div>
        <a href="/" className=" m-call-to-action">
                <div className="wrapper">
                  <div className="title">
                    <i className="fa fa-plus-square-o"></i>
                    Submit Your Ad
                  </div>
                </div>
              </a>
    </div>
  )
}
