import { callApi } from "../utils/Api";
import { ADD_TO_USER_ORDER,REMOVE_FROM_USER_ORDER,FETCH_USER_ORDER_REQUEST,FETCH_USER_ORDER_SUCCESS,FETCH_USER_ORDER_FAILURE } from "./actionType";

export const addToUserOrder = (data)=>({
    type: ADD_TO_USER_ORDER,
    payload: data,
});
export const reomveFromUserOrder = (productId)=>({
    type: REMOVE_FROM_USER_ORDER,
    payload: productId
});
export const fetchUserOrderRequest = ()=>{
    return{
        type: FETCH_USER_ORDER_REQUEST
    }
};
export const fetchUserOrderSuccess=(data)=>{
    return{
        type: FETCH_USER_ORDER_SUCCESS,
        payload: data,
    }
}
export const fetchUserOrderFailur=(error)=>{
    return{
        type: FETCH_USER_ORDER_FAILURE,
        payload: error
    }
}


//Fetch User Visited Data
export const fetchUserOrders =(userId)=>{
    return (dispatch)=>{
        dispatch(fetchUserOrderRequest)
        callApi('post','cart/cartcrud/getUserWiseOrder',{userId:userId, limit: 100, page: 1})
        .then(res=>{
            dispatch(fetchUserOrderSuccess(res.data))
        }).catch(error=>{
            dispatch(fetchUserOrderFailur(error))
        })
    }
}