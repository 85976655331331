import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
export default function ({convenieceFeeModal,toggleConvenieceFeeModal,closeConvineanceModal,args}) {
  return (
    <>
      <Modal
        isOpen={convenieceFeeModal}
        toggle={toggleConvenieceFeeModal}
        {...args}
      >
        <ModalHeader
          toggle={toggleConvenieceFeeModal}
          close={closeConvineanceModal}
        >
          Convenience Fee
        </ModalHeader>
        <ModalBody>
          <div class="shadow-sm p-2 bg-white rounded">
            <div class="card-body mt-3 ml-3 mb-2">
              <p>'Convenience Fee' comprises:</p>
              <ul>
                <li>
                  <p>
                    A flat platform charge, applicable to all customers
                    including Mayifind Insiders
                  </p>
                </li>
                <li>
                  <p>
                    Shipping charges for low value orders (Insiders are exempted
                    from this) or higher than average returns
                  </p>
                </li>
              </ul>
              {/* <p>Have a question?</p>
                <p>For further information</p> */}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
