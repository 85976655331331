import React from 'react'
import PrimaryNav from '../../components/NavBar/PrimaryNav'
import Nav from '../../components/NavBar/Nav'
import HeroComponents from '../../components/HeroComponent'
export default function Header() {
  return (
    <div>
        <header className="hero">
            <div className="hero-wrapper">
                <PrimaryNav/>
                {/* <Nav/> */}
                <HeroComponents/>
            </div>
        </header> 
    </div>
  )
}
