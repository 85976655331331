import React from 'react';
import { Link,useNavigate } from 'react-router-dom'

export default function PhonepePaymentSuccess() {
  localStorage.removeItem("paymentDetails");
  localStorage.removeItem("CartPrice");
  localStorage.removeItem('coupon_d');
  localStorage.removeItem('serv_char');
  return (
    <div className="container">
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
            <div className="col-md-6 text-center pt-10">
                <div className="alert alert-success text-center">
                    <h4 className="alert-heading">Payment Successfull</h4>
                </div>
                <Link to='/'>Back to Home</Link>
            </div>
          </div>
        </div>
  )
}
