import {
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  REMOVE_PRODUCT_SUCCESS,
  REMOVE_PRODUCT_FAILURE,
  ADD_PRODUCTS,
} from '../actions/actionType';

const initialState = {
  products: [],
  error: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: [...state.products, ...action.payload], // Concatenate arrays
        error: null,
      };

    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload],
        error: null,
      };

    case ADD_PRODUCTS:
      return {
        ...state,
        products: [...state.products, ...action.payload],
        error: null,
      };

    case ADD_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case REMOVE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter((product) => product.id !== action.payload),
        error: null,
      };

    case REMOVE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default productReducer;
