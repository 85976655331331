import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { callApi } from "../../utils/Api";
export default function ({refundModal,toggleRefundModal,closeRefundModal,args}) {
    const userId=localStorage.getItem('userid')
    const getData=(key)=> {
        const itemStr = sessionStorage.getItem(key);
        if (!itemStr) {
            // navigate("/myOrder")
            return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > item.expiration) {
            sessionStorage.removeItem(key);
            return null;
        }
        return item.value;
    }
   const handleRefund =()=>{
    let returnOrderId= getData("returnOrderId")//||"66471b5b5b7f1824908a134d";
    if(returnOrderId && userId){
        callApi('post','returnOrder/returnCurd/refundRequest',{userId:userId,returnOrderId:returnOrderId})
        .then(res=>{
            console.log("first",res.data) 
        }).catch(error=>{
            console.log("error",error)  
        })
    } 
    }
  return (
    <>
      <Modal
        isOpen={refundModal}
        toggle={toggleRefundModal}
        {...args}
      >
        <ModalHeader
          toggle={toggleRefundModal}
          close={closeRefundModal}
        >
          Refund Terms
        </ModalHeader>
        <ModalBody>
          <div className="shadow-sm p-2 bg-white rounded">
            <div className="card-body mt-1 ml-3 mb-2">
              <p>'Refund' comprises:</p>
              <ul>
                <li>
                  <p>
                    The refund will be initiated after the product is received by the vendor.
                  </p>
                </li>
                <li>
                  <p>
                  Refund will only be initiated if the item is eligible for return.
                  </p>
                </li>
                <li>
                  <p>
                  Refund amount will be cadit after 48 hour of refund initiated.
                  </p>
                </li>
              </ul>
                <div className="col-6 float-right">
                <button className="btn btn-primary mt-4 width-100" type="submit"
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            handleRefund()
                        }}

                    >
                        Refund Confirm
                    </button>
                </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
