import React, { useEffect, useState } from 'react'
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import axios from "axios";
import NewAdsCard from './Cards/NewAdsCard';
import AddToCartButton from './ProductDetails/AddToCartButton';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, ButtonDropdown } from 'reactstrap';
import { apiUrl } from '../constants/defaultValue';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Link } from 'react-router-dom';
import WishListButton from './ProductDetails/WishListButton';
import {
  paramCase,
} from "change-case";
import { callApi } from '../utils/Api';


export default function SellerProductListing({ productData }) {
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [show, setShow] = useState(null);
  const [isopen, setIsopen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const getProductData = () => {
    setFilteredProduct(productData);
  }
// useEffect(() => {
//   callApi('post', 'category/curd/getAllKeys').then(response => {
//     // console.log(response.data);
//     let Cate = Object.keys(response.data);

//     setCategories(Cate);
// });
// }, [])
  useEffect(() => {
    getProductData();
    
  }, [productData]);
  useEffect(() => {
    if (selectedCategory) {
        let data = productData.filter((item) => item.productCategory[0]?.value === selectedCategory);
        setFilteredProduct((e) => [...data]);
    }
}, [selectedCategory]);
const allProduct=()=>{
  setFilteredProduct(productData);
}
const maxFilter = () => {
  let data = productData.sort((a, b) => b.grossPrice - a.grossPrice);
  console.log(data);
  setFilteredProduct((e) => [...data]);
}
const minFilter = () => {
  let data = productData.sort((a, b) => a.grossPrice - b.grossPrice);
  console.log(data);
  setFilteredProduct((e) => [...data]);
}
const oldestFilter = () => {
  let data = productData.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
  console.log(data);
  setFilteredProduct((e) => [...data]);
}
const newestFilter = () => {
  let data = productData.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
  console.log(data);
  setFilteredProduct((e) => [...data]);
}
const isFeaturedFilter = () => {
  let data = productData.filter((item) => item.isFeatured === 'yes');
  console.log(data);
  setFilteredProduct((e) => [...data]);
}
const highratingFilter = () => {
  let data = productData.sort((a, b) => b.reviewsSummery.rating - a.reviewsSummery.rating);
  console.log(data);
  setFilteredProduct((e) => [...data]);
}
const maxDiscountFilter = () => {
  let data = productData.sort((a, b) => b.offerSummary - a.offerSummary);
  console.log(data);
  setFilteredProduct((e) => [...data]);
}
  const zoomOutProperties = {
    duration: 2000,
    transitionDuration: 600,
    infinite: true,
    indicators: false,
    arrows: false,
    autoplay: true,
  };
  const showDetails = (e) => {

    setShow(e);
  }
  const removeDetails = (e) => {
    setShow(null);
  }
  const toggle = () => {
    setIsopen(!isopen);
  }
  const addUserVisited=(p_Id)=>{
    callApi('post',`product/getProduct/userVisited`, { userId: localStorage.getItem('userid')|| null, productId: p_Id })
    .then((response) => {
      // console.log(response.data)
  }).catch((error)=>{
    console.error(error)
  })
}
  return (
    <div>
      <div className="section-title clearfix">
        <div className="float-xl-left float-md-left float-sm-none">
          <h2>My Products</h2>
        </div>
        <div className="float-xl-right float-md-right float-sm-none">
          <select
            name="categories"
            id="categories"
            className="small width-200px mr-2"
            data-placeholder="Category"
            onChange={e => setSelectedCategory(e.target.value)}
            value={selectedCategory}
          >
            <option>Choose Category</option>
            {categories.map((category) => {
              return (
                <option key={category.id} value={category}>{category}</option>
              )
            })}

          </select>

          <ButtonDropdown isOpen={isopen} className='sorting-option' toggle={toggle}>
            <DropdownToggle style={{ backgroundColor: "rgba(256, 256, 256, 1)", padding: '8px' }} className="small text-dark sorting-option width-200px" caret size="lg">
              Default Sorting
            </DropdownToggle>
            <DropdownMenu className='width-200px '>
              <DropdownItem className='width-200px ' onClick={allProduct}>All</DropdownItem>
              <DropdownItem className='width-200px ' onClick={isFeaturedFilter}>Recommended</DropdownItem>
              <DropdownItem className='width-200px ' onClick={newestFilter}>What's New</DropdownItem>
              <DropdownItem className='width-200px ' onClick={maxFilter}>Sort by Max Price</DropdownItem>
              <DropdownItem className='width-200px ' onClick={minFilter}>Sort Min Price</DropdownItem>
              <DropdownItem className='width-200px ' onClick={maxDiscountFilter}>Sort by Discount</DropdownItem>
              <DropdownItem className='width-200px ' onClick={highratingFilter}>Sort by Ratings</DropdownItem>
              <DropdownItem className='width-200px ' onClick={oldestFilter}>Sort by oldest</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>

          
        </div>
      </div>
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 250: 1, 350: 2, 750: 2, 900: 3,1000:4,1200:5 }}
        gutter={0}
      >
        <Masonry>
          {filteredProduct?.map((item, key) => {

            switch (item.listingType) {
              case "product":
                return (
                  <Link to={`/productdetails/${paramCase(item.productName)}/${item._id}`}>
                    <div key={key} onMouseEnter={() => showDetails(item._id)} onMouseLeave={() => removeDetails()} onClick={()=>addUserVisited(item._id)} className="card masonry-card" >

                      {item.isFeatured === 'yes' ?
                        <div className="ribbon-featured">
                          <div className='ribbon-start'></div>
                          <div className='ribbon-content'>Featured</div>
                          <div className='ribbon-end'>
                            <figure className='ribbon-shadow'></figure>
                          </div>
                        </div>
                        : null}
                      <div className="wrapper-img">
                        <div className="img">

                          <div className="slide-container">
                            {show === item._id ? <>
                              <Fade  {...zoomOutProperties}>
                                {
                                  item.productThumbnailImages.map((each, index) => {
                                    return (
                                      <div className="each-fade">
                                        <img key={index} style={{ width: "100%", height:'200px' }} src={each.imgurl} />
                                      </div>
                                    )
                                  })
                                }
                              </Fade>
                            </>

                              : <img style={{ width: "100%", height:'200px' }} src={item.productThumbnailImages[0].imgurl} />
                            }
                          </div>
                        </div>

                      </div>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6 col-6 mt-4">
                            <div className="m-price">{item.reviewsSummery.rating}&nbsp;<i className='fa fa-star clr-star'></i>
                              {/* {item.reviewsSummery.totalReviews}  */}
                              </div>
                          </div>
                          <div className="col-md-3 col-3 ">
                            {/* <Button className="float-right n-price" color='link' >
                              <i className='fa fa-heart'></i>

                            </Button> */}
                        <WishListButton item={item._id} user={localStorage.getItem('userid')} getProducts={getProductData}></WishListButton>
                          </div>
                          <div className="col-md-3 col-3 ">
                            {/* <Button className="float-right n-price" color='link' >
                              <i className='fa fa-cart-plus'></i>

                            </Button> */}
                          <AddToCartButton pid={item._id} />
                          </div>

                        </div>



                        <div className="m-meta">
                          <a className="title m-indtitle text-capitalize">
                            {item.productName}
                          </a>
                          <a href="/" className=" m-category macategory">
                            {item.productCategory[0]?.value}

                          </a>
                          <div>

                            <div className="row">
                              <div className="col-md-12 col-12">
                                <span className='rupees'>Rs.{item.grossPrice}&nbsp;</span>

                                <span className='cross-mark'>
                                  Rs. {item.offerSummary} </span>
                                <span className='offersummary'>({Math.round(((item.offerSummary-item.grossPrice)/item.offerSummary)*100)}% OFF)</span>
                              </div>
                              <div className="col-md-6 col-6 ">

                                {/* {show != item._id ?
                                  <div><Button className='float-right n-price' style={{top:0}} color='link' onClick={e => showDetails(item._id)} >
                                    <i className='fa fa-angle-down '></i>  </Button></div>
                                  :
                                  <div>
                                    <Button className='float-right n-price' color='link' onClick={e => removeDetails()} >
                                      <i className='fa fa-angle-up'></i> </Button></div>} */}


                              </div>
                            </div>



                          </div>
                        </div>


                        {show === item._id ?
                          <div className='show-details'>
                            <div className="description">
                              {item.productDescription}
                            </div>
                            <div className="m-additional-info">

                              <ul>
                                {item.productFeatures.map(i => {

                                  return <li>
                                    <figure style={{ fontSize: "1.1rem", fontWeight: '600', color: '#000' }} >{i.key}</figure>
                                    <aside style={{ fontSize: "1.1rem", fontWeight: '100' }} >{i.value}</aside>
                                  </li>
                                })}


                              </ul>

                            </div>

                          </div>
                          : null}

                      </div>

                    </div>
                  </Link>
                )
              case "ads":
                return <NewAdsCard />
            }


          })}
        </Masonry>
      </ResponsiveMasonry>


      <div className="center">
        <a href="/" className="btn btn-primary btn-framed btn-rounded">
          Load More
        </a>
      </div>













    </div>
  )
}
