import React, { useState } from 'react'
import PageTitle from '../components/PageTitle'
import Footer from '../layouts/Footer'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { apiUrl } from '../constants/defaultValue'
import { useNavigate } from 'react-router-dom'
// import { Code } from 'react-content-loader'
export default function Login() {
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    // const [pageState, setPageState] = useState('login');
    const [error, setError] = useState({ phoneError: '', passwordError: '' });
    const validate = () => {
        let phoneError = "";
        let passwordError = "";
        const reg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        if (!phone || reg.test(phone) === false) {
            phoneError = "Phone Field is Invalid ";
        }
        if (!password) {
            passwordError = "Password field is required";
        }
        if (phoneError || passwordError) {
            setError({ phoneError, passwordError });
            return false;
        }
        return true;
    }
    const handelPhone = (e) => {
        e.preventDefault();
        setPhone(e.target.value);
    }
    const handelPassword = (e) => {
        e.preventDefault();
        setPassword(e.target.value);
    }
    const handelSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            axios.post(`${apiUrl}/users/userLogin`, {
                password: password,
                phoneNumber: phone,
                api_key: "registeruser"
            }).then(function (response) {
                // console.log(response);
                Store.addNotification({
                    title: "Login Successful",
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('userid', response.data.userId);
                pass();
            })
                .catch(function (error) {
                    console.log(error.message);
                    console.log(error.response.data.message);
                    Store.addNotification({
                        title: error.response.data.message,
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                });
            setError({ phoneError: '', passwordError: '' });
        }
    }
    let navigate = useNavigate();
    const pass = () => {
        var previousPage = sessionStorage.getItem('previousPage');
        if (previousPage ) {
            navigate(previousPage)
        }
        else if (localStorage.getItem('token')) {
            navigate('/')
        }
    }
    return (
        <>
            <ReactNotifications />
            <PageTitle />
            <section className="content">
                <section className="block">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4">
                                <form className="form clearfix">
                                    <div className="form-group">
                                        <label htmlFor="phone" className="col-form-label required">Phone Number</label>
                                        <input name="phone" maxLength={10} value={phone} onChange={(e) => { handelPhone(e) }} type="tel" className="form-control" placeholder="Your Phone Number" required />
                                        <span className="text-danger">{error.phoneError}</span>
                                    </div>

                                    <div className="form-group position-relative">
                                        <label htmlFor="password" className="col-form-label required">Password</label>
                                        <input name="password" type={showPassword ? "text" : "password"} value={password} onChange={(e) => { handelPassword(e) }} className="form-control" id="password" placeholder="Password" required />
                                        <i onClick={() => setShowPassword(!showPassword)} className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`} style={{position: 'absolute', right: '10px', top: '70%', transform: 'translateY(-50%)', cursor: 'pointer'}}></i>
                                        <span className="text-danger">{error.passwordError}</span>
                                    </div>

                                    <div className="form-group">
                                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                                            <div className="custom-control custom-checkbox mb-2 mb-sm-0">
                                                <input type="checkbox" className="custom-control-input" id="remember" name="remember" value="1" />
                                                <label className="custom-control-label" htmlFor="remember">Remember Me</label>
                                            </div>
                                            <button type="submit" onClick={(e) => { handelSubmit(e) }} className="btn btn-primary">Sign In</button>
                                        </div>
                                    </div>
                                </form>
                                <hr />
                                <div className='d-flex flex-column flex-sm-row justify-content-between align-items-center'>
                                    <p className="mb-2 mb-sm-0"><Link to='/forgot-password' className="link">Forgot your password?</Link></p>  
                                    <p><Link to='/register' className="link">Sign up!</Link></p>
                                </div>
                                <p>
                                   
                                </p>
                            </div>

                        </div>

                    </div>

                </section>

            </section>
            <Footer />
        </>
    )
}
