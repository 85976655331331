import React, { useEffect, useState } from 'react'
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Link } from 'react-router-dom';
import { paramCase } from "change-case";
import { callApi } from '../utils/Api.js';
import WishListButton from '../components/ProductDetails/WishListButton.js';
import AddToCartButton from '../components/ProductDetails/AddToCartButton.js';
import { addProducts } from '../actions/productActions.js';
import { useDispatch } from 'react-redux';
import AliceCarousel from 'react-alice-carousel';
export default function TopSellProduct() {
  const [product, setProduct] = useState([]);
  const [show, setShow] = useState(null);
  const [filterVendorData, setFilterVendorData] = useState([]);
  const [filterPriceData, setFilterPriceData] = useState([]);
  const [filterOption, setFilterOption] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isopen, setIsopen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [productCheck, setProductCheck] = useState(true);
  const [pageLimit, setPagelimit] = useState(20)
  const [totalProduct, setTotalProducts] = useState(1)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const usedispatch = useDispatch();

  const getProducts = () => {
    if (totalProduct !== product?.length) {
      callApi('post', 'product/getProduct/getTopSellProduct', { limit: pageLimit, page: currentPage }).then(response => {
        const allProducts = response?.data?.flatMap(category => category.product);
        usedispatch(addProducts(allProducts));
        setProduct(privState => [...privState, ...allProducts]);
        // setTotalProducts(response?.total || 1)
        // if (screenWidth < 768 && product.length === 0) {
        //     setFilteredProduct(allProducts?.slice(0, 6));
        // } else if (product.length === 0) {
        //     setFilteredProduct(allProducts?.slice(0, 10));
        // }
        setFilteredProduct(allProducts);

      }).catch(err =>
        console.log(err)
      );
    }
  }
  useEffect(() => {
    getProducts();
  }, [currentPage]);

  useEffect(() => {
    callApi('post', 'filter/filterCrud/getFilterSection').then(response => {
      if (response.data) {
        let vendorData = response.data.filter((item) => item.filterType === 'Vendor');
        let priceData = response.data.filter((item) => item.filterType === 'Price');
        setFilterVendorData(vendorData);
        setFilterPriceData(priceData);
      }
    }
    ).catch(err =>
      console.log(err)
    );
    // getProducts();
    callApi('post', 'category/curd/getAllKeys').then(response => {
      // console.log(response.data);
      let Category = Object.keys(response.data);

      setCategories(Category);
    });
  }, []);
  useEffect(() => {
    if (selectedCategory) {
      let data = product.filter((item) => item.productCategory[0]?.value === selectedCategory);
      setFilteredProduct((e) => [...data]);
    }
  }, [selectedCategory]);
  const allProduct = () => {
    setFilteredProduct(product);
  }
  const maxFilter = () => {
    let data = product.sort((a, b) => b.grossPrice - a.grossPrice);
    console.log(data);
    setFilteredProduct((e) => [...data]);
  }
  const minFilter = () => {
    let data = product.sort((a, b) => a.grossPrice - b.grossPrice);
    console.log(data);
    setFilteredProduct((e) => [...data]);
  }
  const oldestFilter = () => {
    let data = product.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
    console.log(data);
    setFilteredProduct((e) => [...data]);
  }
  const newestFilter = () => {
    let data = product.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
    console.log(data);
    setFilteredProduct((e) => [...data]);
  }
  const isFeaturedFilter = () => {
    let data = product.filter((item) => item.isFeatured === 'yes');
    console.log(data);
    setFilteredProduct((e) => [...data]);
  }
  const highratingFilter = () => {
    let data = product.sort((a, b) => b.reviewsSummery.rating - a.reviewsSummery.rating);
    console.log(data);
    setFilteredProduct((e) => [...data]);
  }
  const maxDiscountFilter = () => {
    let data = product.sort((a, b) => b.offerSummary - a.offerSummary);
    console.log(data);
    setFilteredProduct((e) => [...data]);
  }

  const zoomOutProperties = {
    duration: 2000,
    transitionDuration: 600,
    infinite: true,
    indicators: false,
    arrows: false,
    autoplay: true,
  };
  // const showDetails = (e) => {
  //     setShow(e);
  // }
  // const removeDetails = (e) => {
  //     setShow(null);
  // }
  const handelFilter = (filterOptions, e) => {
    if (e) {
      setFilterOption((e) => ([...e, filterOptions]));
    } else {
      setFilterOption((e) => e.filter((item) => item.filterValue !== filterOptions.filterValue));
    }
  }
  const toggle = () => {
    setIsopen(!isopen);
  }



  const showHideSection = (e) => {
    if (screenWidth < 768) {
      setFilteredProduct(product.slice(0, 6));
    } else {
      setFilteredProduct(product.slice(0, 10));
    }
    // setFilteredProduct(product.slice(0, 10));
    setProduct([])
    setCurrentPage(1)
    setProductCheck(!productCheck);
  }
  const showMoreSection = (e) => {
    const itemsToAdd = screenWidth < 768 ? 6 : 10;
    const newItems = product.slice(filteredProduct.length, filteredProduct.length + itemsToAdd);
    setFilteredProduct(prevState => [...prevState, ...newItems]);
    setCurrentPage(currentPage + 1)
    // console.log(newItems.length)
    if (newItems.length === 0) {
      setCurrentPage(1)
    }
    // if (product.length <= (filteredProduct.length+newItems.length)) {
    if (totalProduct <= (filteredProduct.length + newItems.length)) {
      setProductCheck(!productCheck);
    }
  }



  return (
    <div>
      <section className="content" >
        <section className="block" >
          <div className="container" style={{marginBottom:"-30px"}}>


            <div className="float-xl-left float-md-left float-sm-none">
              {
                filteredProduct?.length ? <h2 className="text-muted my-0">Recommend</h2> : ""
              }
            </div>
            <AliceCarousel
              items={filteredProduct?.map((item, key) => (
                <Link key={key} to={`/productdetails/${paramCase(item.productName)}/${item._id}`}>
                  <div
                    //   onClick={() => { addUserVisited(item._id) }}
                    className="card masonry-card"
                    style={{ marginTop: "20px" }}
                  >
                    {item.isFeatured === 'yes' && (
                      <div className="ribbon-featured">
                        <div className='ribbon-start'></div>
                        <div className='ribbon-content'>Featured</div>
                        <div className='ribbon-end'>
                          <figure className='ribbon-shadow'></figure>
                        </div>
                      </div>
                    )}
                    <div className="wrapper-img">
                      <div className="img">
                        <div className="slide-container">
                          <img style={{ width: "100%", height: "200px" }} src={item.productThumbnailImages[0]?.imgurl} alt={item.productName} />
                        </div>
                      </div>
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-6 col-6 mt-4">
                          <div className="m-price">{item.reviewsSummery.rating}&nbsp;<i className='fa fa-star clr-star'></i>
                          </div>
                        </div>
                        <div className="col-md-3 col-3 ">
                          <WishListButton item={item._id} user={localStorage.getItem('userid')} getProducts={getProducts}></WishListButton>
                        </div>
                        <div className="col-md-3 col-3 ">
                          <AddToCartButton pid={item._id} />
                        </div>
                      </div>
                      <div className="m-meta">
                        <a className="title m-indtitle text-capitalize">
                          {item.productName}
                        </a>
                        <a href="/" className="m-category macategory">
                          {item.productCategory[item.productCategory.length - 1]?.value}
                        </a>
                        <div>
                          <div className="row" style={{ whiteSpace: "nowrap" }}>
                            <div className="col-md-6 col-6">
                              <span className='rupees'>Rs.{item.grossPrice}&nbsp;</span>
                              <span className='cross-mark'>
                                Rs. {item.offerSummary} </span>
                              <span className='offersummary'>({Math.round(((item.offerSummary - item.grossPrice) / item.offerSummary) * 100)}% OFF)</span>
                            </div>
                            <div className="col-md-6 col-6 ">
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </Link>
              ))}
              responsive={{ 250: { items: 2 }, 750: { items: 2 }, 900: { items: 3 }, 1000: { items: 4 }, 1200: { items: 5 } }}
              controlsStrategy="alternate"
              autoPlay
              // autoPlayInterval={index===0 ? 3000 : index/2===0 ? 3000 : 5000}
              autoPlayInterval={2500}
              infinite
              disableDotsControls
              renderNextButton={() => null}
            />
            {/* {filteredProduct?.length >= (screenWidth < 768 ? 6 : 10) ?
              <div className="center">
                {!productCheck ? <button className="btn btn-primary btn-framed btn-rounded" onClick={(e) => showHideSection(e)}>
                  Show Less
                </button> : <button className="btn btn-primary btn-framed btn-rounded" onClick={(e) => showMoreSection(e)}>
                  Show More
                </button>}
              </div>
              : ''} */}

          </div>
        </section>
      </section>
    </div>
  )
}