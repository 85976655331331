import React from 'react';
import { Link,useNavigate } from 'react-router-dom';

export default function PhonepePaymentFaliur() {
  localStorage.removeItem("paymentDetails");
  localStorage.removeItem("CartPrice");
  localStorage.removeItem('coupon_d');
  localStorage.removeItem('serv_char');
  return (
    <div className="container">
          <div  style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
            <div className="col-md-6 text-center">
                <div className="alert alert-danger text-center">
                    <h4 className="alert-heading">Oops, something went wrong!</h4>
                </div>
                <Link to='/'>Back to Home</Link>
            </div>
          </div>
     </div>
  )
}
