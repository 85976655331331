import React, { useState } from 'react'
import { callApi } from '../../utils/Api'
import { Button } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { styles1 } from '../../utils/Loader'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserWishlist,addToWishlist } from '../../actions/wishlistAction';

export default function WishListButton({ item, user,getProducts }) {
    // console.log("item", item)
    const [reviewFormLoading, setReviewFormLoading] = useState(false);
    const [wishList, setWishList] = useState([]);
    const dispatch=useDispatch()
    useEffect(() => {
        let wishListArray=JSON.parse(localStorage.getItem('wish_list_p'))
        if(wishListArray && wishListArray.length){
        if(wishListArray.includes(item)){
            setWishList(item)
        }
    }
    }, [])

    const addToWishList = (e, item) => {
        e.preventDefault();
        setReviewFormLoading(true)
        const data = {
            user: user,
            product: item
        }
        callApi('post','product/getProduct/addToWishlist', data).then((res) => {
            setTimeout(() => {
                setReviewFormLoading(false)
            }, 1000)
            getProducts();
            setWishList([res.data.productdata])
            getWishlistProduct()
            // console.log(res);
            let recentWishlist=res.data.data;
            recentWishlist.product=res.data.productData
            dispatch(addToWishlist(recentWishlist))
        }).catch((err) => {
            console.log(err);
            setTimeout(() => {
                setReviewFormLoading(false)
            }, 1000)
        })
    }
    const deleteWishList = (e, item) => {
        e.preventDefault();
        setReviewFormLoading(true)
        callApi('post','product/getProduct/deleteWishList', { user: user, product: item }).then(res => {
            // console.log(res);
            setTimeout(() => {
                setReviewFormLoading(false)
            }, 1000)
            getProducts();
            setWishList([])
            getWishlistProduct()
            dispatch(fetchUserWishlist(user))
        }
        ).catch(err => {
            console.log(err);
            setTimeout(() => {
                setReviewFormLoading(false)
            }, 1000)
        }
        )
    }
    const getWishlistProduct=()=>{
        callApi('post','product/getProduct/findWishlistByUserId', { user: localStorage.getItem("userid") })
        .then(res => {
          localStorage.setItem("wish_list_p",JSON.stringify(res.data));
          })
        .catch(err => {console.log(err);})
      }
    return (
        <>

            {
                !wishList.length ? <><LoadingOverlay
                    active={reviewFormLoading}
                    spinner
                    styles={styles1()}
                > <Button className="float-right n-price" onClick={e => addToWishList(e, item)} color='link' >
                        <i className='fa fa-heart fa-heart-unwish'></i>
                    </Button> </LoadingOverlay></> : <><LoadingOverlay
                        active={reviewFormLoading}
                        spinner
                        styles={styles1()}
                    >
                        <Button className="float-right n-price" onClick={e => deleteWishList(e, item)} color='link' >
                            <i className='fa fa-heart fa-heart-wish'></i>
                        </Button> 
                        </LoadingOverlay></>
            }




        </>
    )
}
