import React from 'react'

export default function InstaPostCard({getSize,key}) {
    // const refContainer = React.useRef(null)
    // const [dimension, setDimension] = React.useState(null)

    // React.useEffect(() => {
    //     if (refContainer.current) {
    //         setDimension({
    //             width: refContainer.current.offsetWidth,
    //             height: refContainer.current.offsetHeight
    //         })
    //     }
    // }, [refContainer.current])

    // React.useEffect(() => {
    //     if (dimension) {
    //         getSize(dimension,key)
    //     }
    // } ,[dimension])
    return (
        // <div ref={refContainer}>
        <div>
            <div className="items compact">
            <div className="item">
                {/* <div className="ribbon-featured-product">
                    <div className='ribbon-start'></div>
                    <div className='ribbon-content'>Featured</div>
                    <div className='ribbon-end'>
                        <figure className='ribbon-shadow'></figure>
                    </div>
                </div> */}

                <div className="wrapper">
                    <div className="image">
                        
                            
                                <div className="row">
                                    <div className="col-md-10 col-10"></div>
                                    <div className="col-md-2 col-2">
                                    <div className="tag"><i className='fa fa-star star-icon'></i></div>
                                    </div>
                                   
                                </div>

                                
                             
                            
                       
                        

                      

                        
                        <a href="single-listing-1.html" className="image-wrapper background-image">
                            <img src="/assets/img/image-01.jpg" alt="" />
                        </a>
                    </div>

                    {/* <h4 className="location-product">
                        <a href="/" >Manhattan, NY</a>
                    </h4> */}
                    <div className="price">  </div>
                    <div className="text-insta">@nishikantaray16</div>

                </div>

            </div>
            </div>
            
        </div>
    )
}
