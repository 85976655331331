import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Fade } from 'react-slideshow-image';
import { paramCase } from "change-case";
import WishListButton from '../../ProductDetails/WishListButton';
import AddToCartButton from '../../ProductDetails/AddToCartButton';

const ProductCards = ({ item, getProducts }) => {
    const [show, setShow] = useState("");

    const zoomOutProperties = {
        duration: 2000,
        transitionDuration: 600,
        infinite: true,
        indicators: false,
        arrows: false,
        autoplay: true,
    };

    return (
        <Link to={`/productdetails/${paramCase(item.productName)}/${item._id}`}>
            <div className="card masonry-card" 
            // onMouseEnter={() => setShow(item._id)}
            // onMouseLeave={() => setShow("")}
            >
                {item.isFeatured === 'yes' && (
                    <div className="ribbon-featured">
                        <div className='ribbon-start'></div>
                        <div className='ribbon-content'>Featured</div>
                        <div className='ribbon-end'>
                            <figure className='ribbon-shadow'></figure>
                        </div>
                    </div>
                )}
                <div className="wrapper-img">
                    <div className="img">
                        <div className="slide-container">
                            {show === item._id ? (
                                <Fade {...zoomOutProperties}>
                                    {item.productThumbnailImages.map((each, index) => (
                                        <div className="each-fade" key={index}>
                                            <img style={{ width: "100%", height: "200px" }} src={each.imgurl} alt={`Product Image ${index + 1}`} />
                                        </div>
                                    ))}
                                </Fade>
                            ) : (
                                <img style={{ width: "100%", height: "200px" }} src={item.productThumbnailImages[0].imgurl} alt="Product Thumbnail" />
                            )}
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-6 mt-4">
                            <div className="m-price">
                                {item.reviewsSummery.rating}&nbsp;
                                <i className='fa fa-star clr-star'></i>
                            </div>
                        </div>
                        <div className="col-md-3 col-3">
                            <WishListButton item={item._id} user={localStorage.getItem('userid')} getProducts={getProducts} />
                        </div>
                        <div className="col-md-3 col-3">
                            <AddToCartButton pid={item._id} />
                        </div>
                    </div>
                    <div className="m-meta">
                        <a className="title m-indtitle text-capitalize">
                            {item.productName}
                        </a>
                        <a className="m-category macategory">
                            {item.productCategory[0]?.value}
                        </a>
                        <div>
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <span className='rupees'>Rs.{item.grossPrice}&nbsp;</span>
                                    <span className='cross-mark'> Rs. {item.offerSummary} </span>
                                    <span className='offersummary'>
                                        ({Math.round(((item.offerSummary - item.grossPrice) / item.offerSummary) * 100)}% OFF)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default ProductCards;
