import React, { useState } from 'react'
import { callApi } from '../../utils/Api'
import { Button } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { styles1 } from '../../utils/Loader'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchUserWishlist,addToWishlist } from '../../actions/wishlistAction';

export default function WishListButton({ item, user,getProducts }) {
    // console.log("item", item)
    const [reviewFormLoading, setReviewFormLoading] = useState(false);
    const [isInWishlist, setIsInWishlist] = useState(false);
    const dispatch=useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        let wishListArray = JSON.parse(localStorage.getItem('wish_list_p')) || [];
        if (!Array.isArray(wishListArray)) {
            wishListArray = [];
        }
        if (wishListArray.some(wishItem => wishItem === item)) {
            setIsInWishlist(true);
        } else {
            setIsInWishlist(false);
        }
    }, [item]);

    const addToWishList = (e) => {
        e.preventDefault();
        if (!localStorage.getItem("userid")) {
            navigate('/login');
            return;
        }
        setReviewFormLoading(true)
        const data = {
            user: user,
            product: item
        }
        callApi('post','product/getProduct/addToWishlist', data).then((res) => {
            setTimeout(() => {
                setReviewFormLoading(false)
            }, 1000)
            getWishlistProduct()
            // console.log(res);
            let recentWishlist = res.data;
            dispatch(addToWishlist(recentWishlist));
            setIsInWishlist(true);
        }).catch((err) => {
            console.log(err);
            setTimeout(() => {
                setReviewFormLoading(false)
            }, 1000)
        })
    }

    const deleteWishList = (e) => {
        e.preventDefault();
        setReviewFormLoading(true)
        callApi('post','product/getProduct/deleteWishList', { user: user, product: item }).then(res => {
            // console.log(res);
            setTimeout(() => {
                setReviewFormLoading(false)
            }, 1000)
            setIsInWishlist(false);
            getWishlistProduct();
            dispatch(fetchUserWishlist(user));
        }
        ).catch(err => {
            console.log(err);
            setTimeout(() => {
                setReviewFormLoading(false)
            }, 1000)
        }
        )
    }
    const getWishlistProduct=()=>{
        callApi('post','product/getProduct/findWishlistByUserId', { user: localStorage.getItem("userid") })
        .then(res => {
          localStorage.setItem("wish_list_p",JSON.stringify(res.data));
          })
        .catch(err => {console.log(err);})
      }
    return (
        <>

            {
                !isInWishlist ? (
                    <LoadingOverlay
                        active={reviewFormLoading}
                        spinner
                        styles={styles1()}
                    >
                        <Button className="float-right n-price" onClick={addToWishList} color='link'>
                            <i className='fa fa-heart fa-heart-unwish'></i>
                        </Button>
                    </LoadingOverlay>
                ) : (
                    <LoadingOverlay
                        active={reviewFormLoading}
                        spinner
                        styles={styles1()}
                    >
                        <Button className="float-right n-price" onClick={deleteWishList} color='link'>
                            <i className='fa fa-heart fa-heart-wish'></i>
                        </Button>
                    </LoadingOverlay>
                )
            }




        </>
    )
}