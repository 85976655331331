import React, { useEffect, useState } from 'react';
import CategoriesCard from './Cards/CategoryCard';
import axios from 'axios';
import { apiUrl } from '../constants/defaultValue.js';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

export default function Category() {
    const [category, setCategory] = useState([]);
    const [categoryLess, setCategoryLess] = useState([]);
    const [categoryCheck, setCategoryCheck] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (screenWidth < 768) {
            setCategoryLess(category?.slice(0, 4));
        } else {
            setCategoryLess(category?.slice(0, 10));
        }
    }, [category, screenWidth]);

    const showHideSection = () => {
        setCategoryCheck(!categoryCheck);
    };

    async function fetchAllCategories() {
        try {
            const response = await axios.post(`${apiUrl}/category/curd/getAllCategory`);
            if (response.data.code === 200) {
                const arr = response.data.data
                    .filter(element => element.isFeaturedAtHomePage)
                    .map(element => ({
                        title: element.categoryName,
                        subTitle: element.categoryName,
                        img: element.img,
                        categoryOrder: element.categoryOrder,
                    }));
                setCategory(arr);
            } else {
                notifyError("Something is wrong while getting categories");
            }
        } catch (error) {
            notifyError("Something is wrong while getting categories");
        }
    }

    const notifyError = (message) => {
        Store.addNotification({
            title: message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate_animated", "animate_fadeIn"],
            animationOut: ["animate_animated", "animate_fadeOut"],
            dismiss: {
                duration: 3500,
                onScreen: true,
            }
        });
    };

    useEffect(() => {
        fetchAllCategories();
    }, []);

    return (
        <div>
            <section className="category-section sec-pad mt-8 mb-12">
                <div className="container">
                    <h2>Categories</h2>
                </div>
            <div className="auto-container centred">
                
                <div className="inner-content clearfix">
                    {categoryCheck?<CategoriesCard data={category}/>:<CategoriesCard data={categoryLess}/>}
                </div>
                
                {/* <div className="more-btn">
                    <button className="theme-btn-one btn" onClick={(e)=>showHideSection(e)}>
                        {categoryCheck?'Show Less':'Show More'} 
                        </button>
                    </div> */}
                </div>
            </section>
            <style>
                {`
                .category-title {
                    text-align: left;
                    margin-left: -45px;
                }
                @media (max-width: 768px) {
                    .category-title {
                        margin-left: 0; 
                        padding-left: 15px; 
                    }
                }
                `}
            </style>
        </div>
    );
}
