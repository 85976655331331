import React from 'react'
import { Modal,ModalBody,ModalHeader } from "reactstrap";
import { paramCase } from "change-case";
import { Link } from 'react-router-dom';
export default function SearchPopupModal({searchName,product,vendor,category,touch,searchVendor,onClosedSearch}) {
  return (
    <div className="form-row search-section-each-page mt-1">
    <div className="col-md-12 col-sm-12">
        {(product.length > 0 || vendor.length > 0 || category.length > 0) && searchName.length>0 ? <div className="pt-2">
            <ul className="justify-content-center  ">
            <button className="close"
                  style={{ fontSize: "30px",textDecoration:"none",marginTop:'-5px' }}
                  onClick={onClosedSearch}
                  type="button"
                  >
                  <i className="fa fa-close fa-1x"></i>
                  </button>
                <div className="row">
                    <div className="col-md-4 col-sm-4">
                        <li className="nav-item search-section-item">
                            <a className="nav-link active" href="#" aria-current="page">PRODUCT</a>
                        </li>
                        {product.length > 0 ? <>{product.map((item, index) => {

                            return (
                                <li key={index} className="nav-item search-section-item" onClick={onClosedSearch}>
                                    <div className='row mt-2 mb-2' onClick={() => { console.log("******Search Bar******"); touch(item) }}>
                                        <div className='col-md-2 col-2' >

                                            <img src={item.productThumbnailImages[0]?.imgurl} className="image-product" alt="" />
                                        </div>
                                        <div className='col-md-10 col-10'>
                                            <a className="nav-link product-main-heading" href="#" aria-current="page">{item.productName}</a>
                                            <a className="nav-link product-main-sub" href="#" aria-current="page">{item.productCategory[0]?.value}</a>
                                            <a className="nav-link product-main-price" href="#" aria-current="page">Rs. {item.grossPrice}</a>
                                        </div>
                                    </div>

                                    <Link to={`/productdetails/${paramCase(item.productName)}/${item._id}`}></Link>

                                </li>
                            )
                        })}</> : <p style={{ paddingLeft: "20px", paddingTop: "10px" }}>No Product Found</p>}
                        {product.length >= 1 ? <li className="nav-item search-section-item">
                            <a className="nav-link active" href="#" aria-current="page">see more</a>
                        </li> : null}

                    </div>
                    <div className="col-md-4 col-sm-4">
                        <li className="nav-item search-section-item">
                            <a className="nav-link active" href="#" aria-current="page">VENDOR</a>
                        </li>
                        {vendor.length > 0 ? <> {vendor.map((item, index) => {

                            return (
                                <React.Fragment key={index}>
                                    <li  className="nav-item search-section-item">
                                        <div className='row mt-2 ' onClick={() => { searchVendor(item) }}>
                                            <div className='col-md-2 col-2'>
                                                <img src={item.user?.avatar} className="image-vendor" alt="" />
                                            </div>
                                            <div className='col-md-9 col-9 ml-2'>
                                                <a className="nav-link" href="#" aria-current="page" style={{ fontSize: "12px" }}>{item.user?.firstName} {item.user?.lastName}</a>
                                                <a className="nav-link" style={{ fontSize: "10px", marginTop: "-10px", marginBottom: "-3px" }} href="#" aria-current="page">{item.merchantCompanyName}</a>
                                                <p className='address-vendor'>{item.merchantCompanyCity},{item.merchantCompanyState},{item.merchantCompanyCountry}</p>
                                            </div>
                                        </div>

                                     </li>
                                </React.Fragment>

                            )
                        })}</> : <p style={{ paddingLeft: "20px", paddingTop: "10px" }}>No vendor found</p>}
                        {vendor.length >= 1 ? <li className="nav-item search-section-item">
                            <a className="nav-link active" href="#" aria-current="page">see more</a>
                        </li> : null}

                    </div>
                    <div className="col-md-4 col-sm-4">
                        <li className="nav-item search-section-item">
                            <a className="nav-link active" href="#" aria-current="page">CATEGORY</a>
                        </li>
                        {category.length > 0 ? <>{category.slice(0, 10).map((item, index) => {

                            return (
                                <li key={index} className="nav-item search-section-item-category">

                                    <p className='category-sec'> {item} </p>
                                </li>
                            )
                        })}</> : <p style={{ paddingLeft: "20px", paddingTop: "10px" }}>No Category Found</p>}

                        {category.length > 10 ? <li className="nav-item search-section-item ">
                            <a className="nav-link active" href="#" aria-current="page">see more({category.length - 10})</a>
                        </li> : null}

                    </div>
                </div>
            </ul>
        </div> : null}
    </div>
    {/* <div className="col-md-2 col-sm-12"></div> */}
</div>
)
}
