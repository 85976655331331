import React from 'react';
import Index from './pages/Index';
import ListingGridWithSidebar from './pages/ListingGridWithSidebar';
import ListingListWithSidebar from './pages/ListingListWithSidebar';
import SellersDetails from './pages/SellersDetails';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ProductDetails from './pages/ProductDetails';
import Register from './pages/Register';
import Login from './pages/Login';
import ListingAll from './pages/ListingAll';
import WishListPage from './components/ProductDetails/WishListPage';
import CategoryWiseListing from './pages/CategoryWiseListing';
import ProductSearch from './pages/ProductSearch';
import VendorListing from './pages/VendorListing';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Refund from './pages/Refund';
import Payment from './pages/Payment';
import Cart from './pages/Cart';
import ScrollToTop from './ScrollToTop';
import MyOrder from './pages/MyOrder';
import CartIcon from './pages/CartIcon';
import ShippingPolicy from './pages/ShippingPolicy';
import TrackOrder from './pages/TrackOrder';
import Website from './pages/Website';
import PaymentStatus from './pages/PaymentSucess';
import MyRequirement from './pages/MyRequirement';
import Branding from './pages/Branding';
import UserProfile from './pages/Users/UserProfile';
import AddressPage from './pages/Users/AddressPage';
import PhonepePaymentSuccess from './pages/PhonepePaymentSuccess';
import PhonepePaymentFaliur from './pages/PhonepePaymentFaliur';
import ReturnOrder from './pages/ReturnOrder';
import CancleOrder from './pages/CancleOrder';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

import OfferDetails from './components/NavBar/OfferDetails';
import { OfferProductListing } from './components/OfferProductListing';




function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route exact path="/productdetails" element={<ProductDetails/>} />
          <Route exact path="/productdetails/:name/:id" element={<ProductDetails/>} />
          <Route exact path="/listingGridWithSidebar" element={<ListingGridWithSidebar />} />
          <Route exact path="/listingListWithSidebar" element={<ListingListWithSidebar />} />
          <Route exact path="/sellerDetails" element={<SellersDetails />} />
          <Route exact path="/sellerDetails/:name/:id" element={<SellersDetails />} />
          <Route exact path="/register" element={<Register/>} />
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} /> 
          <Route exact path="/listing" element={<ListingAll/>} />
          <Route exact path="/category/:name" element={<CategoryWiseListing/>} />
          <Route exact path="/wishlist" element={<WishListPage/>} />
          <Route exact path="/productSearch" element={<ProductSearch/>} />
          <Route exact path="/privacy" element={<Privacy/>} />
          <Route exact path="/website" element={<Website/>} />
          <Route exact path="/terms" element={<Terms/>} />
          <Route exact path="/refund" element={<Refund/>} />
          <Route exact path="/payment" element={<Payment/>} />
          <Route exact path="/paymentStatus" element={<PaymentStatus/>} />
          <Route exact path="/requirement" element={<MyRequirement/>} />
          <Route exact path="/branding" element={<Branding/>} />
          <Route exact path="/productSearch/:query" element={<ProductSearch/>} />
          <Route exact path="/vendorListing" element={<VendorListing/>} />
          <Route exact path="/myOrder" element={<MyOrder/>} />
          <Route exact path="/myCart" element={<Cart/>} />
          
          <Route exact path="/cart" element={<Cart/>} />
          <Route exact path="/shippingPolicy" element={<ShippingPolicy/>} />
          <Route exact path="/trackOrder/:cartId" element={<TrackOrder/>} />
          <Route exact path="/return" element={<ReturnOrder/>} />
          <Route exact path="/cancle" element={<CancleOrder/>} />
          <Route exact path="/my/profile" element={<UserProfile/>} />
          <Route exact path="/my/address" element={<AddressPage/>}/>
          <Route exact path="/offerProducts/:couponId" element={<OfferProductListing/>}/>
          <Route exact path="/success" element={<PhonepePaymentSuccess/>} />
          <Route exact path="/failure" element={<PhonepePaymentFaliur/>}/>
          <Route exact path="/offerdetails/:bannerId" element={<OfferDetails/>} />

          <Route path="/*" element={<Index />} />
        </Routes>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
