import React, { useEffect, useState } from 'react';
import { callApi } from '../utils/Api';
import Footer from '../layouts/Footer';
import PageTitle from '../components/PageTitle';
import { Link } from 'react-router-dom';
import { Store } from 'react-notifications-component';

export default function MyOrder() {
  const [myOrder, setMyOrder] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    BuyerName: '',
    phoneNumber: '',
    email:'',
    PinCode: '',
    Address: '',
    City: '',
    State: '',
    AddressType: 'Home',
    Requirement: '',
  });

  const [formErrors, setFormErrors] = useState({
    BuyerName: '',
    phoneNumber: '',
    email:'',
    PinCode: '',
    Address: '',
    City: '',
    State: '',
    Requirement: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: value.trim() === '' ? 'This field is required.' : '',
    });
  };

  const validatePhoneNumber = (number) => {
    const isValid = /^\d{10}$/.test(number);
    return isValid;
  };

  const addRequirement = () => {
    const { BuyerName, phoneNumber, PinCode,email, Address, Locality, City, State, Requirement, ServiceType } = formData;

    if (!BuyerName.trim()) {
      setFormErrors({
        ...formErrors,
        BuyerName: 'This field is required.',
      });
      return;
    }

    if (!validatePhoneNumber(phoneNumber)) {
      setFormErrors({
        ...formErrors,
        phoneNumber: 'Please provide a 10-digit valid phone number.',
      });
      return;
    }

    if (!PinCode.trim()) {
      setFormErrors({
        ...formErrors,
        PinCode: 'This field is required.',
      });
      return;
    }

    if (!Address.trim()) {
      setFormErrors({
        ...formErrors,
        Address: 'This field is required.',
      });
      return;
    }

    if (!email.trim()) {
      setFormErrors({
        ...formErrors,
        email: 'This field is required.',
      });
      return;
    }

    if (!City.trim()) {
      setFormErrors({
        ...formErrors,
        City: 'This field is required.',
      });
      return;
    }

    if (!State.trim()) {
      setFormErrors({
        ...formErrors,
        State: 'This field is required.',
      });
      return;
    }

    if (!Requirement.trim()) {
      setFormErrors({
        ...formErrors,
        Requirement: 'This field is required.',
      });
      return;
    }

    // if (!ServiceType.trim() || ServiceType === '--select--') {
    //   setFormErrors({
    //     ...formErrors,
    //     ServiceType: 'Please select a valid service type.',
    //   });
    //   return;
    // }

    callApi('post', 'cart/cartcrud/addRequirement', { ...formData })
      .then((res) => {
        console.log("Add Requirement:", res.data);

        // Assuming the response structure is something like: res.data.code
        if (res.status === "Requirement added Successfully") {
          setSuccessMessage('Requirement added successfully!');
          setTimeout(() => {
            setSuccessMessage('');
          }, 6000);
          window.location.href = "/"; // Clear success message after 6 seconds
        } else {
          setSuccessMessage('');
          setErrorMessage('Failed to add requirement.');
          setTimeout(() => {
            setErrorMessage('');
          }, 7000); // Clear error message after 6 seconds
        }
      })
      .catch((err) => {
        console.log(err);
        setSuccessMessage('');
        setErrorMessage('Failed to add requirement.');
        setTimeout(() => {
          setErrorMessage('');
        }, 7000); // Clear error message after 6 seconds
      });
  };

  useEffect(() => {
    callApi('post', 'cart/cartcrud/getUserWiseOrder', { userId: localStorage.getItem('userid'), limit: 100, page: 1 })
      .then((res) => {
        // console.log("userWiseCartData", res.data.data);
        setMyOrder(res.data.data);
      }).catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <section className="h-100 gradient-custom">
      <PageTitle />
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-lg-10 col-xl-8">
            <div className="card" style={{ borderRadius: 10 }}>
              <div className="card-header px-4 py-5">
                <h5 className="text-muted mb-0">Fill up the Required Field And Book Your Need, <span style={{ color: "#a8729a" }}>Thank You ! MayIFind</span>!</h5>
              </div>
              <div className="card-body p-4">
                <form>
                  <div className="mb-3">
                    <label htmlFor="BuyerName" className="form-label">Your Name</label>
                    <input type="text" className={`form-control ${formErrors.BuyerName ? 'is-invalid' : ''}`} id="BuyerName" name="BuyerName" value={formData.BuyerName} onChange={handleInputChange} />
                    {formErrors.BuyerName && <div className="invalid-feedback">{formErrors.BuyerName}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                    <input type="text" className={`form-control ${formErrors.phoneNumber ? 'is-invalid' : ''}`} id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} />
                    {formErrors.phoneNumber && <div className="invalid-feedback">{formErrors.phoneNumber}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Locality" className="form-label">Email</label>
                    <input type="text" className={`form-control ${formErrors.email ? 'is-invalid' : ''}`} id="email" name="email" value={formData.email} onChange={handleInputChange} />
                    {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                  </div>
                  {/* <div className="mb-3">
                    <label htmlFor="ServiceType" className="form-label">Service Type(Select Your Requirement From Dropdown)</label>
                    <select className="form-select" id="ServiceType" name="ServiceType" value={formData.ServiceType} onChange={handleInputChange}>
                      <option value="Select Your Requirement">--Select--</option>
                      <option value="Events">Events</option>
                      <option value="Professional Services">Professional Services</option>
                      <option value="Home and Office Services">Home and Office Services</option>
                      <option value="Home Renovation">Home Renovation</option>
                      <option value="Health and Wellness">Health and Wellness</option>
                      <option value="Travel and Transport">Travel and Transport</option>
                      <option value="Education and Career">Education and Career</option>
                      <option value="Real Estate">Real Estate</option>
                    </select>
                    {formErrors.ServiceType && <div className="invalid-feedback">{formErrors.ServiceType}</div>}
                  </div> */}
                
                  <div className="mb-3">
                    <label htmlFor="Address" className="form-label">Address</label>
                    <input type="text" className={`form-control ${formErrors.Address ? 'is-invalid' : ''}`} id="Address" name="Address" value={formData.Address} onChange={handleInputChange} />
                    {formErrors.Address && <div className="invalid-feedback">{formErrors.Address}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="PinCode" className="form-label">Pin Code</label>
                    <input type="text" className={`form-control ${formErrors.PinCode ? 'is-invalid' : ''}`} id="PinCode" name="PinCode" value={formData.PinCode} onChange={handleInputChange} />
                    {formErrors.PinCode && <div className="invalid-feedback">{formErrors.PinCode}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="City" className="form-label">City</label>
                    <input type="text" className={`form-control ${formErrors.City ? 'is-invalid' : ''}`} id="City" name="City" value={formData.City} onChange={handleInputChange} />
                    {formErrors.City && <div className="invalid-feedback">{formErrors.City}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="State" className="form-label">State</label>
                    <input type="text" className={`form-control ${formErrors.State ? 'is-invalid' : ''}`} id="State" name="State" value={formData.State} onChange={handleInputChange} />
                    {formErrors.State && <div className="invalid-feedback">{formErrors.State}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="AddressType" className="form-label">Address Type</label>
                    <select className="form-select" id="AddressType" name="AddressType" value={formData.AddressType} onChange={handleInputChange}>
                      <option value="Home">Home</option>
                      <option value="Office">Office</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Requirement" className="form-label">Describe Your Requirement</label>
                    <input type="text" className={`form-control ${formErrors.Requirement ? 'is-invalid' : ''}`} id="Requirement" name="Requirement" value={formData.Requirement} onChange={handleInputChange} />
                    {formErrors.Requirement && <div className="invalid-feedback">{formErrors.Requirement}</div>}
                  </div>
                </form>
                <div className="d-flex justify-content-center">
                  <button type="button" className="btn btn-primary btn-framed btn-rounded width-8" onClick={addRequirement}>Submit Your Requirement</button>
                </div>
                {/* <button type="button" className="btn btn-primary" onClick={addRequirement}>Submit Requirement</button> */}
                {successMessage && (
                  <div className="alert alert-success mt-3" role="alert">
                    {successMessage}
                  </div>
                )}
                {errorMessage && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

