import React from "react";

export default function PriceDetails({priceCalculation,serviceCharge,couponDiscount,toggleConvenieceFeeModal}) {
  return (
    <>
      <h4 className="payment-header-price">
    PRICE DETAILS ({priceCalculation.totalQuantity} {priceCalculation.totalQuantity === 1 ? 'Item' : 'Items'})
</h4>

      <div className="row">
        <h4 className="col-md-8 col-8 head-p float-left ">Total MRP</h4>
        <h4 className="col-md-4 col-4 amount-p float-right">
          <i className=" ml-2 fa fa-inr mr-2"></i>
          {priceCalculation.totalMRP}
        </h4>
      </div>
      <div className="row">
        <h4 className="col-md-8 col-8 head-p float-left ">Discount on MRP</h4>
        <h4 className="col-md-4 col-4 amount-p float-right text-success">
          -<i className=" ml-2 fa fa-inr mr-2 "></i>
          {priceCalculation.totalDiscountAmount}
        </h4>
      </div>
      {priceCalculation.serviceCharge ? (
        <div className="row">
          <h4 className="col-md-8 col-8 head-p float-left ">
            Delivery Charges
          </h4>
          <h4 className="col-md-4 col-4 amount-p float-right">
            +<i className=" ml-2 fa fa-inr mr-2 "></i>
            {Number(priceCalculation.serviceCharge).toFixed(1)}
          </h4>
        </div>
      ) : null}
      {couponDiscount > 0 && priceCalculation.couponDiscount ? (
        <div className="row">
          <h4 className="col-md-8 col-8 head-p float-left ">Coupon Discount</h4>
          <h4
            className="col-md-4 col-4 amount-p float-right text-success"
            style={{ fontSize: "12px" }}
          >
            -<i className=" ml-2 fa fa-inr mr-2 "></i>
            {priceCalculation.couponDiscount}
          </h4>
        </div>
      ) : null}
      <div className="row">
        <h4 className="col-md-8 col-8 head-p float-left">
          Convenience Fee{' '} 
          <span
            onClick={toggleConvenieceFeeModal}
            className="conveniece-fee-know-more">
            Know More
          </span>
        </h4>
        <h4 className="col-md-4 col-4 amount-p float-right ">
          <i className=" ml-2 fa fa-inr mr-2"></i>
          <span className="cross-p">99</span>
          <span className="text-success">FREE</span>
        </h4>
      </div>
      <hr />
      <div className="row total-amount mb-3">
        <h4 className="col-md-8 col-8 head float-left">Total Amount</h4>
        <h4 className="col-md-4 col-4 amount">
          <i className=" ml-2 fa fa-inr mr-2"></i>
          {priceCalculation.total}
        </h4>
      </div>
    </>
  );
}
