// import React from 'react';
// import { Link } from 'react-router-dom';

// export default function Branding() {
//   return (
//     <div style={{ marginLeft: "40px", marginRight: "40px", marginTop: "20px", marginBottom: "35px", textAlign: "justify", textJustify: "inter-word" }}>
//       <h2 className="text-center">Digital Branding</h2>
//       <p>DIGITAL BRANDING is a process of creating a brand over digital platforms. A slow yet reliable process of making your presence felt online.</p>
//       <p>Are you interested to digitally promote your brand? Are you willing to take the extra leap that your competitors haven’t yet? Are you ready to embrace the change?</p>
//       <p>You are at the right place if your response is positive to the above questions.</p>
//       <p> <Link to="/requirement" style={{ color: 'red',  cursor: 'pointer' }}>Contact</Link> Mayifind now for your digital branding solutions.</p>
//       <img src="assets/images/BrandingMayifind.png" />

//       <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
//         <div className="text-center" style={{ marginRight: '10px' }}>
//           <Link to="/" className="btn btn-primary btn-framed btn-rounded width-8">Home <span><i className='fa fa-home'></i></span></Link>
//         </div>

//         <div className="text-center">
//           <Link to="/requirement" className="btn btn-primary btn-framed btn-rounded width-8">Contact<span><i className='fa fa-phone'></i></span></Link>
//         </div>
//       </div>
//     </div>
//   );
// }

// import React from 'react';
// import { Link } from 'react-router-dom';

// export default function Branding() {
//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
//       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
//         <div style={{ marginRight: '20px' }}>
//         <img src="assets/images/BrandingMayifind.png"  alt="Digital Branding" style={{ maxWidth: '100%', height: 'auto' }} />
//         </div>
//         <div>
//           <h1>Digital Branding</h1>
//           <p>
//             DIGITAL BRANDING is a process of creating a brand over digital platforms.
//             A slow yet reliable process of making your presence felt online.
//           </p>
//           <p>
//             Are you interested to digitally promote your brand?
//             Are you willing to take the extra leap that your competitors haven’t yet?
//             Are you ready to embrace the change?
//           </p>
//           <p>You are at the right place if your response is positive to the above questions.</p>
//           <p>
//             <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
//               <Link to="/requirement">Contact</Link>
//             </span>
//             {' '}Mayifind now for your digital branding solutions.
//           </p>
//         </div>
//       </div>

//       <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
//         <div className="text-center" style={{ marginRight: '10px' }}>
//           <Link to="/" className="btn btn-primary btn-framed btn-rounded width-8">Home <span><i className='fa fa-home'></i></span></Link>
//         </div>

//         <div className="text-center">
//           <Link to="/requirement" className="btn btn-primary btn-framed btn-rounded width-8">Contact <span><i className='fa fa-home'></i></span></Link>
//         </div>
//       </div>
//     </div>
//   );
// }

import React from 'react';
import { Link } from 'react-router-dom';

export default function Branding() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', paddingTop: '40px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', marginLeft: '40px' }}>
        <div style={{ marginRight: '20px' }}>
          <img src="assets/images/BrandingMayifind.png" alt="Digital Branding" style={{ maxWidth: '100%', height: 'auto' }} />
        </div>
        <div>
          <h2>Digital Branding</h2>
          <p>
            DIGITAL BRANDING is a process of creating a brand over digital platforms.
            A slow yet reliable process of making your presence felt online.
          </p>
          <p>
            Are you interested to digitally promote your brand?
            Are you willing to take the extra leap that your competitors haven’t yet?
            Are you ready to embrace the change?
          </p>
          {/* <p>You are at the right place if your response is positive to the above questions.</p>
          <p style={{ color: 'red',  cursor: 'pointer' }}>
            <span style={{ color: 'red',  cursor: 'pointer' }}>
              <Link to="/requirement">Contact</Link>
            </span>
            {' '}Mayifind now for your digital branding solutions.
          </p> */}
          <p> <Link to="/requirement" style={{ color: 'red', cursor: 'pointer' }}>Contact</Link> Mayifind now for your digital branding solutions.</p>

        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <div className="text-center" style={{ marginRight: '10px' }}>
          <Link to="/" className="btn btn-primary btn-framed btn-rounded width-8">Home <span><i className='fa fa-home'></i></span></Link>
        </div>

        <div className="text-center">
          <Link to="/requirement" className="btn btn-primary btn-framed btn-rounded width-8">Contact <span><i className='fa fa-phone'></i></span></Link>
        </div>
      </div>
    </div>
  );
}
