import React, { useState } from 'react'
import PageTitle from '../components/PageTitle'
import Footer from '../layouts/Footer'
import OtpInput from "react-otp-input";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { apiUrl } from '../constants/defaultValue';

export default function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(undefined);
    const [confirmPassword, setConfirmPassword] = useState(undefined);
    const [phone, setPhone] = useState(undefined);
    const [status, setStatus] = useState(0);
    const [otp, setOtp] = useState(undefined);
    const [error, setError] = useState({ phoneError: '', passwordError: '', emailError: '', confirmPasswordError: '' });
    const navigate = useNavigate();
    const validate = () => {
        let phoneError = "";
        let passwordError = "";
        let emailError = "";
        let confirmPasswordError = "";
        const reg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const regPass = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        const emailPass = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
        if (!phone || reg.test(phone) === false) {
            phoneError = "Phone Field is Invalid ";
        }
        if (!email || emailPass.test(email) === false) {
            emailError = "Email is Invalid";
        }
        if (!password) {
            passwordError = "Password field is required";
        }
        else if (!regPass.test(password)) {
            passwordError = "password should contain atleast one number and one special character ";
        }
        if (!confirmPassword) {
            confirmPasswordError = "Confirm Password field is required";
        } else if (password !== confirmPassword) {
            confirmPasswordError = "Password and Confirm Password should be same";
        }
        if (phoneError || passwordError || emailError || confirmPasswordError) {
            setError({ phoneError, passwordError, emailError, confirmPasswordError });
            return false;
        }
        return true;
    }
    const handelEmail = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
    }
    const handelPhone = (e) => {
        e.preventDefault();
        setPhone(e.target.value);
    }
    const handelPassword = (e) => {
        e.preventDefault();
        setPassword(e.target.value);
    }
    const handelConfirmPassword = (e) => {
        e.preventDefault();
        setConfirmPassword(e.target.value);
    }
    const handelSubmit = (e) => {
        e.preventDefault();

        axios.post(`${apiUrl}/users/validateOtp`, {
            phoneNumber: phone,
            otp: otp,
            api_key: "registeruser"

        }).then((res) => {
            // console.log(res);

            if (res.data.code === 200) {
                setTimeout(() => {
                    //  <Navigate replace to="/login" />
                }, 2000);

                submitData();
            }
            Store.addNotification({
                title: res.data.message,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }).catch((err) => {
            // console.log(err);
            Store.addNotification({
                title: err.response.data.message,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        })

    }
    const submitData = () => {

        axios.post(`${apiUrl}/users/userRegister`, {
            email: email,
            password: password,
            phoneNumber: phone,
            api_key: "registeruser",
            role: "user"
        }).then(function (response) {
            // console.log(response);
            Store.addNotification({
                title: response.data.message,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });

        })
            .catch(function (error) {
                // console.log(error.response.data.message);
                Store.addNotification({
                    title: error.response.data.message,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            });
    }
    const handelOtp = (e) => {
        e.preventDefault();
        // console.log(phone, email);
        if (validate()) {
            axios.post(`${apiUrl}/users/generateOtpByUser`, {
                phoneNumber: phone,
                email: email,
                api_key: "registeruser"
            })
                .then(function (response) {
                    // console.log(response.data.message);
                    // console.log(response.data);
                    if (response.data.code === 200) {
                        setStatus(1);
                    
                    Store.addNotification({
                        title: response.data.message,
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
                })
                .catch(function (error) {
                    // console.log(error.response.data.message);
                    Store.addNotification({
                        title: error.response.data.message,
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                    if(error.response.status===409){
                        setTimeout(() => {
                            navigate(`/login`);        
                        }, 3000);
                    }

                });
            setError({ phoneError: '', passwordError: '', emailError: '', confirmPasswordError: '' });
        }
    }
    // let navigate = useNavigate();
    const register = () => {
        navigate(`/login`);
    }

    // *********************************Button Disable************************************************
    // const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);

    // const handleLoginSubmit = (event) => {
    //     event.preventDefault();
    //     setIsRegisterDisabled(true);
    // };

    return (
        <>
            <ReactNotifications />
            <PageTitle />
            <section className="content">
                <section className="block">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">

                                {(() => {
                                    switch (status) {
                                        case 1:
                                            return (
                                                <form className="form clearfix">
                                                    <div className="form-group">
                                                        <label htmlFor="what" className="col-form-label" style={{ fontSize: "17px" }}>Your Mobile Number</label>
                                                        <input name="PhoneNumber" value={phone} type="text" className="form-control" disabled />
                                                    </div>

                                                    <br /><br />
                                                    <div className="form-group">
                                                        <label htmlFor="what" className="col-form-label" style={{ fontSize: "17px" }}>Enter Verification Code</label>
                                                        <OtpInput
                                                            onChange={setOtp}
                                                            numInputs={6}
                                                            separator={<span >-</span>
                                                            } inputStyle={{
                                                                width: "4.2rem",
                                                                height: "5rem",

                                                                fontSize: "1.2rem",
                                                                borderRadius: 4,
                                                                color: "black",
                                                                border: "1px solid rgba(0,0,0,0.3)"
                                                            }}

                                                            value={otp}
                                                        />
                                                    </div>
                                                    <div className="text-center">
                                                        <button type="submit" onClick={e => { handelSubmit(e); register() }} className="btn btn-primary">Validate</button>
                                                    </div>

                                                </form>
                                            )
                                            // break;
                                        default:
                                            return (
                                                <form className="form clearfix">
                                                    <div className="form-group">
                                                        <label htmlFor="email" className="col-form-label required ">Email</label>
                                                        <input name="email" type="email" value={email} onChange={(e) => (handelEmail(e))} className="form-control" placeholder="Your Email" required />
                                                        <span className="text-danger">{error.emailError}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="phone" className="col-form-label required ">Phone Number</label>
                                                        <input name="phone" type="tel" value={phone} onChange={(e) => (handelPhone(e))} className="form-control" placeholder="Your Phone Number" required />
                                                        <span className="text-danger">{error.phoneError}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="password" className="col-form-label required">Password</label>
                                                        <input name="password" type="password" value={password} onChange={(e) => (handelPassword(e))} className="form-control" placeholder="Password" required />
                                                        <span className="text-danger">{error.passwordError}</span>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="repeat_password" className="col-form-label required">Repeat Password</label>
                                                        <input name="repeat_password" type="password" value={confirmPassword} onChange={(e) => (handelConfirmPassword(e))} className="form-control" placeholder="Repeat Password" required />
                                                        <span className="text-danger">{error.confirmPasswordError}</span>
                                                    </div>
                                                    <div className="text-center">
                                                        <button type="submit" onClick={e => handelOtp(e)} className="btn btn-primary">Submit</button>
                                                    </div>

                                                </form>
                                            )
                                            // break;
                                    }
                                })()}
                                <hr />
                                <p>
                                    Already have and account ? <Link to='/login' className="link">Sign in Now</Link>
                                </p>
                                <p>
                                    By clicking "Register" button, you agree with our <a href="/" className="link">Terms & Conditions.</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </>
    )
}
