import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';

export default function PhonepePaymentSuccess() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const navigate = useNavigate();

  useEffect(() => {
  localStorage.removeItem("paymentDetails");
  localStorage.removeItem("CartPrice");
  localStorage.removeItem('coupon_d');
  localStorage.removeItem('serv_char');

    setModalIsOpen(true);
    const timer = setTimeout(() => {
      redirectToHome();
    }, 13000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const redirectToHome = () => {
    navigate('/');
  };

  const handleClose = () => {
    setModalIsOpen(false);
    redirectToHome();
  };
  return (
    <div className="payment-container">
      {modalIsOpen && <Confetti width={width} height={height} />}
      <div className="payment-content">
        <div className="icon-container success-icon">
          <i className="fas fa-check-circle"></i>
        </div>
        <h4 className="heading">Payment Successful</h4>
        <p className="message">Your payment has been processed successfully!</p>
        <button 
          onClick={handleClose} 
          className="button"
        >
          Close
        </button>
      </div>
    </div>
  );
}