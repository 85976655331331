import React,{useState} from 'react';
import { Modal,ModalHeader,ModalBody } from 'reactstrap';
import { callApi } from '../../utils/Api';

export default function AddNewAddress({addAddressModal, toggleAddAddressModal ,closeAddBtn}) {
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [PinCode, setPinCode] = useState("");
    const [address, setAddress] = useState("");
    const [locality, setLocality] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [addressType, setAddressType] = useState("");
    const [country, setCountry] = useState("");
    const [defalutAddress, setDefalutAddress] = useState(false);
    const addAddresshandel = (e) => {
        e.preventDefault();
        const data = {
          Name: name,
          phoneNumber: phoneNumber,
          PinCode: PinCode,
          Address: address,
          Locality: locality,
          City: city,
          State: state,
          Country: country,
          AddressType: addressType,
          isActive: false,
          isDefault: defalutAddress,
          user: localStorage.getItem("userid"),
        };
        callApi("post", "cart/cartcrud/addAddress", data)
          .then((res) => {
            // console.log(res);
            setName("");
            setPhoneNumber("");
            setPinCode("");
            setAddress("");
            setLocality("");
            setCity("");
            setState("");
            setCountry("");
            setAddressType("");
            setDefalutAddress(false);
            toggleAddAddressModal()
          })
          .catch((err) => {
            console.log(err);
          });
      };
    // console.log("first,",addAddressModal, toggleAddAddressModal ,closeAddBtn)
  return (<Modal isOpen={addAddressModal} toggle={toggleAddAddressModal} >
    <ModalHeader toggle={toggleAddAddressModal} close={closeAddBtn}>
      ADD NEW ADDRESS
    </ModalHeader>
    <ModalBody>
      <div className="card shadow-sm p-2 bg-white rounded">
        <div className="card-body mt-3 ml-3 mb-2">
          <form className="form clearfix">
            <h4 className="payment-header-price">CONTACT DETAILS</h4>
            <div className="form-group">
              <input
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Name*"
                required
              />
            </div>
            <div className="form-group">
              <input
                name="phone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                type="tel"
                className="form-control"
                placeholder="Phone Number*"
                required
              />
            </div>
            <h4 className="payment-header-price">ADDRESS</h4>
            <div className="form-group">
              <input
                name="pincode"
                value={PinCode}
                onChange={(e) => setPinCode(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Your Pincode*"
                required
              />
            </div>
            <div className="form-group">
              <input
                name="address"
                value={address}
                type="text"
                onChange={(e) => setAddress(e.target.value)}
                className="form-control"
                placeholder="Your Address*"
                required
              />
            </div>
            <div className="form-group">
              <input
                name="locality"
                value={locality}
                onChange={(e) => setLocality(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Your Locality/Town*"
                required
              />
            </div>
            <div className="row">
              <div className="col-md-6 col-6">
                <div className="form-group">
                  <input
                    name="city"
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="form-control"
                    placeholder="Your City*"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 col-6">
                <div className="form-group">
                  <input
                    name="state"
                    type="text"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    className="form-control"
                    placeholder="Your State*"
                    required
                  />
                </div>
                <div className="form-group">
                <input
                  name="country" 
                  value={country} 
                  onChange={(e) => setCountry(e.target.value)} 
                  type="text"
                  className="form-control"
                  placeholder="Your Country*" 
                    required
                  />
                </div>
              </div>
            </div>
            <h4 className="payment-header-price">SAVE ADDRESS AS</h4>
            <div className="row">
              <div className="col-md-3 col-6">
                <div className="form-check">
                  <input
                    className="form-check-input mr-3"
                    onChange={(e) => setAddressType(e.target.value)}
                    checked={addressType === "Home"}
                    value="Home"
                    type="radio"
                    name="home"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Home
                  </label>
                </div>
              </div>
              <div className="col-md-3 col-6">
                {" "}
                <div className="form-check">
                  <input
                    className="form-check-input mr-3"
                    value="Work"
                    onChange={(e) => setAddressType(e.target.value)}
                    checked={addressType === "Work"}
                    type="radio"
                    name="work"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    Work
                  </label>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>

            <br />
            <label>
              <input
                type="checkbox"
                onChange={(e) => setDefalutAddress(!defalutAddress)}
                className="mr-3"
                name="remember"
                value="1"
              />
              Make This my Default Address
            </label>
            <button
              type="submit"
              className="btn mt-4 btn-primary width-100"
              onClick={(e) => addAddresshandel(e)}
            >
              Add address
            </button>
          </form>
        </div>
      </div>
    </ModalBody>
  </Modal>
  )
}
