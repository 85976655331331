import { Offer_Modal_Controller } from '../actions/actionType';

const initialState = {
    isUserViewed:false,
    offerModalOpen:false
  };

  const offerModalReducer = (state = initialState, action) => {
    switch (action.type) {
      case Offer_Modal_Controller:
        return {
          ...state,
          isUserViewed:true,
          offerModalOpen:action.payload,
        };
      default:
        return state;
    }
  };
  
  export default offerModalReducer;
  