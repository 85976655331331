import React, { useState,useEffect } from 'react';
import PageTitle from '../components/PageTitle';
import Footer from '../layouts/Footer';
import axios from 'axios';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { apiUrl } from '../constants/defaultValue';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function ResetPassword() {
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [token, setToken] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state && location.state.token) {
            setToken(location.state.token);
        }
        else{
            navigate('/forgot-password')
        }
    }, [location.state]);

    const validatePassword = () => {
        if (password.length < 6) {
            setError("Password must be at least 6 characters long.");
            return false;
        }
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return false;
        }
        setError('');
        return true;
    }

    const handlePasswordChange = (e) => {
        e.preventDefault();
        setPassword(e.target.value);
    }

    const handleConfirmPasswordChange = (e) => {
        e.preventDefault();
        setConfirmPassword(e.target.value);
    }

    const handleSubmitPassword = (e) => {
        e.preventDefault();
        if (validatePassword()) {
            axios.post(`${apiUrl}/users/reset-password`, {newPassword: password},
                { headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            ).then(function (response) {
                // console.log(response);
                Store.addNotification({
                    title: "Password Reset Successful",
                    message: "Your password has been reset. Please log in with your new password.",
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                navigate('/login');
            }).catch(function (error) {
                // console.log(error);
                setError("Failed to reset password. Please try again.");
                Store.addNotification({
                    title: "Failed to reset password",
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            });
        }
    }

    return (
        <>
            <ReactNotifications />
            <PageTitle title="Reset Password" />
            <section className="content">
                <section className="block">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <form className="form clearfix">
                                    <div className="form-group">
                                        <label htmlFor="password" className="col-form-label required">New Password</label>
                                        <input name="password" type={showPassword ? "text" : "password"} value={password} onChange={(e) => { handlePasswordChange(e) }} className="form-control" placeholder="New Password" required />
                                        <i onClick={() => setShowPassword(!showPassword)} className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`} style={{position: 'absolute', right: '10px', top: '70%', transform: 'translateY(-50%)', cursor: 'pointer'}}></i>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="confirmPassword" className="col-form-label required">Confirm Password</label>
                                        <input name="confirmPassword" type={showPassword ? "text" : "password"} value={confirmPassword} onChange={(e) => { handleConfirmPasswordChange(e) }} className="form-control" placeholder="Confirm Password" required />
                                        <i onClick={() => setShowPassword(!showPassword)} className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`} style={{position: 'absolute', right: '10px', top: '70%', transform: 'translateY(-50%)', cursor: 'pointer'}}></i>
                                        <span className="text-danger">{error}</span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <button type="submit" onClick={(e) => { handleSubmitPassword(e) }} className="btn btn-primary">Reset Password</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </>
    );
}
