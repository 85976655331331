import React from 'react'
import Nav from '../components/NavBar/Nav'
import PrimaryNav from '../components/NavBar/PrimaryNav'
import ProductPage from '../components/ProductDetails/ProductPage'
import Footer from '../layouts/Footer'
import PageTitle from '../components/PageTitle'
export default function ProductDetails() {
    return (
        <>
        <PageTitle />
           
            <ProductPage/>
            <Footer/>
        </>
    )
}
