import React from 'react'
import Footer from '../layouts/Footer'
import PageTitle from '../components/PageTitle'
import { useState,useEffect } from 'react';
import { callApi } from '../utils/Api';


export default function PaymentSucess() {
    const [currentDate, setCurrentDate] = useState(getCurrentDate());
    const [bankTransections, setBankTransections] = useState();
    // const [mostRecentTransection, setMostRecentTransection] = useState();
    const [mostRecentTransaction, SetmostRecentTransaction] = useState();


    const userId = localStorage.getItem('userid')
    function getCurrentDate() {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = now.getFullYear();
        return `${day}/${month}/${year}`;
      }
    
      // const [currentDate, setCurrentDate] = useState(getCurrentDate());
    
      useEffect(() => {
        const interval = setInterval(() => {
          setCurrentDate(getCurrentDate());
        }, 60000); // Update the date every minute (optional)
    
        return () => clearInterval(interval);
      }, []);

    //   const bankTransection = ()=>{
    //     callApi('post', 'pay/payu/findByBillerId', { id: userId }).then(res => {
    //         // console.log(res.data);
    //         if (res.data) {
    //             setBankTransections(res.data)
    //             // localStorage.setItem('UserData', JSON.stringify(res.data));
    //         }
    //     }).catch(err => { console.log(err); })
    // }

    // useEffect(() => {
    //   bankTransection();
    // }, [])

    // const bankTransection = () => {
    //   callApi('post', 'pay/payu/findByBillerId', { id: userId }).then(res => {
    //     // console.log(res.data);
    //     if (res.data && Array.isArray(res.data) && res.data.length > 0) {
    //       setBankTransections(res.data);
    //       // Access the first data object
    //       const firstData = res.data[0];
    //       console.log("First data object:", firstData);
    //       // localStorage.setItem('UserData', JSON.stringify(res.data));
    //     }
    //   }).catch(err => { console.log(err); });
    // }
    // const bankTransection = () => {
    //   callApi('post', 'pay/payu/findByBillerId', { id: userId })
    //     .then((res) => {
    //       if (res.data && Array.isArray(res.data) && res.data.length > 0) {
    //         setBankTransections(res.data);
    
    //         // Find the most recent bank transaction
    //         const mostRecentTransactions = res.data[0];
    //         setMostRecentTransection(mostRecentTransactions)
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };
    const bankTransaction = () => {
      callApi('post', 'pay/payu/findByBillerId', { id: userId })
        .then((res) => {
          if (res.data && Array.isArray(res.data) && res.data.length > 0) {
            setBankTransections(res.data);
  
            // Find the most recent bank transaction (last item in the array)
            const mostRecentTransaction = res.data[res.data.length - 1];
            console.log("Most Rcnt Trns",mostRecentTransaction)
            SetmostRecentTransaction(mostRecentTransaction);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    useEffect(() => {
      bankTransaction();
    }, []);
    
    // useEffect(() => {
    //   bankTransection();
    //   console.log("Most Recent Bank Transaction:", mostRecentTransection);
    // }, []);
  return (
    
    <section class="h-100 h-custom" style={{backgroundColor: "#eee"}}>
        <PageTitle/>
    <div class="container py-5 h-100">
    <div>
        {bankTransections && bankTransections.length > 0 && (
          
          <div>
            <p>Transection ID: {bankTransections[0]._id}</p>
            
          </div>
        )}
    </div>
    {bankTransections && bankTransections.length > 0 && (
   <div class="row d-flex justify-content-center align-items-center h-100">
   <div class="col-lg-8 col-xl-6">
     <div class="card border-top border-bottom border-3" style={{borderColor: "#72a89b "}}>
       <div class="card-body p-5">

         <p class="lead fw-bold mb-5" style={{ color: "#5a3841"}}>Purchase Reciept</p>

         <div class="row">
           <div class="col mb-3">
             <p class="small text-muted mb-1">Date</p>
             <p>{currentDate}</p>
           </div>
           <div class="col mb-3">
             <p class="small text-muted mb-1">Order No.</p>
             <p>{mostRecentTransaction.orderId}</p>
           </div>
         </div>

         {mostRecentTransaction.paymentStatus==="Success"?<div class="mx-n5 px-5 py-4" style={{backgroundColor: "#f2f2f2"}}>
           <div className="row">
             <div class="col-md-8 col-lg-9">
               <p>Billing Name</p>
             </div>
             <div className="col-md-4 col-lg-3">
               <p>{mostRecentTransaction.userName}</p>
             </div>
           </div>
           <div class="row">
             <div className="col-md-8 col-lg-9">
               <p class="mb-0">Total Amount Paid</p>
             </div>
             <div className="col-md-4 col-lg-3">
               <p className="mb-0"><span>{mostRecentTransaction.totalAmount}</span></p>
             </div>
           </div>
         </div>
         :<div className="mx-n5 px-5 " style={{backgroundColor: "#f2f2f2"}}>
          <div className='row pt-4'>
            <h3 className='payMentFailed'>Payment Failed</h3>
          </div>
          </div>}


         <div className="row my-4">
          <div className="col-md-8 col-lg-9">
               <p className="mb-0">Total Amount</p>
             </div>
           <div className="col-md-4 col-lg-3">
             <p className="mb-0" style= {{color: "#72a89b"}}>Rs. <span>{mostRecentTransaction.totalAmount}</span></p>
           </div>
         </div>

         {/* <p className="lead fw-bold mb-4 pb-2" style={{color:"#72a89b"}}>Thank you For Ordering From MayIfind</p> */}
        {mostRecentTransaction.paymentStatus==="Success"?<img src="/assets/images/successGif.gif"  alt="Success" style={{ width: "100%", maxWidth: "500px" }} />
         :<div className='paymentFailurCross'><i className="fa fa-times-circle " style={{fontSize:"200px",color:"red",marginBottom:"20px"}}></i></div>
        } 
          {/* <>
                        <Image src={SuccessGif} alt="Booking Success" />
                        <h1 className="text-success">Payment Successful.</h1>
                        <h6>Your Booking is CONFIRMED</h6>
          </> */}
          <p className="lead fw-bold mb-4 pb-2" style={{color:"#72a89b"}}>Thank you For Ordering From MayIfind</p>
         <p className="mt-4 pt-2 mb-0">Want any help? <a href="#!" style={{color: "#72a89b"}}>Please contact
             us</a><span><span>@Copyright MayIFind 2023</span></span></p>

       </div>

     </div>
   </div>
 </div>
    )}
   
      <Footer/>
    </div>
    
  </section>
  )
}
