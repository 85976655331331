import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../layouts/Footer'
import { Link,useNavigate } from 'react-router-dom';
import { callApi } from '../utils/Api';
import { Button } from 'reactstrap';
import QRCode from 'qrcode.react';
import PriceDetails from './CartComponet/PriceDetails';
import ConvenienceFeeModal from './CartComponet/ConvenienceFeeModal';
import { paymentMethodOption } from './PaymentComponets/PaymentMethodOptions';
export default function Payment() {
    const userid=localStorage.getItem("userid")
    const screenWidth = window.innerWidth;
    const paymentDetails=JSON.parse(localStorage.getItem("paymentDetails"))
    const navigate=useNavigate()
   if(!paymentDetails){
      navigate("/myCart")
  }
    const ccavenuePaymentDetails=paymentDetails?.ccavenu
    const phonePayPaymentDetails=paymentDetails?.phone_pay
    const ccavenueTransacrion=ccavenuePaymentDetails?.bankTransactionRequest
    const phonePayTransaction=phonePayPaymentDetails?.bankTransactionRequest
    const [paymentMethod,setPaymentMethod]=useState('PAY_PAGE')
    const [paymentSubMethod,SetPaymentSubMethod]=useState(paymentMethodOption.find(method => method.value === paymentMethod)?.subPaymentMethods||[])
    const [subPaymentMethod,setSubPaymentMethod]=useState("")
    const priceCalculation=JSON.parse(localStorage.getItem("CartPrice"));
    const couponDiscount=JSON.parse(localStorage.getItem('coupon_d'))
    const serviceCharge=JSON.parse(localStorage.getItem('serv_char'))
    const [checkedItems, setCheckedItems] = useState({}); // State to hold checked items
    const [cardNumber,setCardNumber]=useState('')
    const [nameOnCard,setNameOnCard]=useState('')
    const [cardValidMonth,setCardValidMonth]=useState('')
    const [cardValidYear,setCardValidYear]=useState('')
    const [cardCVV,setCardCVV]=useState('')
    const [loginOs,setLoginOs]=useState('');
    const [qrData,setQrData]=useState('')
    const [isBlurred, setIsBlurred] = useState(true);
    const [qrScanText,setQrScanText]=useState("Click on QR to scan")
    const [remainingSecond, setRemainingSeconnd] = useState(120)
    const [cardNumberError,setCardNumberError]=useState('')
    const [nameOnCardError,setNameOnCardError]=useState('')
    const [cardValidateMonthError,setCardValidateMonthError]=useState('')
    const [cardValidateYearError,setCardValidateYearError]=useState('')
    const [cardValidateCvvError,setCardValidateCvvError]=useState('')
    const toggleBlur = () => {
      setIsBlurred(false);
      setQrScanText("Scan QR Code to Make Payment")
      // clearQrData()
    };
    // const clearQrData=()=>{
    //   const timer = setTimeout(() => {
    //     setQrData('')
    //   }, 2 * 60 * 1000); // 2 minutes in milliseconds
  
    //   return () => clearTimeout(timer);
    // }
    const showTimer=(isBlurred,remainingSecond)=>{
      let timer;
      if (!isBlurred && remainingSecond > 0) {
        timer = setTimeout(() => {
          setRemainingSeconnd(remainingSecond - 1);
        }, 1000); // Decrease remaining time every second
      }
      return () => clearTimeout(timer);   
    }
    useEffect(() => {
      showTimer(isBlurred,remainingSecond)
      if(remainingSecond<=0){
        setQrData('')
      }
    }, [isBlurred, remainingSecond]);
    const handleCheckboxChange = (event) => {
      const { name, checked } = event.target;
      setCheckedItems({ [name]: checked });
    };
    const [convenieceFeeModal, setConvenieceFeeModal] = useState(false);
    const toggleConvenieceFeeModal = () =>setConvenieceFeeModal(!convenieceFeeModal);
    const closeConvineanceModal = (
        <button
          className="close"
          style={{ fontSize: "25px" }}
          onClick={toggleConvenieceFeeModal}
          type="button"
        >
          &times;
        </button>
      );
    const payByPhonePay=async()=>{
      const options = {
        method: 'post',
        url: `${phonePayTransaction?.PhonePayHostUrl}${phonePayTransaction?.payEndPoint}`,
        headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
              'X-VERIFY': phonePayTransaction?.xVerify
                      },
      data: {
          "request": phonePayTransaction?.base64EncodedPayload
      }
      };
      axios
        .request(options)
            .then(function (response) {
            console.log(response.data);
            const redirectUrl = response?.data?.data?.instrumentResponse?.redirectInfo?.url;
            if (redirectUrl) {
              window.location.href = redirectUrl; // Redirect to the provided URL
            } else {
              console.error('Redirect URL not found in the response');
            }
           
        })
        .catch(function (error) {
          console.error(error);
        });
    }
   const paybyccavenue=()=>{
    document.getElementById('nonseamless').submit();
   }         

  // useEffect(() => {
  //       if(paymentDetails?.encryptedOrderData && paymentDetails?.formUrl && paymentDetails?.access_code){
  //         console.log("console.log",paymentDetails)
  //         setTimeout(() => {
  //           console.log("paymentDetails",paymentDetails.encryptedOrderData)
  //           console.log("paymentDetails",paymentDetails.formUrl)
  //           console.log("paymentDetails",paymentDetails.access_code)
  //           // Router.push("https://test.ccavenue.com/transaction.do?command=initiateTransaction&encRequest=912b9ead4cd4fb1c20b0791683ff7a57e2e156e551e0cce217fd9e434824ab51bc5093b0757de89077aff7fdbc70f4c05a7aa7e9775036ebea3f4085c25420ddf210530afc0227d20643ffb2385536ec37f7a32884fe8bdcf5b34748d2b6a821f59cfddb1af513abac1cd9026ba52a8cf349ed754dc48fc39ee92266084159a99b33422b26e8effddb3905d93e00626850287017b97af710db92ed8b915c748632b928470377e919474b1ef5878589579f2c45849982ae1272ca6fdc95a225ccb3c6eeae1c1a6f97babad5d3d28abebfaab6342192d1ee2a3f868e1967494241057f5423600523b12d2cde21a501a4facfef5cb665e4d7bc581955fc1b622798ad443cb29758cce3d16a5d737856ab9cc1b15d26db81f8cb27fb4a9aefa3ccde6c2d1d5edf2351add67b6d7ed9074edc&access_code=AVBK51KD47BJ64KBJB")
  //             document.getElementById("nonseamless").submit();
  //         }, 1000);
  //       }
  //         // console.error(paymentDetails)
  //   }, [paymentDetails])

    

      
   
    const pay_now=()=>{
      setIsBlurred(true)
      if(paymentMethod==='emi'&& subPaymentMethod==='ccavenue' ){
        paybyccavenue()
      }
      if(['UPI_QR','NET_BANKING',"PAY_PAGE"].includes(paymentMethod)){
        callApi('post', 'pay/payu/pay_p_bill', { u_id:userid,p_m:paymentMethod,p_s_m:subPaymentMethod,o_id:ccavenueTransacrion.order_id })
      .then((res) => {
        const redirectUrl=res.data
        if(redirectUrl){
          window.location.href = redirectUrl
        // if(["Linux","Windows","Mac OS"].includes(loginOs)&&res.qrData){
        //   console.log("res.qrData",res.qrData)
        //   setQrData(res.data)
        //   setRemainingSeconnd(120)
        // }
        // // console.log(res.code);
        // else{
        //   const redirectUrl=res.data
        //   window.location.href = redirectUrl
        // }
        console.log("paymentPage")
      }
      })
      .catch((err) => {
        console.log(err);
      });
        // payByPhonePay()
      }
    } 
  const pay_bill_by_card=()=>{
    if(cardNumber&& nameOnCard && cardValidMonth && cardValidYear &&cardCVV){
      callApi('post', 'pay/payu/pay_p_by_card', { u_id:userid,p_m:paymentMethod,p_s_m:subPaymentMethod,o_id:ccavenueTransacrion.order_id,c_nu:cardNumber,c_nam:nameOnCard,c_v_m:cardValidMonth,c_v_y:cardValidYear,c_cvv:cardCVV })
      .then((res) => {
        // console.log(res.code);
        const redirectUrl=res.data
        window.location.href = redirectUrl
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }  
  // Get the user's operating system
//get all netbanking bank options
useEffect(()=>{
  // get_all_netBanking_bank()
  setLoginOs(getOS())
},[])
const get_all_netBanking_bank=()=>{
  callApi('post', 'pay/payu/get_phonepe_all_net_banking')
  .then((res) => {
    let allBanks=res.data.map((bank, index) => {
      return {
          index: index,
          value: bank.bankId,
          label: bank.bankShortName?bank.bankShortName:bank.bankName,    
            };
      });
    paymentMethodOption[2].subPaymentMethods=allBanks

  })
  .catch((err) => {
    console.log(err);
  });
}
const getOS = () => {
  const userAgent = window.navigator.userAgent
  const  platform = window.navigator.platform

  const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const  windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const  iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os = null;

  if (macPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }
  // setLoginOs(os)
  return os;
};

// Example usage
// const userOS = getOS();
// console.log('Operating System:',getOS());
const cardNumberValidate=(e)=>{
  let card_number=e.target.value
  if(Number.isInteger(parseInt(card_number))&&card_number.length<=16){
    setCardNumber(parseInt(card_number))
    setCardNumberError('')
  } 
  if(!Number.isInteger(parseInt(card_number))){
    setCardNumberError('Card number should be Number')
  }
  if(card_number===''){
    setCardNumber('')
    setCardNumberError("This field should not be empty")
  }
  

}
const validateNameonCard=(e)=>{
  let name_on_card=e.target.value
  setNameOnCard(name_on_card.toUpperCase())
  if(name_on_card===""){
    setNameOnCardError("This field is required")
  }else{
    setNameOnCardError("")
  }
}
const validateCardExpireMonth=(e)=>{
  let card_expire_month=e.target.value
  if(Number.isInteger(parseInt(card_expire_month))&& parseInt(card_expire_month)<=12 && card_expire_month.length<=2 ){
    setCardValidMonth(card_expire_month)
    setCardValidateMonthError('')
  }if(!Number.isInteger(parseInt(card_expire_month))){
    setCardValidateMonthError("Card validate Month should be integer value")
  }
  if(card_expire_month===""){
    setCardValidMonth("")
    setCardValidateMonthError("Card validate Month required")
  }

}
const validateCardExpireYear=(e)=>{
  let card_expire_year=e.target.value
  if(Number.isInteger(parseInt(card_expire_year))&& parseInt(card_expire_year)<=99 && card_expire_year.length<=2){
    setCardValidYear(card_expire_year)
    setCardValidateYearError('')
  }if(!Number.isInteger(parseInt(card_expire_year))){
    setCardValidateYearError("Card validate Year should be integer value")
  }
  if(card_expire_year===""){
    setCardValidYear("")
    setCardValidateYearError("Card validate Year required")
  }
}
const validateCardCvv=(e)=>{
  let card_cvv=e.target.value
  if(card_cvv.length<=3){
    setCardCVV(card_cvv)
  }
  if(card_cvv===""){
    setCardValidateCvvError("Card CVV required")
  }
  if(card_cvv.length===3){
    setCardValidateCvvError('')
  }
}
    return (!paymentDetails?<div className="container">
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
      <div className="col-md-6 text-center pt-10">
          <div className="alert alert-success text-center">
              <h4 className="alert-heading">Payment Successfull</h4>
          </div>
          <Link to='/'>Back to Home</Link>
      </div>
    </div>
  </div>:
        <section className="h-100 gradient-custom">
            <div style= {{ marginLeft: "40px", marginRight: "40px", marginTop: "20px", marginBottom: "35px", textAlign: "justify", textJustify: "inter-word" }}>
                <div className="row">
                  <div className="col-md-4 col-6">
                    <Link to="/" >
                        <img src="/assets/img/logo.png" className='logo' alt="" style={{height:"70px",width:"70px"}}/>
                    </Link>
                    </div>
                    {screenWidth>700&&<div className="col-md-4 col-0 mt-3">
                      <h1 className="text-center">Payment</h1>
                    </div>}
                    <div className="col-md-4 col-6 mt-5">
                      <h4 className="text-center">100% SECURE</h4>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                      <div className="col-md-7 col-12">
                        <h4 className="payment-header-price">
                        Choose Payment Mode
                        </h4>
                        <div className="card shadow-sm p-2 bg-white rounded">
                          <div className="card-body mt-3 ml-3 mb-2">
                          <div className="row">
                              <div className="col-md-6 col-6">
                                {paymentMethodOption.map((method,index)=>(
                              <button
                              key={index}
                                  type="button"
                                  style={{
                                    cursor:  'pointer',
                                    textAlign: 'left',
                                    overflow:'hidden',
                                  }}
                                  onClick={() =>{setPaymentMethod(method.value);
                                   setSubPaymentMethod("")
                                   setQrData('')
                                    SetPaymentSubMethod(paymentMethodOption.find(metho => metho.value === method.value)?.subPaymentMethods||[])
                                  } }
                                  className={`btn mt-2 mb-5 width-100 ${paymentMethod===method.value?"btn-primary":"btn-light"}`}
                                >
                                {method.lable}
                                </button>
                                ))}
                              </div>
                              <div className="col-md-6 col-6">
                               {paymentMethod==='emi'?<h4 className="payment-header-price">Select EMI option</h4>:paymentMethod==="PAY_PAGE"?<h4 className="payment-header-price">UPI/Card/Netbanking</h4>:<h4 className="payment-header-price">Pay using {paymentMethod==='UPI_QR'?<span className='text-uppercase'>UPI</span>:<span className='text-capitalize'>{paymentMethod}</span>}</h4>}
                               {paymentMethod!=="CARD"&&paymentSubMethod.map((submethod,index)=>(
                                <div className="row ml-3 mb-4" key={index}>
                                  <input type='radio' className='danger' value={submethod.value} name={submethod.value} checked={submethod.value===subPaymentMethod} onClick={()=>setSubPaymentMethod(submethod.value)}/><span className='ml-2'>{submethod.label}</span>
                                </div>
                               ))}
                               {paymentMethod==="CARD"&&
                               <div className="container">
                                <div className=" shadow-sm p-2 bg-white rounded">
                                  <div className="row">
                                    <input
                                    type="text"
                                    className="mr-1 mb-2"
                                    name="remember"
                                    value={cardNumber}
                                    placeholder='Card Number'
                                    onChange={(e)=>cardNumberValidate(e)}
                                    /> 
                                  </div>
                                  {cardNumberError&&<span className='conveniece-fee-know-more'>{cardNumberError}</span>}
                                </div>
                                <div className=" shadow-sm p-2 bg-white rounded">
                                  <div className="row">
                                    <input
                                    type="text"
                                    className="mr-1 mb-2"
                                    name="remember"
                                    value={nameOnCard}
                                    placeholder='Name on card'
                                    onChange={(e)=>validateNameonCard(e)}
                                    /> 
                                  </div>
                                  {nameOnCardError&&<span className='conveniece-fee-know-more'>{nameOnCardError}</span>}
                                </div>
                                <div className=" shadow-sm p-2 bg-white rounded">
                                  <div className="row">
                                    <div className="col-8">
                                      <div className="row">
                                    <div className="col-5">
                                    <input
                                    type="text"
                                    className="mr-1 mb-2"
                                    name="remember"
                                    value={cardValidMonth}
                                    placeholder='MM'
                                    onChange={(e)=>validateCardExpireMonth(e)}
                                    /> 
                                    </div>
                                    <div className="col-5">
                                    <input
                                    type="text"
                                    className="mr-1 mb-2"
                                    name="remember"
                                    value={cardValidYear}
                                    placeholder='YY'
                                    onChange={(e)=>validateCardExpireYear(e)}
                                    /> 
                                    </div>
                                    </div>
                                    </div>
                                    <div className="col-4">
                                    <input
                                    type="text"
                                    className="mr-1 mb-2"
                                    name="remember"
                                    value={cardCVV}
                                    placeholder='CVV'
                                    onChange={(e)=>validateCardCvv(e)}
                                    /> 
                                    </div>
                                  </div>
                                  {cardValidateMonthError&&<span className='conveniece-fee-know-more'>{cardValidateMonthError}<br></br></span>}
                                  {cardValidateYearError&&<span className='conveniece-fee-know-more'>{cardValidateYearError}<br></br></span>}
                                  {cardValidateCvvError&&<span className='conveniece-fee-know-more'>{cardValidateCvvError}</span>}
                                </div>
                                <button
                                  type="submit"
                                  style={{
                                    cursor:  'pointer',
                                  }}
                                  onClick={(e) => {
                                    pay_bill_by_card(e);
                                  }}
                                  className="btn btn-primary mt-1 width-100"
                                >
                                 Pay Now
                                </button>
                               </div>
                               }
                               <br></br>
                               {subPaymentMethod&& <button
                                  type="submit"
                                  style={{
                                    cursor:  'pointer',
                                  }}
                                  onClick={(e) => {
                                    pay_now(e);
                                  }}
                                  className="btn btn-primary mt-1 width-100"
                                >
                                 Pay Now
                                </button>}
                                {qrData&&<div className='m-2 d-flex flex-column align-items-center'>
                                  <div className='row mt-2'>
                                  <h4>{qrScanText}</h4>
                                  </div>
                                  <div className={`row mt-2 ${isBlurred?"blur-qrCode":"unBlur-qrCode"}`} onClick={toggleBlur}>
                                    <QRCode value={qrData} />       
                                  </div>
                                  <div className='row mt-2'>
                                  <h4>Expire in : <span className='conveniece-fee-know-more'>{remainingSecond}</span> s</h4>
                                  </div>
                                </div>}
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 col-12">
                      <div className="vline">
                      <PriceDetails
                            priceCalculation={priceCalculation}
                            serviceCharge={serviceCharge}
                            couponDiscount={couponDiscount}
                            toggleConvenieceFeeModal={toggleConvenieceFeeModal}
                          />
                      </div>
                      </div>
                  </div>    
{
  paymentDetails && (
    <form
      id="nonseamless"
      method="post"
      name="redirect"
      // formUrl:" https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction",// Live Url
       action={`https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encodeURIComponent(ccavenueTransacrion.encryptedOrderData)}&access_code=${encodeURIComponent(ccavenueTransacrion.access_code)}`}
    >
      <input type="hidden" id="encRequest" name="encRequest" value={ccavenueTransacrion?.encryptedOrderData} />
      <input type="hidden" name="access_code" id="access_code" value={ccavenueTransacrion?.access_code} />
    </form>
  )
}

{/* <script>
  document.addEventListener('DOMContentLoaded', function() {
    setTimeout(function() {
      document.getElementById('nonseamless').submit();
    }, 2000) 
  });
</script> */}
        </div>
        <ConvenienceFeeModal
                            convenieceFeeModal={convenieceFeeModal}
                            toggleConvenieceFeeModal={toggleConvenieceFeeModal}
                            closeConvineanceModal={closeConvineanceModal}
                          />
            <Footer />
        </section>




    )

}
