import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { callApi } from "../../utils/Api";
import { IoIosSettings } from "react-icons/io";
import { IoLogOut } from "react-icons/io5";
import { FaSignInAlt } from "react-icons/fa";
import { SiGnuprivacyguard } from "react-icons/si";
import { FaAddressCard } from "react-icons/fa";


export default function UserAccount({ handleSignOut, token, handleResponse}) {
    
    const [defalutUser,setDefaultAddress]=useState({})
    const [user,setUser]=useState({});
    const getAllAddress = () => {
        if(localStorage.getItem('userid') && localStorage.getItem("UserData")){
        setUser(JSON.parse(localStorage.getItem("UserData")))
        callApi('post', 'cart/cartcrud/getAddress', { userId: localStorage.getItem('userid') })
            .then((res) => {
              res.data.forEach((item) => {
                if (item.isDefault) {
                    setDefaultAddress(item);
                }
            });
            }).catch((err) => {
                console.log(err);
            })}
    }
    useEffect(()=>{
        getAllAddress();
    },[])
 
  return (
    // <div className="">
    <div className="user-account">
      <ul className="">
        {token ? (
          <>
           <li>
              <p>{defalutUser?.Name}</p>
            </li>
            <li>
              <p>{user?.email}</p>
            </li>
            <li>
              <p>{user?.phoneNumber}</p>
            </li>
            {/* <li>
              <p>pradhansujitkumar52@gmail.com</p>
            </li> */}
          </>
        ) : (
          <>
            <li>
              <Link to="/login">
                <p>
                  <FaSignInAlt />
                  <span>Sign In</span>
                </p>
              </Link>
            </li>
            <li>
              <Link
                to="/register"
                onClick={() =>
                  handleResponse({ message: "Created successfully" })
                }
              >
                <p>
                  <SiGnuprivacyguard />
                 <span>Sign Up</span> 
                </p>
              </Link>
            </li>
          </>
        )}

        <hr />
        <li>
          <Link to="/myOrder">
            <p>Order</p>
          </Link>
        </li>
        <li>
          <Link to="/wishlist">
            <p>Wishlist</p>
          </Link>
        </li>
        <li>
          <Link to="/my/profile">
            <p>Profile</p>
          </Link>
        </li>
        <li>
          <Link to="/my/address">
            <p>Address</p>
          </Link>
        </li>
        <hr />
        {token && (
          <li>
            <Link to="#" onClick={handleSignOut}>
              <p>
                <IoLogOut />
              <span>Logout</span>  
              </p>
            </Link>
          </li>
        )}
        <li>
          <Link to="/">
            <p>
              <IoIosSettings />
             <span>Settings</span>
            </p>
          </Link>
        </li>
        {/* <li>
          <a href="https://merchant.mayifind.com/app">
            <p>
              <FaAddressCard />
              <span>Seller Dashboard</span>
            </p>
          </a>
        </li> */}
      </ul>
    </div>
    // </div>
  );
}
