import React, { useState } from 'react'
import {Button, Row,ButtonGroup} from 'reactstrap'
import StarsRating from 'stars-rating'
import CropImageModal from '../imgUpload/CropImageModal'
import { Colxx } from '../imgUpload/CustomBootstrap';
import { callApi } from '../../utils/Api';
import { styles } from '../../utils/Loader'
import LoadingOverlay from 'react-loading-overlay';

export default function ReviewForm({ seller, getReviews }) {
    const [imageCropper, setImageCropper] = useState(false)
    const [aspectRatio, setAspectRatio] = useState(16 / 9);
    const [url, setUrl] = useState([])
    const [modalPurpose, setModalPurpose] = useState('')
    const [rating, setRating] = useState('');
    const [review, setReview] = useState('');
    const [reviewFormLoading, setReviewFormLoading] = useState(false);
    const setmodal = (showModal) => {
        setImageCropper(showModal)
    }
    const pull_data = (urlData, modalFor, varient) => {
        console.log(urlData, modalFor, 'all over data')
        if (modalFor == 'review') {
            setUrl([...url, { imgurl: urlData, aspectRatio: aspectRatio }])
        }
    }
    const handleAddClick = async () => {
        setReviewFormLoading(true)
        const data = {
            review: review,
            rating: rating,
            image: url,
            seller: seller,
            user: "631971c2f23eab52e63f2b9c"
        }
        callApi('post', `review/reviewCrud/addSellerReview`, data).then(res => {
            setReviewFormLoading(false)
            console.log(res, 'res');
        }).catch(err => {
            setReviewFormLoading(false)
            console.log(err);
        })
        getReviews();
        setReview('');
        setRating('');
        setUrl([]);
    }
    return (
        <>
            <LoadingOverlay
                active={reviewFormLoading}
                spinner
                styles={styles()}
            >
                <div className="review-box activity-loading">
                    <div className="row">
                        {/* <div className="col-md-5">
                            <div className="author">
                                <div className="author-image">
                                    <div className="background-image">
                                        <img src="/assets/img/author-01.jpg" alt="" />
                                    </div>
                                </div>

                                <div className="author-description">
                                    <h3>Jane Doe</h3>
                                    <div className="rating" data-rating="4"></div>
                                    <a href="seller-detail-1.html" className="text-uppercase">Show My Listings
                                        <span className="appendix">(12)</span>
                                    </a>
                                </div>

                            </div>
                            <hr />
                            <dl>
                                <dt>Phone</dt>
                                <dd>830-247-0930</dd>
                                <dt>Email</dt>
                                <dd>hijane@example.com</dd>
                            </dl>

                        </div> */}

                        <div className="col-md-7">
                            <div className="form email">
                                <div className="form-group">
                                    <label htmlFor="message" className="col-form-label">Message</label>
                                    <textarea value={review} name="review"
                                        onChange={(e) => setReview(e.target.value)} id="message" className="form-control" rows="4" placeholder="Hi there! It's a Good Product"></textarea>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label htmlFor="message" className="col-form-label">Ratings</label>
                                            <StarsRating
                                                count={5}
                                                value={rating}
                                                onChange={setRating}
                                                size={30}
                                                color2={'#ff0000'} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <Row>
                                                {url.map((x, i) => {
                                                    return (
                                                        <>
                                                            <Colxx xs="12" md="4" >
                                                                <img src={url[i].imgurl} width={100} height={100} className='m-2 d-block img-fluid' />
                                                            </Colxx>

                                                        </>
                                                    )
                                                })}
                                            </Row>
                                            <br />
                                            {/* <Button color='link' style={{ color: '#ff0000' }} htmlFor="message" className="col-form-label" onClick={() => (setImageCropper(!imageCropper), setModalPurpose('review'), setAspectRatio(1))}>Have photos to upload ?</Button><br /> */}
                                            <button type="submit" className="btn-review btn-primary" style={{ height: '30px' }} onClick={handleAddClick}>
                                                Submit
                                            </button>
                                            <Row>
                                                <Colxx xs="12" md="6">
                                                    <ButtonGroup className='pt-2 pb-2 d-flex justify-content-start'>
                                                    </ButtonGroup>
                                                    <CropImageModal modalActive={imageCropper} imageUrl={pull_data} getModalData={setmodal} selectedAspectRatio={aspectRatio} purpose={modalPurpose} />
                                                </Colxx>
                                                <Colxx xs="12" md="6">
                                                </Colxx>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    )
}
