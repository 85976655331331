import { ADD_TO_USER_ORDER,REMOVE_FROM_USER_ORDER,FETCH_USER_ORDER_REQUEST,FETCH_USER_ORDER_SUCCESS,FETCH_USER_ORDER_FAILURE } from "../actions/actionType";
const initialState={
    loading:true,
    userOrder:[],
    error:""
};
const userOrderReducer=(state=initialState, action)=>{
    switch(action.type) {
        case ADD_TO_USER_ORDER:
            return{
                ...state,
                userOrder: action.payload,
            };
        case FETCH_USER_ORDER_REQUEST:
            return {
                ...state,
                loading:true
            };
        case FETCH_USER_ORDER_SUCCESS:
            return{
                loading: false,
                userOrder: action.payload,
                error:'',
            };
        case FETCH_USER_ORDER_FAILURE:
            return{
                loading:false,
                userOrder:{},
                error: action.payload
            };
        case REMOVE_FROM_USER_ORDER:
            return {
                ...state,
                userOrder: state.userOrder
            };
        default:
            return state;
}
};
export default userOrderReducer;