
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { callApi } from '../../utils/Api';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import { Link } from 'react-router-dom';

export default function OfferModal({ offerModal, toggleOfferModal }) {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    if (offerModal) {
      const fetchBanners = async () => {
        try {
          const response = await callApi('post', 'ad/ads/getAllActiveBanners');
          const activeBanners = response?.data.filter(banner => banner.isBannerActive); // Filter all active banners
          if (activeBanners.length > 0) {
            setBanners(activeBanners);
          } else {
            toggleOfferModal()
          }

          // setBanners(response?.data);
          // console.log(response.data)
          // console.log(activeBanners)
        } catch (error) {
          console.error('Error fetching banners:', error);
        }
      };

      fetchBanners();
    }
  }, [offerModal]);

  const closeOfferBtn = (
    <button
      className="close"
      style={{ fontSize: '35px', color: '#FF0000', textDecoration: 'none' }}
      onClick={toggleOfferModal}
      type="button"
    >
      <i className="fa fa-close fa-1x"></i>
    </button>
  );
  return (
    <Modal
      isOpen={offerModal}
      toggle={toggleOfferModal}
      style={{ width: '35%', maxWidth: '40%', borderRadius: '10px', marginTop: '70px', overflow: 'hidden', }}
    >
      <ModalHeader
        close={closeOfferBtn}
        style={{ backgroundColor: 'transparent', border: 'none', position: 'absolute', top: 0, left: 0, right: 13, zIndex: 1, }}
      />
      <ModalBody style={{ padding: 0 }}>
        {banners.length > 0 && (
          <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay interval={2500} showStatus={false} showIndicators swipeable emulateTouch  >
            {banners
              .sort((a, b) => a.bannerOrder - b.bannerOrder)
              .map((banner) => (
                <Link to={`/offerdetails/${banner._id}`}>
                  <div key={banner._id} style={{ position: 'relative', height: '100%' }}>
                    <img
                      src={banner?.img?.imgurl}
                      alt={banner.bannerName}
                      style={{ width: '100%', height: '100%', objectFit: 'cover', }}
                    />
                    <div
                      style={{
                        position: 'absolute', bottom: '2%', left: '2%', right: '2%', height: '19%', backgroundColor: 'rgba(255, 0, 0, 0.75)', color: '#f0f0f0',
                        padding: '2%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '5px',
                        textDecoration: 'none', boxShadow: 'inset 1px 1px 5px black',
                      }}
                    >
                      <h5
                        style={{ margin: '0', fontWeight: 'bold', textAlign: 'center', }}
                      >
                        {banner.bannerName}
                      </h5>
                      <h6
                        style={{
                          margin: '5px 0 0', fontSize: '13px', overflow: 'hidden', textOverflow: 'ellipsis',
                          display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', whiteSpace: 'normal',
                        }}
                      >
                        {banner.description}
                      </h6>
                    </div>
                  </div>
                </Link>

              ))}
          </Carousel>
        )}
      </ModalBody>
    </Modal>
  );
}
