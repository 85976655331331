import { callApi } from "../utils/Api";
import { ADD_TO_USER_RETURN_ORDER,REMOVE_FROM_USER_RETURN_ORDER,FETCH_USER_RETURN_ORDER_REQUEST,FETCH_USER_RETURN_ORDER_SUCCESS,FETCH_USER_RETURN_ORDER_FAILURE } from "./actionType";

export const addToUserReturnOrder = (data)=>({
    type: ADD_TO_USER_RETURN_ORDER,
    payload: data,
});
export const reomveFromUserReturnOrder = (returnId)=>({
    type: REMOVE_FROM_USER_RETURN_ORDER,
    payload: returnId
});
export const fetchUserReturnOrderRequest = ()=>{
    return{
        type: FETCH_USER_RETURN_ORDER_REQUEST
    }
};
export const fetchUserReturnOrderSuccess=(data)=>{
    return{
        type: FETCH_USER_RETURN_ORDER_SUCCESS,
        payload: data,
    }
}
export const fetchUserReturnOrderFailur=(error)=>{
    return{
        type: FETCH_USER_RETURN_ORDER_FAILURE,
        payload: error
    }
}


//Fetch User Visited Data
export const fetchUserReturnOrders =(userId,returnOrderId)=>{
    return (dispatch)=>{
        dispatch(fetchUserReturnOrderRequest)
        callApi('post','returnOrder/returnCurd/returnOrderById',{userId:userId,returnOrderId:returnOrderId})
        .then(res=>{
            dispatch(fetchUserReturnOrderSuccess(res.data))
        }).catch(error=>{
            dispatch(fetchUserReturnOrderFailur(error))
        })
    }
}