import React from 'react'
import ListingGridSidebar from '../components/ListingGridSidebar'
import Nav from '../components/NavBar/Nav'
import PageTitle from '../components/PageTitle'
import PrimaryNav from '../components/NavBar/PrimaryNav'
import Footer from '../layouts/Footer'

export default function ListingGridWithSidebar() {
  return (
    <div>
        
      <header className="hero">
            <div className="hero-wrapper">
                <PrimaryNav/>
                {/* <PageTitle/> */}
                <Nav/>
            </div>
           
        </header>
        <ListingGridSidebar/>
        <Footer/>
    </div>
  )
}
