import React from 'react'
import { Modal,ModalHeader,ModalBody } from 'reactstrap'
export default function EditAddress({editAddModal,toggleEditAddModal,closeAddBtn,handelAdddressChange,uAddress,updateAddressData }) {
  // console.log("first0",editAddModal,toggleEditAddModal,closeAddBtn,handelAdddressChange,uAddress,updateAddressData)
  return (<Modal isOpen={editAddModal} toggle={toggleEditAddModal}>
                          <ModalHeader toggle={toggleEditAddModal} close={closeAddBtn}>
                            EDIT ADDRESS
                          </ModalHeader>
                          <ModalBody>
                            <div className="card shadow-sm p-2 bg-white rounded">
                              <div className="card-body mt-3 ml-3 mb-2">
                                <form className="form clearfix">
                                  <h4 className="payment-header-price">
                                    CONTACT DETAILS
                                  </h4>
                                  <div className="form-group">
                                    <input
                                      name="Name"
                                      value={uAddress.Name}
                                      onChange={(e) => handelAdddressChange(e)}
                                      type="text"
                                      className="form-control"
                                      placeholder="Name*"
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      name="phoneNumber"
                                      value={uAddress.phoneNumber}
                                      onChange={(e) => handelAdddressChange(e)}
                                      type="tel"
                                      className="form-control"
                                      placeholder="Phone Number*"
                                      required
                                    />
                                  </div>
                                  <h4 className="payment-header-price">
                                    ADDRESS
                                  </h4>
                                  <div className="form-group">
                                    <input
                                      name="PinCode"
                                      value={uAddress.PinCode}
                                      onChange={(e) => handelAdddressChange(e)}
                                      type="text"
                                      className="form-control"
                                      placeholder="Your Pincode*"
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      name="Address"
                                      value={uAddress.Address}
                                      type="text"
                                      onChange={(e) => handelAdddressChange(e)}
                                      className="form-control"
                                      placeholder="Your Address*"
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      name="Locality"
                                      value={uAddress.Locality}
                                      onChange={(e) => handelAdddressChange(e)}
                                      type="text"
                                      className="form-control"
                                      placeholder="Your Locality/Town*"
                                      required
                                    />
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 col-6">
                                      <div className="form-group">
                                        <input
                                          name="City"
                                          type="text"
                                          value={uAddress.City}
                                          onChange={(e) =>
                                            handelAdddressChange(e)
                                          }
                                          className="form-control"
                                          placeholder="Your City*"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-6">
                                      <div className="form-group">
                                        <input
                                          name="State"
                                          type="text"
                                          value={uAddress.State}
                                          onChange={(e) =>
                                            handelAdddressChange(e)
                                          }
                                          className="form-control"
                                          placeholder="Your State*"
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <input
                                      name="Country"
                                      value={uAddress.Country}
                                      onChange={(e) => handelAdddressChange(e)}
                                      type="text"
                                      className="form-control"
                                      placeholder="Your Country*"
                                      required
                                    />
                                  </div>
                                  <h4 className="payment-header-price">
                                    SAVE ADDRESS AS
                                  </h4>
                                  <div className="row">
                                    <div className="col-md-3 col-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input mr-3"
                                          onChange={(e) =>
                                            handelAdddressChange(e)
                                          }
                                          checked={
                                            uAddress.AddressType === "Home" &&
                                            true
                                          }
                                          value="Home"
                                          type="radio"
                                          name="AddressType"
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexRadioDefault1"
                                        >
                                          Home
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                      {" "}
                                      <div className="form-check">
                                        <input
                                          className="form-check-input mr-3"
                                          value="Work"
                                          onChange={(e) =>
                                            handelAdddressChange(e)
                                          }
                                          checked={
                                            uAddress.AddressType === "Work" &&
                                            true
                                          }
                                          type="radio"
                                          name="AddressType"
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexRadioDefault2"
                                        >
                                          Work
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6"></div>
                                  </div>

                                  <br />
                                  <label>
                                    <input
                                      type="checkbox"
                                      onChange={(e) => handelAdddressChange(e)}
                                      className="mr-3"
                                      name="isDefault"
                                      checked={uAddress.isDefault}
                                    />
                                    Make This my Default Address
                                  </label>
                                  <button
                                    type="submit"
                                    className="btn mt-4 btn-primary width-100"
                                    onClick={(e) => updateAddressData(e)}
                                  >
                                    Update address
                                  </button>
                                </form>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
  )
}
