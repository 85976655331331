import React from 'react'
import { Link } from 'react-router-dom'
export default function Refund() {
  return (
    <div style={{marginLeft:"40px",marginRight:"40px",marginTop:"20px",marginBottom:"35px",textAlign:"justify",textJustify:"inter-word"}}>
        <h1 className="text-center">Refund and Cancellation policy</h1>
        <li>
Most items purchased from sellers listed on Mayifind.com are returnable within the return window, except those that are explicitly identified as not returnable. The return is processed only if:</li>
<li>
it is determined that the product was not damaged while in your possession.</li>
<li>The product is not different from what was shipped to you.</li>
<li>
The product is returned in original condition (with brand’s/manufacturer's box, MRP tag intact, user manual, warranty card and accessories)
</li>
<li>For the products that are returned by the customer, the refund is issued to the original payment method (in case of pre-paid transactions) or to the bank account /the details for making such refund and the timelines are detailed in the refund policy available</li>
<ol>
<li value="1">
Applicable products are returnable within the applicable return window if you’ve received them in a condition that is physically damaged, has missing parts or accessories, defective or different from their description on the product detail page on Mayifind.com.</li>
<li>If you report an issue with your Smartphone, Tablet, Laptop, Television, Air Conditioner, Refrigerator, Washing Machine, Microwave, we may facilitate scheduling a technician visit to your location and a resolution will be provided based on the technician's evaluation report.</li>
<li>Return will be processed only if: </li></ol>
<ul>
<li>
it is determined that the product was not damaged while in your possession.</li>
<li>The product is not different from what was shipped to you.</li>
<li>The product is returned in original condition (with brand’s/manufacturer's box, MRP tag intact, user manual, warranty card and all the accessories therein).</li></ul>
<ol>
<li value="4">
If you wish to return an electronic device that stores any personal information, please ensure that you have removed all such personal information from the device prior to returning. Mayifind shall not be liable in any manner for any misuse or usage of such information.
</li>
<li>Products may not be eligible for return in some cases, including cases of buyer's remorse such as incorrect model or colour of product ordered or incorrect product ordered.</li>
<li>Products marked as "non-returnable" on the product detail page cannot be returned. However, in an unlikely event of damaged, defective or wrong item delivered to you, we will provide a full refund or replacement, as applicable. 
  We may contact you to ascertain the damage or defect in the product prior to issuing refund/replacement.
   We reserve the right to pick up the product to ascertain the damage or defect in the product prior to issuing refund/replacement.</li>
   <li>No additional information is required to return an eligible order unless otherwise noted in the category specific policy. </li>
   <li>Products may be eligible for replacement only if the same seller has the exact same item in stock. </li>
   <li>If the replacement request is placed and the seller does not have the exact same product in stock, a refund would be issued to you. </li>
   <li>Products purchased by international customers are not eligible for returns. However, orders made by international customers are eligible for refunds.</li>
    <li>In the event customers are found to misuse the return policy by excessively returning, or cancelling or not accepting the orders placed, Mayifind reserves the right to warn and/or suspend and/or block and/or terminate such customer accounts, as necessary.</li>
</ol>
<h4>Note:</h4> If you've received a non-returnable product in a damaged/defective condition, you can contact us within 07 days from the delivery of the product.
<br/>
<div className="text-center">
    <Link to="/" className="btn btn-primary btn-framed btn-rounded width-8">Home <span><i className='fa fa-home'></i></span></Link>
    </div>
<br/>
</div>
  )
}
