import { ADD_TO_USER_RETURN_ORDER,REMOVE_FROM_USER_RETURN_ORDER,FETCH_USER_RETURN_ORDER_REQUEST,FETCH_USER_RETURN_ORDER_SUCCESS,FETCH_USER_RETURN_ORDER_FAILURE } from "../actions/actionType";
const initialState={
    loading:true,
    userReturnOrder:{},
    error:""
};
const userReturnOrderReducer=(state=initialState, action)=>{
    switch(action.type) {
        case ADD_TO_USER_RETURN_ORDER:
            return{
                ...state,
                userReturnOrder: action.payload,
            };
        case FETCH_USER_RETURN_ORDER_REQUEST:
            return {
                ...state,
                loading:true
            };
        case FETCH_USER_RETURN_ORDER_SUCCESS:
            return{
                loading: false,
                userReturnOrder: action.payload,
                error:'',
            };
        case FETCH_USER_RETURN_ORDER_FAILURE:
            return{
                loading:false,
                userReturnOrder:{},
                error: action.payload
            };
        case REMOVE_FROM_USER_RETURN_ORDER:
            return {
                ...state,
                userReturnOrder: state.userOrder
            };
        default:
            return state;
}
};
export default userReturnOrderReducer;