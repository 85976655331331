import React from 'react'
import Category from '../components/Category'
import Listing from '../components/Listing'
import Footer from '../layouts/Footer'
import Header from '../layouts/Header/Header'
import TopSellProduct from '../components/TopSellProduct'
import Banner from '../components/Banner'


export default function Index2() {
  
  return (
    <div>
      <Header/>
      <Banner />
      <Category/>
      <TopSellProduct />
      <Listing/>
      <Footer/>
    </div>
  )
}
