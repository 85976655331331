import React,{useEffect,memo,useState} from 'react'
import Footer from '../layouts/Footer'
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { Link,useParams,useNavigate } from 'react-router-dom';
import PageTitle from '../components/PageTitle'
import { callApi } from '../utils/Api';
import { useDispatch,useSelector } from "react-redux";
import { fetchUserReturnOrders } from '../actions/userReturnOrderAction';
import ReturnOrderItems from './ReturnOrderComponet/ReturnOrderItems';
import RefundDetails from './ReturnOrderComponet/RefundDetails';
const ReturnOrder=()=> {
    const navigate=useNavigate()
    const userId=localStorage.getItem('userid')
    const dispatch=useDispatch()
    const userReturnOrder=useSelector(state=>state.userReturnOrder)
    const [returnOrderData,setReturnOrderData]=useState(userReturnOrder.userReturnOrder)

    const getData=(key)=> {
        const itemStr = sessionStorage.getItem(key);
        if (!itemStr) {
            navigate("/myOrder")
            return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > item.expiration) {
            sessionStorage.removeItem(key);
            return null;
        }
        return item.value;
    }
    useEffect(()=>{
       let returnOrderId= getData("returnOrderId")//||"66471b5b5b7f1824908a134d";
       if(userId && returnOrderId){
        dispatch(fetchUserReturnOrders(userId,returnOrderId))
       }
    },[])
    useEffect(() => {
        setReturnOrderData(userReturnOrder.userReturnOrder);
    }, [userReturnOrder]);
   
  return (<>
    <section className="h-100 gradient-custom w-100">
      <PageTitle/>
      <div className="container h-100">
            <div className="row">
                <div className="col-md-8 col-12">
                  <ReturnOrderItems returnOrderData={returnOrderData}/>  
                </div>
                <div className="col-md-4 col-12">
                    <div className="vline">
                      <RefundDetails returnOrderData={returnOrderData} />                
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer/>
    </>
  )
}
export default memo(ReturnOrder);
