import React, { useEffect, useState } from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import NewAdsCard from "../../components/Cards/NewAdsCard";
import { Button } from "reactstrap";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Link, useNavigate } from "react-router-dom";
import WishListButton from "../../components/ProductDetails/WishListButton";
import AddToCartButton from "./AddToCartButton";
import PageTitle from "../../components/PageTitle";
// import NotificationList from "../NotificationList";
import Footer from "../../layouts/Footer";
import { paramCase } from "change-case";
import { callApi } from "../../utils/Api";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserWishlist } from "../../actions/wishlistAction";

export default function WishListPage() {
  const dispatch = useDispatch();
  const userReducWishlist = useSelector((state) => state.wishlist);
  const products = userReducWishlist?.wishlists?.length
    ? userReducWishlist?.wishlists.filter((item) => item.product !== null).map((item) => item.product)
    : [];
  // console.log("first",products.length)
  const loading = userReducWishlist.loading;

  const [show, setShow] = useState(null);
  const navigate = useNavigate();
  if (!localStorage.getItem("userid")) {
    navigate("/login");
  }
  // const getProducts = () => {
  //   callApi("post", `product/getProduct/getWishlistById`, {
  //     user: localStorage.getItem("userid"),
  //   }).then((response) => {
  //     // console.log(response.data);
  //     let arr = [];
  //     response?.data?.forEach((item) => {
  //       // console.log(item.product);
  //       arr.push(item.product);
  //     });
  //     setProduct(arr);
  //     setTimeout(() => setLoading(false), 1000);
  //   });
  // };

  useEffect(() => {
    dispatch(fetchUserWishlist(localStorage.getItem("userid")));
  }, [dispatch]);

  const zoomOutProperties = {
    duration: 2000,
    transitionDuration: 600,
    infinite: true,
    indicators: false,
    arrows: false,
    autoplay: true,
  };
  const showDetails = (e) => {
    setShow(e);
  };
  const removeDetails = (e) => {
    setShow(null);
  };
  const addUserVisited = (p_Id) => {
    callApi("post", `product/getProduct/userVisited`, {
      userId: localStorage.getItem("userid") || null,
      productId: p_Id,
    })
      .then((response) => {
        // console.log(response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <PageTitle />
      {/* <NotificationList /> */}
      <section className="content">
        <section className="block">
          <div className="container">
            <div className="section-title clearfix">
              <div className="float-xl-right float-md-right float-sm-none">
                {/* <select
                  name="categories"
                  id="categories"
                  className="small width-200px"
                  data-placeholder="Category"
                >
                  <option value="">Category</option>
                  <option value="1">Computers</option>
                  <option value="2">Real Estate</option>
                  <option value="3">Cars & Motorcycles</option>
                  <option value="4">Furniture</option>
                  <option value="5">Pets & Animals</option>
                </select>
                <select
                  name="sorting"
                  id="sorting"
                  className="small width-200px"
                  data-placeholder="Default Sorting"
                >
                  <option value="">Default Sorting</option>
                  <option value="1">Newest First</option>
                  <option value="2">Oldest First</option>
                  <option value="3">Lowest Price First</option>
                  <option value="4">Highest Price First</option>
                </select> */}
              </div>
            </div>
            {loading ? (
              <ResponsiveMasonry
                columnsCountBreakPoints={{
                  250: 1,
                  350: 2,
                  750: 2,
                  900: 3,
                  1000: 4,
                  1200: 5,
                }}
                gutter={0}
              >
                <Masonry>
                  {[...Array(products.length || 10)].map((_, index) => (
                    <div key={index} className="card masonry-card">
                      <Skeleton height={220} />
                      <div className="container">
                            <div className="row">
                              <div className="col-md-6 col-6 mt-4">
                                <Skeleton width={50} height={20} />
                              </div>
                              <div className="col-md-3 col-3">
                                <Skeleton circle={true} height={30} width={30} />
                              </div>
                              <div className="col-md-3 col-3">
                                <Skeleton circle={true} height={30} width={30} />
                              </div>
                            </div>
                            <Skeleton count={3} />
                          </div>
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            ) : products.length === 0 ? (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <h2>YOUR WISHLIST IS EMPTY</h2>
                <p style={{ marginBottom: "10px" }}>
                  Discover your favorites and add them to your wishlist!
                </p>
                <div style={{ marginTop: "40px" }}>
                  <Button
                    tag={Link}
                    to="/"
                    color="primary"
                    style={{ padding: "8px" }}
                  >
                    Continue Shopping
                  </Button>
                </div>
              </div>
            ) : (
              <ResponsiveMasonry
                columnsCountBreakPoints={{
                  250: 1,
                  350: 2,
                  750: 2,
                  900: 3,
                  1000: 4,
                  1200: 5,
                }}
                gutter={0}
              >
                <Masonry>
                  {products?.map((item, key) => {
                    switch (item?.listingType) {
                      case "product":
                        return (
                          <Link
                            key={item._id}
                            to={`/productdetails/${paramCase(
                              item.productName
                            )}/${item._id}`}
                          >
                            <div
                              // onMouseEnter={() => showDetails(item._id)}
                              // onMouseLeave={() => removeDetails()}
                              onClick={() => addUserVisited(item._id)}
                              className="card masonry-card"
                            >
                              {item.isFeatured === "yes" && (
                                <div className="ribbon-featured">
                                  <div className="ribbon-start"></div>
                                  <div className="ribbon-content">Featured</div>
                                  <div className="ribbon-end">
                                    <figure className="ribbon-shadow"></figure>
                                  </div>
                                </div>
                              )}
                              <div className="wrapper-img">
                                <div className="img">
                                  <div className="slide-container">
                                    {show === item._id ? (
                                      <>
                                        <Fade {...zoomOutProperties}>
                                          {item.productThumbnailImages.map(
                                            (each, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="each-fade"
                                                >
                                                  <img
                                                    style={{
                                                      width: "100%",
                                                      height: "200px",
                                                    }}
                                                    src={each.imgurl}
                                                  />
                                                </div>
                                              );
                                            }
                                          )}
                                        </Fade>
                                      </>
                                    ) : (
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "200px",
                                        }}
                                        src={
                                          item.productThumbnailImages[0].imgurl
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-6 col-6 mt-4">
                                    <div className="m-price">
                                      {item.reviewsSummery.rating}&nbsp;
                                      <i className="fa fa-star clr-star"></i>
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-3 ">
                                    <WishListButton
                                      item={item._id}
                                      user={localStorage.getItem("userid")}
                                      getProducts={() => dispatch(fetchUserWishlist(localStorage.getItem("userid")))}
                                    />
                                  </div>
                                  <div className="col-md-3 col-3 ">
                                    <AddToCartButton pid={item._id} />
                                  </div>
                                </div>
                                <div className="m-meta">
                                  <a className="title m-indtitle text-capitalize">
                                    {item.productName}
                                  </a>
                                  <a href="/" className="m-category macategory">
                                    {item.productCategory[0]?.value}
                                  </a>
                                  <div>
                                    <div className="row">
                                      <div className="col-md-12 col-12">
                                        <span className="rupees">
                                          Rs.{item.grossPrice}&nbsp;
                                        </span>
                                        <span className="cross-mark">
                                          Rs. {item.offerSummary}{" "}
                                        </span>
                                        <span className="offersummary">
                                          (
                                          {Math.round(
                                            ((item.offerSummary -
                                              item.grossPrice) /
                                              item.offerSummary) *
                                              100
                                          )}
                                          % OFF)
                                        </span>
                                      </div>
                                      {/*<div className="col-md-6 col-6 ">
                                 
                                   {show != item._id ?
                                    <div><Button className='float-right n-price' style={{top:0}} color='link' onClick={e => showDetails(item._id)} >
                                      <i className='fa fa-angle-down '></i>  </Button></div>
                                    :
                                    <div>
                                      <Button className='float-right n-price' color='link' onClick={e => removeDetails()} >
                                        <i className='fa fa-angle-up'></i> </Button></div>} 
                                 
                                  
                                </div>*/}
                                    </div>
                                  </div>
                                </div>
                                {/* {show === item._id && (
                                  <div className="show-details">
                                    <div className="description">
                                      {item.productDescription}
                                    </div>
                                    <div className="m-additional-info">
                                      <ul>
                                        {item.productFeatures.map(
                                          (feature, index) => {
                                            if (feature.key && feature.value) {
                                              return (
                                                <li key={index}>
                                                  <figure
                                                    style={{
                                                      fontSize: "1.1rem",
                                                      fontWeight: "600",
                                                      color: "#000",
                                                    }}
                                                  >
                                                    {feature.key}
                                                  </figure>
                                                  <aside
                                                    style={{
                                                      fontSize: "1.1rem",
                                                      fontWeight: "100",
                                                    }}
                                                  >
                                                    {feature.value}
                                                  </aside>
                                                </li>
                                              );
                                            } else {
                                              return null;
                                            }
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </Link>
                        );
                      case "ads":
                        return <NewAdsCard key={key} />;
                      default:
                        return null;
                    }
                  })}
                </Masonry>
              </ResponsiveMasonry>
            )}
            {/* <div className="center">
              <a href="/" className="btn btn-primary btn-framed btn-rounded">
                Load More
              </a>
            </div> */}
          </div>
        </section>
      </section>
      <Footer />
    </div>
  );
}