import React, { useEffect, useState } from "react";
import "react-slideshow-image/dist/styles.css";
import { Button } from "reactstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css';
import LoadingOverlay from "react-loading-overlay";
import PageTitle from "./PageTitle";
import { callApi } from "../utils/Api";
import ProductCards from "./Shared/Cards/ProductCards";
import Footer from "../layouts/Footer";

export function OfferProductListing() {
    const { couponId } = useParams();
    const [couponProducts, setCouponProducts]  = useState([]);
    const [loading,setLoading]=useState(true)
    const [product, setProduct] = useState([]);
    const fetchCouponProducts = () => {
        callApi('post', 'cart/cartcrud/getYCouponProducts', { couponId: couponId })
            .then((res) => {
                setCouponProducts(res.data.bxgy.get);
                setLoading(false)
            }).catch((error)=>{
                setLoading(false)
            })
    }
    useEffect(() => {
        fetchCouponProducts();
    }, [])
    const getProducts=()=>{
        // callApi('post', 'product/getProduct/getProducts', { limit: 30, page: 1 }).then(response => {
        //     console.log(response);
        //     setProduct(response.data);
        // }).catch(err =>
        //     console.log(err)
        // );
    }
    return (
        <><LoadingOverlay
        active={loading}
        spinner
        text='Please wait for a moment...'
        fadeSpeed={100}
        >
            <div>
                <PageTitle />
            
            <section className="content">
                <section className="block">
                    <div className="container" style={{marginTop:"20px"}}>
                        {couponProducts.length === 0 ? (
                            <div style={{ textAlign: "center", marginTop: "20px" }}>
                                <h2>YOUR COUPON PRODUCT IS EMPTY</h2>
                                <p style={{ marginBottom: "10px" }}>
                                    Discover your favorites and add them to your wishlist!
                                </p>
                                <div style={{ marginTop: "40px" }}>
                                    <Button
                                        tag={Link}
                                        to="/"
                                        color="primary"
                                        style={{ padding: "8px" }}
                                    >
                                        Continue Shopping
                                    </Button>
                                </div>
                            </div>) : (
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{
                                    250: 1,
                                    350: 2,
                                    750: 2,
                                    900: 3,
                                    1000: 4,
                                    1200: 5,
                                }}
                                gutter={0}
                            >
                                <Masonry>
                                {couponProducts?.map((item, key) =>(
                                    <React.Fragment key={key}> <ProductCards item={item} getProducts={getProducts}/></React.Fragment>))}
                                </Masonry>
                            </ResponsiveMasonry>
                        )}
                    </div>
                </section>
            </section>
            <Footer/>
            </div>
            </LoadingOverlay>
        </>
    )
}
