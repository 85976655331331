// reducers/cartReducer.js
import { ADD_TO_CART, REMOVE_FROM_CART,FETCH_CART_REQUEST,FETCH_CART_SUCCESS, FETCH_CART_FAILURE } from '../actions/actionType';

const initialState = {
  loading:true,
  cart: [],
  error:""
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        cart: [...state.cart, action.payload],
      };
    case FETCH_CART_REQUEST:
      return {
        ...state,
        loading:true
      }
    case FETCH_CART_SUCCESS:
      return {
        loading:false,
        cart:action.payload,
        error:""
      }
    case FETCH_CART_FAILURE:
      return {
        loading:false,
        cart:[],
        error:action.payload
      }
    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.payload),
      };

    default:
      return state;
  }
};

export default cartReducer;
