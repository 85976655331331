import React from 'react'
import { Link } from 'react-router-dom';

export default function ShippingPolicy() {
  return (
    
    <div style={{marginLeft:"40px",marginRight:"40px",marginTop:"20px",marginBottom:"35px",textAlign:"justify",textJustify:"inter-word"}}>

        <h1 className="text-center">Shipping Policy</h1>
        <li>
        Delivery charges are item-specific.Please check your order summary to know the delivery charges for individual products.</li>
<li>
Sometimes the delivery dates do not correspond to the delivery timeline of the mentioned business days as it is possible that the Seller or our courier partners have a holiday between the day your placed your order and the date of delivery, which is based on the timelines shown on the product page. In this case, we add a day to the estimated date. Some courier partners and Sellers do not work on Sundays and this is factored in to the delivery dates.Business days exclude public holidays and Sundays.</li>
<li>Estimated delivery time depends on the following factors:</li>
<ul>
<li>
The Seller offering the product</li>
<li>Product's availability with the Seller</li>
<li>The destination to which you want the order shipped to and location of the Seller</li>
<li>The destination to which you want the order shipped to and location of the Seller</li>

</ul>

<li value="4">
There are NO hidden charges when you make a purchase on Mayifind. List prices are final and all-inclusive. The price you see on the product page is exactly what you would pay.
Delivery charges are not hidden charges and are charged (if at all) extra depending on the Seller's shipping policy.
</li>
   <li>The estimated delivery time vary for each seller as delivery times are influenced by product availability, geographic location of the Seller, your shipping destination and the courier partner's time-to-deliver in your location. </li>
   <li>Whether your location can be serviced or not depends on :</li>
   <ul>
    <li>Whether the Seller ships to your location</li>
    <li>Legal restrictions, if any, in shipping particular products to your location</li>
    <li>The availability of reliable courier partners in your location
At times Sellers prefer not to ship to certain locations. This is entirely at their discretion.</li>
    </ul>
   <li>Availability of Cash-on-Delivery (CoD) depends on the ability of our courier partner servicing your location to accept cash as payment at the time of delivery.
Our courier partners have limits on the cash amount payable on delivery depending on the destination and your order value might have exceeded this limit. Please enter your pin code on the product page to check if CoD is available in your location. </li>
   <li>Returns are easy. Contact Us to initiate a return. You will receive a call explaining the process, once you have initiated a return.</li>
    <li>In case the product was not delivered and you received a delivery confirmation email/SMS, report the issue within 7 days from the date of delivery confirmation for the seller to investigate.</li>
    <li>For items listed as "In Stock", Sellers will mention the delivery time based on your location pincode (usually 2-3 business days, 4-5 business days or 4-6 business days in areas where standard courier service is available). For other areas, orders will be sent by Registered Post through the Indian Postal Service which may take 1-2 weeks depending on the location.As of now, Mayifind doesn't deliver items internationally.</li>
    <li>You will be able to make your purchases on our site from anywhere in the world with valid credit/debit cards issued in India but the delivery address should be in India.</li>
    <br/>
<div className="text-center">
    <Link to="/" className="btn btn-primary btn-framed btn-rounded width-8">Home <span><i className='fa fa-home'></i></span></Link>
    </div>
<br/>
</div>
  )
}
