import React,{useEffect,memo,useState} from 'react'
import Footer from '../layouts/Footer'
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { Link,useParams,useNavigate } from 'react-router-dom';
import PageTitle from '../components/PageTitle'
import { callApi } from '../utils/Api';
import { useDispatch,useSelector } from "react-redux";
import { fetchUserCancleOrders } from '../actions/userCancleOrderAction';
import CancleOrderItems from './CancleOrderComponet/CancleOrderItems';
import RefundDetails from './CancleOrderComponet/RefundDetails';
const CancleOrder=()=> {
    const navigate=useNavigate()
    const userId=localStorage.getItem('userid')
    const dispatch=useDispatch()
    const userCancleOrder=useSelector(state=>state.userCancleOrder)
    console.log("userCancleOrder",userCancleOrder)
    const [cancleOrderData,setCancleOrderData]=useState(userCancleOrder.userCancleOrder)

    const getData=(key)=> {
        const itemStr = sessionStorage.getItem(key);
        if (!itemStr) {
            navigate("/myOrder")
            return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > item.expiration) {
            sessionStorage.removeItem(key);
            return null;
        }
        return item.value;
    }
    useEffect(()=>{
       let cancleOrderId= getData("cancleOrderId")//||"664ccfee44925530f8184f1d";
       if(userId && cancleOrderId){
        dispatch(fetchUserCancleOrders(userId,cancleOrderId))
       }
    },[])
    useEffect(() => {
        setCancleOrderData(userCancleOrder.userCancleOrder);
    }, [userCancleOrder]);
   
  return (<>
    <section className="h-100 gradient-custom w-100">
      <PageTitle/>
      <div className="container h-100">
            <div className="row">
                <div className="col-md-8 col-12">
                  <CancleOrderItems returnOrderData={cancleOrderData}/>  
                </div>
                <div className="col-md-4 col-12">
                    <div className="vline">
                      <RefundDetails returnOrderData={cancleOrderData} />                
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer/>
    </>
  )
}
export default memo(CancleOrder);
