import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
export default function ItemUnavailableForYourPinCode({availableForPinModal,toggleAvailableForPin,closeAvailableForPin,args}) {
    return (
        <>
          <Modal
            isOpen={availableForPinModal}
            toggle={toggleAvailableForPin}
            {...args}
          >
            <ModalHeader
              toggle={toggleAvailableForPin}
              close={closeAvailableForPin}
            >
              Item Unavailable in Your Area
            </ModalHeader>
            <ModalBody>
              <div class="shadow-sm p-2 bg-white rounded">
                <div class="card-body mt-3 ml-3 mb-2">
                  <ul>
                    <li>
                      <p>
                      We regret to inform you that the selected item is currently unavailable for delivery to your specified location.
                      </p>
                    </li>
                    <li>
                      <p>
                    We apologize for any inconvenience this may cause. 
                    Please consider choosing an alternative item or check back at a later time when the product becomes available for delivery to your pin code.
                      </p>
                    </li>
                  </ul>
                  {/* <p>Have a question?</p>
                    <p>For further information</p> */}
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      );
}
