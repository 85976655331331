/* eslint-disable */
import React, { useState } from 'react'
import PageTitle from '../components/PageTitle'
import Footer from '../layouts/Footer'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { callApi } from '../utils/Api';
import "react-step-progress-bar/styles.css";
import axios from 'axios';
var ObjectId = mongoose.Types.ObjectId;

function CartIcon(args) {
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const toggle = () => setModal(!modal);
    const [modal2, setModal2] = useState(false);
    const toggle2 = () => setModal2(!modal2);
    const [modal3, setModal3] = useState(false);
    const toggle3 = () => setModal3(!modal3);
    const [modal4, setModal4] = useState(false);
    const toggle4 = () => setModal4(!modal4);
    const [modal5, setModal5] = useState(false);
    const toggle5 = () => setModal5(!modal5);
    const toggle1 = () => setModal1(!modal1);


    const { id } = useParams();

    const [cartProduct, setCartProduct] = useState([]);


    //product state variable
    const [productList, setProductList] = useState([]);
    const [product, setProduct] = useState({});
    console.log("Product********", product)

    //get specific product state variable
    const [productUpdateId, setProductUpdateId] = useState('');
    const [specificProduct, setSpecificProduct] = useState([]);
    const [productQuantity, setProductQuantity] = useState(null);
    const [productSize, setProductSize] = useState('');
    const [productColor, setProductColor] = useState('');
    const [productId, setProductId] = useState(null);
    //api call for get all cart product

    // const updateCartDataInLocalStorage = (data) => {
    //     localStorage.setItem('DataProductListNum', JSON.stringify(data));
    //   }
      
    const getCartProduct = () => {
        callApi('post', 'cart/cartcrud/getCartData', { user: localStorage.getItem('userid') })
            .then((res) => {
                let arr = [];
                res.data.map((item) => {
                    console.log("Item For Product Data", item)
                    arr.push(item.product._id);
                })
                // console.log("---------------------------------------------", res.data)
                localStorage.setItem('cartProductt', JSON.stringify(res.data));
                setProductList(arr);
                setCartProduct(res.data);
                updateCartDataInLocalStorage(res.data);

                // console.log("Set cart Product",res.data)
            }).catch((err) => {
                console.log(err);
            })
    }
    console.log("Data For Product List", productList)
    // localStorage.setItem('DataProductList', JSON.stringify(productList));

    //api call for get all Order Of User
    // const getMyOrder = () => {
    //     callApi('post', 'cart/cartcrud/getUserWiseOrder', { user: localStorage.getItem('userid') })
    //     .then((res) => {
    //         let arr = [];
    //         res.data.map((item) => {
    //             console.log("item",item)
    //             arr.push(item.product._id);
    //         })
    //         console.log("arr",arr)
    //         setProductList(arr);
    //         setCartProduct(res.data);
    //     }).catch((err) => {
    //         console.log(err);
    //     })
    // }
    // useEffect(() => {
    //     getMyOrder();
    // }, [])

    // api call for Product Data

    const productData = () => {
        let productData = {};
        if (ObjectId.isValid(productId) && productId !== "") {
            axios.post(`${apiUrl}/product/getProduct/getProductByid`, { productId: productId }).then((response) => {
                console.log("Product Data For Cart Button",response);
                productData = response.data.data.productData;
                setProduct(productData);
                setSpecificationLess(productData.productFeatures.slice(0, 10));
                setSpecificationMore(productData.productFeatures);
                let img = [];
                productData.productImages?.map((item, index) => {

                    img.push({ src: item.imgurl, width: 4, height: 3 });

                })
                let size = [];
                let color = [];
                productData.productVarient?.filter(
                    (item) => {
                        if (item.key == 'Size') {
                            size.push(item.value);

                        } else {
                            color.push(item.value);

                        }
                    }
                )
                setSize(size);
                setColor(color);
                setImg(img);

            }).catch((error) => {
                console.log(error);
            }
            );
        }
    }
    useEffect(() => {

        productData();
    }, [productId]);
    useEffect(() => {
        setProductId(id);
    }, [id]);

    console.log("Product", product)

    //api call for remove cart product
    const removeCartProduct = (id) => {
        callApi('post', 'cart/cartcrud/removefromcart', { user: localStorage.getItem('userid'), product: id })
            .then((res) => {
                console.log(res.data);
                getCartProduct();
                priceCal();
                updateCartDataInLocalStorage(cartProduct);
            }).catch((err) => {
                console.log(err);
            })
    }
    useEffect(() => {
        getCartProduct();
        // getAllAddress();
    }, [])

    //api call for get specific product
    const getSpecificProduct = () => {
        callApi('post', 'cart/cartcrud/getCart', { id: productUpdateId })
            .then((res) => {
                setSpecificProduct(res.data);
                setProductQuantity(res.data[0].quantity);
                setProductSize(res.data[0].size);
                setProductColor(res.data[0].colour);
            }).catch((err) => {
                console.log(err);
            })
    }
    useEffect(() => {
        getSpecificProduct();
    }, [productUpdateId])

    //api call for update cart product
    const updateCartProduct = (id) => {
        const data = {
            id: id,
            quantity: productQuantity,
            size: productSize,
            colour: productColor
        }
        callApi('post', 'cart/cartcrud/updateCart', data)
            .then((res) => {
                console.log(res);
                getCartProduct();
                priceCal();
                updateCartDataInLocalStorage(cartProduct);
            }
            ).catch((err) => {
                console.log(err);
            }
            )
    }

    //custom close button for modal
    const closeBtn = (<button className="close" style={{ fontSize: "25px" }} onClick={toggle5} type="button">
        &times;
    </button>);
    const closeBtn1 = (<button className="close" style={{ fontSize: "25px" }} onClick={toggle} type="button">
        &times;
    </button>);
    const closeBtn2 = (<button className="close" style={{ fontSize: "25px" }} onClick={toggle2} type="button">
        &times;
    </button>);
    const closeBtn3 = (<button className="close" style={{ fontSize: "25px" }} onClick={toggle3} type="button">
        &times;
    </button>);
    const closeBtn4 = (<button className="close" style={{ fontSize: "25px" }} onClick={toggle1} type="button">
        &times;
    </button>);

    let [arrOfId, setArrOfId] = useState([]);


    const setArraofId = () => {
        let arrId = [];
        for (let index = 0; index < cartProduct.length; index++) {
            const element = cartProduct[index];

            arrId.push(element.product._id);

        }
        console.log("test cart => ", arrId)
        setArrOfId(arrId);
    }
    useEffect(() => {
        setArraofId();
    }, [cartProduct])


    return (
        <>
            <PageTitle />
            <section className="content" >
                <section className="block" >


                    <div className="container">
                        <div className="mt-4"></div>
                        <div className="row">
                            <div className="col-md-2"></div>

                            <div className="col-md-2"></div>
                        </div>



                        <div className="row">
                            <div className="col-md-8 col-12">


                                {cartProduct.map((item, index) => {
                                    return (
                                        <div class="card shadow-sm mb-4 p-2 bg-white rounded">
                                            <div class="card-body mt-3 ml-3 mb-2">
                                                <div className='row mt-2 mb-2'>
                                                    <div className='col-md-2 col-12 text-center'>
                                                        <img src={item.product.productThumbnailImages[0].imgurl} className="image-product-main" alt="" />
                                                    </div>
                                                    <div className='col-md-10 col-12 '>
                                                        <div className="ml-3">
                                                            <Button color='link' onClick={() => { removeCartProduct(item.product?._id) }} className='float-right' style={{ backgroundColor: "transparent", color: "#ff0000", fontSize: "12px", marginTop: "-15px", textDecoration: "none" }} >
                                                                <i class="fa fa-close fa-2x"></i>
                                                            </Button>
                                                            <Button color='link' onClick={(e) => { toggle5(), setProductUpdateId(item._id) }} key={index} className='float-right' style={{ backgroundColor: "transparent", color: "#000", fontSize: "10px", marginTop: "-15px", textDecoration: "none" }} >
                                                                <i class="fa fa-edit fa-2x"></i>
                                                            </Button>
                                                            <h4 className='p-details' style={{ fontWeight: "600" }}>{item.product?.merchant?.merchantCompanyName}</h4>
                                                            <h4 className='p-details' style={{ fontWeight: "200", marginTop: "-10px" }}>{item.product?.productName}</h4>
                                                            <h4 className='p-details' style={{ fontWeight: "100", marginTop: "-10px", fontSize: "12px" }}>Sold by: {item.product?.user?.firstName} {item.product?.user?.lastName}</h4>
                                                            <div className="row">
                                                                <div className="col-md-4 col-4"><h4 className='p-details' style={{ fontWeight: "100", marginTop: "-10px", fontSize: "12px" }}>Size: {item.size}</h4></div>
                                                                <div className="col-md-4 col-4"><h4 className='p-details' style={{ fontWeight: "100", marginTop: "-10px", fontSize: "12px" }}>Color:  <Button onClick={() => { }} className="colour-button ml-2" style={{ borderRadius: "50%", width: "5px", height: "5px", backgroundColor: item.colour }} >  </Button> </h4></div>
                                                                <div className="col-md-4 col-4"><h4 className='p-details' style={{ fontWeight: "100", marginTop: "-10px", fontSize: "12px" }}>Quantity: {item.quantity}</h4></div>
                                                                <div className="col-md-3"></div>
                                                            </div>
                                                            <h4 className='amount-p p-details ' style={{ fontWeight: "600" }}> <i class=" ml-2 fa fa-inr mr-2"></i>{item.product?.grossPrice} <span className='product-cross'><i class="  fa fa-inr mr-2" ></i> <span className="cross-p" >{item.product.offerSummary}</span></span>  </h4>

                                                            <h4 className='p-details mt-2' style={{ fontWeight: "100", marginTop: "-10px", fontSize: "12px" }}><i class="fa fa-check mr-3" aria-hidden="true" style={{ color: "#ff0000" }}></i>Delivery by {item.estimatedDeliveryDate}</h4>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })}
                                <Modal isOpen={modal5} toggle={toggle5} {...args}>
                                    <ModalHeader toggle={toggle5} close={closeBtn}>Update Product Description</ModalHeader>
                                    <ModalBody>
                                        <div class="card shadow-sm p-2 bg-white rounded">
                                            <div class="card-body  ml-2 mb-4">
                                                {specificProduct[0]?.product?.productVarient?.map((it, index) => {

                                                    return (
                                                        <>
                                                            <h1 className='mb-4 mt-4 ml-2' style={{ fontSize: "20px", fontWeight: "400" }}>SELECT {it.key.toUpperCase()}<i class="fa fa-angle-right" style={{ paddingLeft: "10px" }} aria-hidden="true"></i></h1>

                                                            {it?.key?.toUpperCase() === "SIZE" ?
                                                                it?.value?.map((i, index) => {

                                                                    return (

                                                                        <Button onClick={() => { setProductSize(i) }} className="size-button ml-2"  >
                                                                            {productSize === i ? <span style={{ color: "#ff0000", borderRadius: "50%" }}>{i}</span> : <span style={{ color: "#000", borderRadius: "50%" }}>{i}</span>}

                                                                        </Button>
                                                                    )

                                                                })
                                                                :
                                                                null}
                                                            {it?.key?.toUpperCase() === "COLOUR" ?
                                                                it?.value?.map((i, index) => {
                                                                    return (<>
                                                                        {productColor === i ? <Button onClick={() => { setProductColor(i) }} className="colour-button ml-3" style={{ border: "2px solid #ff0000", borderRadius: "50%", backgroundColor: i }} >  </Button> : <Button onClick={() => { setProductColor(i) }} className="colour-button ml-3" style={{ borderRadius: "50%", backgroundColor: i }} >  </Button>}
                                                                    </>)
                                                                })
                                                                :
                                                                null}
                                                        </>

                                                    )
                                                })}
                                                <h1 className='mt-4 mb-4 ml-2' style={{ fontSize: "20px", fontWeight: "200" }}>SELECT QUANTITY<i class="fa fa-angle-right" style={{ paddingLeft: "10px" }} aria-hidden="true"></i></h1>

                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <Button onClick={(e) => { productQuantity > 0 ? setProductQuantity(productQuantity - 1) : setProductQuantity(productQuantity) }} className="size-button ml-2"  >
                                                            <span style={{ color: "#000", borderRadius: "50%" }}>-</span>
                                                        </Button>
                                                        <Button onClick={() => { }} className="size-button ml-2"  >
                                                            <span style={{ color: "#000", borderRadius: "50%" }}>{productQuantity}</span>
                                                        </Button>
                                                        <Button onClick={(e) => { setProductQuantity(productQuantity + 1) }} className="size-button ml-2"  >
                                                            <span style={{ color: "#000", borderRadius: "50%" }}>+</span>
                                                        </Button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={() => { updateCartProduct(specificProduct[0]?._id) }} outline className='float-right width-100' style={{ color: "#ff0000", fontSize: "12px", backgroundColor: "transparent", border: "1px solid #ff0000", textDecoration: "none" }} >
                                            Update
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                                <br />


                                <br /><br />
                            </div>

                        </div>

                    </div>
                </section>
            </section>

            <Footer />
        </>
    )
}

export default CartIcon