import { ADD_TO_USER_VISITED,REMOVE_FROM_USER_VISITED,FETCH_USER_VISITED_REQUEST,FETCH_USER_VISITED_SUCCESS,FETCH_USER_VISITED_FAILURE } from "../actions/actionType";
const initialState={
    loading:true,
    userVisited:{},
    error:""
};
const userVisitedReducer=(state=initialState, action)=>{
    switch(action.type) {
        case ADD_TO_USER_VISITED:
            return{
                ...state,
                userVisited: action.payload,
            };
        case FETCH_USER_VISITED_REQUEST:
            return {
                ...state,
                loading:true
            };
        case FETCH_USER_VISITED_SUCCESS:
            return{
                loading: false,
                userVisited: action.payload,
                error:'',
            };
        case FETCH_USER_VISITED_FAILURE:
            return{
                loading:false,
                userVisited:{},
                error: action.payload
            };
        case REMOVE_FROM_USER_VISITED:
            return {
                ...state,
                userVisited: state.userVisited
            };
        default:
            return state;
}
};
export default userVisitedReducer;