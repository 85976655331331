import React from "react";
import { useState, useEffect } from "react";
import { UncontrolledPopover, PopoverBody, PopoverHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { apiUrl } from "../../constants/defaultValue";
import { useNavigate } from "react-router-dom";
import { callApi } from "../../utils/Api";
import { CiUser, CiHeart, CiCreditCard1, CiBellOn } from "react-icons/ci";
import { PiShoppingCartThin } from "react-icons/pi";
import UserAccount from "./UserAccount";
import { fetchUserCart, fetchCartRequest, fetchCartSuccess, fetchCartFailur } from "../../actions/cartActions";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserWishlist, fetchWishlistRequest, fetchWishlistSuccess, fetchWishlistFailur } from "../../actions/wishlistAction";

export default function PrimaryNav() {
  const token = localStorage.getItem("token");
  // const [message, setMessage] = useState('');
  const [message, setMessage] = useState(localStorage.getItem("message") || "");
  // const [message, setMessage] = useState('');
  const [code, setCode] = useState("");
  const [userAccount, setUserAccount] = useState(false);
  const [success, setSuccess] = useState(false);
  const [cartProduct, setCartProduct] = useState([]);
  const [wishListProduct, setWishListProduct] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();
  const userReducCart = useSelector((state) => state.cart);
  const numItemsInCart = userReducCart?.cart ? userReducCart?.cart?.length : 0;
  const userReducWishlist = useSelector((state) => state.wishlist);
  const numItemInWishList = userReducWishlist?.wishlists ? userReducWishlist?.wishlists.length : 0;
  const navigate = useNavigate();
  // console.log("___________________________________________",cartProduct)
  const handleSignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("cartProductt");
    localStorage.removeItem("userid");
    localStorage.removeItem("UserData");
    localStorage.removeItem("DataProductListNumber");
    localStorage.removeItem("CourierAdd");
    localStorage.removeItem("cartData");
    localStorage.removeItem("Pincode");
    localStorage.removeItem("DataProductListNum");
    localStorage.removeItem("CartPrice");
    localStorage.removeItem("cartProductData");
    localStorage.removeItem("delivery_pincode");
    localStorage.removeItem("selectedCourierPrice");
    localStorage.removeItem("selectedCourier");
    localStorage.removeItem("Pincode");
    localStorage.removeItem("paymentDetails");
    localStorage.removeItem("wish_list_p")
    localStorage.removeItem("paymentDetails");
    localStorage.removeItem("CartPrice");
    localStorage.removeItem('coupon_d');
    localStorage.removeItem('serv_char');
    navigate("/");
    window.location.reload();
  };

  const handleResponse = (response) => {
    if (response.message === "Created successfully") {
      setMessage(response.message);
      localStorage.setItem("message", response.message);
    }
  };

  //   useEffect(() => {
  //     if (message === 'Created successfully') {
  //       console.log('Hiding sign up button');
  //     }
  //   }, [message]);
  const getCartProduct = () => {
    dispatch(fetchCartRequest)
    callApi("post", "cart/cartcrud/getCartData", {
      user: localStorage.getItem("userid"),
    })
      .then((res) => {
        let arr = [];
        let cartData=res.data||[]
        cartData.map((item) => {
          // console.log("item", item);
          arr.push(item?.product?._id);
        });
        // console.log("arr", arr);
        // setProductList(arr);
        setCartProduct(cartData);
        dispatch(fetchCartSuccess(cartData));
        localStorage.setItem("cartProductt", JSON.stringify(res.data));

        // console.log("Cart Product Buy Now", res.data)
      })
      .catch((error) => {
        // console.log(error);
        dispatch(fetchCartFailur(error));
      });
  };
  const getWishlistProduct=()=>{
    // dispatch(fetchWishlistRequest)
    callApi('post','product/getProduct/getWishlistById', { user: localStorage.getItem("userid") })
    .then(res => {
      let wish_list_data=res.data;
      dispatch(fetchWishlistSuccess(wish_list_data));
      setWishListProduct(res.data)
      let wish_list_productId=wish_list_data.map(item=>item.product?._id)
      // console.log(wish_list_productId)
      localStorage.setItem("wish_list_p",JSON.stringify(wish_list_productId));
      })
    .catch(error => {
      console.log(error);
      dispatch(fetchWishlistFailur(error));
    })
  }
  // const getSpecialCoupons = () => {
  //   callApi('post', 'cart/cartcrud/getSpecialCoupons', { limit: 10, page: 1 })
  //     .then(res => {
  //       console.log('Special Coupons Response:', res); 
  //       if (res.data && res.data.specialCoupons) {
  //         setNotifications(res.data.specialCoupons);
  //       } else {
  //         console.log('No special coupons found in response');
  //       }
  //     })
  //     .catch(error => {
  //       console.log('Error fetching special coupons:', error);
  //     });
  // } 
  useEffect(() => {
    if (localStorage.getItem("userid")) {
      getCartProduct();
      getWishlistProduct();
      // getSpecialCoupons();
      // dispatch(fetchUserCart(localStorage.getItem("userid")))
      // dispatch(fetchUserWishlist(userId))
    }
    // const handleClickOutside = (e) => {
    //   if(userAccount && e.target.closest('.secondary-navigation')){
    //     setUserAccount(false)
    //   }
    // }
    // window.addEventListener('click', handleClickOutside);
    // return () => {
    //   window.removeEventListener('click', handleClickOutside);
    // };
    // const checkResponse = async () => {
    //   const response = await fetch(`${apiUrl}/users/userRegister`);
    //   const data = await response.json();
    //   if (data.code === 200 && data.message === "Created successfully") {
    //     setSuccess(true);
    //     setMessage(data.message);
    //   }
    //   console.log("A user Signup sucess and button Removal",response.message)
    // };
    // checkResponse();
  }, []);
  
  return (
    <div>
      <div className="secondary-navigation">
        <div className="container">
          <ul className="left">
            <li>
          <Link className="navbar-brand" style={{marginTop:"-10px",marginLeft:"20px"}} to="/">
            <img src="/assets/img/favicon.ico" className='logo-fav' alt=""/><br/>
            <span style={{marginLeft:"-23px"}}>M A Y <span style={{color:'#ff0000',fontWeight:'500'}}> I</span> F I N D</span>
            {/* <p className='text-uppercase' style={{ fontFamily:"Poppins",marginLeft:"-40px",fontSize:"9px",color:"#ff000f",fontWeight:"bold"}}>The 24/7 discount store </p> */}
            <p className='text-uppercase' style={{ fontFamily:"Poppins",marginLeft:"-24px",fontSize:"9px",color:"#ff000f",fontWeight:"bold"}}>FIND THE UNUSUAL</p>
          </Link>
          </li>
          </ul>
            {token ?<ul className="right">
            <li >
             <Link to="#" onClick={()=>setUserAccount(!userAccount)} id="PopoverLegacy">
              <CiUser size={30} />
              </Link>
              </li>
              <li>
              <Link to="/wishlist">
                <CiHeart size={30} />
              {numItemInWishList?(
                <span
                style={{
                  marginLeft:"20px",
                  marginTop:"-40px",
                  position:"relative",
                  background: "red",
                  borderRadius: "50%",
                  color: "white",
                  fontSize: "10px",
                  width: "15px",
                  height: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {numItemInWishList}
                </span>): null
              }
              </Link>
              </li>
              <li>
              <Link to="/myCart">
                <PiShoppingCartThin size={30} />
              {numItemsInCart ? (
                <span
                  style={{
                    marginLeft:"20px",
                    marginTop:"-40px",
                    position:"relative",
                    background: "red",
                    borderRadius: "50%",
                    color: "white",
                    fontSize: "10px",
                    width: "15px",
                    height: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {numItemsInCart}
                </span>
              ) : null}
              </Link>
            </li>
            {/* <li>
              <Link to="#" id="PopoverNotifications">
                <CiBellOn size={30} />
                {notifications.length ? (
                  <span
                    style={{
                      marginLeft: "20px",
                      marginTop: "-40px",
                      position: "relative",
                      background: "red",
                      borderRadius: "50%",
                      color: "white",
                      fontSize: "10px",
                      width: "15px",
                      height: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {notifications.length}
                  </span>
                ) : null}
              </Link>
            </li> */}
          </ul>
            : (
              <ul className="right">
                <li >
                  <Link to="#" onClick={()=>setUserAccount(!userAccount)} id="PopoverLegacy">
                    <CiUser size={30} /> 
                  </Link>
                </li>
              </ul>
            )}
           <UncontrolledPopover
    placement="bottom"
    target="PopoverLegacy"
    trigger="legacy"
    // offset={8}
    style={{
      boxShadow: '0 40px 80px 10px rgba(76, 73, 235, 0.3)', // Example box shadow
      border:'none',
    }}
    // style={{ marginRight: '10%',minWidth:"30%",padding:'5px'}}
  > <PopoverBody><UserAccount 
        handleSignOut={handleSignOut} 
        userAccount={userAccount} 
        token={token} 
        handleResponse={handleResponse} 
        onClose={()=>setUserAccount(false)} 
        /> 
        </PopoverBody>
        </UncontrolledPopover>
        {/* {notifications.length > 0 && (
          <UncontrolledPopover
            placement="bottom"
            target="PopoverNotifications"
            trigger="legacy"
            style={{
              boxShadow: '0 40px 80px 10px rgba(76, 73, 235, 0.3)', 
              border: 'none',
              width: '300px', 
              borderRadius: '10px', 
            }}
          >
            <PopoverHeader style={{ backgroundColor: '#007bff', color: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
              Latest Offers
            </PopoverHeader>
            <PopoverBody style={{ padding: '10px' }}>
              <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                {notifications.map((notification, index) => (
                  <li 
                    key={index} 
                    style={{ 
                      marginBottom: '10px', 
                      borderBottom: '1px solid #ddd', 
                      paddingBottom: '10px',
                      padding: '10px',
                      backgroundColor: '#f9f9f9', 
                      borderRadius: '5px', 
                      transition: 'background-color 0.3s', 
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'} 
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f9f9f9'} 
                  >
                    <Link to={`/offerdetails/${notification._id}`} style={{ textDecoration: 'none', color: 'black' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img 
                          src={notification.img} 
                          alt={notification.couponName} 
                          style={{ 
                            width: '50px', 
                            height: '50px', 
                            marginRight: '10px', 
                            borderRadius: '5px', 
                            objectFit: 'cover', 
                          }} 
                        />
                        <div>
                          <strong style={{ fontSize: '14px', color: '#333' }}>{notification.couponName}</strong>
                          <p style={{ margin: 0, fontSize: '12px', color: '#777' }}>{notification.couponCode}</p>
                          <p style={{ margin: 0, fontSize: '12px', color: '#777' }}>
                            {notification.couponVarient === 'flat' ? (
                              <>Flat ₹{notification.flatAmount} off</>
                            ) : (
                              <>{notification.percentage}% off</>
                            )}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </PopoverBody>
          </UncontrolledPopover>
        )} */}
        </div>
      </div>
      
    </div>
  );
}