import React, { useState } from 'react';
import StepNavigation from '../components/Shared/TrackOrder/StepNavigation';

export default function MyOrderTrackPage({orderData}){
  const [currentStep, setCurrentStep] = useState(0);
  function updateStep(newStep) {
    setCurrentStep(newStep);
  };

  return (
    <div className="order-track-page mt-3 ">
   <h3>Order Tracking</h3>
   <div className='row ml-2'>
    <StepNavigation labelArray={orderData} currentStep={currentStep} updateStep={updateStep}/>
   </div>
   </div>
  );
};

