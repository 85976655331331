import React from 'react'
import PrimaryNav from './NavBar/PrimaryNav'
import { Link ,useLocation} from 'react-router-dom'
import { Button } from 'reactstrap'
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { callApi } from '../utils/Api';
import { data } from 'jquery';
import SearchPopupModal from './NavBar/SearchPopupModal';

export default function PageTitle({word,pulldata}) {
    const location = useLocation();
    const [vendor, setVendor] = useState([])
    const [product, setProduct] = useState([])
    const [category, setCategory] = useState([])
    const [searchName, setSearchName] = useState('')
    // console.log(searchName);
    let navigate = useNavigate();
    const touch = (item) => {
        // console.log("item to be displyed => ", item)
        if (data) {
            navigate(`/productdetails/rr/${item._id}`)
        }
    }


    const searchVendor = (item) => {
        // console.log("vendor to be displyed => ", item)
        if (data) {
            navigate(`/sellerDetails/aj/${item.user._id}`)
        }
    }

    const findCategory = (item) => {
        // console.log("Category to be displyed => ", item)
        if (data) {
            navigate(`/sellerDetails/aj/${item.category}`)
        }
    }
    const searchValidator = (e) => {
        let serchNamelength=searchName.length
        if (serchNamelength > 0){
            callApi('post', `/product/getProduct/productSearch`, { productName: searchName,limit:10,page:1 }).then((res) => {
                // console.log("***ProductSearch***", res.data)
                setProduct(res.data);
            }
            ).catch((err) => {
                // console.log(err);
                setProduct([]);
            }
            )
            callApi('post', `/merchant/getMerchant/merchantSearch`, { merchantCompanyName: searchName }).then((res) => {
                // console.log("********MerChant*********", res.data)
                setVendor(res.data);
            }
            ).catch((err) => {
                // console.log(err);
                setVendor([]);
            }
            )
            callApi('post', `/category/curd/searchCategory`, { key: searchName }).then((res) => {
                // console.log(res.data)
                setCategory(res.data)
            }
            ).catch((err) => {
                // console.log(err);
                setCategory([])
            }
            )}
            if(serchNamelength===0) {
                setProduct([])
                setVendor([])
                setCategory([])
        }
    }
    const navigateHandel = () => {
        // pulldata(searchName)
        setProduct([]);
        setVendor([]);
        setCategory([]);
        navigate(`/productSearch/${searchName}`)       
    }
    const onClosedSearch=()=>{
        setProduct([]);
        setVendor([]);
        setCategory([]);
        setSearchName('');
    }
    // console.log("first",location.pathname)
  return (
    <div>
         <header className="hero">
                <div className="hero-wrapper">
                    <PrimaryNav />   
                <div>
                    <ol className="breadcrumb"><Link to='/' style={{ color: "black" }}>Home / {" "} </Link>
                        {location.pathname.split('/').filter((item) => !item == "").map((item, index) => (
                        // <li key={index} className="breadcrumb-item"> <a to='/'> {item.replace(/%/g, " ").replace(/-/g, ' ').substring(0, 40)}</a></li>
                        <li key={index} className="breadcrumb-item"> <a to='/'> {decodeURIComponent(item.substring(0, 40)).replace(/-/g,' ')}</a></li>
                        ))}
                    </ol>

                    <div className="row">
                        {/* Search bar for large screens */}
                        <div className="col-6"></div>
                        <div className="big-screen-search col-md-6 d-none order-md-last d-md-flex justify-content-end">
                        <div className="form-group d-flex align-items-center">
                            <div className="input-group">
                            <input
                                name="keyword"
                                type="text"
                                className="form-control form-control-sm"
                                id="what1"
                                placeholder="Search Product. . ."
                                style={{ height: '30px', maxWidth: '200px' }}
                                value={searchName} onChange={(e) => setSearchName(e.target.value)} onKeyUp={searchValidator}
                            />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-primary d-flex align-items-center" onClick={navigateHandel} style={{ height: '34px' }}>
                                <i className="fas fa-search"></i>
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>

                        {/* Search bar for small screens */}
                        <div className="col-md-6 order-md-1 d-flex justify-content-center d-md-none">
                        <div className="form-group d-flex align-items-center">
                            <div className="input-group">
                            <input
                                name="keyword"
                                type="text"
                                className="form-control form-control-sm"
                                id="what"
                                placeholder="Search Product. . ."
                                style={{ height: '30px', maxWidth: '200px' }}
                                value={searchName} onChange={(e) => setSearchName(e.target.value)} onKeyUp={searchValidator}
                            />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-primary d-flex align-items-center" onClick={navigateHandel} style={{ height: '34px' }}>
                                <i className="fas fa-search"></i>
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>              
            </div>
            {(product.length > 0 ) && searchName.length>0?<><SearchPopupModal searchName={searchName} product={product}  touch={touch} searchVendor={searchVendor} onClosedSearch={onClosedSearch}/></>:null}
            
        </header>
       
    </div>
  )
}
