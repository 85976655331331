/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { callApi } from '../../utils/Api';
import { addtoCart } from '../../actions/cartActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactNotifications, Store } from 'react-notifications-component';
export default function AddToCartButton({ args, pid }) {
    const [modal5, setModal5] = useState(false);
    const toggle5 = () => setModal5(!modal5);
    const closeBtn = (
        <button className="close" style={{ fontSize: "25px" }} onClick={toggle5} type="button">
            &times;
        </button>
    );
    const [productUpdateId, setProductUpdateId] = useState(pid);
    const [specificProduct, setSpecificProduct] = useState([]);
    const [productQuantity, setProductQuantity] = useState(1);
    const [productSize, setProductSize] = useState('');
    const [productColor, setProductColor] = useState('');
    const dispatch=useDispatch();
    const navigate = useNavigate();
    
    const getSpecificProduct = () => {
        callApi('post','cart/cartcrud/getProductbyProductId', { id: productUpdateId }).then((res) => {
            // console.log(res.data);
            setSpecificProduct(res.data);
            const sizes = res.data[0]?.productVarient.find(it => it.key.toUpperCase() === "SIZE")?.value || [];
            const colors = res.data[0]?.productVarient.find(it => it.key.toUpperCase() === "COLOR" || it.key.toUpperCase() === "COLOUR")?.value || [];
            if (sizes?.length > 0) setProductSize(sizes[0]);
            if (colors?.length > 0) setProductColor(colors[0]); 
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
    }, [productUpdateId])

    const addToCartHandeler = (e) => {
        e.preventDefault();
        if(!localStorage.getItem('userid')){
            navigate('/login');
            return
        }
        toggle5();
        getSpecificProduct();
    }

    const addToCart = (id) => {
        if (specificProduct[0]?.productVarient?.some(v => v.key.toUpperCase() === "COLOR" || v.key.toUpperCase() === "COLOUR") && !productColor) {
            Store.addNotification({
                title: "Please select a color",
                message: "Color selection is required for this product",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            return;
        }
        if (specificProduct[0]?.productVarient?.some(v => v.key.toUpperCase() === "SIZE") && !productSize) {
            Store.addNotification({
                title: "Please select a size",
                message: "Size selection is required for this product",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            return;
        }
    
        let data = {
            product: id,
            user: localStorage.getItem('userid'),
            quantity: productQuantity,
            size: productSize,
            colour: productColor,
            merchentId: specificProduct[0]?.merchant?._id,
        }
        callApi('post', 'cart/cartcrud/addtoCart', data).then((response) => {
            localStorage.setItem('cartProduct', JSON.stringify(response));
            // setNumItemsInCart(prevNumItems => prevNumItems + 1);
            if(response.status==="Cart Added Successfully"){
                // console.log("Add to cart Cart Btn",response);
                dispatch(addtoCart(response.data));
                Store.addNotification({
                    title: "Added to Cart",
                    message: "The product has been added to your cart",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
                toggle5()
                getSpecificProduct();
            }
        }).catch((error) => {
            console.log(error);
            Store.addNotification({
                title: "Error",
                message: "There was an error adding the product to your cart",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        });
    }
    return (
        <>
            <Button color='link' onClick={(e) => { addToCartHandeler(e) }} className='float-right n-price'  >
                <i className='fa fa-cart-plus'></i>
            </Button>
            <Modal isOpen={modal5} toggle={toggle5} {...args}>
                <ModalHeader toggle={toggle5} close={closeBtn}>ADD TO CART</ModalHeader>
                <ModalBody>
                    <div class="card shadow-sm p-2 bg-white rounded">
                        <div class="card-body  ml-2 mb-4">
                            {specificProduct[0]?.productVarient?.map((it, index) => {
                                return (
                                    <React.Fragment key={index}>
                                       {it?.key!==''?<h1 className='mb-4 mt-4 ml-2' style={{ fontSize: "20px", fontWeight: "400" }}>SELECT {it.key.toUpperCase()}<i className="fa fa-angle-right" style={{ paddingLeft: "10px" }} aria-hidden="true"></i></h1>:null}
                                        {it?.key?.toUpperCase() === "SIZE" ? 
                                            it?.value?.map((i, index) => {
                                                return (
                                                    <Button onClick={() => { setProductSize(i) }} className="size-button ml-2"  >
                                                        {productSize === i ? <span style={{ color: "#ff0000", borderRadius: "50%" }}>{i}</span> : <span style={{ color: "#000", borderRadius: "50%" }}>{i}</span>}
                                                    </Button>
                                                )
                                            })
                                            :
                                            null}
                                         {(it?.key?.toUpperCase() === "COLOR" || it?.key?.toUpperCase() === "COLOUR") && (
                                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                                        {it?.value?.map((color, index) => {
                                            const colorImage = specificProduct[0]?.productImages?.find(image =>
                                                Array.isArray(image?.varient) && image.varient.some(variant => variant.label === color)
                                            );
                                            return (
                                                <Button 
                                                    key={index} 
                                                    onClick={() => { setProductColor(color); }} 
                                                    className="colour-button ml-3" 
                                                    style={{ 
                                                        borderRadius: "0", 
                                                        padding: "0", 
                                                        margin: "0px", 
                                                        border: productColor === color ? '2px solid #ff0000' : 'none'
                                                    }}
                                                >
                                                    {colorImage ? (
                                                        <img src={colorImage.imgurl} alt={color} style={{ width: "100%", 
                                                            height: "100%", 
                                                            objectFit: "cover",
                                                            borderRadius: "0px" }} />
                                                    ) : (
                                                        <span style={{ borderRadius: "0", backgroundColor: color, width: "40px", height: "40px", display: "inline-block" }} />
                                                    )}
                                                </Button>
                                            );
                                        })}
                                    </div>
                                )}
                                    </React.Fragment>
                                )
                            })}
                            <h1 className='mt-4 mb-4 ml-2' style={{ fontSize: "20px", fontWeight: "200" }}>SELECT QUANTITY<i className="fa fa-angle-right" style={{ paddingLeft: "10px" }} aria-hidden="true"></i></h1>

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <Button onClick={(e) => { productQuantity > 1 ? setProductQuantity(productQuantity - 1) : setProductQuantity(productQuantity) }} className="size-button ml-2"  >
                                        <span style={{ color: "#000", borderRadius: "50%" }}>-</span>
                                    </Button>
                                    <Button onClick={() => { }} className="size-button ml-2"  >
                                        <span style={{ color: "#000", borderRadius: "50%" }}>{productQuantity}</span>
                                    </Button>
                                    <Button onClick={(e) => { setProductQuantity(productQuantity + 1) }} className="size-button ml-2"  >
                                        <span style={{ color: "#000", borderRadius: "50%" }}>+</span>
                                    </Button>

                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => { addToCart(specificProduct[0]?._id) }} outline className='float-right width-100' style={{ color: "#ff0000", fontSize: "12px", backgroundColor: "transparent", border: "1px solid #ff0000", textDecoration: "none" }} >
                        ADD
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
